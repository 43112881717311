import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Input } from "@mui/material";
import { useSelector } from "react-redux";
import { uploadNewEvidence } from "../../../../../../store/slices/vulnerabilityReducer";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { dispatch, RootState } from "src/store";
import {
  AddEvidenceOnVulnerabilityPageProps,
  EvidenceItem,
} from "../../vulnerabiltyTypes";

const AddEvidenceOnVulnerabilityPage: React.FC<
  AddEvidenceOnVulnerabilityPageProps
> = ({
  handleClose,
  clientOrgId,
  setBtnLoading,
  // btnLoading,
  evidenceName,
  setEvidenceName,
  setEvidenceList,
  evidenceList,
  submitEvidence,
}) => {
  const [batchFiles, setBatchFiles] = useState<File[]>([]);
  const { addVulnerabilityRepose } = useSelector(
    (state: RootState) => state?.vulnerabilityState
  );
  // const dispatch = useDispatch();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setBatchFiles(files);
    event.target.value = ""; // Reset the file input to allow re-uploading the same file
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvidenceName(event.target.value);
  };

  const handleAddEvidenceInList = () => {
    const newEvidence: EvidenceItem[] = batchFiles.map((file) => ({
      evidenceName: evidenceName || file.name,
      file,
      uploadStatus: null,
    }));

    setEvidenceList((prevList: any) => [...prevList, ...newEvidence]);
    setEvidenceName("");
    setBatchFiles([]);
  };

  const handleSubmitEvidence = () => {
    setBtnLoading(true);

    evidenceList?.forEach((evidence, index) => {
      const formData: any = new FormData();
      formData?.append("vulId", addVulnerabilityRepose?.scanVulnerabilityDTO?.scanVulnerabilityId);
      formData?.append("title", evidence.evidenceName);
      formData?.append("clientOrgId", clientOrgId);
      formData?.append("file", evidence.file);

      dispatch(uploadNewEvidence(formData))
        .then((res: any) => {
          if (res?.status === 200) {
            setEvidenceList((prevList: EvidenceItem[]) =>
              prevList?.map((item: any, i: any) => {
                // item and i now have proper types
                return i === index
                  ? { ...item, uploadStatus: "success" }
                  : item;
              })
            );
          } else {
            setEvidenceList((prevList: EvidenceItem[]) =>
              prevList.map((item, i) => {
                // item and i now have proper types
                return i === index ? { ...item, uploadStatus: "error" } : item;
              })
            );
          }
        })
        .catch(() => {
          setEvidenceList((prevList: EvidenceItem[]) =>
            prevList.map((item, i) => {
              // item and i now have proper types
              return i === index ? { ...item, uploadStatus: "error" } : item;
            })
          );
        })
        .finally(() => {
          setBtnLoading(false);
        });
    });
  };

  const handleRemoveEvidence = (indexToRemove: number) => {
    setEvidenceList((prevList: EvidenceItem[]) =>
      prevList.filter((item, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    if (submitEvidence) handleSubmitEvidence();
  }, [submitEvidence]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Title</InputLabel>
          <TextField
            name="evidenceName"
            id="evidenceName"
            fullWidth
            placeholder="Enter Title"
            onChange={handleChange}
            value={evidenceName}
            disabled={submitEvidence}
            autoComplete="off"
          />
        </Grid>
        {!submitEvidence && (
          <Grid textAlign="center" item xs={12}>
            <Input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              inputProps={{
                multiple: true,
                accept: "image/*",
              }}
            />
            <label htmlFor="fileInput">
              <Button component="span" variant="outlined" color="primary">
                Choose File
              </Button>
            </label>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            {!submitEvidence && (
              <Grid item>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            )}
            <Grid item>
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={handleAddEvidenceInList}
                disabled={batchFiles?.length === 0}
              >
                Add
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                disabled={evidenceList?.length === 0}
              >
                {submitEvidence ? "Done" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {evidenceList?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Evidence List:</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Preview</TableCell>
                    <TableCell>Status</TableCell>
                    {!submitEvidence && <TableCell>Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {evidenceList.map((evidence, index) => (
                    <TableRow key={index}>
                      <TableCell>{evidence.evidenceName}</TableCell>
                      <TableCell>
                        <img
                          src={URL.createObjectURL(evidence.file)}
                          alt={`Evidence ${index + 1}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      </TableCell>
                      <TableCell>
                        {evidence.uploadStatus === "success" && (
                          <CheckCircleIcon color="success" />
                        )}
                        {evidence.uploadStatus === "error" && (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      {!submitEvidence && (
                        <TableCell>
                          <IconButton
                            onClick={() => handleRemoveEvidence(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AddEvidenceOnVulnerabilityPage;
