import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import BuildSharpIcon from "@mui/icons-material/BuildSharp";
import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";
import QrCodeScannerSharpIcon from "@mui/icons-material/QrCodeScannerSharp";

import {
  Chip,
  Grid,
  IconButton,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import { makeStyles, useTheme } from "@mui/styles";
import { IconFile, IconFileTypePdf } from "@tabler/icons-react";
import { NO_VALUE } from "../../../constant";
import { ASSET_TYPE } from "../../pages/consultant/assets/constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dispatch, useSelector } from "../../../store";
import { getScanStatusFilterList } from "../../../store/slices/clientScanReducer";
import { getRiskRatingValue } from "../../../store/slices/clientVulnerabilityReducer";

import {
  departmentDownloadVulReport,
  fetchDepartmentInsightData,
  fetchDepartmentPublishedScansList,
  fetchDepartmentPublishedVulnerabilitiesList,
  fetchDepartmentRiskComplianceScore,
} from "../../../store/slices/departmentReducer";
import {
  downloadScan,
  downloadScanSuccess,
  getScanSubType,
  getScanTypes,
} from "../../../store/slices/scanReducer";
import {
  getClientStatusValue,
  getConsultantStatusValue,
} from "../../../store/slices/vulnerabilityReducer";
import VmTable from "../../../ui-component/table";
import { downloadTemplateFile, getChipDesign } from "../../../utils/helpers";
import exportIcon from "../../../assets/images/export_icon.svg";
import GenericFilterBar from "../../../views/Common/genericFilterBar";
import GenericScanFilterBar from "../../../views/Common/genericScanFilterBar";
import { pendingWithScanFilterCode } from "../../../views/pages/client/scan/constant";
import CustomCircularProgressWithLabel from "../../pages/common/dashboard/graphs/progressBar";
import { SCAN_TYPE_DETAIL } from "../../pages/consultant/assets/constant";
import AssetDetailPiechart from "../../../views/pages/consultant/assets/groupAssets/assetGroupGraphs/assetDetailPie";
import { rowsPerPageOptions } from "../../pages/consultant/scan/constant";
import theme from "src/themes/theme";
import BackButtonCustom from "src/ui-component/backButton/backButton";

const DepartmentInsights = () => {
  const themeMode = theme.palette.mode;
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [assetDetailTab, setAssetDetailTab] = useState<string>("insight");
  const [scanType, setScanType] = useState(ASSET_TYPE.ALL);
  const [page, setPage] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const isDarkMode = theme.palette.mode === "dark";
  const [exportData, setExportData] = useState(false);
  const [vulnerabilityName, setVulnerabilityName] = useState<any>("");
  const {
    departmentRiskComplianceScore,
    departmentPublishedVulnerabilitiesList,
    departmentPublishedScansList,
    departmentDownloadVulReportRes,
    departmentInsightData,
  } = useSelector((state) => state?.departmentState);
  const departmentId = param?.id;
  const clientOrgId = location?.state;

  const [dateFilter, setDateFilter] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState<any>({
    assetType: "",
    assetSubType: "",
    assetId: "",
  });

  const [selectedConsultantStatus, setSelectedConsultantStatus] = useState<any>(
    []
  );
  const [selectedClientStatus, setSelectedClientStatus] = useState<any>([]);
  const [selectedRiskRating, setSelectedRiskRating] = useState<any>([]);
  const [filterApplied, setFilterApplied] = useState<any>(false);
  const handleGoBack = () => {
    navigate(-1);
  };
  const { scanSubTypes, scanTypes, downloadScanData } = useSelector(
    (state) => state?.scansState
  );
  const [selectedScanMode, setSelectedScanMode] = useState<any>({
    scanModeCode: "",
    scanModeDesc: "", // scanModeDesc
  });
  const [scanFilter, setScanFilter] = useState<any>({
    scanType: "",
    scanSubType: "",
    scanName: "",
    scanRefNo: "",
  });
  const [createdOnFilter, setCreatedOnFilter] = useState<any>({
    startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [publishedOnFilter, setPublishedOnFilter] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [selectedPendingOption, setSelectedPendingOption] = useState<any>({
    client: null,
    consultant: null,
  });
  const [selectedScanStatus, setSelectedScanStatus] = useState([]);

  const { scanStatusFilterList } = useSelector(
    (state) => state?.clientScansState
  );

  const { clientStatusValue, consultantStatusValue } = useSelector(
    (state) => state?.vulnerabilityState
  );

  const { riskRatingValue } = useSelector(
    (state) => state?.clientVulnerabilityReducerState
  );

  const HandleEditAsset = () => {
    navigate(`/${location?.state}/assets/edit`, { state: param?.assetId });
  };

  const handleVulnerabilitiesExport = () => {
    const payload = {
      departmentId: Number(departmentId),
      clientOrgId,
    };
    dispatch(departmentDownloadVulReport(payload)).then(() => {
      setExportData(true);
    });
  };

  useEffect(() => {
    if (Object.keys(departmentDownloadVulReportRes)?.length > 0 && exportData) {
      const { fileContent, fileName } = departmentDownloadVulReportRes;
      downloadTemplateFile(fileContent, fileName);
    }
  }, [exportData]);

  useEffect(() => {
    const payload = {
      departmentId: Number(departmentId),
      clientOrgId,
      page,
      size: rowsPerPage,
      //   scanType: scanType,
    };
    if (assetDetailTab === "vulnerabilities") {
      dispatch(fetchDepartmentPublishedVulnerabilitiesList(payload));
    } else if (assetDetailTab === "scans") {
      dispatch(fetchDepartmentPublishedScansList(payload));
    } else if (assetDetailTab === "insight") {
      dispatch(
        fetchDepartmentInsightData({
          departmentId: Number(departmentId),
          clientOrgId,
        })
      );
      dispatch(
        fetchDepartmentRiskComplianceScore({
          departmentId: Number(departmentId),
          clientOrgId,
        })
      );
    }
  }, [assetDetailTab, scanType, rowsPerPage, page]);

  const iconStyle = { paddingRight: "3px" };
  const vulColumns = [
    {
      accessorKey: "vulnerabilityName",
      header: "Vulnerability Name",
      size: 200,
      Cell: ({ row }: { row: { original: { vulnerabilityName: string } } }) => (
        <>
          {row?.original?.vulnerabilityName ? (
            <span title={row.original.vulnerabilityName}>
              {row.original.vulnerabilityName}
            </span>
          ) : (
            "NA"
          )}
        </>
      ),
    },
    {
      accessorKey: "owaspCategoryDesc",
      header: "Owasp Category",
      size: 150,
    },
    {
      accessorKey: "riskRatingName",
      header: "Risk Rating",
      Cell: ({ row }: { row: { original: { riskRatingName: string } } }) => {
        return row?.original?.riskRatingName ? (
          <Chip
            size="small"
            label={row?.original?.riskRatingName}
            variant="outlined"
            sx={{
              color:
                getChipDesign(row?.original?.riskRatingName, themeMode)
                  ?.color || "black",
              border:
                "1.5px solid " +
                getChipDesign(row?.original?.riskRatingName, themeMode)?.color,
            }}
          />
        ) : (
          ""
        );
      },
    },
    {
      accessorKey: "scanTypeName",
      header: "Scan Type",
      Cell: ({ row }: any) => (
        <>
          {row?.original?.scanTypeName ? row?.original?.scanTypeName : NO_VALUE}
        </>
      ),
    },
    {
      accessorKey: "consultantStatusName",
      header: "Consultant Status",
      size: 250,
    },
    {
      accessorKey: "clientStatusName",
      header: "Client Status",
      size: 250,
    },
  ];
  const scanColumns = [
    {
      accessorKey: "organisationName",
      header: "Client Organization",
      size: 150,
    },
    {
      accessorKey: "scanName",
      header: "Scan Name",
      size: 100,
      Cell: ({ row }: { row: { original: { scanName: string } } }) => (
        <>
          {row?.original?.scanName ? (
            <span title={row.original.scanName.trim()}>
              {row.original.scanName.trim()?.length >= 10
                ? `${row.original.scanName.trim().substring(0, 15)}...`
                : row.original.scanName}
            </span>
          ) : (
            NO_VALUE
          )}
        </>
      ),
    },
    {
      accessorKey: "scanReferenceNo",
      header: "Scan Ref Code",
      size: 150,
      muiTableBodyCellProps: ({
        row,
      }: {
        row: { original: { clientOrgId: string; scanDetailsId: string } };
      }) => ({
        onClick: () => {
          sessionStorage.setItem("clientOrgId", row?.original?.clientOrgId);
          if (localStorage.getItem("organizationType") === "CO") {
            navigate(`/scan/${row?.original?.scanDetailsId}`, {
              state: row.original.clientOrgId,
            });
          } else {
            navigate(`/scan-detail/${row?.original?.scanDetailsId}`, {
              state: row.original.clientOrgId,
            });
          }
        },
        sx: {
          cursor: "pointer",
          textDecoration: "underline",
        },
      }),
    },
    {
      accessorKey: "totalAsset",
      header: "Total Assets",
      size: 100,
      Cell: ({ row }: { row: { original: { totalAsset: string } } }) => (
        <Grid
          display={"flex"}
          alignItems={"center"}
          textAlign={"center"}
          justifyContent={"center"}
        >
          <span>{row?.original?.totalAsset}</span>
        </Grid>
      ),
    },
    {
      accessorKey: "scanModeDesc",
      header: "Scan Detail",
      size: 300,
      Cell: ({
        row,
      }: {
        row: {
          original: {
            scanModeDesc: string;
            scanCategoryDesc: string;
            status: string;
            scanTypeName: string;
            scanSubTypeName: string;
          };
        };
      }) => (
        <Grid gap={0.2} display={"flex"} flexWrap={"wrap"}>
          {row?.original?.scanModeDesc ? (
            <Chip
              label={row?.original?.scanModeDesc}
              // color="primary"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.scanCategoryDesc ? (
            <Chip
              label={row?.original?.scanCategoryDesc}
              // color="error"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.status ? (
            <Chip
              label={row?.original?.status}
              variant="outlined"
              size="small"
              // sx={{ color: "#edd83b", border: " 1px solid #edd83b" }}
            />
          ) : (
            ""
          )}
          {row?.original?.scanTypeName ? (
            <Chip
              label={row?.original?.scanTypeName}
              // color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.scanSubTypeName ? (
            <Chip
              label={row?.original?.scanSubTypeName}
              // color="info"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      accessorKey: "createdOn",
      header: "Created On",
      size: 100,
      Cell: ({ row }: { row: { original: { createdOn: Date } } }) => (
        <>
          {row?.original?.createdOn &&
            moment(row.original.createdOn).format("Do MMM YYYY")}
        </>
      ),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 50,
      Cell: ({
        row,
      }: {
        row: {
          original: {
            scanDetailsId: string;
            clientOrgId: string;
            downloadPDF: string;
            scanId: string;
          };
        };
      }) => (
        <>
          {row?.original?.scanDetailsId && (
            <IconButton title="Excel">
              <IconFile
                onClick={() => {
                  handleScanDownload({
                    scanId: row?.original?.scanDetailsId,
                    clientOrgId: row?.original?.clientOrgId,
                    fileType: "EXCEL",
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
          )}
          {row?.original?.downloadPDF && (
            <IconButton title="PDF">
              <IconFileTypePdf
                onClick={() => {
                  handleScanDownload({
                    scanId: row?.original?.scanDetailsId,
                    clientOrgId: row?.original?.clientOrgId,
                    fileType: "PDF",
                  });
                }}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  // function calculateValues(category, color) {
  //   if (category) {
  //     const categoryData = departmentInsightData?.find((item) =>
  //       item.hasOwnProperty(category)
  //     );

  //     if (!categoryData) {
  //       throw new Error(`Category "${category}" not found in data`);
  //     }

  //     const values = categoryData[category];

  //     const result = [
  //       {
  //         name: "Open",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.openComplianceCount
  //             : values.openCritical +
  //               values.openHigh +
  //               values.openMedium +
  //               values.openLow +
  //               values.openInfo,
  //         color: color[900],
  //       },
  //       {
  //         name: "Close",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.closeComplianceCount
  //             : values.closeCritical +
  //               values.closeHigh +
  //               values.closeMedium +
  //               values.closeLow +
  //               values.closeInfo,
  //         color: color[200],
  //       },
  //       {
  //         name: "Won't Fix",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.wontFixedComplianceCount
  //             : values.wontFixCritical +
  //               values.wontFixHigh +
  //               values.wontFixMedium +
  //               values.wontFixLow +
  //               values.wontFixInfo,
  //         color: color[300],
  //       },
  //       {
  //         name: "Accepted Risk",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.acceptedRiskComplianceCount
  //             : values.acceptedRiskCritical +
  //               values.acceptedRiskHigh +
  //               values.acceptedRiskMedium +
  //               values.acceptedRiskLow +
  //               values.acceptedRiskInfo,
  //         color: color[700],
  //       },
  //       {
  //         name: "False Positive",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.falsePositiveComplianceCount
  //             : values.falsePositiveCritical +
  //               values.falsePositiveHigh +
  //               values.falsePositiveMedium +
  //               values.falsePositiveLow +
  //               values.falsePositiveInfo,
  //         color: color[500],
  //       },
  //       {
  //         name: "Fixed",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.fixedComplianceCount
  //             : values.fixedCritical +
  //               values.fixedHigh +
  //               values.fixedMedium +
  //               values.fixedLow +
  //               values.fixedInfo,
  //         color: color[400],
  //       },
  //       // {
  //       //   name: "Total",
  //       //   value:
  //       //     values.totalOpen +
  //       //     values.totalClose +
  //       //     // values.totalFix +
  //       //     // values.totalWontFix +
  //       //     // values.totalAcceptedRisk +
  //       //     values.totalFalsePositive,
  //       //   color: color[800],
  //       // },
  //     ];

  //     return {
  //       result,
  //       total:
  //         category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //           ? values?.closeComplianceCount +
  //             values?.falsePositiveComplianceCount +
  //             values?.openComplianceCount
  //           : values.totalOpen + values.totalClose + values.totalFalsePositive,
  //     };
  //   } else {
  //     return {
  //       result: {},
  //       total: 0,
  //     };
  //   }
  // }

  interface CategoryValues {
    openComplianceCount?: number;
    fixedComplianceCount?: number;
    acceptedRiskComplianceCount?: number;
    wontFixedComplianceCount?: number;
    closeComplianceCount?: number;
    falsePositiveComplianceCount?: number;
    totalOpen?: number;
    totalFix?: number;
    totalWontFix?: number;
    totalAcceptedRisk?: number;
    totalClose?: number;
    totalFalsePositive?: number;
  }

  interface InsightDataItem {
    [key: string]: CategoryValues;
  }

  interface SCAN_TYPE_DETAIL {
    VAPT: string;
    VULNERABILITY_ASSESSMENT: string;
    CONFIGURATION_AUDIT: string;
    SECURE_CODE_REVIEW: string;
    BLACKBOX: string;
    GREYBOX: string;
  }

  const SCAN_TYPE_DETAIL: SCAN_TYPE_DETAIL = {
    VAPT: "VAPT",
    VULNERABILITY_ASSESSMENT: "VA",
    CONFIGURATION_AUDIT: "CA",
    SECURE_CODE_REVIEW: "SCR",
    BLACKBOX: "BLACKBOX",
    GREYBOX: "GREYBOX",
  };

  // Your insight data here
  function calculateValues(category: string) {
    // const departmentInsightData: InsightDataItem[] = []; // Replace with actual data
    const colors = [
      "#75F993", // Open
      "#7086FD", // Close
      "#FFAE4C", // Won't Fix
      "#07DBFA", // Accepted Risk
      "#988AFC", // False Positive
      "#DB4635", // Fixed
    ];
    if (category) {
      const categoryData = departmentInsightData?.find(
        (item) => category in item
      );

      if (!categoryData) {
        console.warn(
          `Category "${category}" not found in data`,
          departmentInsightData
        );
        return { result: [], total: 0 };
      }
      if (!categoryData) {
        throw new Error(`Category "${category ?? category}" not found in data`);
      }

      const values = categoryData[category as keyof typeof categoryData];

      // if (!values) {
      //   throw new Error(`No values found for category "${category}"`);
      // }

      const isConfigurationAudit =
        category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT;

      const pendingValue = isConfigurationAudit
        ? (values.openComplianceCount || 0) -
          (values.fixedComplianceCount || 0) -
          (values.acceptedRiskComplianceCount || 0) -
          (values.wontFixedComplianceCount || 0)
        : (values.totalOpen || 0) -
          (values.totalFix || 0) -
          (values.totalWontFix || 0) -
          (values.totalAcceptedRisk || 0);

      const result = [
        {
          name: "Pending",
          value: pendingValue,
          color: colors[0],
        },
        {
          name: "Close",
          value: isConfigurationAudit
            ? values.closeComplianceCount
            : values.totalClose,
          color: colors[1],
        },
        {
          name: "Won't Fix",
          value: isConfigurationAudit
            ? values.wontFixedComplianceCount
            : values.totalWontFix,
          color: colors[2],
        },
        {
          name: "Accepted Risk",
          value: isConfigurationAudit
            ? values.acceptedRiskComplianceCount
            : values.totalAcceptedRisk,
          color: colors[3],
        },
        {
          name: "False Positive",
          value: isConfigurationAudit
            ? values.falsePositiveComplianceCount
            : values.totalFalsePositive,
          color: colors[4],
        },
        {
          name: "Fixed",
          value: isConfigurationAudit
            ? values.fixedComplianceCount
            : values.totalFix,
          color: colors[5],
        },
      ];

      const total = isConfigurationAudit
        ? (values.openComplianceCount || 0) +
          (values.falsePositiveComplianceCount || 0) +
          (values.closeComplianceCount || 0)
        : (values.totalOpen || 0) +
          (values.totalFalsePositive || 0) +
          (values.totalClose || 0);

      return {
        result,
        total:
          category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
            ? values?.openComplianceCount +
              values?.falsePositiveComplianceCount +
              values?.closeComplianceCount
            : values.totalOpen +
              values?.totalFalsePositive +
              values?.totalClose,
      };
    } else {
      return {
        result: [],
        total: 0,
      };
    }
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleReset = () => {
    setFilterApplied(false);
    const resetData = {
      page,
      departmentId,
      size: rowsPerPage,
      // setCreatedOnFilter({
      //   startDate: "",
      //   endDate: ""
      // });
      // setPublishedOnFilter({
      //   startDate: "",
      //   endDate: ""
      // });
      // createdOnStartDate: "",
      // createdOnEndDate: "",
      clientOrgId,
    };
    setSelectedPendingOption({} as any);
    setSelectedScanMode({} as any);
    setSelectedScanStatus([]);
    setScanFilter({
      scanType: "",
      scanSubType: "",
      scanName: "",
      scanRefNo: "",
    });
    setSelectedRiskRating([]);
    setSelectedConsultantStatus([]);
    setSelectedClientStatus([]);
    setVulnerabilityName("");
    setFilters({} as any);
    setDateFilter({} as any);
    dispatch(
      assetDetailTab === "scans"
        ? fetchDepartmentPublishedScansList(resetData as any)
        : fetchDepartmentPublishedVulnerabilitiesList(resetData as any)
    );
  };
  const handleApplyFilters = () => {
    setFilterApplied(true);
    const filterData = {
      page: page,
      size: rowsPerPage,
      departmentId,
      clientOrgId: location?.state,

      // scanTypeCode: scanTypes.find(
      //   (scanType: { scanTypeId: number | string }) =>
      //     scanType.scanTypeId === scanFilter?.scanType
      // )?.scanTypeCode,
      ...(vulnerabilityName && {
        vulnerabilityName: vulnerabilityName,
      }),
      ...(selectedScanStatus?.length > 0 && {
        scanStatus: selectedScanStatus
          ?.map((mode: { id: string }) => mode.id)
          .join(","),
      }),
      ...(selectedConsultantStatus?.length > 0 && {
        consultantStatus: selectedConsultantStatus
          ?.map((mode: { vulStatusId: number }) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedClientStatus?.length > 0 && {
        clientStatus: selectedClientStatus
          ?.map((mode: { vulStatusId: number }) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedRiskRating?.length > 0 && {
        riskRating: selectedRiskRating
          ?.map((mode: { riskRatingCode: string }) => mode.riskRatingCode)
          .join(","),
      }),
      // ...(selectedScanMode?.length > 0 && {
      //   scanMode: selectedScanMode?.map((mode) => mode.scanModeCode).join(","),
      // }),
      ...(selectedScanMode && { scanMode: selectedScanMode?.scanModeCode }),
      ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
      ...(scanFilter?.scanSubType && { scanSubType: scanFilter.scanSubType }),
      ...(scanFilter?.scanName && { scanName: scanFilter.scanName }),
      ...(scanFilter?.scanRefNo && { scanRefNo: scanFilter.scanRefNo }),
      // ...(createdOnFilter?.startDate && {
      //   createdOnStartDate: createdOnFilter?.startDate,
      // }),
      // ...(createdOnFilter?.endDate && {
      //   createdOnEndDate: createdOnFilter?.endDate,
      // }),
      // ...(publishedOnFilter?.startDate && {
      //   publishedOnStartDate: publishedOnFilter?.startDate,
      // }),
      // ...(publishedOnFilter?.endDate && {
      //   publishedOnEndDate: publishedOnFilter?.endDate,
      // }),
    };
    if (selectedPendingOption?.client) {
      filterData.pendingWith = pendingWithScanFilterCode?.CLIENT;
    }

    if (selectedPendingOption?.consultant) {
      filterData.pendingWith = pendingWithScanFilterCode?.CONSULTANT;
    }
    dispatch(
      assetDetailTab === "scans"
        ? fetchDepartmentPublishedScansList(filterData as any)
        : fetchDepartmentPublishedVulnerabilitiesList(filterData as any)
    );
  };

  useEffect(() => {
    if (filterApplied) {
      const payload = {
        page: page,
        size: rowsPerPage,
        departmentId,
        clientOrgId: location?.state,
        // scanTypeCode: scanTypes.find(
        //   (scanType: { scanTypeId: string | number }) =>
        //     scanType.scanTypeId === scanFilter?.scanType
        // )?.scanTypeCode,
        ...(vulnerabilityName && {
          vulnerabilityName: vulnerabilityName,
        }),
        ...(selectedScanStatus?.length > 0 && {
          scanStatus: selectedScanStatus
            ?.map((mode: { id: string }) => mode.id)
            .join(","),
        }),
        ...(selectedConsultantStatus?.length > 0 && {
          consultantStatus: selectedConsultantStatus
            ?.map((mode: { vulStatusId: string }) => mode.vulStatusId)
            .join(","),
        }),
        ...(selectedClientStatus?.length > 0 && {
          clientStatus: selectedClientStatus
            ?.map((mode: { vulStatusId: string }) => mode.vulStatusId)
            .join(","),
        }),
        ...(selectedRiskRating?.length > 0 && {
          riskRating: selectedRiskRating
            ?.map((mode: { riskRatingCode: string }) => mode.riskRatingCode)
            .join(","),
        }),
        // ...(selectedScanMode?.length > 0 && {
        //   scanMode: selectedScanMode
        //     ?.map((mode) => mode.scanModeCode)
        //     .join(","),
        // }),
        ...(selectedScanMode && { scanMode: selectedScanMode?.scanModeCode }),
        ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
        ...(scanFilter?.scanSubType && { scanSubType: scanFilter.scanSubType }),
        ...(scanFilter?.scanName && { scanName: scanFilter.scanName }),
        ...(scanFilter?.scanRefNo && { scanRefNo: scanFilter.scanRefNo }),
        // ...(createdOnFilter?.startDate && {
        //   createdOnStartDate: createdOnFilter?.startDate,
        // }),
        // ...(createdOnFilter?.endDate && {
        //   createdOnEndDate: createdOnFilter?.endDate,
        // }),
        // ...(publishedOnFilter?.startDate && {
        //   publishedOnStartDate: publishedOnFilter?.startDate,
        // }),
        // ...(publishedOnFilter?.endDate && {
        //   publishedOnEndDate: publishedOnFilter?.endDate,
        // }),
      };
      dispatch(
        assetDetailTab === "scans"
          ? fetchDepartmentPublishedScansList(payload as any)
          : fetchDepartmentPublishedVulnerabilitiesList(payload as any)
      );
    } else {
      const payload = {
        page: page,
        size: rowsPerPage,
        departmentId,
        clientOrgId: location?.state,
      };
      dispatch(
        assetDetailTab === "scans"
          ? fetchDepartmentPublishedScansList(payload as any)
          : fetchDepartmentPublishedVulnerabilitiesList(payload as any)
      );
    }
  }, [page, rowsPerPage]);

  const handleScanDownload = (payload: {
    scanId: string;
    clientOrgId: string;
    fileType: string;
  }) => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
    dispatch(downloadScan(payload));
    // }
  };

  const handleAssetDetailTabChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setAssetDetailTab(newValue);
    }
  };

  useEffect(() => {
    if (Object.keys(downloadScanData)?.length > 0) {
      const { fileContent, fileName } = downloadScanData;
      downloadTemplateFile(fileContent, fileName);
      return () => {
        dispatch(downloadScanSuccess({}));
      };
    }
  }, [downloadScanData]);
  const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      // flexDirection:'row',
      padding: theme.spacing(1),
      // gap: theme.spacing(0.1),
    },
    filterContainer: {
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    tableContainer: {
      [theme.breakpoints.up("md")]: {
        maxWidth: "75%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      {/* <GroupAssetHeader
                title={"Asset Name: " + assetDetail?.assetName}
                handleGoBack={handleGoBack}
                selectAsset={false}
                HandleEditAsset={HandleEditAsset}
            > */}

      <Grid>
        <Grid
          justifyContent="center"
          xs={11}
          sx={{
            display: "flex",
            // bgcolor: " #1D1D3A",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BackButtonCustom handleGoBack={handleGoBack} />
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={assetDetailTab}
            aria-label="Platform"
            onChange={handleAssetDetailTabChange}
            fullWidth
            size="small"
            sx={{ width: "100%" }} // Make ToggleButtonGroup take full width
          >
            <ToggleButton value="insight">
              <InsightsSharpIcon fontSize="small" style={iconStyle} />
              Insights
            </ToggleButton>

            <ToggleButton value="scans">
              <QrCodeScannerSharpIcon fontSize="small" style={iconStyle} />
              Scans
            </ToggleButton>
            <ToggleButton value="vulnerabilities">
              <BuildSharpIcon fontSize="small" style={iconStyle} />{" "}
              Vulnerabilities
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid
          sx={{
            // bgcolor: " #1D1D3A",
            borderRadius: "8px",
            // backgroundColor: isDarkMode
            //   ? theme.palette.background.default
            //   : theme.palette.background.paper,
            // color: isDarkMode
            //   ? theme.palette.text.primary
            //   : theme.palette.text.secondary,
          }}
          // m={5}
          mt={1}
          xs={12}
        >
          {assetDetailTab === "assetOverview" ? (
            <></>
          ) : assetDetailTab === "insight" ? (
            <>
              <Grid
                container
                sx={{
                  // bgcolor: " #1D1D3A",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                pt={1}
                px={1}
              >
                <Grid item xs={4.8}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${
                        SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW
                      } (${calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW)
                        ?.total})`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW)
                          ?.result || []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={4.8}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.VAPT} (${calculateValues(
                        SCAN_TYPE_DETAIL.VAPT
                      )?.total})`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.VAPT)?.result ?? []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item xs={3.2}>
                                    {departmentInsightData?.length > 0 ? (
                                        <AssetDetailPiechart
                                            label={SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW}
                                            data={calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW, orange)}
                                            height={350}
                                            color={green[50]}
                                            lineColor={green[400]}
                                            legend={"exposure"}
                                            dataKey="value"
                                            axisLabel="name"
                                            displayLegend={true}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Grid> */}
                <Grid
                  xs={2}
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    "@media (max-width: 768px)": {
                      flexDirection: "row",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {Object.keys(departmentRiskComplianceScore)?.length > 0 ? (
                    <>
                      <CustomCircularProgressWithLabel
                        value={Number(
                          departmentRiskComplianceScore?.risk_score
                        )}
                        label="Risk Score"
                        primaryColor={red[500]}
                        secondaryColor={grey[300]}
                        size={135}
                      />
                      <CustomCircularProgressWithLabel
                        value={Number(
                          departmentRiskComplianceScore?.compliance_score
                        )}
                        label="Compliance Score"
                        primaryColor={blue[500]}
                        secondaryColor={grey[300]}
                        size={135}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  // background: "#1E1E3A",
                }}
                pt={1}
              >
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.GREYBOX} (${calculateValues(
                        SCAN_TYPE_DETAIL.GREYBOX
                      )?.total})`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.GREYBOX)?.result ?? []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.BLACKBOX} (${calculateValues(
                        SCAN_TYPE_DETAIL.BLACKBOX
                      )?.total})`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.BLACKBOX)?.result ?? []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item xs={3.9}>
                                    {departmentInsightData?.length > 0 ? (
                                        <AssetDetailPiechart
                                            label={SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT}
                                            data={calculateValues(SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT, red)}
                                            height={350}
                                            color={green[50]}
                                            lineColor={green[400]}
                                            legend={"exposure"}
                                            dataKey="value"
                                            axisLabel="name"
                                            displayLegend={true}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Grid> */}
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  // background: "#1E1E3A",
                }}
                pt={1}
              >
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${
                        SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
                      } (${calculateValues(SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT)
                        ?.total})`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT)
                          ?.result ?? []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${
                        SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT
                      } (${calculateValues(
                        SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT
                      )?.total})`}
                      data={
                        calculateValues(
                          SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT
                        )?.result ?? []
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                sx={{
                  paddingBottom: "30px",

                  // background: "#1E1E3A",
                }}
              >
                <Grid
                  // container
                  sx={{
                    border: "0.5px solid #a2a2d299",
                     // background: "#1D1D3A",
                    padding: "30px",
                    height: "100%",
                    borderRadius: "10px",
                    // color: "#fff",
                  }}
                >
                  <VmTable
                    columns={
                      assetDetailTab === "scans" ? scanColumns : vulColumns
                    }
                    data={
                      assetDetailTab === "scans"
                        ? departmentPublishedScansList?.data?.length > 0
                          ? departmentPublishedScansList?.data
                          : []
                        : departmentPublishedVulnerabilitiesList?.data?.length >
                          0
                        ? departmentPublishedVulnerabilitiesList?.data
                        : []
                    }
                    enableBottomToolbar={false}
                    enablePagination={false}
                    tableTitle={
                      assetDetailTab === "vulnerabilities" ? (
                        <Grid textAlign={"start"}>
                          <IconButton
                            color="inherit"
                            title="Export"
                            size="large"
                            disableRipple
                            aria-label="live customize"
                            onClick={handleVulnerabilitiesExport}
                            sx={{ p: 0, color: "#fff" }}
                          >
                            <img
                              src={exportIcon}
                              alt="Icon"
                              width={50}
                              height={50}
                            />
                          </IconButton>
                        </Grid>
                      ) : (
                        ""
                      )
                    }
                    headerAction={
                      <Grid
                        sx={{
                          paddingBottom: "30px",
                        }}
                      >
                        {assetDetailTab === "scans" ? (
                          <GenericScanFilterBar
                            page={page}
                            rowsPerPage={rowsPerPage}
                            enableScanFilter={true}
                            scanTypeApi={getScanTypes}
                            scanTypes={scanTypes}
                            scanSubTypeApi={getScanSubType}
                            scanSubTypes={scanSubTypes}
                            enableScanModeFilter={true}
                            enableScanSubTypeFilter={false}
                            enableScanRefFilter={false}
                            enableApplyFilter={true}
                            selectedScanMode={selectedScanMode}
                            setSelectedScanMode={setSelectedScanMode}
                            scanFilter={scanFilter}
                            setScanFilter={setScanFilter}
                            selectedScanStatus={selectedScanStatus}
                            setSelectedScanStatus={setSelectedScanStatus}
                            handleApplyFilters={handleApplyFilters}
                            enableClientStatus={false}
                            scanStatusList={scanStatusFilterList}
                            scanStatusApi={getScanStatusFilterList}
                            createdOnFilter={createdOnFilter}
                            setCreatedOnFilter={setCreatedOnFilter}
                            publishedOnFilter={publishedOnFilter}
                            setPublishedOnFilter={setPublishedOnFilter}
                            enableDateRangeFilter={true}
                            selectedPendingOption={selectedPendingOption}
                            setSelectedPendingOption={setSelectedPendingOption}
                            enablePendingWithFilter={false}
                            handleReset={handleReset}
                            enableClearFilter={true}
                            size={0}
                            departmentId={undefined}
                            clientOrgId={undefined}
                            filters={undefined}
                            riskRatingValue={undefined}
                            clientStatusValue={undefined}
                            riskRatingApi={function (): void {
                              throw new Error("Function not implemented.");
                            }}
                            clientStatusApi={function (): void {
                              throw new Error("Function not implemented.");
                            }}
                            dateFilter={{
                              startDate: null,
                              endDate: null,
                            }}
                            setDateFilter={undefined}
                            selectedConsultantStatus={undefined}
                            setSelectedConsultantStatus={undefined}
                            selectedClientStatus={undefined}
                            setSelectedClientStatus={undefined}
                            selectedRiskRating={undefined}
                            setSelectedRiskRating={undefined}
                            setVulnerabilityName={undefined}
                            setFilters={function (filters: any): void {
                              throw new Error("Function not implemented.");
                            }}
                            enableClientStatusFilter={false}
                            consultantStatusValue={undefined}
                            enableReportedOnFilter={false}
                            consultantStatusApi={function (): void {
                              throw new Error("Function not implemented.");
                            }}
                            enableRiskRatingFilter={false}
                            enableVulnerabilityNameFilter={false}
                            enableConsultantStatusFilter={false}
                            setPublishOnFilter={function (filter: any): void {
                              throw new Error("Function not implemented.");
                            }}
                            selectedConsultantScanStatus={undefined}
                            publishOnFilter={undefined}
                            enableClientOrganizationFilter={false}
                            enablePublishedDateFilter={false}
                            enableScanCategory={false}
                            enablePublishOnFilter={false}
                            enableConsultantStatus={false}
                            selectedTesterName={undefined}
                            consultantScanStatusList={[]}
                            selectedScanCategory={undefined}
                            selectedSecurityManagerName={undefined}
                            enableManagerFilter={false}
                            enableTesterFilter={false}
                            clientOrganizationList={[]}
                            selectedClientOrganization={undefined}
                            setSelectedClientOrganization={function (
                              org: any
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedScanCategory={function (
                              category: any
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedSecurityManagerName={function (
                              name: any
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedTesterName={function (name: any): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedConsultantScanStatus={function (
                              status: any
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            consultantSMList={[]}
                            consultantTesterList={[]}
                          />
                        ) : (
                          <GenericFilterBar
                            // page={page}
                            // rowsPerPage={rowsPerPage}
                            enableScanFilter={true}
                            scanTypeApi={getScanTypes}
                            scanTypes={scanTypes}
                            enableRiskRatingFilter={true}
                            enableApplyFilter={true}
                            enableClearFilter={true}
                            enableClientStatusFilter={true}
                            enableConsultantStatusFilter={true}
                            enableReportedOnFilter={false}
                            consultantStatusValue={consultantStatusValue}
                            consultantStatusApi={getConsultantStatusValue}
                            riskRatingValue={riskRatingValue}
                            riskRatingApi={getRiskRatingValue}
                            clientStatusValue={clientStatusValue}
                            clientStatusApi={getClientStatusValue}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            selectedConsultantStatus={selectedConsultantStatus}
                            setSelectedConsultantStatus={
                              setSelectedConsultantStatus
                            }
                            selectedClientStatus={selectedClientStatus}
                            setSelectedClientStatus={setSelectedClientStatus}
                            selectedRiskRating={selectedRiskRating}
                            setSelectedRiskRating={setSelectedRiskRating}
                            handleApplyFilters={handleApplyFilters}
                            handleReset={handleReset}
                            vulnerabilityName={vulnerabilityName}
                            setVulnerabilityName={setVulnerabilityName}
                            enableVulnerabilityNameFilter={true}
                            setFilters={setFilters}
                            enablePublishedDateFilter={false}
                            vulReference={""}
                            clientOrganizationList={[]}
                            selectedClientOrganization={[]}
                            scanFilter={scanFilter}
                            setScanFilter={setScanFilter}
                            setVulReference={function (
                              vulReference: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedComplianceStatus={function (
                              status: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedClientOrganization={function (
                              organization: { orgName: string; id: string }[]
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedDepartment={function (
                              department: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedAssetGroupList={function (
                              assetGroupList: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedSecurityManagerName={function (
                              manager: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                            setSelectedTesterName={function (
                              tester: string
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        )}
                      </Grid>
                    }
                  />
                  <TablePagination
                    sx={{ display: "flex", justifyContent: "end" }}
                    count={
                      assetDetailTab === "scans"
                        ? departmentPublishedScansList?.totalRecords
                          ? departmentPublishedScansList?.totalRecords
                          : 0
                        : departmentPublishedVulnerabilitiesList?.totalRecords
                        ? departmentPublishedVulnerabilitiesList?.totalRecords
                        : 0
                    }
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* </GroupAssetHeader> */}
    </>
  );
};

export default DepartmentInsights;
