import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index"; // Adjust the path if needed
import { openSnackbar } from "./snackbarReducer";
import axios from "src/utils/axios";
import { dispatch } from "../index";
import { notificationManager } from "src/ui-component/notificationManager";

// Define types for state and params
interface DashboardState {
  error: string | null;
  vulnerabilityCount: Record<string, unknown>;
  orgRiskScore: Record<string, unknown>;
  exposureVulnerabilityCount: Record<string, unknown>;
  assetsCount: Record<string, unknown>;
  acceptedRiskCount: Record<string, unknown>;
  scanVulnerabilityTrend: Array<{ Scans: number; Vulnerabilities: number }>;
  ongoingScan: Record<string, unknown>;
  vulStatusCount: Record<string, unknown>;
  assetNotScanBuckets: Record<string, unknown>;
  testerDashboardData: Record<string, unknown>;
  smDashboardData: Record<string, unknown> | any;
  OrgRiskTimeLine: unknown[];
  MostOccurringVulnerabilities: unknown[];
  MostOccurringComplianceIssues: unknown[];
  waspCategoryVulnerabilitiesCount: unknown[];
  BoxListData: unknown[];
  assetManagerVulCount: Record<string, unknown>;
  departmentScanGraph: Record<string, unknown>;
  assetScanGraphData: Record<string, unknown>;
  assetSummaryDashboardData: unknown[] | any;
  departmentDashboardList: unknown[];
  assetGroupDashboardList: unknown[];
  AssetSensitivityRiskRatingData: unknown[];
  exportAssetSensitivityRiskRatingRes: Record<string, unknown>;
  vulnerabilityBuckets: Record<string, unknown> | null;
  uniqueComplianceIssuesCount: Record<string, unknown> | null;
  selectedDeptValue: string | number | undefined; // Add this field with the correct type
  selectedAssetValue: string | number | undefined; // Add this field with the correct type
}

interface FetchParams {
  [key: string]: string | number | undefined | null;
}

interface ScanVulnerabilityTrend {
  Scans: number;
  Vulnerabilities: number;
}

// Initial state
const initialState: DashboardState = {
  error: null,
  vulnerabilityCount: {},
  orgRiskScore: {},
  exposureVulnerabilityCount: {},
  assetsCount: {},
  acceptedRiskCount: {},
  scanVulnerabilityTrend: [],
  ongoingScan: {},
  vulStatusCount: {},
  vulnerabilityBuckets: null,
  assetNotScanBuckets: {},
  testerDashboardData: {},
  smDashboardData: {},
  OrgRiskTimeLine: [],
  MostOccurringVulnerabilities: [],
  MostOccurringComplianceIssues: [],
  waspCategoryVulnerabilitiesCount: [],
  BoxListData: [],
  assetManagerVulCount: {},
  departmentScanGraph: {},
  assetScanGraphData: {},
  assetSummaryDashboardData: [],
  departmentDashboardList: [],
  assetGroupDashboardList: [],
  AssetSensitivityRiskRatingData: [],
  exportAssetSensitivityRiskRatingRes: {},
  selectedDeptValue: undefined,
  selectedAssetValue: undefined,
  uniqueComplianceIssuesCount: {},
};

// Constants
const clientDashboardEndPoint = "/client/v1/dashboard/";

const clientEndPoint = "/client/v1/";
const consultantclientDashboardEndPoint = "/consultant/v1/dashboard/";

// Slice
const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    vulnerabilityCountSuccess(
      state,
      action
    ) {
      state.vulnerabilityCount = action.payload;
    },

    setselectedDepartmentValue(
      state,
      action
    ) {
      state.selectedDeptValue = action.payload;
    },
    setselectedAssetValue(
      state,
      action
    ) {
      state.selectedAssetValue = action.payload;
    },

    orgRiskScoreSuccess(state, action) {
      state.orgRiskScore = action.payload;
    },

    exposureVulnerabilityCountSuccess(
      state,
      action
    ) {
      state.exposureVulnerabilityCount = action.payload;
    },

    assetsCountSuccess(state, action) {
      state.assetsCount = action.payload;
    },

    acceptedRiskCountSuccess(
      state,
      action
    ) {
      state.acceptedRiskCount = action.payload;
    },

    scanVulnerabilityTrendSuccess(
      state,
      action
    ) {
      state.scanVulnerabilityTrend = action.payload;
    },

    ongoingScanSuccess(state, action) {
      state.ongoingScan = action.payload;
    },

    vulStatusCountSuccess(
      state,
      action
    ) {
      state.vulStatusCount = action.payload;
    },

    vulnerabilityBucketsSuccess(
      state,
      action
    ) {
      state.vulnerabilityBuckets = action.payload;
    },

    assetNotScanBucketsSuccess(
      state,
      action
    ) {
      state.assetNotScanBuckets = action.payload;
    },

    uniqueComplianceIssuesCountSuccess(
      state,
      action
    ) {
      state.uniqueComplianceIssuesCount = action.payload;
    },

    testerDashboardSuccess(
      state,
      action
    ) {
      state.testerDashboardData = action.payload;
    },

    smDashboardSuccess(state, action) {
      state.smDashboardData = action.payload;
    },

    fetchOrgRiskTimeLineSuccess(state, action) {
      state.OrgRiskTimeLine = action.payload;
    },

    fetchMostOccurringVulnerabilitiesSuccess(
      state,
      action
    ) {
      state.MostOccurringVulnerabilities = action.payload;
    },

    fetchMostOccurringComplianceIssuesSuccess(
      state,
      action
    ) {
      state.MostOccurringComplianceIssues = action.payload;
    },

    fetchWaspCategoryVulnerabilitiesCountSuccess(
      state,
      action
    ) {
      state.waspCategoryVulnerabilitiesCount = action.payload;
    },

    fetchfetchBoxListDataSuccess(state, action) {
      state.BoxListData = action.payload;
    },

    fetchAssetManagerVulCountSuccess(
      state,
      action
    ) {
      state.assetManagerVulCount = action.payload;
    },

    fetchdepartmentScanGraph(
      state,
      action
    ) {
      state.departmentScanGraph = action.payload;
    },

    fetchassetScanGraphData(
      state,
      action
    ) {
      state.assetScanGraphData = action.payload;
    },

    fetchAssetSummaryDashboardDataSuccess(
      state,
      action
    ) {
      state.assetSummaryDashboardData = action.payload;
    },

    fetchDepartmentDashboardListSuccess(
      state,
      action
    ) {
      state.departmentDashboardList = action.payload;
    },

    fetchAssetGroupDashboardListSuccess(
      state,
      action
    ) {
      state.assetGroupDashboardList = action.payload;
    },

    fetchAssetSensitivityRiskRatingSuccess(
      state,
      action
    ) {
      state.AssetSensitivityRiskRatingData = action.payload;
    },

    exportAssetSensitivityRiskRatingSuccess(
      state,
      action
    ) {
      state.exportAssetSensitivityRiskRatingRes = action.payload;
    },
  },
});

// Export reducer
export default dashboard.reducer;

// Export actions
export const {
  vulnerabilityCountSuccess,
  assetNotScanBucketsSuccess,
  vulnerabilityBucketsSuccess,
  exportAssetSensitivityRiskRatingSuccess
} = dashboard.actions;

// Async actions
export const fetchAssetGroupScanGraphData =
  (params: FetchParams) => async (dispatch: AppDispatch) => {
    try {
      const url = `${clientDashboardEndPoint}asset-scan-stats`;
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.fetchassetScanGraphData(response?.data));
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    }
  };

export const fetchDepartmentScanGraphData = (params: FetchParams) => async (dispatch: AppDispatch) => {
    try {
      const url = `${clientDashboardEndPoint}scan-stats`;
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== "" // Omitting the unused key
        )
      );
      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.fetchdepartmentScanGraph(response?.data));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
        dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    }
  };

export function fetchVulnerabilityCount(params: FetchParams) {
  return async (dispatch: AppDispatch) => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}vulnerabilities-count`,
        { params: filteredParams }
      );
      dispatch(dashboard.actions.vulnerabilityCountSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
        dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchOrgRiskScore() {
  return async (dispatch: AppDispatch) => {
    // // dispatch(setLoading(true));;

    try {
      const response = await axios.get(`${clientDashboardEndPoint}org-score`);
      dispatch(dashboard.actions.orgRiskScoreSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
        dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchExposureVulnerabilityCount(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}exposure-vulnerability-count`,
        { params: filteredParams }
      );
      dispatch(
        dashboard.actions.exposureVulnerabilityCountSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
        dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetsCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}assets-count`
      );
      dispatch(dashboard.actions.assetsCountSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAcceptedRiskCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}accepted-risk-count`
      );
      dispatch(dashboard.actions.acceptedRiskCountSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchScanVulnerabilityTrend(params: FetchParams) {
  return async (dispatch: AppDispatch) => {
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );

      const response = await axios.get(
        `${clientDashboardEndPoint}scan-vulnerability-trend`,
        { params: filteredParams }
      );

      // Map the response data
      const trendData = (
        response.data as Array<{ sc: number; vc: number,name:string }>
      ).map((value) => ({
        date:value?.name,
        Scans: value.sc,
        Vulnerabilities: value.vc,
      }));

      dispatch(dashboard.actions.scanVulnerabilityTrendSuccess(trendData));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    }
  };
}

export function fetchOngoingScans() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}ongoing-scan-count`
      );
      dispatch(dashboard.actions.ongoingScanSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchVulStatusCount(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}vulnerabilities-status-count`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(dashboard.actions.vulStatusCountSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchVulnerabilityBuckets() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}vulnerability_buckets
       `
      );
      dispatch(dashboard.actions.vulnerabilityBucketsSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetNotScanBuckets() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}asset_not_scan_buckets
       `
      );
      dispatch(dashboard.actions.assetNotScanBucketsSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchUniqueComplianceIssuesCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}unique-compliance-issues-count
       `
      );
      dispatch(
        dashboard.actions.uniqueComplianceIssuesCountSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchTesterDashboard() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}tester-dashboard
       `
      );
      dispatch(dashboard.actions.testerDashboardSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchSmDashboard() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}sm-dashboard
       `
      );
      dispatch(dashboard.actions.smDashboardSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchOrgRiskTimeLine() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}org-risk-timeline
       `
      );
      dispatch(dashboard.actions.fetchOrgRiskTimeLineSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchMostOccurringVulnerabilities(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}most-occurring-vulnerabilities`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchMostOccurringVulnerabilitiesSuccess(
          response.data
        )
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchMostOccurringComplianceIssues(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}most-occurring-compliance-issues`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchMostOccurringComplianceIssuesSuccess(
          response.data
        )
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchoWaspCategoryVulnerabilitiesCount(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const response = await axios.get(
        `${clientDashboardEndPoint}owasp-category-vulnerabilities-count`,
        { params: filteredParams }
      );
      dispatch(
        dashboard.actions.fetchWaspCategoryVulnerabilitiesCountSuccess(
          response.data
        )
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchBoxListData(payload: {
  role: string;
  scanStatusList: unknown[];
  scanMode: string;
}) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${consultantclientDashboardEndPoint}${
          payload?.role ? "sm" : "tester"
        }/dashboard-scans/${payload?.scanStatusList}/${payload?.scanMode}
       `
      );
      dispatch(dashboard.actions.fetchfetchBoxListDataSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetManagerVulCount() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const response = await axios.get(
        `${clientDashboardEndPoint}scan-type-am-vulnerability-count
       `
      );
      dispatch(
        dashboard.actions.fetchAssetManagerVulCountSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchAssetSummaryDashboardData(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const url = `${clientDashboardEndPoint}assets-stats`;

      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );

      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetSummaryDashboardDataSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}

export function fetchDepartmentDashboardList() {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      const url = `${clientEndPoint}department/user-department-list`;
      const response = await axios.get(url);
      dispatch(
        dashboard.actions.fetchDepartmentDashboardListSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function fetchAssetGroupDashboardList(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientEndPoint}asset/group/user-asset-group-list`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetGroupDashboardListSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function fetchAssetSensitivityRiskRating(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}asset-sensitivity-risk-rating-heatmap`;
      const response = await axios.get(url, { params: filteredParams });
      dispatch(
        dashboard.actions.fetchAssetSensitivityRiskRatingSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(dashboard.actions.hasError(errorMessage));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
export function exportAssetSensitivityRiskRating(params: FetchParams) {
  return async () => {
    // dispatch(setLoading(true));;

    try {
      // Filter out params with empty values (null, undefined, or empty string)
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(
          ([, value]) => value != null && value !== ""
        )
      );
      const url = `${clientDashboardEndPoint}asset-sensitivity-risk-rating-heatmap/export`;
      const response = await axios.get(url, {
        params: filteredParams,
      });
      dispatch(
        dashboard.actions.exportAssetSensitivityRiskRatingSuccess(response.data)
      );
      notificationManager(response);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(dashboard.actions.hasError(errorMessage));
      console.error("Error fetching asset summary dashboard data:", error);
    } finally {
      // dispatch(setLoading(true));
    }
  };
}
