// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { setLoading } from "./user";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import { FetchParams } from "./reducerTypes";
// ----------------------------------------------------------------------

const scantestEndPointApi = `/consultant/v1/scan-test-plans`;

const initialState = {
  error: null,
  scanTestList: [],
  refreshDataRes: {},
  updateTestDataRes: {},
};

const scanTest = createSlice({
  name: "scanTest",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getScanTestCasesListSuccess(state, action) {
      state.scanTestList = action.payload;
    },
    refreshTheTestDataSuccess(state, action) {
      state.refreshDataRes = action.payload;
    },
    updateTestDataSuccess(state, action) {
      state.updateTestDataRes = action.payload;
    },
  },
});

// Reducer
export default scanTest.reducer;

export const getScanTestCasesList = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${scantestEndPointApi}/list`, { params });
      dispatch(scanTest.actions.getScanTestCasesListSuccess(response.data));
    } catch (error) {
      dispatch(scanTest.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const refreshTheTestData = (params: {
  clientOrgId: number;
  scanId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${scantestEndPointApi}/refresh/${params?.scanId}/${params?.clientOrgId}`
      );
      dispatch(scanTest.actions.refreshTheTestDataSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(scanTest.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateTestData = (payload: {
  scanId: number;
  clientOrgId: number;
  data: unknown;
}) => {
  return async () => {
    // Ensure dispatch is available here
    dispatch(setLoading(true));
    try {
      const response = await axios.put(
        `${scantestEndPointApi}/test-status?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`,
        payload?.data // Pass the data directly
      );
      dispatch(scanTest.actions.updateTestDataSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(scanTest.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
