import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import theme from "src/themes/theme";

interface ErrorDetails {
  processed?: number;
  valid?: number;
  invalid?: number;
  errors?: string[];
}

interface ErrorDisplayProps {
  errorDetails?: ErrorDetails;
}

const useStyles = makeStyles(() => ({
  errorContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#ffebee",
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: "#f44336",
  },
}));

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ errorDetails }) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Typography>Processed : {errorDetails?.processed || 0}</Typography>
        <Typography>Valid : {errorDetails?.valid || 0}</Typography>
        <Typography>Invalid : {errorDetails?.invalid || 0}</Typography>
      </div>
      <div className={classes.errorContainer}>
        <Typography variant="h6" className={classes.errorText}>
          Errors:
        </Typography>
        {errorDetails?.errors?.map((error, index) => (
          <Typography variant="body1" className={classes.errorText} key={index}>
            {error}
          </Typography>
        ))}
      </div>
    </>
  );
};

export default ErrorDisplay;

// Binding element 'errorDetails' implicitly has an 'any' type.ts(7031)

// Parameter 'error' implicitly has an 'any' type.ts(7006)
