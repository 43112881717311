// third-party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// project imports
import { PORTAL_TYPE } from "../../constant";
import { notificationManager } from "../../ui-component/notificationManager";
import axios from "../../utils/axios";
import { AppDispatch, dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { OrgDetail, State, User } from "./reducerTypes";

// ----------------------------------------------------------------------

// Default values for User
const defaultUser: User = {
  userId: 0,
  loginName: null,
  firstName: "",
  displayName: "",
  lastName: "",
  emailId: "",
  mobileNo: "",
  empCode: "",
  organizationId: null,
  groupIdList: null,
  groupName: null,
  createdOn: null,
  lastAuthenticated: "",
  previousAuthenticated: "",
  allDepartments: false,
  allAssetGroups: false,
  dmIdList: null,
  dmNameList: null,
  agmIdList: null,
  agmNameList: null,
  active: false,
  locked: false,
};

// Default values for OrgDetail
const defaultOrgDetail: OrgDetail = {
  organizationId: 0,
  organizationName: "",
  emailId: "",
  organizationType: "",
  createdOn: null,
  registrationNumber: "",
  address: null,
  website: null,
  logo: null,
  appLogoUploaded: false,
  scanReportLogoUploaded: false,
  adminGenerated: false,
};

// Initial State
const initialState: State = {
  loading: false,
  setDataloading: false,
  error: null,
  userList: {
    data: [], // Default to an empty array if `data` is a list of users
    totalRecords: 0, // Default to 0 if `totalRecords` is a number
  },
  singleUser: { ...defaultUser },
  addUser: { ...defaultUser },
  updateUser: { ...defaultUser },
  loginUserDetail: { ...defaultUser },
  orgDetail: { ...defaultOrgDetail },
  visibleFilterDetail: false,
};

const slice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    // ADD LOADING
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    isLoading(state, action: PayloadAction<boolean>) {
      state.setDataloading = action.payload;
    },
    // GET USER LIST
    getUserListSuccess(state, action) {
      state.userList = action.payload;
    },
    // GET SINGLE USER
    getSingleUserSuccess(state, action) {
      state.singleUser = action.payload;
    },
    // ADD NEW_USER
    addNewUserSucces(state, action) {
      state.addUser = action.payload;
    },
    // UPDATE NEW_USER
    updateUserInfoSucces(state, action) {
      state.updateUser = action.payload;
    },
    // GET USER DETAIL
    getUserDetailSuccess(state, action) {
      state.loginUserDetail = action.payload;
    },
    // GET ORG DETAIL
    getOrgDetailSuccess(state, action) {
      state.orgDetail = action.payload;
    },
    // GET FILTER DETAIL
    visibleFilterDrawer(state, action) {
      state.visibleFilterDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  setLoading,
  isLoading,
  getOrgDetailSuccess,
  getSingleUserSuccess,
  visibleFilterDrawer,
} = slice.actions;

// Async Thunks

export const fetchUserList = (params: Record<string, unknown>) => {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    dispatch(slice.actions.isLoading(true));

    try {
      const organizationType = localStorage.getItem("organizationType");
      const prefix =
        organizationType === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : organizationType === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";

      const response = await axios.get(`${prefix}/list`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("serviceToken") || ""}`,
        },
      });

      dispatch(slice.actions.getUserListSuccess(response.data));
      dispatch(slice.actions.getSingleUserSuccess(defaultUser)); // Ensure defaultUser is defined in scope
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(slice.actions.setLoading(false));
      dispatch(slice.actions.isLoading(false));
    }
  };
};

export const fetchSingleUser = (userId: string) => {
  return async () => {
    dispatch(slice.actions.setLoading(true));

    try {
      dispatch(slice.actions.getSingleUserSuccess(defaultUser));
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.get(`${prefix}/${userId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("serviceToken") || ""}`,
        },
      });
      dispatch(slice.actions.getSingleUserSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const addNewUser = (payload: User) => {
  return async () => {
    dispatch(isLoading(true));
    dispatch(slice.actions.setLoading(true));

    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.post(
        `${prefix}`,
        { ...payload },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${
              localStorage.getItem("serviceToken") || ""
            }`,
          },
        }
      );
      dispatch(slice.actions.addNewUserSucces(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const updateUserInfo = (payload: User) => {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.put(
        `${prefix}`,
        { ...payload },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${
              localStorage.getItem("serviceToken") || ""
            }`,
          },
        }
      );
      dispatch(slice.actions.updateUserInfoSucces(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(slice.actions.setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getUserDetail = () => {
  return async () => {
    dispatch(slice.actions.setLoading(true));
    dispatch(slice.actions.isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.get(`${prefix}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("serviceToken") || ""}`,
        },
      });
      dispatch(slice.actions.getUserDetailSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(slice.actions.isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};

export const getOrgDetail = () => {
  return async () => {
    dispatch(slice.actions.isLoading(true));
    dispatch(slice.actions.setLoading(true));

    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/organization"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/organization"
          : "/client/v1/organization";
      const response = await axios.get(`${prefix}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("serviceToken") || ""}`,
        },
      });
      dispatch(slice.actions.getOrgDetailSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(slice.actions.isLoading(false));
      dispatch(slice.actions.setLoading(false));
    }
  };
};
