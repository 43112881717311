import React, { useEffect } from "react";
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Box } from "@mui/system";
import {
  AssetDetailPiechartProps,
  PieChartData,
} from "../../../assetInterfaceTypes";
import theme from "src/themes/theme";

am4core.addLicense("CH199370475"); // Replace with your actual license key

const AssetDetailPiechart: React.FC<AssetDetailPiechartProps> = ({
  label,
  data,
  displayLegend,
  lastPublished,
  visibleAssetInsightLastScanDate = false,
}) => {
  // const theme = useTheme();
  const bgColor = "#1E1E3A";
  // theme.palette.mode === "dark" ? theme.palette.background.paper : "#ffffff";
  const borderColor = theme.palette.mode === "dark" ? "#455f95" : "#EEEEEE";
  const boxShadowColor =
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.1)";
  // const textColor = theme.palette.text.primary;
  const textColor = "#ffffff";

  useEffect(() => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create(`chartdiv-${label}`, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    if (displayLegend) {
      chart.legend = new am4charts.Legend();
      chart.legend.maxWidth = 1;
      chart.legend.useDefaultMarker = false;

      const markerTemplate = chart.legend.markers.template;

      if (
        chart.legend &&
        chart.legend.markers &&
        chart.legend.markers.template
      ) {
        const marker = chart.legend.markers.template.children.getIndex(
          0
        ) as am4core.RoundedRectangle;
        if (marker) {
          marker.cornerRadius(12, 12, 12, 12);
          marker.strokeWidth = 1;
          marker.strokeOpacity = 1;
          marker.stroke = am4core.color("#ccc");
        }
      }

      // chart.legend.labels.template.fill = am4core.color(
      //   theme.palette.mode === "dark" ? "#FFFFFFB2" : "#000000B2"
      // );
      chart.legend.labels.template.text = "{category}";
      chart.legend.valueLabels.template.disabled = true;
      markerTemplate.width = 10;
      markerTemplate.height = 10;
    }

    chart.data = data;
    chart.hiddenState.properties.opacity = 0;
    chart.innerRadius = 60;

    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.slices.template.strokeWidth = 0;
    series.slices.template.strokeOpacity = 0;
    series.slices.template.stroke = am4core.color("transparent"); // Transparent stroke

    series.ticks.template.propertyFields.stroke = "color"; // Use color field from data
    series.slices.template.propertyFields.fill = "color"; // Use color field from data
    series.labels.template.propertyFields.fill = "color"; // Use color field from data
    series.labels.template.text = "{category}: {value.value}";

    series.slices.template.events.on("over", (event) => {
      const slice = event.target;
      const dataContext = slice.dataItem?.dataContext as PieChartData;

      if (dataContext) {
        const originalColor = dataContext.color; // Use color from data context
        slice.strokeWidth = 2; // Add a border on hover
        slice.stroke = am4core.color("#fff"); // Change border color on hover
        slice.fill = am4core.color("#FF5733"); // Change fill color on hover
      }
    });

    series.slices.template.events.on("out", (event) => {
      const slice = event.target;
      const dataContext = slice.dataItem?.dataContext as PieChartData;

      if (dataContext) {
        slice.strokeWidth = 0;
        slice.stroke = am4core.color("transparent");

        // Revert to original color
        slice.fill = am4core.color(dataContext.color);
      }
    });

    return () => {
      chart.dispose();
    };
  }, [data, displayLegend, label]);

  return (
    <Grid
      // bgcolor={bgColor}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${borderColor}`,
        boxShadow: `7px 13px 24px 1px ${boxShadowColor}`,
      }}
      p={2}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        // bgcolor={bgColor}
      >
        <Typography variant="h5" pl={3} pb={2} >
          {label}
        </Typography>

        {visibleAssetInsightLastScanDate && (
          <Typography
            variant="subtitle2"
            pr={3}
            pb={2}
            color="grey"
            textAlign="right"
          >
            Last Scan:{" "}
            {lastPublished ? moment(lastPublished).format("LLL") : "No Record"}
          </Typography>
        )}
      </Grid>
      <Divider />
      {data?.length && data?.some((item) => item.value > 0) ? (
        <Grid pt={2} pb={2} width="100%" height="370px">
          <div
            id={`chartdiv-${label}`}
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
      ) : (
        <Grid
          alignContent={"center"}
          textAlign={"center"}
          xs={12}
          width="100%"
          height="380px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          gap={0.5}
        >
          <ErrorIcon  />
          <Typography >No Vulnerability Found</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AssetDetailPiechart;
