import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import axios from "src/utils/axios";
import { setLoading } from "./user";
import { FetchParams, LocationState } from "./reducerTypes";
import { notificationManager } from "src/ui-component/notificationManager";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { PORTAL_TYPE } from "src/constant";

const locationEndPointClient = "client/v1/asset/location";
const locationEndPointConsultant = "consultant/v1/asset/location";
const initialState: LocationState = {
  error: null,
  locationList: [],
  addLocationResponse: null,
  selectedLocationResponse: {
    almId: 0,
    locationCode: "",
    locationDesc: "",
  },
  updateLocationResponse: {},
  deleteLocationResponse: {},
};

const location = createSlice({
  name: "location",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    // Fetch Location List
    fetchLocationListSuccess(state, action) {
      state.locationList = action.payload;
    },
    // Add Location
    addLocationSuccess(state, action) {
      state.addLocationResponse = action.payload;
    },
    // Fetch Selected Location
    fetchSelectedLocationSuccess(state, action) {
      state.selectedLocationResponse = action.payload;
    },
    // Update Location
    updateLocationSuccess(state, action) {
      state.updateLocationResponse = action.payload;
    },
    // Delete Location
    deleteLocationSuccess(state, action) {
      state.updateLocationResponse = action.payload;
    },
  },
});

export default location.reducer;

export function fetchLocationList(clientOrgId?: number) {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE?.CONSULTANT
        ? `${locationEndPointConsultant}/list/${clientOrgId}`
        : `${locationEndPointClient}/list`;

    try {
      const response = await axios.get(url);
      dispatch(location.actions.fetchLocationListSuccess(response.data));
    } catch (error) {
      dispatch(location.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function addLocation(payload: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${locationEndPointClient}`, payload);
      dispatch(location.actions.addLocationSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(location.actions.hasError(error));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
export function fetchSelectedLocation(assetLocationId: number | string) {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${locationEndPointClient}/${assetLocationId}`
      );
      dispatch(location.actions.fetchSelectedLocationSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(location.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
export function updateLocation(payload: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(`${locationEndPointClient}`, payload);
      dispatch(location.actions.updateLocationSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(location.actions.hasError(error));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
export function deleteLocation(payload: { idList: string[] }) {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.request({
        url: `${locationEndPointClient}`,
        method: "delete",
        data: payload,
      });
      dispatch(location.actions.deleteLocationSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(location.actions.hasError(error));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
