import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import theme from "src/themes/theme";

interface DeleteProps {
  handleClose?: () => void;
  handleDeleteAction: (id: string | any) => void;
  loading?: boolean; // Renamed for clarity
}

const lableStyle = {
  // color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: "600",
  fontSize: "20px",
  lineHeight: "33px",
  letterSpacing: "0px",
};

const subLableStyle = {
  // color: "#A2A2D2",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0px",
};

const Delete: React.FC<DeleteProps> = ({
  handleClose,
  handleDeleteAction,
  loading,
}) => {
  return (
    <>
      <Stack
        maxWidth={"683px"}
        // direction="column"
        // justifyContent="center"
        // alignItems="center"
        gap={"7px"}
        mt={0}
      >
        <Typography
          sx={{
            ...lableStyle,
          }}
        >
          Are you sure you want to delete the selected record?
        </Typography>
        <Typography
          sx={{
            ...subLableStyle,
          }}
        >
          By deleting this column, all tasks inside it will also be deleted.
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" gap={2} mt={4}>
        <Button
          size="large"
          variant="outlined"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            borderRadius: "10px",
            color: " #F45F43",
            border: "1px solid",
            height: "50px",
            borderImageSlice: 1,
            textTransform: "none",
            "&:hover": {
              border: "1px solid",
              borderRadius: "10px",
              color: "#F45F43",
              borderImageSlice: 1,
            },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px !important",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          onClick={handleDeleteAction}
          loading={loading as boolean}
        >
          Delete
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Delete;
