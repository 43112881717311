export enum ASSET_TYPE {
  APPLICATION = "APPLICATION",
  SERVERS = "SERVERS",
  NETWORK = "NETWORK_DEVICES",
  ENDPOINT = "ENDPOINTS",
  CLOUD = "CLOUD",
  ALL = "",
}

export enum SCAN_TYPE {
  BLACK_BOX = "Black_Box",
  GREY_BOX = "GREY_Box",
  SCR = "SCR",
  VA = "VA",
  CA = "CA",
}
export enum SCAN_TYPE_DETAIL {
  VAPT = "VAPT",
  VULNERABILITY_ASSESSMENT = "VA",
  CONFIGURATION_AUDIT = "CA",
  SECURE_CODE_REVIEW = "SCR",
  BLACKBOX = "BLACKBOX",
  GREYBOX = "GREYBOX",
}

export const scanCategory = [
  {
    scanCategory: "New",
    scanCategoryId: "N",
  },
  {
    scanCategory: "Calender",
    scanCategoryId: "C",
  },
  {
    scanCategory: "Adhoc",
    scanCategoryId: "A",
  },
];
export const AssetInsightGraph = {
  greyBlackBox: ["Application", "Others"],
  CA: ["Servers", "Endpoints", "Network Devices", "Cloud", "Others"],
  VA: ["Servers", "Endpoints", "Network Devices", "Cloud", "Others"],
  VAPT: ["Servers", "Endpoints", "Network Devices", "Cloud", "Others"],
  SCR: ["Application", "Others"],
};
