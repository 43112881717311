import React, { ElementType } from "react";
import PropTypes from "prop-types";

// material-ui
import { Autocomplete, FormControl, TextField } from "@mui/material";
import {
  DataProps,
  FormAutoCompleteProps,
} from "src/views/pages/consultant/vulnerabilities/vulnerabiltyTypes";
import theme from "src/themes/theme";

// ==============================|| FORM CONTROL SELECT ||============================== //

const FormAutoComplete: React.FC<DataProps> = ({
  placeholder,
  captionLabel,
  itemsList,
  formState,
  iconPrimary,
  iconSecondary,
  selected,
  textPrimary,
  textSecondary,
  onChange,
  name,
  optionLabel,
  optionValue,
  errors,
  clearIcon,
  ...props
}) => {
  // Render Primary Icon
  const primaryIcon = iconPrimary
    ? React.createElement(iconPrimary, {
        fontSize: "small",
        sx: { color: theme.palette.grey[700] },
      })
    : null;

  // Render Secondary Icon
  const secondaryIcon = iconSecondary
    ? React.createElement(iconSecondary, {
        fontSize: "small",
        sx: { color: theme.palette.grey[700] },
      })
    : null;

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...props}
        onChange={onChange}
        options={itemsList || []}
        getOptionLabel={(options: any) => options[optionLabel] || ""}
        renderInput={(params: any) => (
          <TextField placeholder={name} {...params} {...props} />
        )}
        clearIcon={clearIcon}
      />
    </FormControl>
  );
};

// FormAutoComplete.propTypes = {
//   captionLabel: PropTypes.string,
//   itemsList: PropTypes.array.isRequired,
//   formState: PropTypes.string,
//   iconPrimary: PropTypes.elementType, // Updated to match TypeScript expectations
//   iconSecondary: PropTypes.elementType, // Updated to match TypeScript expectations
//   selected: PropTypes.string,
//   textPrimary: PropTypes.string,
//   textSecondary: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   name: PropTypes.string.isRequired,
//   optionLabel: PropTypes.string.isRequired,
//   optionValue: PropTypes.string.isRequired,
//   errors: PropTypes.object,
//   clearIcon: PropTypes.bool,
// };

export default FormAutoComplete;
