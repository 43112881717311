import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import defaultConfig from '../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { ConfigState } from 'src/types';

// Initial state
const initialState: ConfigState = {
  ...defaultConfig,
  onChangeLayout: () => {},
  onChangeDrawer: () => {},
  onChangeMenuType: () => {},
  onChangePresetColor: () => {},
  onChangeLocale: () => {},
  onChangeRTL: () => {},
  onChangeContainer: () => {},
  onChangeFontFamily: () => {},
  onChangeBorderRadius: () => {},
  onChangeOutlinedField: () => {},
  onReset: () => {},
};

// Context creation
const ConfigContext = createContext<ConfigState>(initialState);

interface ConfigProviderProps {
  children: ReactNode;
}

const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useLocalStorage('berry-config', {
    layout: initialState.layout,
    drawerType: initialState.drawerType,
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius,
    container: initialState.container,
    outlinedFilled: initialState.outlinedFilled,
    navType: initialState.navType,
    presetColor: initialState.presetColor,
    locale: initialState.locale,
    rtlLayout: initialState.rtlLayout,
  });

  // Handlers// Handlers
const onChangeLayout = (layout: string) => setConfig({ ...config, layout });
const onChangeDrawer = (drawerType: string) => setConfig({ ...config, drawerType });
const onChangeMenuType = (navType: string) => setConfig({ ...config, navType });
const onChangePresetColor = (presetColor: 'theme1' | 'theme2' | 'theme3' | 'theme4' | 'theme5' | 'theme6' | 'default') => setConfig({ ...config, presetColor });
const onChangeLocale = (locale: string) => setConfig({ ...config, locale });
const onChangeRTL = (rtlLayout: boolean) => setConfig({ ...config, rtlLayout });
const onChangeContainer = (container: string|boolean|unknown) => setConfig({ ...config, container });
const onChangeFontFamily = (fontFamily: string) => setConfig({ ...config, fontFamily });
// Updated handler for onChangeBorderRadius
const onChangeBorderRadius = (newValue: number) => setConfig({ ...config, borderRadius: newValue });
const onChangeOutlinedField = (outlinedFilled: boolean) => setConfig({ ...config, outlinedFilled });
const onReset = () => setConfig({ ...defaultConfig });


  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLayout,
        onChangeDrawer,
        onChangeMenuType,
        onChangePresetColor,
        onChangeLocale,
        onChangeRTL,
        onChangeContainer,
        onChangeFontFamily,
        onChangeBorderRadius,
        onChangeOutlinedField,
        onReset,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

// PropTypes validation
// ConfigProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export { ConfigProvider, ConfigContext };
