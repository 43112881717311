import React from "react";
import PropTypes from "prop-types";

// material-ui
import { experimentalStyled as styled } from "@mui/material";
import { InputLabel as MuiInputLabel } from "@mui/material";
import theme from "src/themes/theme";

const BInputLabel = styled((props) => <MuiInputLabel {...props} />, {
  shouldForwardProp: (prop) => prop !== "horizontal",
})<{ horizontal: boolean; children: React.ReactNode }>(({ horizontal }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  marginBottom: horizontal ? 0 : 8,
}));

const InputLabel: React.FC<{
  children: React.ReactNode;
  horizontal?: boolean;
  [key: string]: any;
}> = ({ children, horizontal, ...others }) => {
  return (
    <BInputLabel horizontal={horizontal ?? false} {...others}>
      {children}
    </BInputLabel>
  );
};

// InputLabel.propTypes = {
//     children: PropTypes.node,
//     horizontal: PropTypes.bool
// };

InputLabel.defaultProps = {
  horizontal: false,
};

export default InputLabel;
