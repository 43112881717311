import React, { Suspense, useEffect, useRef } from "react";

// project imports
import Locales from "./ui-component/Locales";
import RTLLayout from "./ui-component/RTLLayout";
import Snackbar from "./ui-component/extended/Snackbar";

// auth provider
import { JWTProvider as AuthProvider } from "./contexts/JWTContext";

import GlobalLoader from "./views/Common/GlobalLoader";
import NavigationScroll from "./layout/NavigationScroll";
import ThemeCustomization from "./themes/index";
import router from "./routes/index";
import { RouterProvider } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

// ==============================|| APP ||============================== //

const App: React.FC = () => {
  // const currentVersionRef = useRef<string | null>(null);

  // useEffect(() => {
  //   const checkVersion = async () => {
  //     try {
  //       const response = await fetch("/version.json", { cache: "no-store" });
  //       const data: { version: string } = await response.json();

  //       if (
  //         currentVersionRef.current &&
  //         data.version !== currentVersionRef.current
  //       ) {
  //         toast("New version available. Refreshing...", {
  //           icon: "🔄",
  //           duration: 5000,
  //         });
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 2000);
  //       }

  //       currentVersionRef.current = data.version;
  //     } catch (error) {
  //       console.error("Error fetching version:", error);
  //       toast.error("Failed to check app version.");
  //     }
  //   };

  //   // Initial version check and set up interval
  //   checkVersion();
  //   const interval = setInterval(checkVersion, 5 * 60 * 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ThemeCustomization>
      <RTLLayout>
        {/* <Locales> */}
        <NavigationScroll>
          <AuthProvider>
            <>
              <GlobalLoader />
              <Suspense fallback={<div className="loader">Loading...</div>}>
                <RouterProvider router={router} />
              </Suspense>
              <Snackbar />
              <Toaster position="top-center" reverseOrder={false} />
            </>
          </AuthProvider>
        </NavigationScroll>
        {/* </Locales> */}
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
