import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useTheme,
  Divider, // Imported Divider
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import useAuth from "src/hooks/useAuth";
import useConfig from "src/hooks/useConfig";
import { getUserDetail } from "../../../../store/slices/user";
import { dispatch } from "src/store";
import UserProfile from "../../../../assets/images/UserProfile.svg";
import LogoutIcon from "../../../../assets/images/logout_icon.svg";
import ChangedPasswordIcon from "../../../../assets/images/changePassword_Icon.svg";
import DisplayLoginUserName from "../DisplayLoginUserName";

const ProfileSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { onChangeMenuType } = useConfig();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderColor: "transparent",
          "& .MuiChip-label": { visibility: "hidden" },
        }}
        icon={
          <Avatar
            src={UserProfile}
            sx={{ cursor: "pointer" }}
            ref={anchorRef}
            onClick={handleToggle}
            alt="user-account"
          />
        }
        variant="outlined"
      />
      <DisplayLoginUserName />

      <Popper
        sx={{
          zIndex: 1300,
          transform: "translate(calc(100vw - 200px), calc(7vh + 20px))",
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper
            sx={{
              borderRadius: "10px",
              overflow: "hidden",
              position: "fixed",
              top: "-10px",
              left: "-33px",
            }}
          >
            <MainCard border={false} sx={{ py: 0 }} content={false}>
              <Box>
                <List
                  component="nav"
                  sx={{
                    // background: "#28284E",
                    borderRadius: "10px",
                    maxWidth: 350,
                    width: "185px",
                  }}
                >
                  <ListItemButton onClick={handleChangePassword}>
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      <img
                        src={ChangedPasswordIcon}
                        alt="Change Password"
                        width={16}
                        height={16}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{  fontSize: "12px" }}>
                          Change Password
                        </Typography>
                      }
                    />
                  </ListItemButton>

                  {/* Horizontal Line */}
                  <Divider
                    sx={{
                      borderColor: "#181835", // Change border color
                      borderWidth: "1px", // Ensure the border is visible
                      borderStyle: "solid", // Set the border style
                      marginX: "10px", // 10px gap from right and left
                    }}
                  />

                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      <img
                        src={LogoutIcon}
                        alt="Logout"
                        width={16}
                        height={16}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={{  fontSize: "12px" }}>
                          Logout
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </Box>
            </MainCard>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ProfileSection;
