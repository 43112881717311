// action - state management
import { dispatch } from "../store";
import { CHANGE_PASSWORD, LOGIN, LOGOUT, REGISTER } from "./actions";
import { addUserInfo } from "./slices/userInfoReducer";
import { locale } from "moment";
import { getMenu, getMenuSuccess } from "./slices/menu";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: any) => {
  dispatch(
    addUserInfo({
      ...state,
      ...action?.payload,
      serviceToken: localStorage.getItem("serviceToken"),
    })
  );

  switch (action?.type) {
    case LOGIN: {
      return {
        ...action?.payload,
        isLoggedIn: true,
        isInitialized: true,
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...action?.payload,
        isLoggedIn: true,
        isInitialized: true,
      };
    }
    case LOGOUT: {
      dispatch(addUserInfo({}));
      dispatch(getMenuSuccess([]));
      localStorage.removeItem("serviceToken");
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
