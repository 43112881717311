import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatch, useSelector } from "src/store";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BugReportIcon from "@mui/icons-material/BugReport";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { IconDashboard } from "@tabler/icons-react";
import { IconAffiliate, IconHeartRateMonitor } from "@tabler/icons-react";
import "./index.css";
import { getMenutitleForHeader } from "src/store/slices/menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useConfig from "src/hooks/useConfig";

export interface MenuItem {
  menuCode: string;
  menuDesc: string;
  menuPath: string;
  menuIcon: string;
  menuChildren: MenuItem[] | null;
}

interface SidebarProps {
  isOpen: boolean;
  menuData: MenuItem[];
}
const iconMapping: { [key: string]: JSX.Element } = {
  AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
  DocumentScannerIcon: <DocumentScannerIcon />,
  PeopleOutlineIcon: <PeopleOutlineIcon />,
  CorporateFareIcon: <CorporateFareIcon />,
  HomeIcon: <HomeIcon />,
  IconDashboard: <IconDashboard />,
  TextSnippetIcon: <TextSnippetIcon />,
  HomeRepairServiceOutlinedIcon: <HomeRepairServiceOutlinedIcon />,
  CropFreeOutlinedIcon: <CropFreeOutlinedIcon />,
  AssessmentIcon: <AssessmentIcon />,
  InsightsIcon: <InsightsIcon />,
  BugReportIcon: <BugReportIcon />,
  IconHeartRateMonitor: <IconHeartRateMonitor />,
  IconAffiliate: <IconAffiliate />,
};

const getIconComponent = (iconName: string) => {
  return iconMapping[iconName] || <IconAffiliate />;
};

interface SubMenuProps {
  items: MenuItem[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onNavigate: (path: string, menuDesc: string) => void;
}

const textStyle = {
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0%",
  marginLeft: "6px",
};
const SubMenu: React.FC<SubMenuProps> = ({
  items,
  onMouseEnter,
  onMouseLeave,
  onNavigate,
}) => {
  const [activeItem, setActiveItem] = React.useState<string | null>(null);
  const submenuTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const { navType } = useConfig();
  const textColor = navType === "dark" ? "#ffffff" : "black";
  const bgColor = navType === "dark" ? "#28284E" : "#ffffff";

  return (
    <div
      className={`absolute left-full top-0 rounded-lg shadow-lg border border-gray-200 min-w-[200px] z-50 border-[#A2A2D2]`}
      style={{ background: bgColor }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => {
        submenuTimeout.current = setTimeout(() => setActiveItem(null), 500);
      }}
    >
      <div className="py-2">
        {items.map((item) => (
          <div
            key={item.menuCode}
            className="relative"
            onMouseEnter={() => {
              if (submenuTimeout.current) clearTimeout(submenuTimeout.current);
              setActiveItem(item.menuCode);
            }}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (item.menuPath !== "#") {
                  onNavigate(item.menuPath, item.menuDesc);
                }
              }}
              className={`flex justify-between w-full px-4 py-2 text-sm rounded-lg transition-all duration-300  bg-transparent hover:bg-${bgColor} hover:shadow-md`}
            >
              <span
                title={item.menuDesc}
                className="w-[15em] whitespace-nowrap overflow-hidden text-ellipsis inline-block text-left"
                style={{ color: textColor }}
              >
                {item.menuDesc}
              </span>
              {item.menuChildren && <ChevronRightIcon className="w-4 h-4" />}
            </button>
            {item.menuChildren && activeItem === item.menuCode && (
              <SubMenu
                items={item.menuChildren}
                onMouseEnter={() => {
                  if (submenuTimeout.current)
                    clearTimeout(submenuTimeout.current);
                  setActiveItem(item.menuCode);
                }}
                onMouseLeave={() => {
                  submenuTimeout.current = setTimeout(
                    () => setActiveItem(null),
                    500
                  );
                }}
                onNavigate={onNavigate}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const PopoutMenu: React.FC<{
  item: MenuItem;
  isOpen: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  position: { top: number; left: number };
  onNavigate: (path: string, menuDesc: string) => void;
}> = ({ item, onMouseEnter, onMouseLeave, position, onNavigate, isOpen }) => {
  const [activeSubmenu, setActiveSubmenu] = React.useState<string | null>(null);
  const { navType } = useConfig();
  const textColor = navType === "dark" ? "#ffffff" : "black";
  const bgColor = navType === "dark" ? "#28284E" : "#ffffff";

  const handleClick = (child: MenuItem, e: React.MouseEvent) => {
    e.stopPropagation();
    if (child.menuPath !== "#") {
      onNavigate(child.menuPath, child.menuDesc);
    }
  };

  let submenuTimeout: NodeJS.Timeout;

  return (
    <div
      className="fixed rounded-lg shadow-lg border border-gray-200 z-[1050]"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        minWidth: "200px",
        // background: "#28284E",

        background: bgColor,

        border: "1px solid #A2A2D2",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="py-2">
        {!isOpen && (
          <div
            className="px-4 py-2 text-sm text-gray-900"
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: textColor,
            }}
          >
            {item.menuDesc}
          </div>
        )}
        {item.menuChildren?.map((child) => (
          <div
            key={child.menuCode}
            className="relative"
            onMouseEnter={() => {
              clearTimeout(submenuTimeout);
              setActiveSubmenu(child.menuCode);
            }}
            onMouseLeave={() => {
              submenuTimeout = setTimeout(() => setActiveSubmenu(null), 500);
            }}
          >
            <button
              onClick={(e) => handleClick(child, e)}
              className="flex items-center justify-between w-full px-4 py-2 text-sm transition-all duration-300 rounded-lg"
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "0px",
                color: textColor,
                background: "transparent",
                transition: "background 0.3s, box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background = bgColor; // Darker shade for hover
                e.currentTarget.style.boxShadow =
                  "0px 4px 10px rgba(0, 0, 0, 0.5), inset 0px 2px 4px rgba(255, 255, 255, 0.1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background = "transparent";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <span>{child.menuDesc}</span>
              {child.menuChildren && child.menuChildren.length > 0 && (
                <ChevronRightIcon className="w-4 h-4" />
              )}
            </button>

            {child.menuChildren &&
              child.menuChildren.length > 0 &&
              activeSubmenu === child.menuCode && (
                <SubMenu
                  items={child.menuChildren}
                  onMouseEnter={() => {
                    clearTimeout(submenuTimeout);
                    setActiveSubmenu(child.menuCode);
                  }}
                  onMouseLeave={() => {
                    submenuTimeout = setTimeout(
                      () => setActiveSubmenu(null),
                      500
                    );
                  }}
                  onNavigate={onNavigate}
                />
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, menuData }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current path
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
  const [hoveredItem, setHoveredItem] = React.useState<string | null>(null);
  const [menuPosition, setMenuPosition] = React.useState({ top: 0, left: 0 });
  const menu = useSelector((state: any) => state.menu);
  const { navType } = useConfig();
  const textColor = navType === "dark" ? "#ffffff" : "black";
  const activeEffect = navType === "dark" ? "icon-effect" : "icon-effect-light";

  const toggleExpand = (menuCode: string) => {
    setExpandedItems((prev) =>
      prev.includes(menuCode)
        ? prev.filter((code) => code !== menuCode)
        : [...prev, menuCode]
    );
  };

  const handleMouseEnter = (e: React.MouseEvent, menuCode: string) => {
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    setMenuPosition({
      top: rect.top,
      left: isOpen ? rect.right : rect.right,
    });
    setHoveredItem(menuCode);
  };

  const handleNavigate = (path: string, menuDesc: string) => {
    if (path !== "#") {
      navigate(path);
      dispatch(getMenutitleForHeader(menuDesc));
    }
  };
  function findMenuItem(
    menuList: MenuItem[],
    targetPath: string
  ): MenuItem[] | null {
    for (const menu of menuList) {
      if (menu.menuPath === targetPath) {
        return [menu]; // Return the found object in an array
      }
      if (menu.menuChildren) {
        const result = findMenuItem(menu.menuChildren, targetPath);
        if (result) return [menu, ...result];
      }
    }
    return null; // Return null if not found
  }

  // const menuData = [
  //   /* Paste your JSON object here */
  // ];

  const renderMenuItem = (
    item: MenuItem,
    level: number = 0,
    activePath: MenuItem[] | null
  ) => {
    const hasChildren = item.menuChildren && item.menuChildren.length > 0;
    const isHovered = hoveredItem === item.menuCode;
    const isCollapsed = !isOpen;

    const isActive = activePath?.some(
      (activeItem) => activeItem.menuCode === item.menuCode
    );

    return (
      <div
        key={item.menuCode}
        className="w-full relative"
        style={{
          paddingInline: isCollapsed ? "0px" : "15px",
        }}
      >
        <button
          onClick={() => {
            if (hasChildren) {
              toggleExpand(item.menuCode);
            } else if (item.menuPath !== "#") {
              handleNavigate(item.menuPath, item.menuDesc);
            }
          }}
          onMouseEnter={(e) => handleMouseEnter(e, item.menuCode)}
          onMouseLeave={() => setHoveredItem(null)}
          className={`w-full flex items-center justify-between px-4 text-white transition-all duration-300
      ${isCollapsed && (isHovered || isActive) ? "glow-circle" : ""}
      ${!isCollapsed && isActive ? "bg-gradient" : ""}
      ${!isCollapsed && isHovered ? "open-sidebar-hover" : ""}`}
          style={{
            paddingBlock: isCollapsed ? "9px" : "10px",
            // border: "1.2px solid",

            // borderImageSource:
            //   "linear-gradient(94.51deg, #C9218F -51.81%, #6917C9 101.41%)",

            // background:
            //   "linear-gradient(94.51deg, rgba(201, 33, 143, 0.15) -51.81%, rgba(105, 23, 201, 0.15) 101.41%)",
          }}
        >
          <div
            className="flex items-center space-x-3 relative"
            style={{ paddingInline: isCollapsed ? "10px" : "0" }}
          >
            {level === 0 && (
              <span
                className={`relative flex items-center justify-center transition-all duration-300
                ${isCollapsed && (isHovered || isActive) ? "glow-circle" : ""}`}
              >
                <div
                  className={`${isCollapsed ? "icon-container" : ""} ${
                    isCollapsed && isActive ? activeEffect : ""
                  }${isCollapsed && isHovered ? "icon-hover" : ""}`}
                  style={{ color: textColor }}
                >
                  {getIconComponent(item.menuIcon)}
                </div>
              </span>
            )}

            <span
              title={item.menuDesc}
              className={`${isOpen ? "block" : "hidden"} text-[16px]`}
              style={{ color: textColor }}
            >
              {item.menuDesc.length > 15
                ? `${item.menuDesc.substring(0, 14)}...`
                : item.menuDesc}
            </span>

            {/* Left indicator when hovered or active */}
            {isCollapsed && isActive && (
              <span className="left-indicator"></span>
            )}
          </div>

          {hasChildren && isOpen && (
            <ChevronRightIcon className="w-4 h-4 text-white" />
          )}
        </button>

        {isHovered && hasChildren && (
          <PopoutMenu
            isOpen={isOpen}
            item={item}
            onMouseEnter={() => setHoveredItem(item.menuCode)}
            onMouseLeave={() => setHoveredItem(null)}
            position={menuPosition}
            onNavigate={handleNavigate}
          />
        )}
      </div>
    );
  };
  const targetPath = location.pathname;
  const activePath = findMenuItem(menu?.menu, targetPath);
  return (
    <aside
      style={{
        border: "1px solid transparent",
        borderImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 8.19%, ${
          navType === "dark"
            ? "rgba(255, 255, 255, 0.8) 49.63%"
            : "rgba(24, 21, 21, 0.8) 49.63%"
        }, rgba(255, 255, 255, 0.08) 91.06%) 1`,
        marginTop: "0px",
        // backgroundColor: "#1E1E3A",
        transition: "width 0.3s ease-in-out",
        width: isOpen ? "16rem" : "6rem",
      }}
      className="fixed top-16 left-0 h-[calc(100vh-2rem)] z-[1000] shadow-lg flex flex-col"
    >
      {/* Adjusted to take more space (based on isOpen state) */}
      <div style={{ flexGrow: isOpen ? 0.2 : 0.4 }}></div>

      {/* Menu items take the remaining space */}
      <nav className="flex-grow flex flex-col items-center">
        {menu?.menu.length > 0 &&
          menu?.menu?.map((item: MenuItem) =>
            renderMenuItem(item, 0, activePath)
          )}
      </nav>
    </aside>
  );
};
export default Sidebar;
