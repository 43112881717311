// third-party imports
import { configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { persistStore } from "redux-persist";

// project imports
import rootReducer from "./reducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

// Type the RootState based on the rootReducer
export type RootState = ReturnType<typeof rootReducer>;

// Define AppDispatch type based on the store's dispatch
export type AppDispatch = typeof store.dispatch;

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state check
      immutableCheck: false, // Disable immutability check
    }),
});

// Create a persister for Redux persist
const persister = persistStore(store);

// Typed hooks for dispatch and selector
export type ReducerRootState = ReturnType<typeof store.getState>;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

// Export store, persister, and dispatch
export const { dispatch } = store;
export { store, persister };
