// third-party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the state type
interface UserInfoState {
  error: string | null; // Error can be a string or null
  userInfo: Record<string, unknown> | any; // You can use a more specific type instead of `Record<string, any>` if you know the structure of `userInfo`
}

// Initial state
const initialState: UserInfoState = {
  error: null,
  userInfo: {},
};

// Create the slice
const slice = createSlice({
  name: "user-info",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    // Add USER_INFO
    addUserInfo(state, action: PayloadAction<Record<string, unknown>>) {
      state.userInfo = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { addUserInfo } = slice.actions;
