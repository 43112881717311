import React from "react";
import { Card, darken, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useConfig from "src/hooks/useConfig";
import Palette from "src/themes/palette";
import "./VmTable.css"; // Import your CSS file
import { download, generateCsv, mkConfig } from "export-to-csv";
import theme from "src/themes/theme";
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
interface VmTableProps {
  data: any[];
  handleCloseAssetGroupDialog?: () => void;
  columns: any[];
  isLoading?: boolean;
  selectedIdsField?: string;
  handleChangeRowSelect?: (selectedIds: string[] | any) => void;
  selectedIds?: string[] | any;
  enableRowSelection?: boolean;
  tableActions?: any;
  enableGlobalFilter?: boolean;
  enablePagination?: boolean;
  enableBottomToolbar?: boolean;
  headerAction?: React.ReactNode;
  tableTitle?: React.ReactNode;
  customAccordion?: React.ReactNode;
  sx?: any;
}

interface RootState {
  userState: {
    setDataloading: boolean;
  };
}

const VmTable: React.FC<VmTableProps> = (props) => {
  const { borderRadius, fontFamily, navType, presetColor } = useConfig();
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const { setDataloading } = useSelector(
    (state: RootState) => state?.userState
  );
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(props.data);
    download(csvConfig)(csv);
  };
  useEffect(() => {
    if (rowSelection) {
      const selectedIds = Object.keys(rowSelection);
      if (props?.handleChangeRowSelect) {
        props.handleChangeRowSelect(selectedIds);
      }
    }
  }, [rowSelection]);
  useEffect(() => {
    if (Object.keys(rowSelection)?.length && !props?.selectedIds?.length) {
      setRowSelection({});
    }
  }, [props?.selectedIds]);

  // const themes = useMemo(
  //   () => Palette(navType as PaletteMode, presetColor),
  //   [navType, presetColor]
  // );
  // background: rgba(29, 29, 58, 1);

  const baseBackgroundColor = navType === "dark" ? "#1e1e3d" : "#ffffff";

  const columns = useMemo(
    () =>
      props.columns.map(({ size, ...column }) => ({
        ...column,
        Header: () => (
          <Typography
            variant="subtitle2"
            sx={{
              padding: "0px",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {column.header}
          </Typography>
        ),
      })),
    [props.columns]
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    enableStickyHeader: true,
    enableRowSelection: props.enableRowSelection,
    enableRowActions: props.tableActions,
    getRowId: (row) =>
      props.selectedIdsField ? row[props.selectedIdsField] : undefined,
    renderRowActions: () => props.tableActions,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    positionActionsColumn: "last",
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "15px",
        background: baseBackgroundColor,

        border: "none",
        // backgroundColor: baseBackgroundColor, // Inherit background color from parent
        boxShadow: "none",
        overflow: "auto",
        paddingBottom: "5px", // Added padding to the bottom
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#F45F43",
        height: "0px",
        paddingLeft: "14px",
        // paddingLeft: props.enableRowSelection ? "40px" : "14px",
        paddingTop: "13px",
        paddingBottom: "13px",
        padding: "0px",
        color: "#fff",
        // wordBreak: "break-word",
        "& .MuiIconButton-root": {
          display: "none",
        },
        borderRight: "none",
        alignItems: "center",
        border: "none",
        "& .MuiTableSortLabel-icon": {
          fontSize: "25px",
          color: "#ffffff !important",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        maxHeight: "auto",
        height: "0px",
        borderRight: "none",
        borderBottom: "none",
        paddingY: "3px",
        border: "none",
      },
    },
    muiTableBodyProps: {
      sx: () => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1),
            // paddingLeft: "40px",
            borderRight: "none",
            borderBottom: "none",
            border: "none",
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
            // paddingLeft: "40px",
            borderRight: "none",
            borderBottom: "none",
            border: "none",
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.3),
            // paddingLeft: "40px",
            borderRight: "none",
            borderBottom: "none",
            border: "none",
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
            // paddingLeft: "40px",
            borderRight: "none",
            borderBottom: "none",
            border: "none",
          },
      }),
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: baseBackgroundColor, // Apply red background
        border: "none",
      },
    },
    enablePagination: props?.enablePagination,
    // pageSizeOptions: [10, 20, 50], // Define your page size options
    initialState: { pagination: { pageSize: 10, pageIndex: 0 } }, // Initial pagination state
    enableTopToolbar: false,
    enableBottomToolbar: props?.enableBottomToolbar,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
  });

  return (
    <Card
      sx={{
        marginLeft: "0px",
        border: "none",
        // backgroundColor: baseBackgroundColor, // Inherit background color from parent
        boxShadow: "none", // Removes shadow
        textDecoration: "none",
        textDecorationColor: "none",
        textDecorationLine: "none",
        textDecorationStyle: "none",
        textDecorationThickness: "none",
        background: baseBackgroundColor,
      }}
    >
      {(props.headerAction || props.tableTitle) && (
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            {props.tableTitle}

            <Typography pb={"30px"} variant="h4"></Typography>
            {props.headerAction && (
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
              >
                {props.headerAction}
              </Stack>
            )}
          </Stack>
          {props.customAccordion}
        </Stack>
      )}
      <MaterialReactTable table={table} />
    </Card>
  );
};

export default VmTable;
