import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MainCard from "../../../../ui-component/cards/MainCard";
import SubCard from "../../../../ui-component/cards/SubCard";
import TestCaseInputControls from "./category";
import ActiveTestCase from "./active";
import DeactiveTestCase from "./deactive";
import { dispatch, RootState } from "../../../../store";
import { gridSpacing } from "../../../../store/constant";
import { manageSelectedScanDetailsTab } from "../../../../store/slices/scanReducer";
import {
  getAllOwasp,
  getAllServiceTypes,
  getAllTypes,
  getMasterTestListAPI,
  setServiceTypeId,
  setOwaspCategoryMasterId,
} from "../../../../store/slices/masterTestPlanReducer";
import { TEST_PLAN_MASTER_TYPE } from "../../../../constant";
import theme from "src/themes/theme";

const styles = {
  main: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  chip: {
    cursor: "pointer",
  },
  handleGoBack: {
    color: "grey",
  },
  pages: {
    maxHeight: "45vh",
    overflow: "auto",
    paddingRight: 16,
    paddingBottom: 16,
  },
};

interface FilterState {
  selectedTypes: { testPlanTypeCode?: string } | null;
  selectedServiceType: { serviceId?: string } | null;
  selectedOwasp: { owaspcategoryId?: string; categoryId?: string } | null;
}

interface TabOption {
  label: string;
}

// TabPanel Component
interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

const TestPlanMaster: React.FC = () => {
  const [tabsOption, setTabsOption] = useState<TabOption[]>([]);
  const [filters, setFilters] = useState<FilterState>({
    selectedTypes: null,
    selectedServiceType: null,
    selectedOwasp: null,
  });

  const { allTypes, masterTestList } = useSelector(
    (state: RootState) => state.masterTestPlan
  );

  const { selectedScanDetailsTab } = useSelector(
    (state: RootState) => state.scansState
  );

  useEffect(() => {
    dispatch(getAllTypes());
    dispatch(getAllServiceTypes());
  }, []);

  useEffect(() => {
    const activeCount =
      masterTestList?.filter((item) => item.active).length || 0;
    const deactiveCount =
      masterTestList?.filter((item) => !item.active).length || 0;

    setTabsOption([
      { label: `Active (${activeCount})` },
      { label: `Deactive (${deactiveCount})` },
    ]);
  }, [masterTestList]);

  useEffect(() => {
    if (filters.selectedOwasp?.owaspcategoryId) {
      dispatch(setOwaspCategoryMasterId(filters.selectedOwasp.owaspcategoryId));
    }
  }, [filters.selectedOwasp?.owaspcategoryId]);

  useEffect(() => {
    const { serviceId } = filters.selectedServiceType || {};
    const { testPlanTypeCode } = filters.selectedTypes || {};

    if (
      serviceId &&
      testPlanTypeCode &&
      testPlanTypeCode !== TEST_PLAN_MASTER_TYPE.MISCELLANEOUS
    ) {
      dispatch(getAllOwasp({ serviceId, testPlanTypeCode }));
      dispatch(setServiceTypeId(serviceId));
    }
  }, [
    filters.selectedServiceType?.serviceId,
    filters.selectedTypes?.testPlanTypeCode,
  ]);

  const handleSearchFilters = () => {
    const { selectedOwasp, selectedServiceType, selectedTypes } = filters;
    if (selectedServiceType && selectedTypes) {
      const payload = {
        id: selectedOwasp?.categoryId || 0,
        serviceType: selectedServiceType?.serviceId,
        testPlanTypeCode: selectedTypes?.testPlanTypeCode,
      };
      dispatch(getMasterTestListAPI(payload));
    }
  };

  const payload = {
    id: filters.selectedOwasp?.categoryId,
    serviceType: filters.selectedServiceType?.serviceId,
    testPlanTypeCode: filters.selectedTypes?.testPlanTypeCode,
    testPlanType: allTypes?.find(
      (type) =>
        type?.testPlanTypeCode === filters.selectedTypes?.testPlanTypeCode
    )?.testPlanTypeId,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(manageSelectedScanDetailsTab(newValue));
  };

  return (
    <Stack direction="column">
      <SubCard
        title={
          <Typography variant="h6" style={styles.main}>
            Test Plan Master
          </Typography>
        }
      >
        <TestCaseInputControls
          filters={filters}
          setFilters={setFilters}
          handleSearch={handleSearchFilters}
        />
      </SubCard>

      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Tabs
              value={selectedScanDetailsTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              sx={{
                mb: 0.5,
                "& a": {
                  py: 1.5,
                  px: 1,
                  mr: 2.25,
                  // color:
                  //   theme.palette.mode === "dark" ? "grey.600" : "grey.900",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& a.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                "& .MuiTabs-indicator": {
                  bottom: 2,
                },
              }}
            >
              {tabsOption.map((tab, index) => (
                <Tab
                  key={index}
                  component={Link}
                  to="#"
                  label={tab.label}
                  id={`tab-${index}`}
                  aria-controls={`tabpanel-${index}`}
                />
              ))}
            </Tabs>

            <Box style={styles.pages}>
              <TabPanel value={selectedScanDetailsTab} index={0}>
                <ActiveTestCase payload={payload} />
              </TabPanel>
              <TabPanel value={selectedScanDetailsTab} index={1}>
                <DeactiveTestCase payload={payload} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </MainCard>
    </Stack>
  );
};

export default TestPlanMaster;
