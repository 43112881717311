import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";
import axios from "src/utils/axios";
import { PORTAL_TYPE } from "src/constant";
import { notificationManager } from "src/ui-component/notificationManager";

// Define types for state

interface DepartmentOption {
  departmentName: string;
  dmId: string;
}

 interface ComplianceData {
  openCritical: number;
  openHigh: number;
  openMedium: number;
  openLow: number;
  openInfo: number;
  closeCritical: number;
  closeHigh: number;
  closeMedium: number;
  closeLow: number;
  closeInfo: number;
  wontFixCritical: number;
  wontFixHigh: number;
  wontFixMedium: number;
  wontFixLow: number;
  wontFixInfo: number;
  acceptedRiskCritical: number;
  acceptedRiskHigh: number;
  acceptedRiskMedium: number;
  acceptedRiskLow: number;
  acceptedRiskInfo: number;
  falsePositiveCritical: number;
  falsePositiveHigh: number;
  falsePositiveMedium: number;
  falsePositiveLow: number;
  falsePositiveInfo: number;
  fixedCritical: number;
  fixedHigh: number;
  fixedMedium: number;
  fixedLow: number;
  fixedInfo: number;
  totalOpen: number;
  totalClose: number;
  totalFix: number;
  totalWontFix: number;
  totalAcceptedRisk: number;
  totalFalsePositive: number;
  openComplianceCount: number;
  closeComplianceCount: number;
  fixedComplianceCount: number;
  wontFixedComplianceCount: number;
  falsePositiveComplianceCount: number;
  acceptedRiskComplianceCount: number;
  passedComplianceCount: number;
}
 interface insightDataState {
  GREYBOX: ComplianceData;
  SCR: ComplianceData;
  VAPT: ComplianceData;
  BLACKBOX: ComplianceData;
  VA: ComplianceData;
  CA: ComplianceData;
}

interface ClientDepartmentState {
  error: string | null;
  departmentList: any[];
  departmentMyList: DepartmentOption[];
  addDepartmentRes: Record<string, unknown>;
  selectedDepartmentDetail: Record<string, unknown> | any;
  departmentSpocList: unknown[];
  departmentHeadList: unknown[];
  updateDepartmentRes: unknown[];
  assetGroupListForDepartment: any[];
  deleteDepartmentRes: Record<string, unknown>;
  // departmentInsightData: any | Record<string, string | number | unknown | boolean>[];
  departmentInsightData: insightDataState[];
  departmentRiskComplianceScore: Record<string, unknown>;
  departmentPublishedVulnerabilitiesList: unknown[] | any;
  departmentDownloadVulReportRes: Record<string, unknown>;
  departmentPublishedScansList: unknown | any;
  deleteAssetGroupInDepartmentRes: Record<string, unknown>;
  selectedDepartment: Record<string, unknown>;
}

// Initial state
const initialState: ClientDepartmentState = {
  error: null,
  departmentList: [],
  departmentMyList: [],
  addDepartmentRes: {},
  selectedDepartmentDetail: {},
  selectedDepartment: {},
  departmentSpocList: [],
  departmentHeadList: [],
  updateDepartmentRes: [],
  assetGroupListForDepartment: [],
  deleteDepartmentRes: {},
  departmentInsightData: [],
  departmentRiskComplianceScore: {},
  departmentPublishedVulnerabilitiesList: [],
  departmentDownloadVulReportRes: {},
  departmentPublishedScansList: [],
  deleteAssetGroupInDepartmentRes: {},
};

interface FetchParams {
  [key: string]: string | number | undefined | null;
}

// API Endpoints
const clientDepartmentApiEndpoint = "/client/v1/department";
const consultantDepartmentApiEndpoint = "/consultant/v1/department";
const platformApiEndpoint = "/platform/v1/department";

// Slice
const clientDepartment = createSlice({
  name: "clientDepartment",
  initialState,
  reducers: {
    // Handle error
    hasError(state, action) {
      state.error = action.payload;
    },
    // Success reducers
    fetchDepartmentSuccess(state, action) {
      state.departmentList = action.payload;
    },
    fetchDepartmentMyListSuccess(state, action) {
      state.departmentMyList = action.payload;
    },
    addDepartmentSuccess(state, action) {
      state.addDepartmentRes = action.payload;
    },
    fetchSelectedDepartmentSuccess(state, action) {
      state.selectedDepartmentDetail = action.payload;
    },
    fetchDepartmentSpocListSuccess(state, action) {
      state.departmentSpocList = action.payload;
    },
    fetchDepartmentHeadListSuccess(state, action) {
      state.departmentHeadList = action.payload;
    },
    updateDepartmentSuccess(state, action) {
      state.updateDepartmentRes = action.payload;
    },
    fetchAssetGroupForDepartmentSuccess(state, action) {
      state.assetGroupListForDepartment = action.payload;
    },
    deleteDepartmentSuccess(state, action) {
      state.deleteDepartmentRes = action.payload;
    },
    fetchDepartmentInsightDataSuccess(state, action) {
      state.departmentInsightData = action.payload;
    },
    fetchDepartmentRiskComplianceScoreSuccess(state, action) {
      state.departmentRiskComplianceScore = action.payload;
    },
    fetchDepartmentPublishedVulnerabilitiesListSuccess(state, action) {
      state.departmentPublishedVulnerabilitiesList = action.payload;
    },
    departmentDownloadVulReportSuccess(state, action) {
      state.departmentDownloadVulReportRes = action.payload;
    },
    fetchDepartmentPublishedScansListSuccess(state, action) {
      state.departmentPublishedScansList = action.payload;
    },
    deleteAssetGroupInDepartmentSuccess(state, action) {
      state.deleteAssetGroupInDepartmentRes = action.payload;
    },
  },
});

export default clientDepartment.reducer;

export function fetchDepartmentMyList() {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientDepartmentApiEndpoint}/my-list`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentMyListSuccess(response.data)
      );
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function fetchDepartmentList() {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${clientDepartmentApiEndpoint}/list`);
      dispatch(clientDepartment.actions.fetchDepartmentSuccess(response.data));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export const addDeparment = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.post(`${prefix}`, { ...payload });
      dispatch(clientDepartment.actions.addDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchSelectedDepartment = (payload: { dmId: number | any }) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(
        `${prefix}/details/{dmId}?dmId=${payload?.dmId}`
      );
      dispatch(
        clientDepartment.actions.fetchSelectedDepartmentSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentSpocList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(`${prefix}/department-spoc-user-list`);
      dispatch(
        clientDepartment.actions.fetchDepartmentSpocListSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentHeadList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(`${prefix}/department-head-user-list`);
      dispatch(
        clientDepartment.actions.fetchDepartmentHeadListSuccess(response.data)
      );
      // notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const updateDeparment = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.put(`${prefix}`, { ...payload });
      dispatch(clientDepartment.actions.updateDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const fetchAssetGroupForDepartment = (payload: {
  departmentId: number | string;
}) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? consultantDepartmentApiEndpoint
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? platformApiEndpoint
          : clientDepartmentApiEndpoint;
      const response = await axios.get(
        `${prefix}/department-asset-group-list?departmentId=${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchAssetGroupForDepartmentSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const deleteDepartment = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.delete(`${prefix}`, {
        params: payload,
        // data: payload,
      });
      dispatch(clientDepartment.actions.deleteDepartmentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchDepartmentInsightData = (payload: {
  departmentId: number;
  clientOrgId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios?.get(
        `${prefix}/vulnerability-count/${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentInsightDataSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentPublishedScansList = (payload: {
  departmentId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-scans/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentPublishedScansListSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentPublishedVulnerabilitiesList = (payload: {
  departmentId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-vulnerabilities/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentPublishedVulnerabilitiesListSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentRiskComplianceScore = (payload: {
  departmentId: number;
  clientOrgId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/risk-compliance-score/${payload?.departmentId}`
      );
      dispatch(
        clientDepartment.actions.fetchDepartmentRiskComplianceScoreSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const departmentDownloadVulReport = (payload: {
  departmentId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const prefix =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.get(
        `${prefix}/published-vulnerabilities/export/${payload?.departmentId}`,
        { params: payload }
      );
      dispatch(
        clientDepartment.actions.departmentDownloadVulReportSuccess(
          response.data
        )
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const deleteAssetGroupInDepartment = (payload: {
  dmId: number;
  data: unknown;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const organizationType = localStorage.getItem("organizationType");

    // Determine the correct API endpoint based on organization type
    const prefix =
      organizationType === PORTAL_TYPE.CONSULTANT
        ? consultantDepartmentApiEndpoint
        : organizationType === PORTAL_TYPE.PLATFORM
        ? platformApiEndpoint
        : clientDepartmentApiEndpoint;

    try {
      const response = await axios.delete(`${prefix}/department-asset-group`, {
        params: { dmId: payload?.dmId }, // Pass dmId as query param
        // data: { dmId: payload?.dmId },
      });

      // Dispatch success action
      dispatch(
        clientDepartment.actions.deleteAssetGroupInDepartmentSuccess(
          response.data
        )
      );

      // Manage notification for the successful response
      notificationManager(response);

      return response;
    } catch (error) {
      // Dispatch error-related actions
      dispatch(openSnackbar(error as SnackbarState));

      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(clientDepartment.actions.hasError(errorMessage));

      return Promise.reject(error); // Return rejected promise for further error handling
    } finally {
      dispatch(setLoading(false));
    }
  };
};
