// third-party
import { createSlice } from "@reduxjs/toolkit";

import { AppDispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { setLoading } from "./user";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";
import { SLAInitialState } from "src/views/pages/client/SLA/types";
// ----------------------------------------------------------------------

const slaApiEndpoint = `/client/v1/vulnerability`;

const initialState: SLAInitialState = {
  error: null,
  addSlaData: {},
  slaData: {
    clCriticalFixDays: 0,
    clHighFixDays: 0,
    clInfoFixDays: 0,
    clMediumFixDays: 0,
    clLowFixDays: 0,
  },
  setDataloading: false,
};

const sla = createSlice({
  name: "sla",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    isLoading(state, action) {
      state.setDataloading = action.payload;
    },
    addSlaSuccess(state, action) {
      state.addSlaData = action.payload;
    },
    getSlaSuccess(state, action) {
      state.slaData = action.payload;
    },
  },
});

// Reducer
export default sla.reducer;
export const { isLoading } = sla.actions;

export const addSLA = (payload: unknown) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.put(`${slaApiEndpoint}/sla`, payload);
    dispatch(sla.actions.addSlaSuccess(response.data));
    notificationManager(response);
  } catch (error) {
    dispatch(openSnackbar(error as SnackbarState));
    dispatch(sla.actions.hasError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSLA = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(`${slaApiEndpoint}/sla`);
    dispatch(sla.actions.getSlaSuccess(response.data));
  } catch (error) {
    dispatch(sla.actions.hasError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
