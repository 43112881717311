import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define allowed color variants for alerts
type AlertColor = "primary" | "success" | "info" | "warning" | "error";
type AlertVariant = "filled" | "outlined" | "standard";
type SnackbarVariant = "default" | "alert";
type TransitionType = "Fade" | "Grow" | "SlideLeft" | "SlideUp" | "SlideRight" | "SlideDown";

interface AlertOptions {
  color: AlertColor;
  variant: AlertVariant;
}

interface AnchorOrigin {
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
}

export interface SnackbarState {
  action: boolean;
  open: boolean;
  message: string;
  anchorOrigin: AnchorOrigin;
  variant: SnackbarVariant;
  alert: AlertOptions;
  transition: TransitionType;
  close: boolean;
  maxStack: number;
  dense: boolean;
  iconVariant: string | void | any;
  actionButton: boolean;
}

const initialState: SnackbarState = {
  action: false,
  open: false,
  message: "Execution completed successfully",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  variant: "default",
  alert: {
    color: "primary",
    variant: "filled",
  },
  transition: "Fade",
  close: true,
  maxStack: 3,
  dense: false,
  iconVariant: "hide",
  actionButton: false,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar(state, action: PayloadAction<Partial<SnackbarState>>) {
      Object.assign(state, {
        ...initialState,
        ...action.payload,
        alert: {
          color: action.payload.alert?.color ?? initialState.alert.color,
          variant: action.payload.alert?.variant ?? initialState.alert.variant,
        },
      });
    },

    closeSnackbar(state) {
      state.open = false;
    },

    handlerIconVariants(state) {
      state.iconVariant = "hide"; // Consider making this dynamic or configurable
    },
  },
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar, handlerIconVariants } = snackbar.actions;
