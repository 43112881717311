import React, { lazy } from "react";

// project imports
import MainLayout from "src/layout/MainLayout";
import Loadable from "src/ui-component/Loadable";
import AuthGuard from "src/utils/route-guard/AuthGuard";
import DepartmentInsights from "src/views/Common/departmentInsights";
import ScanDetail from "src/views/pages/consultant/scan/scanInfo";
import TestPlanMaster from "src/views/pages/consultant/testCasesForMaster";
import AddVulnerability from "src/views/pages/consultant/vulnerabilities/addvulnerability";

// Type for lazy component import
type LazyComponent = () => Promise<{ default: React.ComponentType<unknown> }>;

// Generic lazy loader with retry logic
const lazyWithRetry = (componentImport: LazyComponent) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      // Attempt to load the component
      const component = await componentImport();
      // Reset the reload flag
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      // Check if the page has already been refreshed
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        // Reload the page, then throw an error to ensure the promise type remains consistent
        window.location.reload();
        throw new Error("Page is reloading");
      }
      // Re-throw the error if the page has already been refreshed
      throw error;
    }
  });

// dashboard routing
const Dashboard = Loadable(
  lazy(() => import("src/views/pages/common/dashboard"))
);
// const DemoDashboard = Loadable(
//   lazy(() => import("src/views/pages/common/demoDashboard"))
// );

// scan routing
const Scan = Loadable(lazy(() => import("src/views/pages/consultant/scan")));
// const ScanDetail = Loadable(
//   lazy(() => import("src/views/pages/client/scan/scanInfo/scanDetails"))
// );
const AddScan = Loadable(
  lazy(() => import("src/views/pages/consultant/scan/addScan"))
);
const AssetGroupInsights = Loadable(
  lazy(() => import("src/views/Common/assetGroupInsights"))
);
const AddScanAsset = Loadable(
  lazy(
    () =>
      import("src/views/pages/consultant/scan/scanInfo/scanAssets/addScanAsset")
  )
);

// vulnerabilities routing
const Vulnerabilities = Loadable(
  lazy(() => import("src/views/pages/consultant/vulnerabilities"))
);
const VulnerabilityInfo = Loadable(
  lazy(
    () => import("src/views/pages/consultant/vulnerabilities/vulnerabilityInfo")
  )
);

// Users
// const Users = Loadable(lazy(() => import("src/views/pages/common/users")));

// Users
const UsersNew = Loadable(
  lazy(() => import("src/views/pages/common/users/allUsers/NewUser"))
);

// Profile
const Profile = Loadable(lazy(() => import("src/views/pages/common/profile")));

// new Profile
const OrganizationCard = Loadable(
  lazy(() => import("src/views/pages/common/profile/newProfile"))
);

// Assets
const Assets = Loadable(
  lazy(() => import("src/views/pages/consultant/assets"))
);
const AddAsset = Loadable(
  lazyWithRetry(
    () => import("src/views/pages/consultant/assets/allAssets/addAsset")
  )
);
const AssetGroups = Loadable(
  lazy(() => import("src/views/pages/consultant/assets/groupAssets"))
);
const ClientAssetGroups = Loadable(
  lazy(() => import("src/views/pages/client/assets/groupAssets"))
);

const NewClientAssetGroups = Loadable(
  lazy(() => import("src/views/pages/client/Insights/newAssets/newGroupAssets"))
);
const Groups = Loadable(
  lazy(() => import("src/views/pages/consultant/assets/groupAssets/groups"))
);
const ClientGroups = Loadable(
  lazy(() => import("src/views/pages/client/assets/groupAssets/groups"))
);
const UserGroup = Loadable(
  lazy(() => import("src/views/pages/common/user-group"))
);
const UserGroupNew = Loadable(
  lazy(() => import("src/views/pages/common/user-group/newUserGroup"))
);
const AddUserGroup = Loadable(
  lazy(() => import("src/views/pages/common/user-group/addGroup/"))
);
const UserGroupInfo = Loadable(
  lazy(() => import("src/views/pages/common/user-group/userGroupInfo/"))
);

// change password
const ChangePassword = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/common/authentication/authentication/changePassword"
      )
  )
);
const VulnerabilityMaster = Loadable(
  lazy(() => import("src/views/pages/platform/vulnerbilityMaster/index"))
);
const AddVulnerabilityForMaster = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/platform/vulnerbilityMaster/allVulnerbility/addVulnerability/index"
      )
  )
);
const ClientOrganizationForAsset = Loadable(
  lazy(() => import("src/views/pages/consultant/assets/clientOrgForAssets"))
);

//Scan Report Configuration
// const ReportContent = Loadable(
//   lazy(() => import("src/views/pages/consultant/ReportContent"))
// );

//Scan Report Configuration
const ReportContentNew = Loadable(
  lazy(
    () => import("src/views/pages/consultant/ReportContent/newReportContent")
  )
);

// Consultant-Client Management - Mapping Consultant
const ConsultantMapping = Loadable(
  lazy(() => import("src/views/pages/consultant/clientOrganization"))
);

const ConsultantMappingDetailPage = Loadable(
  lazy(
    () =>
      import("src/views/pages/consultant/clientOrganization/clientMappingPage")
  )
);

// Platform client mapping
const ClientMapping = Loadable(
  lazy(
    () =>
      import("src/views/pages/consultant/clientConsultant/clientMappingList")
  )
);
const ClientMappingDetailPage = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/consultant/clientConsultant/clientMappingDetailPage"
      )
  )
);

// security manager
const SecurityManager = Loadable(
  lazy(() => import("src/views/pages/consultant/securityManager"))
);

// Platform Add Organization
const AddOrganization = Loadable(
  lazy(() => import("src/views/pages/platform/organizationDetail"))
);

// const DashboardLabel = Loadable(
//   lazy(() => import("src/views/pages/common/dashboardLabel"))
// );

// Consultant Add Client Organization
// const ConsultantOrganization = Loadable(
//   lazy(() => import("src/views/pages/consultant/consultantOganization"))
// );

const ConsultantOrganizationNew = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/consultant/consultantOganization/newConsultantOrganization"
      )
  )
);

//Consultant Pull Vulnerability
const PullVulnerability = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/consultant/vulnerabilities/pullvulnerability/pullVulnerabilityList"
      )
  )
);

//Client Asset
const ClientAssets = Loadable(
  lazy(() => import("src/views/pages/client/assets"))
);

//Client new Asset
const NewClientAssets = Loadable(
  lazy(() => import("src/views/pages/client/Insights/newAssets"))
);

//Add Client Asset
const AddClientAsset = Loadable(
  lazy(() => import("src/views/pages/client/assets/allAssets/addAsset"))
);

//Get Consultant Published Vulnerability List
const PublishedVulnerability = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/consultant/vulnerabilities/publishedVulnerabilities/publishedVulList"
      )
  )
);

//Get Consultant_Published_Vulnerability_Info
const PublishedVulnerabilityInfo = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/consultant/vulnerabilities/publishedVulnerabilities/publishedVulInfo"
      )
  )
);

// Get Client All Scans
const ClientAllScan = Loadable(
  lazy(() => import("src/views/pages/client/scan/allScan"))
);

// Client Add Scan
const ClientAddScan = Loadable(
  lazy(() => import("src/views/pages/client/scan/addScan"))
);

//Client Scan Details
const ClientScanInfo = Loadable(
  lazy(() => import("src/views/pages/client/scan/scanInfo"))
);

//Client Add Scan Assets
const ClientAddScanAssets = Loadable(
  lazy(
    () => import("src/views/pages/client/scan/scanInfo/scanAssets/addScanAsset")
  )
);

//Client Published Vulnerability Details
const ClientPublishedVulnerabilityDetails = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/client/scan/vulnerabilities/publishedVulnerabilityInfo"
      )
  )
);

//Client Rescan Vulnerability Details
const ClientRescanVulnerabilityDetails = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/client/scan/vulnerabilities/rescanVunerabilityInfo"
      )
  )
);

//Exception initial List
const IntialList = Loadable(
  lazy(() => import("src/views/pages/client/exception/initialList"))
);

//Approved List
const ApprovedList = Loadable(
  lazy(() => import("src/views/pages/client/exception/approve"))
);

//History List
const HistoryList = Loadable(
  lazy(() => import("src/views/pages/client/exception/vulHistory"))
);

// Selected Exception Vulnerability Detail
const ExceptionHistoryDetail = Loadable(
  lazy(() => import("src/views/pages/client/exception/selectedExceptionDetail"))
);

// Client Download Report
const DownloadReport = Loadable(
  lazy(() => import("src/views/pages/client/downloadReport"))
);

// Client Published Vulnerabilities
const ClientPublishedVulnerabilities = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/client/publishedVulnerabilities/publishedVulnerabilitiesList"
      )
  )
);
// const SLA = Loadable(lazy(() => import("src/views/pages/client/SLA")));
const ClientSLA = Loadable(
  lazy(() => import("src/views/pages/client/SLA/clientSLA"))
);

// DEPARTMENT
// const AllDepartment = Loadable(
//   lazy(() => import("src/views/pages/client/departments/allDepartment"))
// );

const NewDepartment = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/client/departments/allDepartment/newDepartmentPage"
      )
  )
);

// NEW DEPARTMENT
const NewAllDepartment = Loadable(
  lazy(
    () =>
      import("src/views/pages/client/Insights/newDepartment/newAllDepartment")
  )
);
//  ASM
const AttackSurfaceMonitoring = Loadable(
  lazy(() => import("src/views/pages/client/asm"))
);
//  CART
const CARTPage = Loadable(lazy(() => import("src/views/pages/client/cart")));

// Client Selected Published Vulnerabiliy Detail
const SelectedPublishedVulnerabilityDetail = Loadable(
  lazy(
    () =>
      import(
        "src/views/pages/client/publishedVulnerabilities/publishedVulnerabilityDetail"
      )
  )
);
// Asset Detail
const AssetDetail = Loadable(
  lazy(() => import("src/views/pages/consultant/assets/allAssets/assetDetail"))
);

// Client Download Report
const VulAge = Loadable(lazy(() => import("src/views/pages/client/vulAge")));

// Location
const Location = Loadable(
  lazy(() => import("src/views/pages/client/location"))
);

// Box List Data
// const BoxDataList = Loadable(
//   lazy(() => import("src/views/pages/consultant/boxList/boxList"))
// );

// set of client routes
// const Routes = [
//   {
//     path: "/client/dashboard",
//     element: <MainLayout />,
//     children: [
//       { path: "vulnerabilities", element: <Vulnerabilities /> },
//       { path: "scan", element: <Scan /> },
//       { path: "assets", element: <ClientAssets /> },
//       { path: "assetDetail", element: <AssetDetail /> },
//       { path: "scanInfo", element: <ClientScanInfo /> },
//       { path: "exception", element: <IntialList /> },
//       { path: "downloadReport", element: <DownloadReport /> },
//       { path: "vulAge", element: <VulAge /> },
//       { path: "publishedVulnerabilities", element: <ClientPublishedVulnerabilities /> },
//       { path: "publishedVulnerabilityInfo", element: <ClientPublishedVulnerabilityDetails /> },
//       { path: "boxList", element: <BoxDataList /> },
//       { path: "assetGroups", element: <ClientAssetGroups /> },
//       { path: "newAssetGroups", element: <NewClientAssetGroups /> },
//       { path: "groups", element: <ClientGroups /> },
//       { path: "userGroup", element: <UserGroup /> },
//       { path: "addUserGroup", element: <AddUserGroup /> },
//       { path: "userGroupInfo", element: <UserGroupInfo /> },
//       { path: "vulnerabilityMaster", element: <VulnerabilityMaster /> },
//       { path: "addVulnerabilityMaster", element: <AddVulnerabilityForMaster /> },
//       { path: "changePassword", element: <ChangePassword /> },
//       { path: "securityManager", element: <SecurityManager /> },
//     ],
//   },
// ];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </>
  ),
  children: [
    // {
    //   path: "/:type/dashboard",
    //   element: <DemoDashboard />,
    // },
    {
      path: "/home",
      element: <Dashboard />,
    },
    // {
    //   path: "/:type/scan",
    //   element: <Scan />,
    // },
    {
      path: "/scan",
      element: <Scan />,
    },
    {
      path: "/:type/client-org",
      element: <ClientOrganizationForAsset />,
    },
    {
      path: "/scan/add",
      element: <AddScan />,
    },
    {
      path: "/confirm-scan/:clientOrgId/:scanId",
      element: <AddScan />,
    },
    {
      path: "/scan/:scanId",
      element: <ScanDetail />,
    },
    {
      path: "/scan/:scanId/assets/add",
      element: <AddScanAsset />,
    },
    {
      path: "/:type/vulnerabilities",
      element: <Vulnerabilities />,
    },
    {
      path: "/vulnerabilities/:valId",
      element: <VulnerabilityInfo />,
    },
    {
      path: "/test-plan-master",
      element: <TestPlanMaster />,
    },
    // {
    //   path: "/users",
    //   element: <Users />,
    // },
    {
      path: "/users",
      element: <UsersNew departments={[]} />,
    },
    {
      path: "/org-details",
      element: <OrganizationCard />,
    },
    // {
    //   path: "/org-details/new",
    //   element: <OrganizationCard />,
    // },
    {
      path: "/:type/assets",
      element: <Assets />,
    },
    {
      path: "/client/assets",
      element: <ClientAssets />,
    },
    {
      path: "/client/assets-group",
      element: <ClientAssetGroups />,
    },
    {
      path: "/:type/assets-group",
      element: <AssetGroups />,
    },
    {
      path: "/client/assets/groups/:groupId",
      element: <ClientGroups />,
    },
    {
      path: "/client/assets-groups-insights/:id",
      element: <AssetGroupInsights />,
    },
    {
      path: "/client/department-insights/:id",
      element: <DepartmentInsights />,
    },
    {
      path: "/:type/assets/groups/:groupId",
      element: <Groups />,
    },
    {
      path: "/:type/assets/add",
      element: <AddAsset />,
    },
    {
      path: "/assets/add",
      element: <AddClientAsset />,
    },
    {
      path: "/:type/assets/edit",
      element: <AddAsset />,
    },
    {
      path: "/assets/edit/:type",
      element: <AddClientAsset />,
    },
    {
      path: "/scan/:scanId/vulnerability/add",
      element: <AddVulnerability />,
    },

    {
      path: "/scan/:scanId/vulnerability/pull",
      element: <PullVulnerability />,
    },

    {
      path: "/scan/:scanId/asset/:assetId/vulnerability/add",
      element: <AddVulnerability />,
    },

    {
      path: "/user-group",
      element: <UserGroupNew departments={[]} />,
    },
    // {
    //   path: "/user-group/new",
    //   element: <UserGroupNew departments={[]} />,
    // },
    {
      path: "/user-group/add",
      element: <AddUserGroup />,
    },
    {
      path: "/user-group/:groupId",
      element: <UserGroupInfo />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/vulnerability-master",
      element: <VulnerabilityMaster />,
    },
    {
      path: "/vulnerability-master/add",
      element: <AddVulnerabilityForMaster />,
    },
    {
      path: "/vulnerability-master/:id/edit",
      element: <AddVulnerabilityForMaster />,
    },
    // {
    //   path: "/scan-report-configuration",
    //   element: <ReportContent />,
    // },
    {
      path: "/scan-report-configuration",
      element: <ReportContentNew />,
    },
    {
      path: "/consultant-mapping",
      element: <ConsultantMapping />,
    },
    {
      path: "consultant-mapping/detail-page/:id",
      element: <ConsultantMappingDetailPage />,
    },
    {
      path: "/client-mapping",
      element: <ClientMapping />,
    },
    {
      path: "/client-mapping/detail-page/:id",
      element: <ClientMappingDetailPage />,
    },
    {
      path: "/add-organization",
      element: <AddOrganization />,
    },
    // {
    //   path: "/dashboard",
    //   element: <DashboardLabel />,
    // },
    {
      path: "/:type/client-organization",
      element: <ConsultantOrganizationNew departments={[]} />,
    },
    // {
    //   path: "/client/consultant-organization",
    //   element: <ConsultantOrganization />,
    // },
    {
      path: "/client/consultant-organization",
      element: <ConsultantOrganizationNew departments={[]} />,
    },
    {
      path: ":type/security-manager",
      element: <SecurityManager />,
    },
    {
      path: "/published-vulnerability",
      element: <PublishedVulnerability />,
    },
    {
      path: "/published-vulnerability/:id",
      element: <PublishedVulnerabilityInfo />,
    },
    {
      path: "/client-scan",
      element: <ClientAllScan />,
    },
    {
      path: "/client-add-scan",
      element: <ClientAddScan />,
    },
    {
      path: "/add-confirmatory-scan/:scanId",
      element: <ClientAddScan />,
    },
    {
      path: "/scan-detail/:scanId",
      element: <ClientScanInfo />,
    },
    {
      path: "/client-scan/:scanId/assets/add",
      element: <ClientAddScanAssets />,
    },
    {
      path: "/client-published-vulnerability/:svId",
      element: <ClientPublishedVulnerabilityDetails />,
    },
    {
      path: "/rescan-vulnerability/:cvId",
      element: <ClientRescanVulnerabilityDetails />,
    },
    {
      path: "/exception-maker",
      element: <IntialList />,
    },
    {
      path: "/exception-checker",
      element: <ApprovedList />,
    },
    {
      path: "/exception-maker-history",
      element: <HistoryList />,
    },
    {
      path: "/exception-checker-history",
      element: <HistoryList />,
    },
    {
      path: "/selected-exception-vulnerability/:cvExceptionId",
      element: <ExceptionHistoryDetail />,
    },
    {
      path: "/asset-group-report",
      element: <DownloadReport />,
    },
    {
      path: "/published-client-vulnerabilities",
      element: <ClientPublishedVulnerabilities />,
    },

    {
      path: "/published-vulnerability-detail/:cvId",
      element: <SelectedPublishedVulnerabilityDetail />,
    },
    {
      path: "/client-vul-age-analysis",
      element: <VulAge />,
    },
    {
      path: "/asset-detail/:assetId",
      element: <AssetDetail />,
    },

    // {
    //   path: "/scan-list/:type",
    //   element: <BoxDataList />,
    // },
    // {
    //   path: "/client-vul-define-sla",
    //   element: <SLA />,
    // },
    {
      path: "/client-vul-define-sla",
      element: <ClientSLA />,
    },
    {
      path: "/department",
      element: <NewDepartment />,
    },
    {
      path: "/user-department-insights",
      element: <NewAllDepartment />,
    },
    {
      path: "/user-asset-group-insights",
      element: <NewClientAssetGroups />,
    },
    {
      path: "/user-asset-insights",
      element: <NewClientAssets />,
    },
    {
      path: "/asm",
      element: <AttackSurfaceMonitoring />,
    },
    {
      path: "/cart",
      element: <CARTPage />,
    },
    {
      path: "/location",
      element: <Location />,
    },
  ],
};

export default MainRoutes;
