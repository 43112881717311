import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Select, MenuItem, Typography } from "@mui/material";
import { RootState, useSelector } from "../../../../../../store";
import VmTable from "../../../../../../ui-component/table";
import { dispatch } from "../../../../../../store";
import {
  fetchScanAssetVerification,
  updateScanAssetVerificationStatus,
} from "../../../../../../store/slices/scanReducer";
import {
  AssetData,
  AssetsLastScanDetailProps,
  StatusChange,
} from "../../scanTypes";

const styles = {
  selectInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select": {
      padding: "8px",
    },
  },
  table: {
    mb: 2, // Add spacing below the table
  },
};

const AssetsLastScanDetail: React.FC<AssetsLastScanDetailProps> = ({
  handleClose,
  scanId,
  selectedScanStatus,
  clientOrgId,
  setCofrimDialog,
  setOpenScanAssetVerificationDialog,
}) => {
  const { scanAssetVerificationList } = useSelector(
    (state) => state?.scansState
  );
  const { scanDetails } = useSelector((state: RootState) => state?.scansState);

  const [statusChanges, setStatusChanges] = useState<StatusChange[]>([]); // New state to store isScanned change objects

  const handleStatusChange = (
    assetId: string | number,
    newStatus: "on" | "off" | ""
  ) => {
    setStatusChanges((prevStatusChanges) => {
      const updatedStatusChanges = prevStatusChanges.filter(
        (statusChange: any) => statusChange.assetId !== assetId
      );
      updatedStatusChanges.push({ assetId, isScanned: newStatus });
      return updatedStatusChanges;
    });
  };

  const handleSubmit = () => {
    const allUpdated = statusChanges.every(
      (statusChange) => statusChange.isScanned !== ""
    );

    if (!allUpdated) {
      console.error("Error: Some records' isScanned values are not updated.");
    } else {
      if (scanId && clientOrgId) {
        const transformedData = statusChanges.map((item) => {
          let newStatus = null;

          if (item.isScanned === "on") {
            newStatus = true;
          } else if (item.isScanned === "off") {
            newStatus = false;
          }

          return {
            ...item,
            isScanned: newStatus,
          };
        });

        const payload: any = { scanId, clientOrgId, data: transformedData };
        dispatch(updateScanAssetVerificationStatus(payload)).then((res) => {
          if (res?.status === 200) {
            setOpenScanAssetVerificationDialog(false);
            setCofrimDialog(true);
            setStatusChanges([]);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (scanId && clientOrgId) {
      const payload = { scanId, clientOrgId };
      dispatch(fetchScanAssetVerification(payload))
        .then((response: any) => {
          const initialStatusChanges =
            response?.data?.map((asset: any) => ({
              assetId: asset.assetId,
              isScanned: asset.isScanned
                ? "on"
                : asset.isScanned === null
                ? ""
                : "off", // Handling null case
            })) || [];

          setStatusChanges(initialStatusChanges);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [scanId, clientOrgId]);

  const columns = [
    {
      accessorKey: "assetName",
      header: "Asset Name",
      size: 300,
    },
    {
      accessorKey: "Status",
      header: "Status",
      size: 50,
      Cell: ({ row }: { row: { original: AssetData } }) => {
        const assetId = row.original.assetId;
        const currentStatus = statusChanges?.find(
          (change) => change.assetId === assetId
        )?.isScanned;

        return (
          <Select
            value={currentStatus || ""} // If status is null or undefined, it will show an empty value
            onChange={(e) => {
              handleStatusChange(assetId, e.target.value as "on" | "off" | ""); // Update status change
            }}
            size="small"
            fullWidth
            // sx={styles.selectInput}
          >
            <MenuItem value="on">Yes</MenuItem>
            <MenuItem value="off">No</MenuItem>
          </Select>
        );
      },
    },
  ];

  const validateUpdateData = statusChanges
    .map((item) => item.isScanned)
    .filter((value) => value !== null && value !== undefined && value !== "");

  const assetsWithVulnerabilityCount =
    scanDetails?.totalAsset - scanAssetVerificationList?.length;
  return (
    <>
      <Typography variant="h6">
        Vulnerabilities added for only {assetsWithVulnerabilityCount}/
        {scanDetails?.totalAsset || 0} assets
      </Typography>
      <br />
      <VmTable
        enablePagination={false}
        enableBottomToolbar={false}
        columns={columns}
        data={scanAssetVerificationList || []}
        sx={styles.table}
      />
      <br />
      <Typography sx={{ fontSize: "18px" }}>
        YES - Scanned but no vulnerabilities
      </Typography>
      <Typography sx={{ fontSize: "18px" }}> NO - Not Scanned </Typography>
      <Stack direction={"row"} justifyContent={"flex-end"} gap={2} mt={4}>
        <Button
          size="large"
          onClick={handleClose}
          sx={{
            borderRadius: "10px",
            padding: "8px 16px",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            color: " #F45F43",
            border: "1px solid",
            height: "50px",
            borderImageSlice: 1,
            textTransform: "none",
            "&:hover": {
              border: "1px solid",
              borderRadius: "10px",
              color: "#F45F43",
              borderImageSlice: 1,
            },
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          onClick={handleSubmit} // Trigger validation on Save
          disabled={
            scanAssetVerificationList?.length !== validateUpdateData?.length
          }
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none",
          }}
        >
          Save
        </LoadingButton>
      </Stack>
    </>
  );
};

export default AssetsLastScanDetail;
