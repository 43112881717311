import React from "react";
// import { useTheme } from "@emotion/react";
import { IconButton } from "@mui/material";
// import { Stack } from "immutable";
import { useEffect, useMemo, useState } from "react";
import { dispatch } from "../../../../../../store";
import {
  getCommentAttachments,
  getCommentImage,
  getCommentValAttachments,
  getValCommentImage,
} from "../../../../../../store/slices/scanReducer";
import DownloadIcon from "@mui/icons-material/Download";

import VmTable from "../../../../../../ui-component/table";
import moment from "moment";
import { AttachmentDetail, PreviewAttachmentListProps } from "../../scanTypes";

export const PreviewAttachmentList: React.FC<PreviewAttachmentListProps> = (props) => {
  // const theme = useTheme();
  const [attachmentList, setAttachmentList] = useState([]);
  useEffect(() => {
    if (props.endPoint === "vulnerability") {
      if (props?.item?.[props?.fieldName]) {
        dispatch(
          getCommentValAttachments(
            props?.item?.[props?.fieldName],
            props?.item?.clientOrgId
          )
        ).then((res: any) => {
          setAttachmentList(res?.data);
        });
      }
    }
    if (props.endPoint === "scan") {
      if (props?.item?.[props?.fieldName]) {
        setTimeout(() => {
          dispatch(getCommentAttachments(props?.item?.[props?.fieldName], props?.item?.clientOrgId)).then(
            (res: any) => {
              setAttachmentList(res?.data);
            }
          );
        }, 2000);
      }
    }
  }, [props?.item?.[props?.fieldName]]);
  function downloadBase64File(base64Data: string, fileName: string) {
    // Create a link element
    const link = document.createElement("a");

    // Use the base64 data to create a blob
    const blob = base64ToBlob(base64Data);
    const url = URL.createObjectURL(blob);

    // Set properties on link element for download
    link.href = url;
    link.download = fileName;

    // Append link to the body, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Function to convert base64 to Blob
    function base64ToBlob(base64: string): Blob {
      const parts = base64.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw?.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    }
  }
  const handleDownload = (detail: AttachmentDetail | any) => {
    if (props.endPoint === "vulnerability") {
      dispatch(
        getValCommentImage(detail?.vulCommentAttachmentId, detail?.clientOrgId)
      )
        .then((res: any) => {
          downloadBase64File(
            `data:image/png;base64,${res?.data?.fileContent}`,
            res?.data?.fileName
          );
        })
        .catch(() => {});
    } else {
      dispatch(
        getCommentImage(detail?.scanCommentAttachmentId, detail?.clientOrgId)
      ).then((res: any) => {
        downloadBase64File(
          `data:image/png;base64,${res?.data?.fileContent}`,
          res?.data?.fileName
        );
      });
    }
  };
  const commentAttachment = useMemo(
    () => [
      {
        accessorKey: "fileName",
        header: "File Name",
        size: 150,
      },
      {
        accessorKey: "lastModifiedDate",
        header: "Uploaded On",
        Cell: ({ cell, row }: any) => (
          <>
            {row?.original?.createdOn &&
              moment(row.original.createdOn).format("DD MMM YYYY")}
          </>
        ),
      },
      {
        header: "Actions",
        Cell: ({ cell, row }: any) => (
          <IconButton onClick={() => handleDownload(row?.original)}>
            <DownloadIcon />
          </IconButton>
        ),
      },
    ],
    []
  );

  return <VmTable columns={commentAttachment} data={attachmentList || []} />;
};
