import React, { useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Input,
  TextField,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  handleUploadReport,
  getScanReportHistory,
} from "../../../../../../../store/slices/scanReducer";
import { dispatch } from "../../../../../../../store";
import { useParams } from "react-router-dom";
import { userActionAccess } from "../../../../../../../utils/helpers";
import { ACTION_TYPE } from "../../../../../../../constant";
import { UploadReportPdfProps } from "../../../scanTypes";
import theme from "src/themes/theme";

  const UploadReportPdf: React.FC<UploadReportPdfProps> = ({ handleCloseUploadDialog }) => {

  // const theme = useTheme();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [reportName, setReportName] = useState<string>("");
  const params: any = useParams();
  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const formData: any = new FormData();

  const handleUpload = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    const file = fileInput?.files ? fileInput.files[0] : null;
    if (file) {
      formData?.append("scanId", params?.scanId || "");
      formData?.append("clientOrgId", clientOrgId || "");
      formData?.append("reportName", reportName);
      formData?.append("file", file);
      try {
        dispatch(handleUploadReport(params?.scanId, clientOrgId, formData))
          .then((res) => {
            if (res && res.status === 200) {
              // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
              dispatch(getScanReportHistory(params?.scanId, clientOrgId));
              // }
              handleCloseUploadDialog();
            }
          })
          .catch(() => {});
        handleCloseUploadDialog();
      } catch (err) {}
    }
  };

  return (
    <>
      <Box>
        <InputLabel htmlFor="reportNameInput">Report Name :</InputLabel>
        <TextField
          id="reportNameInput"
          value={reportName}
          onChange={(event: any) => setReportName(event.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        {/* File upload section */}
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <InputLabel
            htmlFor="fileInput"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "2px dashed #ddd",
              borderRadius: "4px",
              padding: "10px",
              cursor: "pointer",
              width: "700px", // Adjust width as needed
            }}
          >
            <CloudUploadIcon
              sx={{ color: theme.palette.error.main, fontSize: 40 }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Drop file here or click to upload
            </Typography>
            <Input
              onChange={(event: any) => setSelectedFile(event?.target?.value)}
              type="file"
              id="fileInput"
              sx={{ display: "none" }}
              //   ref={ref}
            />
          </InputLabel>

          {/* )} */}
        </Box>

        <Typography variant="body2" mt={1} noWrap>
          {selectedFile && `Uploading ${selectedFile}...`}
        </Typography>

        {/* Upload button */}
        <Stack
          mt={4}
          direction={"row"}
          width={"100%"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="contained"
            size="large"
            color="secondary"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              width: "105px",
              height: "50px",
              borderRadius: "10px !important",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation
            }}
            onClick={handleUpload}
            disabled={!selectedFile || !reportName}
          >
            Upload
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default UploadReportPdf;
