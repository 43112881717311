export const TEST_RESULT = [
  {
    label: "Safe",
    value: "S",
  },
  {
    label: "Unsafe",
    value: "U",
  },
  {
    label: "Not Applicable",
    value: "N",
  },
];
