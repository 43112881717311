import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import VmTable from "../../../../../ui-component/table";
import { Grid } from "@mui/material";
// import TestPlanGenericFilter from "../../../../../views/Common/testPlanGenericFilter";
import { LoadingButton } from "@mui/lab";
import {
  activeAndDeactive,
  getMasterTestListAPI,
  setDeactiveCount,
} from "../../../../../store/slices/masterTestPlanReducer";
import { dispatch, RootState } from "src/store";

interface TestPlan {
  testPlanId: string;
  testPlanDesc: string;
  active: boolean;
}

// Define props for the DeactiveTestCase component
interface DeactiveTestCaseProps {
  payload: any;
}

// Define props for the ActionButton component
interface ActionButtonProps {
  selectedIds: string[];
  payloadData: any;
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const styles = {
  mainActionModal: { display: "flex", gap: "5px" },
  subActionModal: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    paddingBottom: "30px",
  },
};

const ActionButton: React.FC<ActionButtonProps> = ({
  selectedIds,
  payloadData,
  setSelectedIds,
}) => {
  const handleActive = () => {
    dispatch(activeAndDeactive(true, selectedIds)).then(() => {
      dispatch(getMasterTestListAPI(payloadData));
      setSelectedIds([]);
    });
  };
  return (
    <>
      <Grid xs={12} style={styles.mainActionModal}>
        {/* <TestPlanGenericFilter /> */}
      </Grid>
      <Grid style={styles.subActionModal}>
        <LoadingButton
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          variant="contained"
          color="secondary"
          type="submit"
          size="small"
          disabled={selectedIds.length === 0}
          onClick={handleActive}
        >
          Active
        </LoadingButton>
      </Grid>
    </>
  );
};

const DeactiveTestCase: React.FC<DeactiveTestCaseProps> = ({ payload }) => {
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { masterTestList } = useSelector(
    (state: RootState) => state.masterTestPlan
  );

  // Filter for deactive tests
  const deactiveTests = useMemo(() => {
    return masterTestList?.filter((test: TestPlan) => test.active === false);
  }, [masterTestList]);

  useEffect(() => {
    if (deactiveTests?.length === 0) {
      setSelectedIds([]);
    }
  }, [deactiveTests]);

  useEffect(() => {
    if (deactiveTests) {
      dispatch(setDeactiveCount(deactiveTests));
    }
  }, [deactiveTests, dispatch]);

  const handleChangeRowSelect = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const columns = [
    {
      accessorKey: "testPlanDesc",
      header: "Test Plan",
      size: 500,
      Cell: ({ row }: { row: { original: TestPlan } }) => (
        <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {row.original.testPlanDesc}
        </span>
      ),
    },
  ];

  return (
    <>
      <Grid
        // container
        sx={{
          border: "0.5px solid #a2a2d299",
           // background: "#1D1D3A",
          padding: "30px",
          // padding: "30px",
          height: "100%",
          borderRadius: "10px",
          // color: "#fff",
        }}
      >
        <VmTable
          enableRowSelection={true}
          enablePagination={false}
          enableBottomToolbar={false}
          enableGlobalFilter={true}
          columns={columns}
          data={deactiveTests || []}
          tableTitle={
            <ActionButton
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
              payloadData={payload}
            />
          }
          selectedIdsField={"testPlanId"}
          selectedIds={selectedIds}
          handleChangeRowSelect={handleChangeRowSelect}
        />
      </Grid>
    </>
  );
};

export default DeactiveTestCase;
