import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import VmTable from "../../../../../ui-component/table";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Grid, IconButton, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import TestPlanGenericFilter from "../../../../../views/Common/testPlanGenericFilter";
import { LoadingButton } from "@mui/lab";
import {
  activeAndDeactive,
  addNewTestPlan,
  changeOrderBy,
  deleteTestPlanMaster,
  getChangeTestPlanName,
  getMasterTestListAPI,
  setActiveCount,
} from "../../../../../store/slices/masterTestPlanReducer";
import DialogBox from "../../../../../ui-component/dialogbox";
import Delete from "../../../../../ui-component/delete";
import AddTestPlanMaster from "./addDialogBox";
import { setLoading } from "../../../../../store/slices/user";
import { TEST_PLAN_MASTER_TYPE } from "../../../../../constant";
import { dispatch, RootState } from "src/store";
import { Box } from "@mui/system";

const styles = {
  filterMainGrid: {
    display: "flex",
    gap: "5px",
  },
  Buttons: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    paddingBottom: "30px",
  },
  TextField_testplaid: {
    width: "100%",
    fontSize: "0.875rem",
    [".MuiInputBase-input"]: {
      padding: "5px !important",
    },
  },
  iconWidth: {
    width: "70%",
  },
  IDEdit_icon: {
    color: "#aec8f0",
    padding: "2px",
    marginLeft: "2px",
    width: "24px",
    height: "24px",
  },
  DriveFileRenameOutlineIcon_icon: { width: "18px", height: "14px" },
  fieldWidth: { width: "100%" },
  empty_span: { whiteSpace: "pre-wrap", wordWrap: "break-word" },
  pointer: {
    cursor: "pointer",
  },
};

interface ActiveTestCaseProps {
  payload: any;
}

interface State {
  selectedRow: any;
  selectedIds: string[];
  visibleActionModal: boolean;
  openDeleteAssetDialog: boolean;
  selectedOrderBy: any;
  editableTestPlanDesc: any;
  editedTestPlan: string;
  editedOrderBy: string | number | any;
}

const ActionButton = (props: any) => {
  const handleDeactive = (selectedIds: string[]) => {
    dispatch(activeAndDeactive(false, selectedIds))
      .then(() => {
        dispatch(getMasterTestListAPI(props?.payloadData));
        props?.setState((prevState: State) => ({
          ...prevState,
          selectedIds: [],
        }));
      })
      .catch((error) => {
        console.error("Error while deactivating:", error);
      });
  };

  const canAddAction = () => {
    const { id, serviceType, testPlanTypeCode, testPlanType } =
      props?.payloadData || {};

    if (
      serviceType &&
      testPlanTypeCode === TEST_PLAN_MASTER_TYPE?.MISCELLANEOUS &&
      testPlanType
    ) {
      return true;
    } else if (id && serviceType && testPlanType) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid xs={12} style={styles.filterMainGrid}>
        <TestPlanGenericFilter {...props} />
      </Grid>
      <Grid style={styles.Buttons}>
        <LoadingButton
          variant="contained"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          color="secondary"
          type="submit"
          size="small"
          disabled={!canAddAction()} // Directly evaluate the condition
          onClick={() => props?.setVisibleActionModal(true)}
        >
          {"Add"}
        </LoadingButton>
        <Box sx={{ paddingLeft: "20px" }}>
          <LoadingButton
            variant="contained"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              width: "105px",
              height: "50px",
              borderRadius: "10px",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation
            }}
            color="secondary"
            type="submit"
            size="small"
            disabled={props?.selectedIds?.length === 0}
            onClick={() => handleDeactive(props?.selectedIds)}
          >
            {"Deactive"}
          </LoadingButton>
        </Box>
      </Grid>
    </>
  );
};

const ActiveTestCase = (props: ActiveTestCaseProps) => {
  const {
    allTypes,
    serviceTypes,
    owaspList,
    serviceTypeId,
    owaspCategoryMasterId,
    masterTestList,
  } = useSelector((state: RootState) => state.masterTestPlan);
  const { setDataloading } = useSelector(
    (state: RootState) => state?.userState
  );
  const { userInfo } = useSelector((state: RootState) => state?.userInfoState);
  const [state, setState] = useState<State>({
    selectedRow: null,
    selectedIds: [],
    visibleActionModal: false,
    openDeleteAssetDialog: false,
    selectedOrderBy: null,
    editableTestPlanDesc: null,
    editedTestPlan: "",
    editedOrderBy: "",
  });
  const consultantOrgId = userInfo?.organizationId;

  const activeTests = useMemo(() => {
    return masterTestList
      ?.filter((test) => test.active === true)
      ?.sort((a, b) => {
        return a.orderBy - b.orderBy;
      });
  }, [masterTestList]);

  useEffect(() => {
    if (activeTests) {
      dispatch(setActiveCount(activeTests));
    }
  }, [activeTests, masterTestList]);

  const handleStateChange = (key: string, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditClickTestPlanId = (row: any) => {
    handleStateChange("selectedOrderBy", row.orderBy);
    handleStateChange("editableTestPlanDesc", null);
    handleStateChange("editedOrderBy", row.orderBy);
    handleStateChange("editedTestPlan", row.testPlanDesc);
  };

  const handleEditClickTestPlanDesc = (row: any) => {
    handleStateChange("editableTestPlanDesc", row.testPlanId);
    handleStateChange("selectedOrderBy", null);
    handleStateChange("editedTestPlan", row.testPlanDesc);
    handleStateChange("editedOrderBy", row.orderBy);
  };

  const handleSaveEditRowTestPlanId = (para: any) => {
    dispatch(changeOrderBy(para?.testPlanId, state?.editedOrderBy))?.then(
      () => {
        // if (owaspCategoryMasterId) {
        dispatch(getMasterTestListAPI(props?.payload));
        // }
        handleStateChange("selectedOrderBy", null);
        handleStateChange("editableTestPlanDesc", null);
      }
    );
  };

  const handleDeleteAction = () => {
    if (state?.selectedRow) {
      const payload = {
        idList: [state?.selectedRow?.testPlanId],
      };
      dispatch(deleteTestPlanMaster(payload))
        .then(() => {
          // if (owaspCategoryMasterId) {
          dispatch(getMasterTestListAPI(props?.payload));
          // }
        })
        .catch(() => {});
    }
    handleStateChange("openDeleteAssetDialog", false);
  };

  const handleSaveEditRow = (id: any) => {
    dispatch(getChangeTestPlanName(id?.testPlanId, state?.editedTestPlan))
      .then(() => {
        // if (owaspCategoryMasterId) {
        dispatch(getMasterTestListAPI(props?.payload));
        // }
        handleStateChange("selectedOrderBy", null);
        handleStateChange("editableTestPlanDesc", null);
      })
      .catch((error) => {
        console.error("Failed to update the test plan:", error);
      });
  };

  useEffect(() => {
    if (activeTests?.length === 0) {
      handleStateChange("selectedIds", []);
    }
  }, [activeTests, masterTestList]);

  const handleChangeRowSelect = (selectedIds: string[]) => {
    handleStateChange("selectedIds", selectedIds);
  };

  const handleSubmit = async (testPlan: string) => {
    if (!testPlan.trim()) {
      return;
    }

    try {
      setLoading(true);
      const payload = {
        testPlanDesc: testPlan,
        orgId: consultantOrgId,
        // ocmId: props.payload?.owaspCategoryMasterId,
        categoryId: props.payload?.id,
        stId: props.payload?.serviceType,
        testPlanType: props.payload?.testPlanType,
      };

      // Dispatch action to add a new test plan
      await dispatch(addNewTestPlan(payload)).then(() => {
        // if (owaspCategoryMasterId) {
        dispatch(getMasterTestListAPI(props.payload));
        // }
      });

      // Fetch the updated master test list if `owaspCategoryMasterId` is available
      handleStateChange("visibleActionModal", false);
      // handleClose(); // Close the dialog or perform post-submit action
    } catch (error) {
      console.error("Error submitting:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      accessorKey: "orderBy",
      header: "Order",
      size: 20,
      Cell: ({ row }: any) => (
        <Grid container flexWrap="nowrap" alignItems="center" spacing={0}>
          <Grid item xs>
            {state?.selectedOrderBy === row.original.orderBy ? (
              <TextField
                sx={{
                  fontSize: "0.875rem",
                  [".MuiInputBase-input"]: {
                    padding: "10px 5px 10px 6px !important",
                    width: "40px",
                  },
                }}
                type="text"
                value={state?.editedOrderBy}
                onChange={(e) =>
                  handleStateChange("editedOrderBy", e.target.value)
                }
                variant="outlined"
                size="small"
              />
            ) : (
              <span>{row.original.orderBy}</span>
            )}
          </Grid>
          <Grid style={styles.iconWidth}>
            {state?.selectedOrderBy === row.original.orderBy ? (
              <IconButton
                onClick={() => handleSaveEditRowTestPlanId(row.original)}
                color="success"
                size="small"
                title="Save"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                title="Update Order"
                onClick={() => handleEditClickTestPlanId(row.original)}
                size="small"
                style={styles.IDEdit_icon}
              >
                <DriveFileRenameOutlineIcon
                  style={styles.DriveFileRenameOutlineIcon_icon}
                  fontSize="small"
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      accessorKey: "testPlanDesc",
      header: "Test Plan",
      size: 700,
      Cell: ({ row }: any) => (
        <>
          {state?.editableTestPlanDesc === row.original.testPlanId ? (
            <TextField
              style={styles.fieldWidth}
              type="text"
              value={state?.editedTestPlan}
              onChange={(e) =>
                handleStateChange("editedTestPlan", e.target.value)
              }
            />
          ) : (
            <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {row.original.testPlanDesc}
            </span>
          )}
        </>
      ),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 50,
      Cell: ({ row }: any) => (
        <>
          {state?.editableTestPlanDesc === row.original.testPlanId ? (
            <>
              <IconButton
                onClick={() => handleSaveEditRow(row.original)}
                color="success"
                title="Done"
              >
                <CheckIcon style={styles.pointer} />
              </IconButton>
              <IconButton
                title="Delete"
                onClick={() => {
                  handleStateChange("openDeleteAssetDialog", true);
                  handleStateChange("selectedRow", row.original);
                }}
              >
                <DeleteIcon style={styles.pointer} />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                title="Edit"
                onClick={() => handleEditClickTestPlanDesc(row.original)}
              >
                <EditIcon style={styles.pointer} />
              </IconButton>
              <IconButton
                title="Delete"
                onClick={() => {
                  handleStateChange("openDeleteAssetDialog", true);
                  handleStateChange("selectedRow", row.original);
                }}
              >
                <DeleteIcon style={styles.pointer} />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid
        // container
        sx={{
          border: "0.5px solid #a2a2d299 !important",
           // background: "#1D1D3A",
          padding: "30px",
          // padding: "30px",
          height: "100%",
          borderRadius: "10px",
          // color: "#fff",
        }}
      >
        <VmTable
          enableRowSelection={true}
          enablePagination={false}
          enableBottomToolbar={false}
          enableGlobalFilter={true}
          data={activeTests}
          columns={columns}
          handleChangeRowSelect={handleChangeRowSelect}
          selectedIds={state?.selectedIds}
          isLoading={setDataloading}
          selectedIdsField={"testPlanId"}
          tableTitle={
            <ActionButton
              setVisibleActionModal={(value: any) =>
                handleStateChange("visibleActionModal", value)
              }
              setState={setState}
              activeTests={activeTests}
              masterTestList={masterTestList}
              selectedIds={state?.selectedIds}
              owaspCategoryMasterId={owaspCategoryMasterId}
              enableRiskRatingFilter={false}
              enableTestResultFilter={false}
              enableTestPlanNameFilter={false}
              enableApplyFilter={false}
              enableClearFilter={false}
              payloadData={props.payload}
            />
          }
        />

        <DialogBox
          maxWidth="md"
          modelTitle={"Add Test Plan"}
          open={state?.visibleActionModal}
          handleClose={() => handleStateChange("visibleActionModal", false)}
          dialogContent={
            <AddTestPlanMaster
              loading={setDataloading}
              handleSubmit={handleSubmit}
              handleClose={() => handleStateChange("visibleActionModal", false)}
            />
          }
        />

        <DialogBox
          maxWidth="sm"
          modelTitle={"Delete Test Plan"}
          open={state?.openDeleteAssetDialog}
          handleClose={() => handleStateChange("openDeleteAssetDialog", false)}
          dialogContent={
            <Delete
              handleClose={() =>
                handleStateChange("openDeleteAssetDialog", false)
              }
              handleDeleteAction={handleDeleteAction}
              loading={setDataloading}
            />
          }
        />
      </Grid>
    </>
  );
};

export default ActiveTestCase;
