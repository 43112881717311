import React, { useState } from "react";
import { Button, Grid, InputLabel, TextField, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import theme from "src/themes/theme";

interface AddTestPlanMasterProps {
  handleClose: () => void;
  handleSubmit: (testPlan: string) => void;
  loading: boolean;
}

const AddTestPlanMaster: React.FC<AddTestPlanMasterProps> = ({
  handleClose,
  handleSubmit,
  loading,
}) => {
  const [testPlan, setTestPlan] = useState("");
  const [error, setError] = useState("");

  const validateForm = () => {
    if (!testPlan.trim()) {
      setError("Test Plan field is required.");
      return false;
    }
    setError(""); // Clear the error if the form is valid
    return true;
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestPlan(event.target.value);
    if (error) setError(""); // Clear the error when the user starts typing
  };

  const onSubmit = () => {
    if (validateForm()) {
      handleSubmit(testPlan);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>Test Plan</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              name="testPlan"
              value={testPlan}
              onChange={handleOnChange}
              error={!!error}
              helperText={error}
              placeholder="Enter Test Plan"
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button size="large" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              size="large"
              variant="contained"
              color="secondary"
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0px",
                color: "white",
                width: "105px",
                height: "50px",
                borderRadius: "10px !important",
                background:
                  "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                textTransform: "none", // This removes the uppercase transformation
              }}
              loading={loading}
              onClick={onSubmit}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddTestPlanMaster;
