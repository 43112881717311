import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RootState, useSelector } from "../../store";
import theme from "src/themes/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface ConfirmChangeScanStatusProps {
  open: boolean;
  handleClose: (status: boolean | any) => void;
  handleChangeStatusAction: (description: string) => void;
  selectedScanStatus: string | boolean;
  description: string;
  setDescription: (desc: string) => void;
  setReportPublishedDate?: (date: Dayjs) => void;
  reportPublishedDate?: Dayjs;
}

const ConfirmChangeScanStatus: React.FC<ConfirmChangeScanStatusProps> = (
  props
) => {
  const { loading } = useSelector((state: RootState) => state?.userState);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setDescription(event.target.value);
  };

  const isStatusChange = props?.selectedScanStatus;

  return (
    <Dialog
      open={props?.open}
      onClose={props?.handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 10,
        sx: {
          borderRadius: 4,
          padding: 3,
        },
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          textAlign: "center",
          fontSize: 22,
          fontWeight: 700,
          // color: theme.palette.text.primary,
          marginBottom: 2,
        }}
      >
        {isStatusChange ? "Confirm Status Change" : "Confirm Publish Scan"}
      </DialogTitle>

      <DialogContent sx={{ paddingX: 4, paddingBottom: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 2,
            // color: theme.palette.text.primary,
          }}
        >
          {isStatusChange
            ? "Are you sure you want to change the scan status?"
            : "Are you sure you want to publish this scan?"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: 3,
          }}
        >
          {isStatusChange
            ? "Changing the status may affect ongoing tasks related to the scan."
            : "Publishing the scan will make it available for review."}
        </Typography>
        <Grid container spacing={2}>
          {!isStatusChange ? (
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: 500, marginBottom: 1 }}>
                Report Publish Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={
                    props?.reportPublishedDate
                      ? dayjs(props?.reportPublishedDate)
                      : null
                  }
                  onChange={(d) => {
                    if (d && props?.setReportPublishedDate) {
                      props.setReportPublishedDate(d);
                    }
                  }}
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12}>
            <TextField
              label="Description (Optional)"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={props?.description}
              onChange={handleDescriptionChange}
              placeholder="Add an optional description..."
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "center", paddingX: 4, paddingBottom: 3 }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          gap={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            size="medium"
            onClick={props?.handleClose}
            loading={loading}
            sx={{
              borderRadius: 2,
              padding: "8px 16px",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              color: " #F45F43",
              border: "1px solid",
              height: "50px",
              borderImageSlice: 1,
              textTransform: "none",
              "&:hover": {
                border: "1px solid",
                borderRadius: "10px",
                color: "#F45F43",
                borderImageSlice: 1,
              },
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="medium"
            variant="contained"
            onClick={() => props.handleChangeStatusAction(props.description)}
            loading={loading}
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              // width: "185px",
              // height: "50px",
              // borderRadius: "10px !important",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation

              borderRadius: 2,
              padding: "8px 16px",
            }}
          >
            {isStatusChange ? "Submit" : "Publish"}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangeScanStatus;
