import React, { Suspense, ComponentType } from 'react';

// project imports
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = <P extends object>(Component: ComponentType<P>): React.FC<P> => {
    const LoadableComponent: React.FC<P> = (props: P) => (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );

    LoadableComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;

    return LoadableComponent;
};

export default Loadable;
