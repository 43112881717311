// third-party
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import { isLoading, setLoading } from "./user";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { FetchParams, VulnerabilityStateTypes } from "./reducerTypes";

// ----------------------------------------------------------------------

// const vulnerabiltyMasterApi = "/platform/v1/vulnerability-master";
const vulnerabilityApiEndPoint = "/vulnerability";
const vulnerabilityMasterApiEndPoint = "/consultant/v1/vulnerability-master";
const consultantVulnerabilityApi = "/consultant/v1/vulnerability";
const consultantApi = "/consultant/v1";
const consultantPublishedVulnerabilityApi = "/consultant/v1/published/vul";
const consultantNessusApi = "/consultant/v1/nessus";

const initialState: VulnerabilityStateTypes = {
  error: null,
  allVulnerabilities: [],
  VulnerabiltySans25Success: [],
  riskRating: [],
  easeOfExploitation: [],
  testerVulnerabilities: [],
  vulnerabilityStatus: [],
  assetsGroups: [],
  scanVulnerabilities: {
    data: [],
    totalRecords: 0,
  },
  vulnerabilityStageFlow: [],
  vulnerabilityStatusHistory: [],
  vulnerabilityComment: [],
  allAssets: [],
  OWASPCategories: [],
  addVulnerabilityRepose: {},
  vulnerabilityDetails: {},
  addVulnerabilityCommentResponse: {},
  vulnerabilityTask: {},
  deleteVulnerabilityToScanResponse: {},
  deleteVulnerabilityCommentResponse: {},
  submitToSM: {},
  submitToTester: {},
  statusTester: {},
  statusSM: {},
  addEvidence: {},
  evidenceList: [],
  uploadNessusReponse: {},
  evidenceImageResponse: {
    fileName: "",
    fileContent: [],
  },
  deleteEvidence: {},
  publishedVulnerabilityList: {
    data: [],
    totalRecords: 0,
  },
  selectedPublishedVulDetail: {},
  complianceStatusList: [],
  downlaodNessusTemplateResponse: {},
  nessusUploadFileList: {},
  nessusUploadAssetSummaryList: [],
  approveNessusUploadRes: {},
  rejectNessusUploadRes: {},
  consultantStatusValue: [],
  clientStatusValue: [],
  smartCloseVulnerabilitiesRes: {},
  riskRatingValue: [],
  updateVulnerabilityStatusRes: [],
  OWASPSansCategories: [],
};

const slice = createSlice({
  name: "vulnerability",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------------- GET APIs ------------------------------

    // GET ALL_Vulnerabilities
    getVulnerabilitiesSuccess(state, action) {
      state.allVulnerabilities = action.payload;
    },
    // GET sans25_Vulnerabilities
    getVulnerabiltySans25Success(state, action) {
      state.VulnerabiltySans25Success = action.payload;
    },

    // GET RISK_RATING
    getRiskRatingSuccess(state, action) {
      state.riskRating = action.payload;
    },
    // GET EASE_OF_EXPLORATION
    getEaseOfExploitationSuccess(state, action) {
      state.easeOfExploitation = action.payload;
    },
    // GET EASE_OF_EXPLORATION
    getVulnerabilitiesForTesterSuccess(state, action) {
      state.testerVulnerabilities = action.payload;
    },
    // GET ALL_VULNERABILITY_STATUS
    getVulnerabilityStatusSuccess(state, action) {
      state.vulnerabilityStatus = action.payload;
    },
    // GET ASSETS_GROUP
    getAssetsGroupSuccess(state, action) {
      state.assetsGroups = action.payload;
    },
    // GET SCAN_VULNERABILITY_LIST
    getScanVulnerabilitiesSuccess(state, action) {
      state.scanVulnerabilities = action.payload;
    },
    // GET VULNERABILITY_DETAILS
    getVulnerabilityDetailsSuccess(state, action) {
      state.vulnerabilityDetails = action.payload;
    },
    // GET VULNERABILITY_STAGEFLOW
    getVulnerabilityStageFlowSuccess(state, action) {
      state.vulnerabilityStageFlow = action.payload;
    },
    // GET VULNERABILITY_STATUS_HISTORY
    getVulnerabilityStatusHistorySuccess(state, action) {
      state.vulnerabilityStatusHistory = action.payload;
    },
    // GET VULNERABILITY_COMMNET
    getVulnerabilityCommentsSuccess(state, action) {
      state.vulnerabilityComment = action.payload;
    },
    // GET VULNERABILITY_TASK
    getVulnerabilityTaskSuccess(state, action) {
      state.vulnerabilityTask = action.payload;
    },
    // GET ADD_VULNERABILITY_COMMENT
    addVulnerabilityCommentsSuccess(state, action) {
      state.addVulnerabilityCommentResponse = action.payload;
    },
    // GET All_ASSETS
    getAllAssetsSuccess(state, action) {
      state.allAssets = action.payload;
    },
    // GET ALL_VULNAERABILITY
    getOWASPCategoriesSuccess(state, action) {
      state.OWASPCategories = action.payload;
    },

    // GET_CONSULTANT_PUBLISHED_VULNERABILITY_LIST
    getPublishedVulnerabilitySuccess(state, action) {
      state.publishedVulnerabilityList = action.payload;
    },

    //GET_SELECTED_CONSULTNT_PUBLISHED_VULNERABILITY_DETAIL
    getSelectedPublishedVulnerabilityDetailSuccess(state, action) {
      state.selectedPublishedVulDetail = action.payload;
    },

    //GET CONSULTANT STATUS VALUE
    getConsultantStatusValueSuccess(state, action) {
      state.consultantStatusValue = action.payload;
    },
    //GET CLIENT STATUS VALUE
    getClientStatusValueSuccess(state, action) {
      state.clientStatusValue = action.payload;
    },

    // --------------------------- ADD APIs ------------------------------

    // ADD VULNERABILITY
    addVulnerabilitySuccess(state, action) {
      state.addVulnerabilityRepose = action.payload;
    },

    // UPLOAD NESSUS_FILE
    uploadNessusSuccess(state, action) {
      state.uploadNessusReponse = action.payload;
    },

    // --------------------------- DELETE APIs ---------------------------

    // DELETE VULNERABILITY_TO_SCAN
    deleteVulnerabilityToScanSuccess(state, action) {
      state.deleteVulnerabilityToScanResponse = action.payload;
    },
    // DELETE VULNERABILITY_COMMENT
    deleteVulnerabilityCommentSuccess(state, action) {
      state.deleteVulnerabilityCommentResponse = action.payload;
    },

    //DELETE VULNERABILITY_EVIDENCE
    deleteVulnerabilityEvidenceSuccess(state, action) {
      state.deleteEvidence = action.payload;
    },
    //-----------------------PUT APIs-----------------------
    // SUBMIT_TO_SM
    submitToSMSuccess(state, action) {
      state.submitToSM = action.payload;
    },
    // SUBMIT_TO_TESTER
    submitToTesterSuccess(state, action) {
      state.submitToTester = action.payload;
    },
    // STATUS_CHANGE_BY_TESTER
    statusChangeTesterSuccess(state, action) {
      state.statusTester = action.payload;
    },
    // STATUS_CHANGE_BY_SM
    statusChangeSMSuccess(state, action) {
      state.statusSM = action.payload;
    },
    // ADD_EVIDENCE
    addEvidenceSuccess(state, action) {
      state.addEvidence = action.payload;
    },
    // GET_EVIDENCE_LIST
    evidenceListSuccess(state, action) {
      state.evidenceList = action.payload;
    },
    // GET_EVIDENCE_IMAGE
    getEvidenceImageSuccess(state, action) {
      state.evidenceImageResponse = action.payload;
    },

    // GET_COMPLIANCE_STATUS_LIST
    complianceStatusListSuccess(state, action) {
      state.complianceStatusList = action.payload;
    },

    // DOWNLOAD_NESSUS_FILE_TEMPLATE
    downlaodNessusTemplateSuccess(state, action) {
      state.downlaodNessusTemplateResponse = action.payload;
    },

    // GET_NESSUS_UPLOAD_FILE_LIST
    nessusUploadFileListSuccess(state, action) {
      state.nessusUploadFileList = action.payload;
    },

    // GET_NESSUS_UPLOAD_ASSET_SUMMARY_LIST
    nessusUploadAssetSummaryListSuccess(state, action) {
      state.nessusUploadAssetSummaryList = action.payload;
    },

    // APPROVE_NESSUS_UPLOAD_ASSET_SUMMARY_LIST
    approveNessusUploadSuccess(state, action) {
      state.approveNessusUploadRes = action.payload;
    },
    // GET_NESSUS_UPLOAD_ASSET_SUMMARY_LIST
    rejectNessusUploadSuccess(state, action) {
      state.rejectNessusUploadRes = action.payload;
    },
    // SMART_CLOSE_VULNERABILITIES
    smartCloseVulnerabilitiesSuccess(state, action) {
      state.smartCloseVulnerabilitiesRes = action.payload;
    },
    // RISK_RATING
    getRiskRatingValueSuccess(state, action) {
      state.riskRatingValue = action.payload;
    },
    // UPDATE_VULNERABILITY_STATUS
    updateVulnerabilityStatusSuccess(state, action) {
      state.updateVulnerabilityStatusRes = action.payload;
    },
    // GET_OWASP_CATEGORIES_BY_SCAN_ID
    getOWASPCategoriesByScanIdSuccess(state, action) {
      state.OWASPCategories = action.payload;
    },
    // GET_OWASP_SANS_CATEGORIES_BY_SCAN_ID
    getOWASPAndSansCategoriesByScanIdSuccess(state, action) {
      state.OWASPSansCategories = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { getOWASPCategoriesSuccess, downlaodNessusTemplateSuccess } =
  slice.actions;

// ----------------------------------------------------------------------
export const addVulnerabilityComments = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantVulnerabilityApi}/comment`,
        payload
      );
      dispatch(slice.actions.addVulnerabilityCommentsSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addVulnerability = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${consultantVulnerabilityApi}`,
        payload
      );
      dispatch(slice.actions.addVulnerabilitySuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      return error;
      // dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getVulnerabilitiesMaster = (
  scanId?: number,
  clientOrgId?: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/master-vulnerabilities/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.getVulnerabilitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getRiskRating = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/risk-rating`
      );
      dispatch(slice.actions.getRiskRatingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getEaseOfExploitation = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/ease-of-exploitation`
      );
      dispatch(slice.actions.getEaseOfExploitationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilities = (
  params: FetchParams | any,
  customEndPoint: string
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/list/${customEndPoint}`,
        {
          params,
        }
      );
      dispatch(slice.actions.getVulnerabilitiesForTesterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityStatus = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/all-status`
      );
      dispatch(slice.actions.getVulnerabilityStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getScanVulnerabilities = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/list/${params?.scanId}/${params?.clientOrgId}`,
        { params }
      );
      dispatch(slice.actions.getScanVulnerabilitiesSuccess(response.data));
      dispatch(slice.actions.getVulnerabilityDetailsSuccess({}));
      dispatch(slice.actions.addVulnerabilitySuccess({}));
      // notificationManager(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityDetails = (
  scanId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.getVulnerabilityDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityStageFlow = (
  valId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/stage-flow/${valId}/${clientOrgId}`
      );
      dispatch(slice.actions.getVulnerabilityStageFlowSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityStatusHistory = (
  scanVulnerabilityId: number,
  clientVulnerabilityId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/status-history/${scanVulnerabilityId}/${clientVulnerabilityId}/${clientOrgId}`
      );
      dispatch(
        slice.actions.getVulnerabilityStatusHistorySuccess(response.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityComments = (
  valId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/comment/${valId}/${clientOrgId}`
      );
      dispatch(slice.actions.getVulnerabilityCommentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getAssetsGroup = (clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/asset-group/${clientOrgId}`
      );
      dispatch(slice.actions.getAssetsGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getVulnerabilityTask = (role: string) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/consultant/v1/${role}/task${vulnerabilityApiEndPoint}`
      );
      dispatch(slice.actions.getVulnerabilityTaskSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateVulnerabilityDetails = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(`${consultantVulnerabilityApi}`, {
        ...payload,
      });
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const deleteVulnerabilityToScan = (
  scanId: number,
  clientOrgId: number,
  payload: FetchParams
) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const url = `${consultantVulnerabilityApi}/${scanId}/${clientOrgId}`;
    try {
      const response = await axios.request({
        url,
        method: "DELETE",
        data: payload,
      });
      dispatch(slice.actions.deleteVulnerabilityToScanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const deleteVulnerabilityComment = (
  commentId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(
        `${consultantVulnerabilityApi}/comment/${commentId}/${clientOrgId}`
      );
      dispatch(slice.actions.deleteVulnerabilityCommentSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getVulnerbilitySans25 = (serviceTypeId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/SANS25/{serviceType}?serviceType=${serviceTypeId}`
      );
      dispatch(slice.actions.getVulnerabiltySans25Success(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getOWASPCategories = (serviceType: string) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response =
        await axios.get(`${consultantVulnerabilityApi}/owasp-categories/{serviceType}?serviceType=${serviceType}
`);
      dispatch(slice.actions.getOWASPCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getOWASPCategoriesServices = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response =
        await axios.get(`${vulnerabilityMasterApiEndPoint}/service-type
`);
      dispatch(slice.actions.getOWASPCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const submitToSM = (clientOrgId: number, payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/submit-by-tester-to-sm/${clientOrgId}`,
        payload
      );
      dispatch(slice.actions.submitToSMSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const submitToTester = (clientOrgId: number, payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/submit-by-sm-to-tester/${clientOrgId}`,
        payload
      );
      dispatch(slice.actions.submitToTesterSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const statusTester = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/vulnerability-status-tester`,
        payload
      );
      dispatch(slice.actions.statusChangeTesterSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const statusSM = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/vulnerability-status-sm`,
        payload
      );
      dispatch(slice.actions.statusChangeSMSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const uploadNewEvidence = (
  payload: FormData | FetchParams | any | File | unknown
) => {
  const accessToken = localStorage.getItem("serviceToken");
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantApi}/vul-evidence`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(slice.actions.addEvidenceSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getEvidenceList = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantApi}/vul-evidence/list/${payload?.vulnerabilityId}/${payload?.clientOrgId}`,
        payload
      );
      dispatch(slice.actions.evidenceListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const uploadNessusCsv = (
  clientOrgId: number,
  scanId: number,
  file: string,
  normalUpload: boolean
) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${consultantNessusApi}/upload/${clientOrgId}/${scanId}?normalUpload=${normalUpload}`,
        file,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(slice.actions.uploadNessusSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

// export const uploadNessusCsv = (clientOrgId, scanId, file) => {
//   return async () => {     dispatch(setLoading(true));

//     try {
//       const endpoint = `consultant/v1/vulnerability/upload/nessus-csv/${clientOrgId}/${scanId}`;
//       const response = await axios.post(`/${endpoint}`, file, {
//         headers: {
//           "Access-Control-Allow-Origin": "*",
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       dispatch(slice.actions.uploadNessusSuccess(response.data));
//       notificationManager(response);
//     } catch (error) {
//       dispatch(openSnackbar(error as SnackbarState));
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// };
export const getEvidenceImage = (
  vulnerabilityEvidenceId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const endpoint = `consultant/v1/vul-evidence/download/${vulnerabilityEvidenceId}/{clientOrgId}?clientOrgId=${clientOrgId}`;
      const response = await axios.get(`/${endpoint}`);
      dispatch(slice.actions.getEvidenceImageSuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const deleteVulnerabilityEvidence = (param: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(
        `${consultantApi}/vul-evidence/${param?.vulEvidenceId}/${param?.clientOrgId}`
      );
      dispatch(slice.actions.deleteVulnerabilityEvidenceSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getConsultantPublishedVulnerabilityList = (
  params: FetchParams
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantPublishedVulnerabilityApi}/list`,
        { params }
      );
      dispatch(slice.actions.getPublishedVulnerabilitySuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedPublishedVulnerabilityDetail = (
  clientVulId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantPublishedVulnerabilityApi}/${clientVulId}/${clientOrgId}`
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilityDetailSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const uploadNewValComment = (payload: FetchParams) => {
  const accessToken = localStorage.getItem("serviceToken");
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantVulnerabilityApi}/comment/attachment/${payload.vaCommentId}/${payload.clientOrgId}`,
        payload.file,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getComplianceStatusList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/compliance-status/list`
      );
      dispatch(slice.actions.complianceStatusListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const downlaodNessustemplate = (normalUpload: string | boolean) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        normalUpload
          ? `${consultantVulnerabilityApi}/upload/vulnerability-file/download`
          : `${consultantVulnerabilityApi}/upload/nessus-file/download`
      );
      dispatch(slice.actions.downlaodNessusTemplateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchNessusUploadFileList = (
  scanId: number,
  clientOrgId: number
) => {
  return async () => {
    // dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantNessusApi}/file-upload-list/{scanId}/{clientOrgId}?scanId=${scanId}&clientOrgId=${clientOrgId}`
      );
      dispatch(slice.actions.nessusUploadFileListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      // dispatch(setLoading(false));
    }
  };
};

export const fetchNessusUploadAssetSummaryList = (
  scanId: number,
  clientOrgId: number,
  fileUploadId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantNessusApi}/asset-summary-list/{scanId}/{clientOrgId}/{fileUploadId}?scanId=${scanId}&clientOrgId=${clientOrgId}&fileUploadId=${fileUploadId}`
      );
      dispatch(
        slice.actions.nessusUploadAssetSummaryListSuccess(response.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const approveNessusUpload = (
  scanId: number,
  clientOrgId: number,
  fileUploadId: number,
  data: FetchParams
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `/consultant/v1/vulnerability/upload/approve/${scanId}/${clientOrgId}/${fileUploadId}`,
        data
      );
      dispatch(slice.actions.approveNessusUploadSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const rejectNessusUpload = (
  scanId: number,
  clientOrgId: number,
  fileUploadId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `/consultant/v1/vulnerability/upload/reject/${scanId}/${clientOrgId}/${fileUploadId}`
      );
      dispatch(slice.actions.rejectNessusUploadSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));

      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getConsultantStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `/client/v1/vulnerability/consultant-status`
        : `${consultantVulnerabilityApi}/consultant-status`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getConsultantStatusValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const smartCloseVulnerabilities = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantApi}/scan/vulnerabilities-smart-close/${payload?.scanId}/${payload?.clientOrgId}`
      );
      dispatch(slice.actions.smartCloseVulnerabilitiesSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getClientStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `/client/v1/vulnerability/client-status`
        : `${consultantVulnerabilityApi}/client-status`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getClientStatusValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getRiskRatingValue = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantVulnerabilityApi}/risk-rating`
      );
      dispatch(slice.actions.getRiskRatingValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateVulnerabilityStatus = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/status?scanId=${params?.scanId}`,
        params
      );
      dispatch(slice.actions.updateVulnerabilityStatusSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getOWASPCategoriesByScanId = (
  scanId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response =
        await axios.get(`${consultantVulnerabilityApi}/scan-owasp-categories/{scanId}/{clientOrgId}?scanId=${scanId}&clientOrgId=${clientOrgId}
`);
      dispatch(slice.actions.getOWASPCategoriesByScanIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getOWASPandSansCategoriesByScanId = (
  scanId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response =
        await axios.get(`${consultantVulnerabilityApi}/scan-owasp-sans-categories/{scanId}/{clientOrgId}?scanId=${scanId}&clientOrgId=${clientOrgId}
`);
      dispatch(slice.actions.getOWASPAndSansCategoriesByScanIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const acceptTesterStatus = (payload: FetchParams) => {
  return async () => {
    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${consultantVulnerabilityApi}/accept-tester-status?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`,
        payload
      );
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
    }
  };
};
