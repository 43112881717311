import React from "react";
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineSeparator,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import theme from "src/themes/theme";
import { CommentItem } from "src/views/pages/consultant/scan/scanTypes";

const CheckedIcon = () => {
  return (
    <CheckCircleIcon
      sx={{
        // color: theme.palette.success.main,
        fontSize: "1.5rem",
      }}
    />
  );
};

const FirstCheckedIcon = () => {
  return (
    <CheckCircleIcon
      sx={{
        color: "#FFA500",
        fontSize: "1.5rem",
      }}
    />
  );
};

interface InfoBoxProps {
  userName: string;
  startDate: string;
  endDate: string;
}

const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const paperStyle = {
    p: 2.5,
    boxShadow: "none",
    // background:
    //   theme.palette.mode === "dark"
    //     ? theme.palette.dark.main
    //     : theme.palette.primary.light,
    border: "1px dashed",
    // borderColor:
      // theme.palette.mode === "dark"
        // ? theme.palette.dark.dark
        // : theme.palette.primary.dark,
  };

  return (
    <Paper elevation={3} sx={paperStyle}>
      <Stack direction="row" alignItems="center" gap={1} mb={1}>
        <Typography variant="h5" fontSize={16}>
          User Name :
        </Typography>
        <Typography variant="h5" fontSize={16} color="secondary">
          {props.userName}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="subtitle2" fontSize={14}>
          Start Date:
        </Typography>
        <Typography variant="subtitle2" fontSize={14}>
          {props.startDate}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="subtitle2" fontSize={14}>
          End Date:
        </Typography>
        <Typography variant="subtitle2" fontSize={14}>
          {props.endDate}
        </Typography>
      </Stack>
    </Paper>
  );
};


interface TimeLineProps {
  commentList: CommentItem[];
  fieldsName: string;
  userName: string;
}

const TimeLine: React.FC<TimeLineProps> = (props) => {
  return (
    <Timeline position="alternate">
      {props.commentList?.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography color="textSecondary">
              {item[props.fieldsName]}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            {index === 0 ? <FirstCheckedIcon /> : <CheckedIcon />}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <InfoBox
              userName={item[props.userName] || ""}
              startDate={
                item.createdOn
                  ? moment(item.createdOn).format("Do MMMM, YYYY h:mm A")
                  : ""
              }
              endDate={
                item.completedOn
                  ? moment(item.completedOn).format("Do MMMM, YYYY h:mm A")
                  : ""
              }
            />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TimeLine;
