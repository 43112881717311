import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { dispatch } from "../index";
import { setLoading } from "./user";
import { PORTAL_TYPE } from "src/constant";

// Define types
interface MenuItem {
  id: string;
  name: string;
  children?: MenuItem[];
}

interface MenuState {
  selectedItem: string[];
  selectedID: string | null;
  drawerOpen: boolean;
  error: string | null;
  menu: MenuItem[];
  userRoles: string[];
  selectMenu: string;
}

// Initial state
const initialState: MenuState = {
  selectedItem: [""],
  selectedID: null,
  drawerOpen: false,
  error: null,
  menu: [],
  userRoles: [],
  selectMenu: "",
};

interface MenuResponse {
  menus: MenuItem[];
  actions: string[];
}
type UserRoleResponse = {
  roles: string[];
};

// ==============================|| SLICE - MENU ||============================== //
const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action: PayloadAction<string[]>) {
      state.selectedItem = action.payload;
    },

    activeID(state, action: PayloadAction<string | null>) {
      state.selectedID = action.payload;
    },

    openDrawer(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
    },

    hasError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },

    getMenuSuccess(state, action: PayloadAction<MenuItem[]>) {
      state.menu = action.payload;
    },

    getRoles(state, action: PayloadAction<string[]>) {
      state.userRoles = action.payload;
    },
    getMenutitleForHeader(state, action: PayloadAction<string>) {
      state.selectMenu = action.payload;
    },
  },
});

export default menu.reducer;
export const {
  activeItem,
  openDrawer,
  activeID,
  getMenuSuccess,
  getRoles,
  hasError,
  getMenutitleForHeader,
} = menu.actions;

// ==============================|| ASYNC THUNKS ||============================== //

const getAuthorizationHeader = () => {
  const token = localStorage.getItem("serviceToken");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const getPortalPrefix = () => {
  const orgType = localStorage.getItem("organizationType");
  if (orgType === PORTAL_TYPE.CONSULTANT) return "consultant";
  if (orgType === PORTAL_TYPE.PLATFORM) return "platform";
  return "client";
};

export const getMenu = () => async () => {
  dispatch(setLoading(true));

  try {
    if (!localStorage.getItem("serviceToken")) return;

    const prefix = getPortalPrefix();
    const response = await axios?.get<MenuResponse | any>(
      `${process.env.REACT_APP_API_URL}/${prefix}/v1/user/menus-actions`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...getAuthorizationHeader(),
        },
      }
    );

    dispatch(getMenuSuccess(response.data.menus || []));
    localStorage.setItem(
      "actions",
      JSON.stringify(response.data.actions || [])
    );
  } catch (error) {
    dispatch(
      hasError((error as unknown as Error).message || "Failed to fetch menu")
    );
  } finally {
    dispatch(setLoading(false));
  }
};
export const getUsersRoles = () => async () => {
  dispatch(setLoading(true));

  try {
    if (!localStorage.getItem("serviceToken")) return;

    const prefix = getPortalPrefix();
    const response = await axios.get<any>(
      `${process.env.REACT_APP_API_URL}/${prefix}/v1/user/assigned-roles`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...getAuthorizationHeader(),
        },
      }
    );

    // Use response.data.roles instead of response.data
    // dispatch(getRoles(response?.data || []));
    dispatch(getRoles(response?.data || []));
  } catch (error) {
    dispatch(
      hasError(
        (error as unknown as Error).message || "Failed to fetch user roles"
      )
    );
  } finally {
    dispatch(setLoading(false));
  }
};
