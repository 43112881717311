import React, { ReactNode, useMemo } from "react";

// material-ui
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  PaletteMode,
  Typography,
} from "@mui/material";
import theme from "src/themes/theme";
import Palette from "src/themes/palette";
import useConfig from "src/hooks/useConfig";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

interface MainCardProps {
  border?: boolean;
  elevation?: number;
  boxShadow?: boolean;
  children?: ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: object;
  darkTitle?: boolean;
  secondary?: ReactNode;
  shadow?: string | number;
  sx?: object;
  backgroundColor?: string | any;
  title?: ReactNode;
}

const MainCard = React.forwardRef<HTMLDivElement, MainCardProps>(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      backgroundColor,
      title,
      ...others
    },
    ref
  ) => {
    const {
      borderRadius,
      fontFamily,
      navType,
      outlinedFilled,
      presetColor,
      rtlLayout,
    } = useConfig();

    const themes = useMemo(
      () => Palette(navType as PaletteMode, presetColor),
      [navType, presetColor]
    );

    const baseBackgroundColor = themes.palette.mode === "dark" ? "14142b" : "#121926";
    // const orgdetailBGCOlor = themes.palette.mode ? "#14142b" : "#fff";

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderRadius: "0px",
          // background: themes.palette.mode === "dark" ? "#14142b" : "#fff",
          // borderColor:
          //   theme.palette.mode === "dark"
          //     ? theme.palette.background.default
          //     : theme.palette.grey[100] + 98,
          ":hover": {
            boxShadow: boxShadow
              ? shadow ||
                (theme.palette.mode === "dark"
                  ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                  : "0 2px 14px 0 rgb(32 40 45 / 8%)")
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={
              <Typography
                sx={{
                  margin: "0px",
                  fontSize: "1.5rem",
                  // color: baseBackgroundColor,
                  fontWeight: "700",
                  fontFamily: "Inter, sans-serif",
                  lineHeight: "1.2",
                }}
                //  variant="h4"
              >
                {title}
              </Typography>
            }
            action={secondary}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h2">{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.displayName = "MainCard";
export default MainCard;
