import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import { dispatch } from "./../../../../../../../store";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { handleTrim } from "../../../../../../../utils/helpers";
import {
  ReportConfigTableProps,
  RowDataReportConfigTable,
} from "../../../scanTypes";

const errorStyle = {
  errorText: {
    color: "#f44336",
  },
};
const ReportConfigTable: React.FC<ReportConfigTableProps> = ({
  tableName,
  columns,
  data,
  tableWidth,
  cellWidth,
  valueLabel,
  tableAction,
  handleSave,
  url,
  isEdit,
  allowUpdate,
}) => {
  const [rows, setRows] = useState(data);
  const [newRow, setNewRow] = useState<RowDataReportConfigTable>({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showList, setShowList] = useState(isEdit);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableDeleteIcon, setDisableDeleteIcon] = useState<any>(false);
  const [emptyField, setEmptyField] = useState(false);

  const handleAddRow = () => {
    if (selectedIndex !== null) {
      const updatedRows = [...rows];
      updatedRows[selectedIndex] = newRow;
      setRows(updatedRows);
      setNewRow({ [valueLabel[0]]: "", [valueLabel[1]]: "" });
      setSelectedIndex(null);
    } else {
      setRows((prevRows) => [...prevRows, newRow]);
      setNewRow({ [valueLabel[0]]: "", [valueLabel[1]]: "" });
    }
  };

  const handleDeleteRow = (index: string | number | any) => () => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    setSelectedIndex(null);
    setEmptyField(false);
  };
  const handleUpdateRow = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const updatedRows = rows?.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );
    setRows(updatedRows);
    setEmptyField(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    setNewRow((prevRows) => ({ ...prevRows, [property]: event.target.value }));
    setEmptyField(false);
  };

  const handleSubmit = (
    name: string,
    data: Record<string, string>[],
    url: string
  ) => {
    if (data?.length <= 0 && Object.values(newRow)?.length <= 0) {
      setEmptyField(true);
      return;
    }
    const isEmptyField = data.some((item) =>
      Object.values(item).some((value) => handleTrim(!value))
    );
    if (isEmptyField) {
      setEmptyField(isEmptyField);
      return;
    }
    const newRowData = Object.values(newRow);
    if (
      (newRowData?.length > 0 && newRowData[0] && !newRowData[1]) ||
      (!newRowData[0] && newRowData[1])
    ) {
      setEmptyField(true);
      return;
    }
    if (newRowData?.length > 0 && newRowData[0] && newRowData[1]) {
      handleSave(name, [...data, newRow], url);
      setEmptyField(false);
      setNewRow({});
      setShowList(false);
      return;
    }
    handleSave(name, data, url);
    setEmptyField(false);
    setNewRow({});
    setShowList(false);
  };

  const styles = {
    table: {
      width: tableWidth,
      // color: "black",
      borderCollapse: "collapse",
    },
    heading: {
      fontSize: "18px",
      border: "1px solid #dddddd",
      padding: "12px",
      // backgroundColor: "#ebe2e2",
    },
    cell: {
      // color: "black",
      border: "1px solid #dddddd",
      padding: "10px",
      width: cellWidth,
      // backgroundColor: "#f9f5f5",
    },
  };

  useEffect(() => {
    setRows(data);
    setEmptyField(false);
  }, [data]);
  useEffect(() => {
    setShowList(isEdit);
  }, []);

  useEffect(() => {
    if (
      rows?.length === 1 &&
      Object.values(rows[0]).every((item) => item === "")
    ) {
      setDisableSaveBtn(true);
    } else {
      setDisableSaveBtn(false);
    }
    if (rows?.length > 0) {
      setDisableDeleteIcon(true);
    } else {
      setDisableDeleteIcon(false);
      setDisableSaveBtn(false);
    }
  }, [rows]);

  return (
    <Stack direction="column" gap={1}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        direction="row"
        marginBottom={2}
      >
        <Typography variant="h6">{tableName}</Typography>
        {allowUpdate ? (
          <IconButton
            title={!showList ? "Edit" : "Cancel"}
            // variant="contained"
            size="large"
            onClick={() => setShowList(!showList)}
            color="inherit"
            aria-label="live customize"
          >
            {!showList ? <ModeEditIcon /> : <CloseIcon />}
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
      {emptyField ? (
        <Typography variant="body1" sx={errorStyle.errorText}>
          Please fill in all fields before submitting.
        </Typography>
      ) : (
        ""
      )}
      {data?.length === 0 || showList ? (
        <TableContainer component={Paper}>
          <Table
          //  style={styles.table}
          >
            <TableHead>
              <TableRow>
                {columns?.map((value, key) => (
                  <TableCell key={key}>{value}</TableCell>
                ))}
                <TableCell style={{ width: "100" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      value={rows[index][valueLabel[0]]}
                      onChange={(e) => handleUpdateRow(e, index)}
                      fullWidth
                      name={valueLabel[0]}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={rows[index][valueLabel[1]]}
                      onChange={(e) => handleUpdateRow(e, index)}
                      fullWidth
                      name={valueLabel[1]}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={!disableDeleteIcon}
                      onClick={handleDeleteRow(index)}
                      size="small"
                      title="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    value={newRow[valueLabel[0]]}
                    onChange={(e: any) => handleInputChange(e, valueLabel[0])}
                    fullWidth
                    name={valueLabel[0]}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newRow[valueLabel[1]]}
                    onChange={(e: any) => handleInputChange(e, valueLabel[1])}
                    fullWidth
                    name={valueLabel[1]}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={handleAddRow}
                    size="small"
                    disabled={!showList}
                    title="Add"
                  >
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table
          // style={styles.table}
          >
            <TableHead>
              <TableRow>
                {columns?.map((value, key) => (
                  <TableCell key={key} style={styles.heading}>
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={styles.cell} align="left">
                    {row[valueLabel[0]]}
                    {/* {row.userName} */}
                  </TableCell>
                  <TableCell style={styles.cell} align="left">
                    {row[valueLabel[1]]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {showList && allowUpdate ? (
        <IconButton
          onClick={() => handleSubmit(tableAction, rows, url)}
          sx={{ alignSelf: "flex-end" }}
          disabled={disableSaveBtn}
          color="inherit"
          size="large"
          aria-label="live customize"
          title="Save"
        >
          <SaveIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ReportConfigTable;
