import React from 'react';
import { Divider, FormControl, InputAdornment, MenuItem, TextField } from '@mui/material';
import theme from 'src/themes/theme';
// import { useTheme } from '@mui/material/styles';

// Define interface for props
interface FormControlSelectProps {
  placeholder?: string;
  captionLabel?: string;
  itemsList?: { [key: string]: any }[] | any; // Array of objects
  formState?: any; // Adjust type based on your form state
  iconPrimary?: React.ElementType; // Changed to React.ElementType
  iconSecondary?: React.ElementType; // Changed to React.ElementType
  selected?: string | number;
  textPrimary?: string;
  textSecondary?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  name: string;
  optionLabel: string;
  optionValue: string;
  errors?: any; // Adjust type based on your validation
  [key: string]: any; // For any additional props that might be passed
}

// ==============================|| FORM CONTROL SELECT ||============================== //
const FormControlSelect: React.FC<FormControlSelectProps> = ({
  placeholder,
  captionLabel,
  itemsList,
  formState,
  iconPrimary,
  iconSecondary,
  selected,
  textPrimary,
  textSecondary,
  onChange,
  name,
  optionLabel,
  optionValue,
  errors,
  ...props
}) => {
  // const theme = useTheme();

  const primaryIcon = iconPrimary ? (
    React.createElement(iconPrimary, { fontSize: 'small', sx: { color: theme.palette.grey[700] } })
  ) : null;

  const secondaryIcon = iconSecondary ? (
    React.createElement(iconSecondary, { fontSize: 'small', sx: { color: theme.palette.grey[700] } })
  ) : null;

  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-select-currency"
        select
        fullWidth
        name={name}
        placeholder={placeholder}
        sx={{
          textTransform: 'capitalize',
        }}
        label={captionLabel}
        onChange={onChange}
        {...props}
        InputProps={{
          startAdornment: (
            <>
              {primaryIcon && (
                <InputAdornment position="start">{primaryIcon}</InputAdornment>
              )}
              {textPrimary && (
                <>
                  <InputAdornment position="start">
                    {textPrimary}
                  </InputAdornment>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </>
              )}
            </>
          ),
          endAdornment: (
            <>
              {secondaryIcon && (
                <InputAdornment position="end">{secondaryIcon}</InputAdornment>
              )}
              {textSecondary && (
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <InputAdornment position="end">
                    {textSecondary}
                  </InputAdornment>
                </>
              )}
            </>
          ),
        }}
      >
        {itemsList && itemsList?.map((option: { [key: string]: any }, index: number) => (
          <MenuItem
            {...props}
            key={index}
            value={option[optionValue]}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {option[optionLabel]}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

// FormControlSelect.propTypes = {
//   captionLabel: PropTypes.string,
//   itemsList: PropTypes.array,
//   formState: PropTypes.string,
//   iconPrimary: PropTypes.object,
//   iconSecondary: PropTypes.object,
//   selected: PropTypes.string,
//   textPrimary: PropTypes.string,
//   textSecondary: PropTypes.string,
// };

export default FormControlSelect;
