import { DialogContent } from "@mui/material";
import React from "react";
import VmTable from "../../../../../../../ui-component/table";
import { VulnerabilityDialogContentProps } from "../../../scanTypes";

const VulnerabilityDialogContent: React.FC<VulnerabilityDialogContentProps> = (
  props
) => {
  return (
    <DialogContent>
      <VmTable data={[]} columns={[]} {...props} />
    </DialogContent>
  );
};

export default VulnerabilityDialogContent;
