import React, { useState, FC } from "react";
// import { useTheme } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { IconPlus, IconTextSize, IconColorSwatch } from "@tabler/icons-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";

import BoxContainer from "./BoxContainer";
import Layout from "./Layout";
import InputFilled from "./InputFilled";
import BorderRadius from "./BorderRadius";
import ThemeModeLayout from "./ThemeMode";
import SidebarDrawer from "./SidebarDrawer";
import MenuOrientation from "./MenuOrientation";

import MainCard from "../../ui-component/cards/MainCard";
import { toggleCustomizeLayout } from "../../store/slices/custmizeLayout";
import useConfig from "src/hooks/useConfig";
import { dispatch } from "src/store";
import theme from "src/themes/theme";

interface CustomTabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const CustomTabPanel: FC<CustomTabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Customization: FC = () => {
  const { onReset } = useConfig();
  const { showCustimzeLayoutOption } = useSelector(
    (state: { customizeLayoutState: { showCustimzeLayoutOption: boolean } }) =>
      state.customizeLayoutState
  );

  // drawer on/off
  const handleToggle = () => {
    dispatch(toggleCustomizeLayout(!showCustimzeLayoutOption));
  };

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {/* toggle button */}
      {/* <Tooltip title="Live Customize">
        <Fab
          component="div"
          onClick={handleToggle}
          size="medium"
          variant="circular"
          color="secondary"
          sx={{
            borderRadius: 0,
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '4px',
            top: '25%',
            position: 'fixed',
            right: 10,
            zIndex: 1200,
            boxShadow: theme.customShadows.secondary,
          }}
        >
          <AnimateButton type="rotate">
            <IconButton color="inherit" size="large" disableRipple aria-label="live customize">
              <IconSettings />
            </IconButton>
          </AnimateButton>
        </Fab>
      </Tooltip> */}

      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={showCustimzeLayoutOption}
        PaperProps={{ sx: { width: 375 } }}
      >
        {showCustimzeLayoutOption && (
          <PerfectScrollbar component="div">
            <MainCard content={false} border={false}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1.5}
                sx={{ p: 2.5 }}
              >
                <Typography variant="h5">Theme Customization</Typography>
                <Stack direction="row" alignItems="center" spacing={1.25}>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => onReset()}
                  >
                    Reset
                  </Button>
                  <IconButton sx={{ p: 0 }} onClick={handleToggle}>
                    <IconPlus
                      size={24}
                      style={{
                        transform: "rotate(45deg)",
                        color: theme.palette.grey[600],
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "dark.800" : "grey.50",
                    minHeight: 56,
                    "& .MuiTabs-flexContainer": { height: "100%" },
                  }}
                  centered
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={<IconColorSwatch />}
                    {...a11yProps(0)}
                    sx={{ width: "50%" }}
                  />
                  <Tab
                    label={<IconTextSize />}
                    {...a11yProps(1)}
                    sx={{ width: "50%" }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    <ThemeModeLayout />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {/* Theme Preset Color */}
                    {/* <PresetColor /> */}
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {/* Input Background */}
                    <InputFilled />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <BoxContainer />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Layout />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <SidebarDrawer />
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <MenuOrientation />
                    <Divider />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* font family */}
                    {/* <FontFamily /> */}
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {/* border radius */}
                    <BorderRadius />
                    <Divider />
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </MainCard>
          </PerfectScrollbar>
        )}
      </Drawer>
    </>
  );
};

export default Customization;

