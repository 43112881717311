import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Chip, TablePagination, Grid, Button } from "@mui/material";
// import { useTheme } from "@mui/system";
import VmTable from "../../../../../../ui-component/table";
import {
  Link,
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import VulnerabilityDialogContent from "./vulnerabilityAssets";
import DialogBox from "../../../../../../ui-component/dialogbox";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../../store";
import { NO_VALUE } from "../../../../../../constant";
import {
  deleteAssetsToScan,
  getAssetsForScan,
  getScanDetails,
} from "../../../../../../store/slices/scanReducer";
import Delete from "../../../../../../ui-component/delete";
import DeleteIcon from "@mui/icons-material/Delete";
import ScanAssetDetails from "./scanAssetAdditionalInfo";
import { getChipDesign } from "../../../../../../utils/helpers";
import { rowsPerPageOptions } from "../../constant";
import { AssetsActionButtonProps, ScanAssetsProps } from "../../scanTypes";
import theme from "src/themes/theme";

const AssetsActionButton: React.FC<AssetsActionButtonProps> = (props) => {
  const params = useParams();
  // const location = useLocation().pathname.split("/")[1];
  // const theme = useTheme();
  const handleDeleteAssets = () => {
    props?.handleDeleteAssetDialog();
  };
  const handleClick = () => {
    sessionStorage.setItem("scanTypeId", props?.scanDetails?.scanTypeId);
  };

  return (
    <>
      <Grid
        container
        sx={{ paddingBottom: "30px", paddingRight: "18px", gap: "10px" }}
        >
          <Grid>
            <Link
              to={
                {
                  pathname: `/scan/${params?.scanId}/assets/add`,
                  state: { orgId: props?.orgId }, // Pass the additional value here
              } as Location
            } // Type the object as Location
          >
            {props?.scanDetails?.displayScanAssetAction?.addAsset ? (
              <Button
                title="Add Asset"
                onClick={handleClick}
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                  letterSpacing: "0px",
                  color: "white",
                  width: "105px",
                  height: "50px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                  textTransform: "none", // This removes the uppercase transformation
                }}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            ) : (
              <></>
            )}
          </Link>
        </Grid>
        <Grid>
          {props?.scanDetails?.displayScanAssetAction?.deleteAsset ? (
            <Button
              title="Delete"
              // variant="contained"
              disabled={!props?.selectedIds?.length}
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0px",
                color: "white",
                width: "105px",
                height: "50px",
                borderRadius: "10px",
                background:
                  "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                textTransform: "none", // This removes the uppercase transformation
              }}
              onClick={handleDeleteAssets}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const ScanAssets: React.FC<ScanAssetsProps> = (props) => {
  // const theme = useTheme();
  const themeMode = theme.palette.mode;
  const navigate = useNavigate();
  const location = useLocation()?.pathname?.split("/")[1];
  const params: any = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteAssetDialog, setOpenDeleteAssetDialog] =
    useState<boolean>(false);
  const [selectAssetsId, setSelectAssetsId] = useState<string | undefined>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [openAdditionalInfoDialog, setOpenAdditionalInfoDialog] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const { scanAssetsVulnerability } = useSelector(
    (state: any) => state?.scansState
  );
  const clientOrgId: any = sessionStorage.getItem("clientOrgId")!;
  const { setDataloading } = useSelector((state: any) => state?.userState);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  useEffect(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_ADDED_ASSET)) {
    dispatch(
      getAssetsForScan({
        scanId: params?.scanId,
        page,
        size: rowsPerPage,
        clientOrgId,
      })
    );
    // }
  }, [page, rowsPerPage, params?.scanId]);

  useEffect(() => {
    dispatch(getScanDetails(params?.scanId, clientOrgId));
  }, [props?.assetsForScan]);

  // const handleClickOpen = (cell) => {
  //   setSelectAssetsId(cell?.row?.original?.assetId);
  //   setOpen(true);
  // };
  const handleClickAddVulnerability = (cell: any) => {
    navigate(
      `/scan/${params?.scanId}/asset/${cell?.row?.original?.assetId}/vulnerability/add`
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdditionalInfoDialog = () => {
    setOpenAdditionalInfoDialog(true);
  };

  const handleCloseScanAssetDetailsDialog = () => {
    setOpenAdditionalInfoDialog(false);
  };

  const handleSelectedRowData = (data: any) => {
    setSelectedRow(data);
    setOpenAdditionalInfoDialog(true);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "assetName",
        header: "Asset Name",
        size: 150,
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
        size: 150,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ row }: any) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      // {
      //   accessorKey: "locationDesc",
      //   header: "Location",
      //   size: 150,
      // },
      {
        accessorKey: "cloudProviderName",
        header: "Cloud Type",
        Cell: ({ cell, row }: any) => (
          <>
            {row?.original?.cloudProviderName
              ? row?.original?.cloudProviderName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        Cell: ({ row }: any) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
      {
        accessorKey: "totalVulnerabilities",
        header: "Total Vulnerabilities",
        size: 150,
      },
      {
        accessorKey: "decommissionedName",
        header: "Decommissioned",
        size: 150,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 50,
        // muiTableBodyCellProps: ({ row }) => ({}),
        Cell: ({ cell, row }: any) => (
          <>
            {/* <IconButton onClick={() => handleClickOpen(cell)}>
              <VisibilityIcon sx={{ color: theme.palette.secondary.main }} />
            </IconButton> */}

            {props?.scanDetails?.displayScanVulnerabilityAction
              ?.addVulnerability && (
              <IconButton onClick={() => handleClickAddVulnerability(cell)}>
                <AddCircleIcon />
              </IconButton>
            )}

            <IconButton
              onClick={() => {
                handleSelectedRowData(row?.original);
              }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );
  //

  // table Column
  const visibilityColumns = useMemo(
    () => [
      {
        accessorKey: "vulnerabilityName",
        header: "Vulnerability",
        size: 150,
      },
      {
        accessorKey: "easeOfExploitationName",
        header: "Ease of Exploitation",
        size: 150,
      },
      {
        accessorKey: "riskRatingName",
        header: "Risk Rating",
        Cell: ({ row }: any) => {
          return row?.original?.riskRatingName ? (
            <Chip
              size="small"
              label={row?.original?.riskRatingName}
              variant="outlined"
              sx={{
                color:
                  getChipDesign(row?.original?.riskRatingName, themeMode)
                    ?.color || "black",
                border:
                  "1.5px solid " +
                  getChipDesign(row?.original?.riskRatingName, themeMode)
                    ?.color,
              }}
            />
          ) : (
            ""
          );
        },
      },
    ],
    []
  );

  const handleChangeRowSelect = (selectedIds: any) => {
    setSelectedIds(selectedIds);
  };
  const handleDeleteAssetDialog = () => {
    setOpenDeleteAssetDialog(!openDeleteAssetDialog);
  };
  const handleAssetDeleteAction = () => {
    const payload: any = {
      deleteIdList: selectedIds,
    };
    setSelectedIds([]);
    dispatch(deleteAssetsToScan(payload, params?.scanId, clientOrgId))
      .then((response: any) => {
        if (response?.status >= 200 && response?.status < 400) {
          // if (userActionAccess(ACTION_TYPE?.GET_SCAN_ADDED_ASSET)) {
          dispatch(
            getAssetsForScan({
              scanId: params?.scanId,
              page,
              size: rowsPerPage,
              clientOrgId,
            })
          );
          // }
        }
      })
      .catch(() => {});
    handleDeleteAssetDialog();
    handleClose();
  };

  // useEffect(() => {
  let assetList = [];
  if (Array.isArray(props?.assetsForScan?.data)) {
    assetList = props?.assetsForScan?.data.map((val: any) => {
      return {
        ...val?.assetDetails,
        totalVulnerabilities: val?.totalVulnerabilities,
      };
    });
  }
  // }, [props?.assetsForScan])

  return (
    <>
      <Grid
        // container
        sx={{
          border: "0.5px solid #a2a2d299",
          // background: "#1D1D3A",
          padding: "30px",
          // padding: "30px",
          height: "100%",
          borderRadius: "10px",
          // color: "#fff",
        }}
      >
        <VmTable
          enableRowSelection={true}
          enablePagination={false}
          enableBottomToolbar={false}
          tableTitle={
            <AssetsActionButton
              selectedIds={selectedIds}
              handleDeleteAssetDialog={handleDeleteAssetDialog}
              scanDetails={props?.scanDetails}
              orgId={clientOrgId}
            />
          }
          columns={columns}
          data={assetList || []}
          selectedIdsField={"assetId"}
          selectedIds={selectedIds}
          handleChangeRowSelect={handleChangeRowSelect}
        />

        <TablePagination
          component="div"
          count={Number(props?.assetsForScan?.totalRecords || 0)}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
        <DialogBox
          maxWidth="md"
          open={open}
          handleClose={handleClose}
          modelTitle={"All Vulnerabilities"}
          dialogContent={
            <VulnerabilityDialogContent
            // columns={visibilityColumns}
            // data={scanAssetsVulnerability}
            />
          }
        />

        <DialogBox
          maxWidth="sm"
          modelTitle={"Delete Asset"}
          open={openDeleteAssetDialog}
          handleClose={handleDeleteAssetDialog}
          dialogContent={
            <Delete
              handleDeleteAction={handleAssetDeleteAction}
              handleClose={handleDeleteAssetDialog}
              loading={setDataloading}
            />
          }
        />

        <DialogBox
          maxWidth="md"
          open={openAdditionalInfoDialog}
          handleClose={handleCloseScanAssetDetailsDialog}
          modelTitle={"Additional Information"}
          dialogContent={
            <ScanAssetDetails
              handleOpenDialog={handleOpenAdditionalInfoDialog}
              data={selectedRow}
            />
          }
        />
      </Grid>
    </>
  );
};

export default ScanAssets;
