// third-party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// project imports
import { isLoading, setLoading } from "./user";
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { ScanStateTypes, ScanType } from "./reducerTypes";
import { PORTAL_TYPE } from "src/constant";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import moment from "moment";

// ----------------------------------------------------------------------
const scanApiEndpoint = `/scan`;
const consultantScanReportConfigApiEndpoint = `/consultant/v1`;
const consultantScanAssetApiEndpoint = `/consultant/v1/scan-asset`;
const consultantScanApiEndpoint = `/consultant/v1/scan`;
const clientApiEndpoint = `/client/v1`;
const clientScanApiEndpoint = `/client/v1/scan`;
const clientScanAssetApiEndpoint = `client/v1/scan-assets`;
const consultantAssetApi = `/consultant/v1/asset`;
const consultantScanAssetApi = `/consultant/v1/scan-asset`;
const clientVulnerabilitiesApiEndpoint = `/consultant/v1/vulnerability/client-vulnerability`;
const clientAssetApi = `/client/v1/asset`;

interface FetchParams {
  [key: string | number]: string | number | boolean | Date | null | undefined;
}

const initialState: ScanStateTypes = {
  error: null,
  scanTypes: [],
  allScans: {
    data: [],
    totalRecords: 0,
  }, //done
  scanSubTypes: [], //done
  organizationForTester: [],
  testerList: [],
  organizationForSM: [],
  assetsForScan: [],
  VACAscanTypes: [],
  scanReportHistory: [],
  scanStageFlow: [],
  securityManagerList: [],
  scanAssetsVulnerability: [],
  scanDetails: {},
  scanComments: [],
  allScanStatus: [],
  selectedScanDetailsTab: 0,
  assetSubTypes: [],
  unmappedAssets: [],
  assetsTypes: [],
  affectedAssets: [],
  updateScanResponse: {},
  addAssetsToScan: {},
  deleteAssetsToScan: {},
  submitToTester: {},
  submitToSM: {},
  publishScan: {},
  addScanCommentResponse: {},
  updateReportConfig: {},
  addScanResponse: {},
  addConfirmScan: {},
  getConfirmScan: {},
  scanReportConfig: {},
  scanReportDownload: {},
  scanTask: {},
  addVulnerabilityForAssets: {},
  deleteScanResponse: {},
  deleteScanCommentResponse: {},
  addScanReportConfig: {},
  getScanReportConfig: {},
  reportConfigDescription: {},
  getClientVulnerabilityList: {},
  addClientVulnerability: {},
  generateReport: {},
  uploadReport: {},
  scanStatus: {},
  commentImage: {},
  scanStatusFlow: [],
  consultantClientOrganizationList: [],
  scanLinkedReportHistory: [],
  consultantSMList: [],
  consultantTesterList: [],
  downloadScanData: {},
  unmappedAssetsForFilter: [],
  departmentListForAddScan: [],
  scanAssetVerificationList: [],
  updateScanAssetVerificationStatusRes: {},
  updateScanNameResponse: [],
  updateExistingStartDate: {},
  updateExistingEndDate: {},
  updateReportedPublishedDateSuccessRes: {},
};
const slice = createSlice({
  name: "scans",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    manageSelectedScanDetailsTab(state, action) {
      state.selectedScanDetailsTab = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    updateScanNameSuccess(state, action) {
      state.updateScanNameResponse = action.payload;
    },
    updateExistingStartDateSuccess(state, action) {
      state.updateExistingStartDate = action.payload;
    },
    updateExistingEndDateSuccess(state, action) {
      state.updateExistingEndDate = action.payload;
    },
    // GET SCAN_TYPES
    getScanTypesSuccess(state, action) {
      state.scanTypes = action.payload;
    },
    // GET VACA SCAN_TYPES
    getVACAScanTypesSuccess(state, action) {
      state.VACAscanTypes = action.payload;
    },

    // GET CONFIRM SCAN
    getConfirmScanSuccess(state, action) {
      state.getConfirmScan = action.payload;
    },
    // GET SCAN_SUB_TYPES
    getScanSubTypesSuccess(state, action) {
      state.scanSubTypes = action.payload;
    },
    // GET ALL_SCAN
    getAllScanSuccess(state, action) {
      state.allScans = action.payload;
      state.selectedScanDetailsTab = 0;
    },
    // GET SCAN_DETAILS
    getScanDetailsSuccess(state, action) {
      state.scanDetails = action.payload;
    },
    // GET ORGANIZATION_LIST_FOR_TESTER
    getOrganizationListSuccess(state, action) {
      state.organizationForTester = action.payload;
    },
    // GET TESTER_LIST
    getTesterSuccess(state, action) {
      state.testerList = action.payload;
    },
    // GET ASSETS_FOR_SCAN
    getAssetsForScanSuccess(state, action) {
      state.assetsForScan = action.payload;
    },
    // GET SCAN_REPORT_CONFIG
    getScanReportConfigSuccess(state, action) {
      state.scanReportConfig = action.payload;
    },
    // GET SCAN_REPORT_CONFIG
    handleDownloadSuccess(state, action) {
      state.scanReportDownload = action.payload;
    },
    // GET SCAN_REPORT_CONFIG
    getScanReportHistorySuccess(state, action) {
      state.scanReportHistory = action.payload;
    },
    // GET SCAN_STAGE_FLOW
    getScanStageFlowSuccess(state, action) {
      state.scanStageFlow = action.payload;
    },
    // GET SM_LIST
    getSecurityManagerListSuccess(state, action) {
      state.securityManagerList = action.payload;
    },
    // GET ORGANIZATION_LIST_FOR_SM
    getOrganizationListForSMSuccess(state, action) {
      state.organizationForSM = action.payload;
    },
    // GET STATUS
    getScanAssetVulnerabilitySuccess(state, action) {
      state.scanAssetsVulnerability = action.payload;
    },
    // GET SCANCOMMENT
    getScanCommentsSuccess(state, action) {
      state.scanComments = action.payload;
    },
    // GET ALL_SCAN_STATUS
    getAllScanStatusSuccess(state, action) {
      state.allScanStatus = action.payload;
    },
    // GET ASSETS_TYPE
    getAssetsTypeSuccess(state, action) {
      state.assetsTypes = action.payload;
    },
    // GET ASSETS_SUB_TYPE
    getAssetsSubTypeSuccess(state, action) {
      state.assetSubTypes = action.payload;
    },
    // GET UNMAPPED_ASSETS
    getUnmappedAssetsSuccess(state, action) {
      state.unmappedAssets = action.payload;
    },
    // GET UNMAPPED_ASSETS_FOR_FILTER
    getUnmappedAssetsForFilterSuccess(state, action) {
      state.unmappedAssetsForFilter = action.payload;
    },
    // GET SCAN_TASK
    getScansTaskSuccess(state, action) {
      state.scanTask = action.payload;
    },

    // GET CONSULTANT_SCAN_REPORT_CONFIG
    getConsultantScanReportConfig(state, action) {
      state.getScanReportConfig = action.payload;
    },
    // GET AFFECTED_ASSETS_LIST
    getAffectedAssetsSuccess(state, action) {
      state.affectedAssets = action.payload;
    },

    //GET PULL_VULNERABILITY_LIST
    getPullVulnerabilitySuccess(state, action) {
      state.getClientVulnerabilityList = action.payload;
    },

    //GET SCAN_STATUS_FLOW
    getScanStatusFlowSuccess(state, action) {
      state.scanStatusFlow = action.payload;
    },

    //GET CLIENT ORGANIZATION LIST TO CONSULTANT
    getClientOrganizationListToConsultantSuccess(state, action) {
      state.consultantClientOrganizationList = action.payload;
    },

    //GET SCAN ASSET VERIFICATION
    fetchScanAssetVerificationSuccess(state, action) {
      state.scanAssetVerificationList = action.payload;
    },

    // --------------------- POST APIs -------------------------------

    // ADD SCAN
    postScanSuccess(state, action) {
      state.addScanResponse = action.payload;
    },
    // ADD CONFIRM SCAN
    postConfirmScanSuccess(state, action) {
      state.addConfirmScan = action.payload;
    },
    // ADD SCANCOMMENT
    addScanCommentSuccess(state, action) {
      state.addScanCommentResponse = action.payload;
    },
    // SUBMIT_TO_TESTER
    submitToTesterSuccess(state, action) {
      state.submitToTester = action.payload;
    },
    // SUBMIT_TO_SM
    submitToSMSuccess(state, action) {
      state.submitToSM = action.payload;
    },
    // PUBLISH_SCAN
    publishScanSuccess(state, action) {
      state.publishScan = action.payload;
    },
    // ADD ASSET_TO_SCAN
    addAssetsToScanSuccess(state, action) {
      state.addAssetsToScan = action.payload;
    },

    // ADD CONSULTANT_SCAN_REPORT_CONFIG
    addConsultantReportContentSuccess(state, action) {
      state.addScanReportConfig = action.payload;
    },

    addVulnerabilityForAssetsSuccess(state, action) {
      state.addVulnerabilityForAssets = action.payload;
    },

    // ADD PUll_VULNERABILITY
    addClientVulnerabilitySuccess(state, action) {
      state.addClientVulnerability = action.payload;
    },

    // GENERATE_REPORT
    generateScanReportSuccess(state, action) {
      state.generateReport = action.payload;
    },
    // GET_COMMENT_IMAGE
    getCommentImageSuccess(state, action) {
      state.commentImage = action.payload;
    },
    //GET SM LIST
    getConsultantSMListSuccess(state, action) {
      state.consultantSMList = action.payload;
    },
    //GET TESTER LIST
    getConsultantTesterListSuccess(state, action) {
      state.consultantTesterList = action.payload;
    },

    // --------------------- PUT APIs -------------------------------

    // UPDATE REPORT_CONFIG
    updateReportConfigSuccess(state, action) {
      state.updateReportConfig = action.payload;
    },
    // UPDATE REPORT_CONFIG
    updateScanSuccess(state, action) {
      state.updateScanResponse = action.payload;
    },
    // UPLOAD REPORT PDF
    updateUploadReport(state, action) {
      state.uploadReport = action.payload;
    },
    //UPDATE SCAN STATUS
    updateScanStatus(state, action) {
      state.scanStatus = action.payload;
    },
    //UPDATE SCAN ASSET VERIFICATION STATUS
    updateScanAssetVerificationStatusSuccess(state, action) {
      state.updateScanAssetVerificationStatusRes = action.payload;
    },
    //UPDATE REPORT PUBLISHED DATE
    updateReportedPublishedDateSuccess(state, action) {
      state.updateReportedPublishedDateSuccessRes = action.payload;
    },

    // --------------------- DELETE APIs -----------------------------

    // DELETE SCAN_COMMENT
    deleteScanCommentSuccess(state, action) {
      state.deleteScanCommentResponse = action.payload;
    },
    // DELETE ASSETS_TO_SCAN
    deleteAssetsToScanSuccess(state, action) {
      state.deleteAssetsToScan = action.payload;
      state.assetsForScan = [];
    },
    // DELETE SCAN
    deleteScanSuccess(state, action) {
      state.deleteScanResponse = action.payload;
    },
    // ADD REPORT DESCRIPTION
    addReportConfigDescriptionSuccess(state, action) {
      state.reportConfigDescription = action.payload;
    },
    // GET LINKED REPORT DATA
    scanLinkedReportHistorySuccess(state, action) {
      state.scanLinkedReportHistory = action.payload;
    },
    // HAS PUBLISH SCAN ERROR
    hasPublishError(state, action) {
      state.error = action.payload;
    },
    // Download Scan
    downloadScanSuccess(state, action) {
      state.downloadScanData = action.payload;
    },

    // GET DEPARTMENT FOR ADD SCAN
    fetchDepartmentForAddScanSuccess(state, action) {
      state.departmentListForAddScan = action.payload;
    },
  },
});
export const { downloadScanSuccess, getConfirmScanSuccess } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const manageSelectedScanDetailsTab = (tab: number) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(slice.actions.manageSelectedScanDetailsTab(tab));
    try {
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getScanTypes = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE?.CONSULTANT
        ? `${consultantScanApiEndpoint}/scan-type`
        : `${clientScanApiEndpoint}/scan-type`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanTypesSuccess(response.data));
      dispatch(slice.actions.getScanSubTypesSuccess([]));
      dispatch(slice.actions.getTesterSuccess([]));
      dispatch(slice.actions.getSecurityManagerListSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getVACAScanTypes = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE?.CONSULTANT
        ? `${consultantScanApiEndpoint}/vaca-scan-type`
        : `${clientScanApiEndpoint}/vaca-scan-type`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getVACAScanTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getScanSubType = (scanTypeId: number | string | any) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE?.CONSULTANT
        ? `${consultantScanApiEndpoint}/scan-sub-type/${scanTypeId}`
        : `${clientScanApiEndpoint}/scan-sub-type/${scanTypeId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanSubTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
// export const getAllScans = (params, endPoint) => {
//   return async () => {     dispatch(setLoading(true));

//     try {
//       const response = await axios.get(
//         `${consultantScanApiEndpoint}/all-scan-${endPoint}`,
//         {
//           params,
//         }
//       );
//       dispatch(slice.actions.getAllScanSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// };
export const getAllScans = (params: FetchParams) => {
  const url =
    localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
      ? `${consultantScanApiEndpoint}/all-scan`
      : `${clientScanApiEndpoint}/scan-list`;
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(url, {
        params,
      });
      dispatch(slice.actions.getAllScanSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getScanDetails = (scanId: number, clientOrgId?: number | null) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE?.CONSULTANT
        ? `${consultantScanApiEndpoint}/scan-detail/${scanId}/${clientOrgId}`
        : `${clientScanApiEndpoint}/scan-detail/${scanId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanDetailsSuccess(response.data));
      dispatch(slice.actions.postScanSuccess({}));
      dispatch(slice.actions.postConfirmScanSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getOrganizationListForTester = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/organization-for-tester`
      );
      dispatch(slice.actions.getOrganizationListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getOrganizationListForSM = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/organization-for-sm`
      );
      dispatch(slice.actions.getOrganizationListForSMSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getClientOrganizationToConsultant = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/organization-for-consultant`
      );
      dispatch(
        slice.actions.getClientOrganizationListToConsultantSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getTesterList = (orgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/tester-list/${orgId}`
      );
      dispatch(slice.actions.getTesterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getSecurityManagerList = (orgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/smlist/${orgId}`
      );
      dispatch(slice.actions.getSecurityManagerListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addScan = (payload: unknown, customEndPoint?: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/${customEndPoint}`
        : `${clientScanApiEndpoint}/new-scan`;
    try {
      const response = await axios.post(url, payload);
      dispatch(slice.actions.postScanSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const confirmScan = (payload: unknown, scanId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/confirmatory/${scanId}`
        : `${clientScanApiEndpoint}/confirmatory-scan/${scanId}`;
    try {
      const response = await axios.post(url, payload);
      dispatch(slice.actions.postConfirmScanSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getConfirmScanDetails = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/confirmatory-data/${scanId}/${clientOrgId}
    ?scanId=${scanId}&clientOrgId=${clientOrgId}`
        : `${clientScanApiEndpoint}/confirmatory-data/${scanId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getConfirmScanSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getAssetsForScan = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/scan-asset/list/${params?.scanId}/${params?.clientOrgId}`
        : `${clientScanAssetApiEndpoint}/asset-list/${params?.scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(url, { params });
      dispatch(slice.actions.getAssetsForScanSuccess(response.data));
      dispatch(slice.actions.getUnmappedAssetsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getScanReportConfig = (scanId?: number, orgId?: unknown) => {
  dispatch(slice.actions.getScanReportConfigSuccess({}));
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/report-config/${scanId}/${orgId}`
        : `${clientScanApiEndpoint}/report-config/${scanId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanReportConfigSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getScanReportHistory = (scanId: number, clientOrgId?: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/scan-report/list/${scanId}/{clientOrgId}?clientOrgId=${clientOrgId}`
        : `${clientApiEndpoint}/scan-report/list/${scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanReportHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const handleDownload = (scanReportId: number, clientOrgId?: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/scan-report/download/${scanReportId}/${clientOrgId}`
        : `${clientApiEndpoint}/scan-report/download/${scanReportId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.handleDownloadSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const generateScanReport = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      dispatch(isLoading(true));
      const response = await axios.post(
        `${consultantScanReportConfigApiEndpoint}/scan-report/generate`,
        payload
      );
      dispatch(slice.actions.generateScanReportSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getScanStageFlow = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/stage-flow-for-scan/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.getScanStageFlowSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addAssetsToScan = (
  payload: FetchParams,
  scanId?: number | string
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanAssetApi}/asset-to-scan`
        : `${clientScanAssetApiEndpoint}/add/${scanId}`;
    try {
      const response = await axios.post(url, payload);
      dispatch(slice.actions.addAssetsToScanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const deleteAssetsToScan = (
  payload: FetchParams,
  scanId: number,
  clientOrgId: number | null
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/scan-asset/${scanId}/${clientOrgId}`
        : `${clientScanAssetApiEndpoint}/${scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.request({
        url,
        method: "DELETE",
        data: payload,
      });
      dispatch(slice.actions.deleteAssetsToScanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const submitToSM = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantScanApiEndpoint}/submit-to-sm/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.submitToSMSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const submitToTester = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantScanApiEndpoint}/submit-to-tester/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.submitToTesterSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const publishScan = (
  scanId: number,
  clientOrgId: number,
  description: string,
  reportPublishedDate: Date
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantScanApiEndpoint}/publish-scan/${scanId}/${clientOrgId}`,
        {
          autoClose: false,
          commentDesc: description,
          reportPublishedDate: reportPublishedDate
            ? moment(reportPublishedDate).format("YYYY-MM-DD")
            : "",
        }
      );
      dispatch(slice.actions.publishScanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasPublishError(error));
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getScanAssetsVulnerability = (assetId: number, scanId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/vulnerabilities/${assetId}/${scanId}`
      );
      dispatch(slice.actions.getScanAssetVulnerabilitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const addScanComment = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/comment`
        : `${clientScanApiEndpoint}/comment`;
    try {
      const response = await axios.post(url, payload);
      dispatch(slice.actions.addScanCommentSuccess(response.data));
      notificationManager(response);
      return response.data;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const uploadNewScanComment = (payload: FetchParams) => {
  const accessToken = localStorage.getItem("serviceToken");
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/comment/attachment/${payload.commentId}/${payload.clientOrgId}`
        : `${clientScanApiEndpoint}/comment/attachment/${payload.commentId}`;
    try {
      const response = await axios.post(url, payload.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getScanComments = (scanId: number, clientOrgId?: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/comment/list/${scanId}/${clientOrgId}`
        : `${clientScanApiEndpoint}/comment/list/${scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanCommentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getAllScanStatus = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/all-status`
      );
      dispatch(slice.actions.getAllScanStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateReportConfig = (
  selectedReportTitle: string,
  scanId: number,
  content: string
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantScanApiEndpoint}/report-config/${selectedReportTitle}/${scanId}`,
        content,
        { headers: { "Content-Type": "application/json" } }
      );
      dispatch(slice.actions.updateReportConfigSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getAssetsType = (scanTypeId?: string | any) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? scanTypeId
          ? `${consultantScanAssetApi}/asset-types?scanTypeId=${scanTypeId}`
          : `${consultantAssetApi}/asset-types`
        : scanTypeId
        ? `${clientScanAssetApiEndpoint}/asset-types?scanTypeId=${scanTypeId}`
        : `${clientAssetApi}/asset-types`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getAssetsTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getAssetsSubType = (assetType?: string | any) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanAssetApiEndpoint}/asset-sub-type/${assetType}`
        : `${clientScanAssetApiEndpoint}/asset-sub-type/${assetType}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getAssetsSubTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getUnmappedAssets = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanAssetApi}/un-mapped-assets/${params?.scanId}/${params?.clientOrgId}`
        : `${clientScanAssetApiEndpoint}/un-mapped-assets/${params?.scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(url, { params });
      dispatch(slice.actions.getUnmappedAssetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getAffectedAssets = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${consultantScanAssetApi}/list/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.getAffectedAssetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getScansTask = (role: string) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/consultant/v1/${role}/task${scanApiEndpoint}`
      );
      dispatch(slice.actions.getScansTaskSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateScan = (payload: FetchParams, customEndPoint: string) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantScanApiEndpoint}/${customEndPoint}`,
        payload
      );
      dispatch(slice.actions.updateScanSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateExpectedDate = (type: any, payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/expected-date`
        : `${clientScanApiEndpoint}/expected-date`;

    try {
      const response = await axios.put(url, payload);

      if (type === "startDate") {
        dispatch(slice.actions.updateExistingStartDateSuccess(response.data));
      } else {
        dispatch(slice.actions.updateExistingEndDateSuccess(response.data));
      }

      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateScanName = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/scan-name`
        : `${clientScanApiEndpoint}/scan-name`;

    try {
      const response = await axios.put(url, payload);
      dispatch(slice.actions.updateScanNameSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteScanComment = (commentId: number, clientOrgId?: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/comment/${commentId}/${clientOrgId}`
        : `${clientScanApiEndpoint}/comment/${commentId}`;
    try {
      const response = await axios.delete(url);
      dispatch(slice.actions.deleteScanCommentSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const deleteScan = (payload: {
  deleteIdList: Array<number>;
  clientOrgId: number;
}) => {
  const params = payload.deleteIdList;

  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const url = `${consultantScanApiEndpoint}/{clientOrgId}?clientOrgId=${payload.clientOrgId}`;
    try {
      const response = await axios.request({
        url,
        method: "DELETE",
        data: payload.deleteIdList,
      });
      dispatch(slice.actions.deleteScanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const addConsultantScanReportConfig = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${consultantScanReportConfigApiEndpoint}/report-config`,
        payload
      );
      dispatch(slice.actions.addConsultantReportContentSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getReportContent = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanReportConfigApiEndpoint}/report-config`
      );
      dispatch(slice.actions.getConsultantScanReportConfig(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addVulnerabilityForAssets = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${consultantScanApiEndpoint}/vulnerabilityForAssets`,
        payload
      );
      dispatch(slice.actions.addVulnerabilityForAssetsSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      // dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getClientVulnerability = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilitiesApiEndpoint}/list/${params?.scanId}/${params?.clientOrgId}`,
        { params }
      );
      dispatch(slice.actions.getPullVulnerabilitySuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const addReportConfigDetails = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanApiEndpoint}/report-config/${payload?.url}`
        : `${clientScanApiEndpoint}/report-config`;
    try {
      const response = await axios.put(url, payload?.data);
      dispatch(slice.actions.addReportConfigDescriptionSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addClientVulnerabilities = (
  scanId: number,
  clientOrgId: number,
  payload: FetchParams
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientVulnerabilitiesApiEndpoint}/${scanId}/{clientOrgId}?clientOrgId=${clientOrgId}`,

        payload
      );
      dispatch(slice.actions.addClientVulnerabilitySuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleUploadReport = (
  scanId: number,
  clientOrgId: number,
  payload: FetchParams
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantScanReportConfigApiEndpoint}/scan-report/scan-report-pdf/${scanId}/${clientOrgId}`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(slice.actions.updateUploadReport(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleUpdateScanStatus = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantScanApiEndpoint}/scan-status`,
        payload
      );
      dispatch(slice.actions.updateScanStatus(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getScanStatusFlow = (scanId: number, clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/status-flow/${scanId}/${clientOrgId}`
      );
      dispatch(slice.actions.getScanStatusFlowSuccess(response.data));
      // notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getCommentImage = (
  scanCommentAttachmentId: number,
  clientOrgId?: number
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `consultant/v1/scan/comment/attachment/download/{commentId}/${clientOrgId}?scanCommentAttachId=${scanCommentAttachmentId}`
        : `${clientScanApiEndpoint}/comment/attachment/download/{commentId}?scanCommentAttachId=${scanCommentAttachmentId}`;
    dispatch(isLoading(true));
    try {
      const endpoint = url;
      const response = await axios.get(endpoint);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};
export const getValCommentImage = (
  valCommentAttachmentId: number,
  clientOrgId: number | null
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const endpoint = `consultant/v1/vulnerability/comment/attachment/download/{commentId}/${clientOrgId}?vulCommentAttachId=${valCommentAttachmentId}`;
      const response = await axios.get(`/${endpoint}`);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getCommentAttachments = (
  scanCommentAttachmentId: number,
  clientOrgId?: number
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `consultant/v1/scan/comment/attachment/list/${scanCommentAttachmentId}/${clientOrgId}`
        : `${clientScanApiEndpoint}/comment/attachment/list/${scanCommentAttachmentId}`;
    dispatch(isLoading(true));
    try {
      const endpoint = url;
      const response = await axios.get(endpoint);
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const getCommentValAttachments = (
  valCommentAttachmentId: number,
  clientOrgId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const endpoint = `consultant/v1/vulnerability/comment/attachment/${valCommentAttachmentId}/{clientOrgId}?clientOrgId=${clientOrgId}`;
      const response = await axios.get(`/${endpoint}`);
      return response;
    } catch (error) {
      return error;
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getScanLinkedReportHistory = (
  scanId: number,
  clientOrgId?: number
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/scan-report/history/${scanId}/{clientOrgId}?clientOrgId=${clientOrgId}`
        : `${clientApiEndpoint}/scan-report/history/${scanId}`;
    dispatch(isLoading(true));
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.scanLinkedReportHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getConsultantSMList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/consultant-sm-list`
      );
      dispatch(slice.actions.getConsultantSMListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getConsultantTesterList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${consultantScanApiEndpoint}/consultant-tester-list`
      );
      dispatch(slice.actions.getConsultantTesterListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const downloadScan = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `/client/v1/scan/report?scanId=${payload?.scanId}&fileType=${payload?.fileType}`
        : `${consultantScanApiEndpoint}/report?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}&fileType=${payload?.fileType}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.downloadScanSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getUnmappedAssetsForFilter = (params: FetchParams | any) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanAssetApi}/un-mapped-assets/${params?.scanId}`
        : `${clientScanAssetApiEndpoint}/un-mapped-assets/${params?.scanId}/${params?.clientOrgId}`;
    try {
      const response = await axios.get(url, { params });
      dispatch(slice.actions.getUnmappedAssetsForFilterSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchDepartmentForAddScan = (clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantScanReportConfigApiEndpoint}/client-department/list?clientOrgId=${clientOrgId}`
        : `${clientApiEndpoint}/client-department/list?clientOrgId=${clientOrgId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.fetchDepartmentForAddScanSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchScanAssetVerification = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      // localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
      // ?
      `${consultantScanAssetApiEndpoint}/scan-asset-verification?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`;
    // : `${clientApiEndpoint}/client-department/list?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.fetchScanAssetVerificationSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const updateScanAssetVerificationStatus = (
  payload: FetchParams & { data: unknown }
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      // localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
      // ?
      `${consultantScanAssetApiEndpoint}/scan-asset-verification?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`;
    // : `${clientApiEndpoint}/client-department/list?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}`;
    try {
      const response = await axios.put(url, payload?.data);
      dispatch(
        slice.actions.updateScanAssetVerificationStatusSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateReportedPublishedDate = (payload: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(
        `${consultantScanApiEndpoint}/update-report-published-date?scanId=${payload?.scanId}&clientOrgId=${payload?.clientOrgId}&reportPublishedDate=${payload?.reportPublishedDate}`
      );
      dispatch(slice.actions.updateReportedPublishedDateSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
