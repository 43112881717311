import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import { ExceptionState, FetchParams } from "./reducerTypes";

// initial state
const initialState: ExceptionState = {
  error: null,
  otp: {},
  initialList: [],
  approvedList: {
    data: [],
    totalRecords: 0,
  },
  historyList: [],
  checkerStatusResponse: {},
  pullExceptionVulnerabilitiesList: [],
  changeVulExceptionStatusRes: {},
  allVulForExceptionList: [],
  addVulForExceptionRes: {},
  submitToApprovalRes: {},
  resubmitExceptionVulRes: {},
  deleteExceptionVulRes: {},
  selectedExceptionVulnerability: {},
};

// ==============================|| SLICE - CHANGE PASSOWORD ||============================== //
const vulExceptionApiEndPoint = "/client/v1/vul-exception";
const vulException = createSlice({
  name: "vulException",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    fetchIntialListSuccess(state, action) {
      state.initialList = action.payload;
    },
    fetchApprovedListSuccess(state, action) {
      state.approvedList = action.payload;
    },
    fetchHistoryListSuccess(state, action) {
      state.historyList = action.payload;
    },
    CheckerStatusSuccess(state, action) {
      state.checkerStatusResponse = action.payload;
    },
    pullExceptionVulnerabilitiesSuccess(state, action) {
      state.pullExceptionVulnerabilitiesList = action.payload;
    },

    changeVulExceptionStatusSuccess(state, action) {
      state.changeVulExceptionStatusRes = action.payload;
    },

    allVulForExceptionSuccess(state, action) {
      state.allVulForExceptionList = action.payload;
    },

    addVulForExceptionSuccess(state, action) {
      state.addVulForExceptionRes = action.payload;
    },

    submitToApprovalSuccess(state, action) {
      state.submitToApprovalRes = action.payload;
    },

    resubmitExceptionVulSuccess(state, action) {
      state.resubmitExceptionVulRes = action.payload;
    },

    deleteExceptionVulSuccess(state, action) {
      state.deleteExceptionVulRes = action.payload;
    },

    getSelectedExceptionVulnerabilitySuccess(state, action) {
      state.selectedExceptionVulnerability = action.payload;
    },
  },
});

export default vulException.reducer;
export const { getSelectedExceptionVulnerabilitySuccess } =
  vulException.actions;

export function fetchIntialList(params: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    vulException.actions.getSelectedExceptionVulnerabilitySuccess({});
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${vulExceptionApiEndPoint}/maker-exception-vulnerabilities-list`,
        {
          params,
        }
      );
      dispatch(vulException.actions.fetchIntialListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
export function fetchApprovedList(params: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    vulException.actions.getSelectedExceptionVulnerabilitySuccess({});
    try {
      const response = await axios.get(
        `${vulExceptionApiEndPoint}/checker-exception-vulnerabilities-list`,
        { params }
      );
      dispatch(vulException.actions.fetchApprovedListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
export function fetchHistoryList(pathname: string, params?: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    vulException.actions.getSelectedExceptionVulnerabilitySuccess({});
    try {
      const response = await axios.get(
        `${vulExceptionApiEndPoint}/${pathname}`,
        { params }
      );
      dispatch(vulException.actions.fetchHistoryListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function handleCheckerStatus(payload: {
  data: unknown;
  vulExceptionStatus: string;
}) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${vulExceptionApiEndPoint}/vulnerability-exception-status-checker/${payload?.vulExceptionStatus}`,
        payload.data
      );
      dispatch(vulException.actions.CheckerStatusSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function pullExceptionVulnerabilities(payload: {
  data: unknown;
  vulExceptionStatus: string;
}) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${vulExceptionApiEndPoint}/new-exception-vulnerabilities-list/${payload?.vulExceptionStatus}`,
        payload.data
      );
      dispatch(
        vulException.actions.pullExceptionVulnerabilitiesSuccess(response.data)
      );
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function changeVulExceptionStatus(payload: {
  data: unknown;
  exceptionStatus: string;
}) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${vulExceptionApiEndPoint}/vulnerability-exception-client-status/${payload?.exceptionStatus}`,
        payload.data
      );
      dispatch(
        vulException.actions.changeVulExceptionStatusSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function allVulForException(params: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${vulExceptionApiEndPoint}/new-exception-vulnerabilities-list`,
        { params }
      );
      dispatch(vulException.actions.allVulForExceptionSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function addVulForException(payload: {
  data: unknown;
  exceptionStatus: string;
}) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${vulExceptionApiEndPoint}/new-exception-vulnerabilities-list/{clientStatus}?clientStatus=${payload?.exceptionStatus}`,
        payload?.data
      );
      dispatch(vulException.actions.addVulForExceptionSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function submitToApproval(payload: unknown) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${vulExceptionApiEndPoint}/vulnerability-exception-submit-approval`,
        payload
      );
      dispatch(vulException.actions.submitToApprovalSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function resubmitExceptionVul(payload: unknown) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.put(
        `${vulExceptionApiEndPoint}/vulnerability-exception-resubmit`,
        payload
      );
      dispatch(vulException.actions.resubmitExceptionVulSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function deleteExceptionVul(payload: FetchParams) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      // const response = await axios.delete(
      //   `${vulExceptionApiEndPoint}/maker-exception-vulnerabilities-list`,
      //   // { data: payload }
      //   { params: payload }
      // );

      const response = await axios.request({
        url: `${vulExceptionApiEndPoint}/maker-exception-vulnerabilities-list`,
        method: "DELETE",
        data: payload,
      });
      dispatch(vulException.actions.deleteExceptionVulSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function getSelectedExceptionVulnerability(
  clientVulnerabilityExceptionId: string
) {
  vulException.actions.getSelectedExceptionVulnerabilitySuccess({});
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${vulExceptionApiEndPoint}/exception-vulnerability/${clientVulnerabilityExceptionId}`
      );
      dispatch(
        vulException.actions.getSelectedExceptionVulnerabilitySuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(vulException.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
