import { createSlice } from "@reduxjs/toolkit";
import { PORTAL_TYPE } from "../../constant";
import { isLoading, setLoading } from "./user";

import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";
import { ConsultantOrgTypes } from "./reducerTypes";

const clientOrgApiProfile = "client/v1/organization";
const clientOrgApiEndpoint = "client/v1/consultant-org";
const consultantOrgApiEndpoint = "consultant/v1/client-org";
const consultantOrgApiProfile = "consultant/v1/organization";
const platformOrgApiEndpoint = "platform/v1/client-org";
const platformOrgApiProfile = "platform/v1/organization";

const initialState: ConsultantOrgTypes = {
  error: null,
  clientOrganizationList: [],
  selectedOrganizationDetails: {},
  updateOrganizationDetails: {},
  newOrganizationDetails: {},
  clientScanReportLogo: {},
  clientAppLogo: {},
  orgLogo: {},
  scanReportLogo: {},
  updateLogo: {},
  updateAppLogo: {},
  updateOrgLogo: {},
  updateScanReportLogo: {},
  organizationProfile: {},
  updOrganizationProfile: {},
  adminGenerated: {},
};

const slice = createSlice({
  name: "addOrganization",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    getOrganizationList(state, action) {
      state.clientOrganizationList = action.payload;
    },
    getSelectedOrganizationDetails(state, action) {
      state.selectedOrganizationDetails = action.payload;
    },
    getClientLogo(state, action) {
      state.clientScanReportLogo = action.payload;
    },
    getClientAppLogo(state, action) {
      state.clientAppLogo = action.payload;
    },
    getOrgLogo(state, action) {
      state.orgLogo = action.payload;
    },
    getScanReportLogo(state, action) {
      state.scanReportLogo = action.payload;
    },
    getProfileSuccess(state, action) {
      state.organizationProfile = action.payload;
    },

    //---------------------- PUT APIs ------------------------------------
    updateOrganizationDetails(state, action) {
      state.updateOrganizationDetails = action.payload;
    },
    updateLogoSuccess(state, action) {
      state.updateLogo = action.payload;
    },
    updateAppLogoSuccess(state, action) {
      state.updateAppLogo = action.payload;
    },
    updateOrgLogoSuccess(state, action) {
      state.updateOrgLogo = action.payload;
    },
    updateScanReportLogoSuccess(state, action) {
      state.updateScanReportLogo = action.payload;
    },
    updateProfileSuccess(state, action) {
      state.updOrganizationProfile = action.payload;
    },
    //--------------------POST APIS--------------------------
    addNewOrganizationDetails(state, action) {
      state.newOrganizationDetails = action.payload;
    },
    generateAdminSuccess(state, action) {
      state.adminGenerated = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

//-------------------------------------------------
export const fetchOrganizationList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? clientOrgApiEndpoint
        : platformOrgApiEndpoint;
    try {
      const response = await axios.get(`/${orgTypeApiEndpoint}/list`);
      dispatch(slice.actions.getOrganizationList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedOrganizationDetails = (payload: {
  orgId: number;
}) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? clientOrgApiEndpoint
        : platformOrgApiEndpoint;
    try {
      const response = await axios.get(
        `/${orgTypeApiEndpoint}/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedOrganizationDetails(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getSelectedOrganizationDetails({}));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateOrganizationData = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? clientOrgApiEndpoint
        : platformOrgApiEndpoint;
    try {
      const response = await axios.put(`${orgTypeApiEndpoint}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(slice.actions.updateOrganizationDetails(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addNewOrganization = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiEndpoint
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? clientOrgApiEndpoint
        : platformOrgApiEndpoint;
    try {
      const response = await axios.post(`${orgTypeApiEndpoint}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // const response = await axios.post(`${orgTypeApiEndpoint}`, payload);
      dispatch(slice.actions.addNewOrganizationDetails(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(slice.actions.addNewOrganizationDetails({}));
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientScanReportLogo = (clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? `${consultantOrgApiEndpoint}/scan-report-logo/{clientOrgId}?clientOrgId=${clientOrgId}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiEndpoint}/scan-report-logo/{orgId}?orgId=${clientOrgId}`
        : `${platformOrgApiEndpoint}/scan-report-logo/{clientOrgId}?clientOrgId=${clientOrgId}`;
    try {
      dispatch(slice.actions.getClientLogo({}));
      const response = await axios.get(`/${orgTypeApiEndpoint}`);
      dispatch(slice.actions.getClientLogo(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchClientAppLogo = (clientOrgId: number) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? `${consultantOrgApiEndpoint}/app-logo/{clientOrgId}?clientOrgId=${clientOrgId}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiEndpoint}/app-logo/{orgId}?orgId=${clientOrgId}`
        : `${platformOrgApiEndpoint}/app-logo/{clientOrgId}?clientOrgId=${clientOrgId}`;
    try {
      dispatch(slice.actions.getClientAppLogo({}));
      const response = await axios.get(`/${orgTypeApiEndpoint}`);
      dispatch(slice.actions.getClientAppLogo(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchOrgLogo = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantOrgApiProfile}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.get(`/${url}/app-logo`);
      dispatch(slice.actions.getOrgLogo(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const fetchScanReportLogo = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantOrgApiProfile}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.get(`/${url}/scan-report-logo`);
      dispatch(slice.actions.getScanReportLogo(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateOrganizationLogo = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiProfile
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.put(
        `/${orgTypeApiEndpoint}/app-logo`,
        payload
      );
      dispatch(slice.actions.updateOrgLogoSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateScanReportLogo = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? consultantOrgApiProfile
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.put(
        `/${orgTypeApiEndpoint}/scan-report-logo`,
        payload
      );
      dispatch(slice.actions.updateScanReportLogoSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateClientScanReportLogo = (
  clientOrgId: number,
  payload: unknown
) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? `${consultantOrgApiEndpoint}/scan-report-logo/{clientOrgId}?clientOrgId=${clientOrgId}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiEndpoint}/scan-report-logo/{orgId}?orgId=${clientOrgId}`
        : `${platformOrgApiEndpoint}/scan-report-logo/{clientOrgId}?clientOrgId=${clientOrgId}`;
    try {
      const response = await axios.put(`/${orgTypeApiEndpoint}`, payload);
      dispatch(slice.actions.updateLogoSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateClientAppLogo = (clientOrgId: number, payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    const orgTypeApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? `${consultantOrgApiEndpoint}/app-logo/{clientOrgId}?clientOrgId=${clientOrgId}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiEndpoint}/app-logo/{orgId}?orgId=${clientOrgId}`
        : `${platformOrgApiEndpoint}/app-logo/{clientOrgId}?clientOrgId=${clientOrgId}`;
    try {
      const response = await axios.put(`/${orgTypeApiEndpoint}`, payload);
      dispatch(slice.actions.updateAppLogoSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchProfile = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantOrgApiProfile}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.get(`/${url}`);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateProfile = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
        ? `${consultantOrgApiProfile}`
        : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
        ? `${clientOrgApiProfile}`
        : `${platformOrgApiProfile}`;
    try {
      const response = await axios.put(`/${url}`, payload);
      dispatch(slice.actions.updateProfileSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const generateAdmin = (payload: unknown) => {
  const orgTypeApiEndpoint =
    localStorage.getItem("organizationType") === "CO"
      ? consultantOrgApiEndpoint
      : localStorage.getItem("organizationType") === PORTAL_TYPE.CLIENT
      ? clientOrgApiEndpoint
      : platformOrgApiEndpoint;
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${orgTypeApiEndpoint}/generate-admin`,
        payload
      );
      dispatch(slice.actions.generateAdminSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
