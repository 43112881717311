import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// Project imports
import GuestGuard from "../utils/route-guard/GuestGuard";
import MinimalLayout from "../layout/MinimalLayout";
import NavMotion from '../layout/NavMotion';
import Loadable from "../ui-component/Loadable";

// Lazy-loaded components using the Loadable HOC for Suspense
const AuthLogin = Loadable(
  lazy(() => import('../views/pages/common/authentication/authentication/Login'))
);

const NewLogin = Loadable(
  lazy(() => import('../views/pages/common/authentication/authentication/NewLogin'))
);

const ForgotPassword = Loadable(
  lazy(() => import('../views/pages/common/authentication/authentication/forgotPassword'))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes: RouteObject = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    // {
    //   path: 'login', // Relative path for "login"
    //   element: <AuthLogin />,
    // },
    {
      path: 'login', // Relative path for "login"
      element: <NewLogin />,
    },
    {
      path: 'forgot-password', // Relative path for "forgot-password"
      element: <ForgotPassword />,
    },
  ],
};

export default LoginRoutes;