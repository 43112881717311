import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { styled } from "@mui/material";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./FigmaSidebar";
import Customization from "../Customization";
import { LAYOUT_CONST } from "../../constant";
import useConfig from "../../hooks/useConfig";
import { drawerWidth } from "../../store/constant";
import { getMenu, getUsersRoles, openDrawer } from "../../store/slices/menu";
import { useDispatch, useSelector } from "../../store";
// assets
import theme from "src/themes/theme";
import { Theme } from "@emotion/react";

// Interface for main container props
interface MainProps {
  open: boolean;
  layout: "vertical" | "horizontal";
}

// Styled main container
const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "layout",
})<MainProps>(({ open, layout }) => ({
  // paddingRight: '7px',
  position: "relative",
  // marginTop: open ? "10.5em" : "9em",
  marginTop: "70px",
  // marginBottom: "33px",
  marginBottom: "9px",

  height: "auto",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  // marginLeft: open ? drawerWidth : 96, // Smoothly shift based on drawer state
  marginLeft: open ? drawerWidth : 80, // Smoothly shift based on drawer state
  // width: `calc(100% - ${open ? drawerWidth : 63}px)`, // Adjust width
  width: `calc(100% - ${open ? drawerWidth : 80}px)`, // Adjust width
  zIndex: 1, // Keep content below sidebar
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    width: "100%",
  },
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { drawerType, container, layout, navType } = useConfig();
  const { drawerOpen, selectMenu } = useSelector(
    (state: { menu: { drawerOpen: boolean; selectMenu: string } }) => state.menu
  );
  useEffect(() => {
    dispatch(getMenu()).then(() => {
      dispatch(getUsersRoles());
    });

    dispatch(openDrawer(drawerType === LAYOUT_CONST.DEFAULT_DRAWER));
  }, [drawerType, dispatch]);

  useEffect(() => {
    if (matchDownMd) {
      dispatch(openDrawer(false));
    }
  }, [matchDownMd, dispatch]);

  const header = (
    //  useMemo(
    // () => (
    <Toolbar
      sx={{
        // py: drawerOpen ? "10px !important" : "10px !important",
        py: "0px !important",
        // : "30px !important",
        // p:
        //   layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
        //     ? "10px"
        //     : "30px !important",
        // bgcolor: theme.palette.mode === "dark" ? "#000000" : "#000000",
        // bgcolor: "#1E1E3A",
        borderBottom:
          navType === "dark" ? "1px solid #FFFFFF33" : ".5px solid grey",
      }}
    >
      <Header />
    </Toolbar>
  );
  // ),
  // [layout, matchDownMd, drawerType]
  // [dr  awerType, navType]
  // );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        // sx={{ bgcolor: theme.palette.background.default, zIndex: 999 }}
      >
        {header}
      </AppBar>

      {/* Sidebar */}
      {/* <Sidebar isOpen={drawerOpen} menuData={[]} /> */}

      {/* Main Content */}
      <Sidebar isOpen={drawerOpen} menuData={[]} />
      <Main open={drawerOpen} layout={layout as "vertical" | "horizontal"}>
        <Container
          maxWidth={container ? "lg" : false}
          sx={{ paddingRight: "7px !important" }} // Remove extra right space
        >
          <Outlet />
        </Container>
      </Main>

      {/* Customization Panel */}
      <Customization />
    </Box>
  );
};

export default MainLayout;
