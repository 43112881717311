import React from "react"; // Explicit import if needed for older setups
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { RootState } from "../../../src/store";

const GlobalLoader: React.FC = () => {
  const loading = useSelector((state: RootState) => state?.userState?.loading);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalLoader;
