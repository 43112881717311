import React from "react";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const styles = {
  main: {
    padding: "0.5px",
  },
  formcontrol: {
    m: 1,
    width: "100%",
  },
  searchBtn: {
    display: "flex",
    justifyContent: "center",
  },
  searchBtn_submit: {
    width: "6em",
  },
};

interface FilterType {
  selectedTypes?: number | any;
  selectedServiceType?: string[] | any; // Change to an array
  selectedOwasp?: string[] | any;
}

// Define props interface
interface TestCaseInputControlsProps {
  filters: FilterType;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>> | any;
  handleSearch: () => void;
}

const TestCaseInputControls: React.FC<TestCaseInputControlsProps> = ({
  filters,
  setFilters,
  handleSearch,
}) => {
  const { allTypes, serviceTypes, owaspList } = useSelector(
    (state: RootState) => state.masterTestPlan
  );

  // Handles filter changes and clears `selectedOwasp` when `selectedTypes` changes
  const handleChange = (
    name: keyof FilterType,
    event: React.SyntheticEvent | null,
    value: any
  ) => {
    // const newValue = value || event?.target?.value;
    const newValue = value || (event?.target as HTMLInputElement)?.value;

    setFilters((prevFilters: FilterType) => {
      const updatedFilters: FilterType = {
        ...prevFilters,
        [name]: newValue,
      };

      // Clear `selectedOwasp` if `selectedTypes` changes
      if (name === "selectedTypes" && prevFilters?.selectedOwasp) {
        updatedFilters.selectedOwasp = [];
        updatedFilters.selectedServiceType = [];
        // updatedFilters.selectedServiceType = [] as { serviceDesc?: string | undefined; }[];
      }
      if (name === "selectedServiceType" && prevFilters?.selectedServiceType) {
        updatedFilters.selectedOwasp = [];
      }

      return updatedFilters;
    });
  };

  // Renders an Autocomplete field
  const renderAutocomplete = (
    name: keyof FilterType,
    label: string,
    options: any[],
    getOptionLabel: (option: any) => string
  ) => (
    <FormControl style={styles.formcontrol}>
      <Autocomplete
        size="small"
        options={options || []}
        getOptionLabel={getOptionLabel}
        value={filters[name] || []}
        onChange={(event, value) => handleChange(name, event, value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={label} />
        )}
      />
    </FormControl>
  );

  return (
    <Grid style={styles.main} container>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3.6}>
          {renderAutocomplete(
            "selectedTypes",
            "Types",
            allTypes,
            (option) => option?.testPlanTypeDesc || ""
          )}
        </Grid>
        <Grid item xs={3.6}>
          {renderAutocomplete(
            "selectedServiceType",
            "Service Types",
            serviceTypes,
            (option) => option?.serviceDesc || ""
          )}
        </Grid>
        {filters.selectedTypes?.testPlanTypeId !== 3 && (
          <Grid item xs={3.6}>
            {renderAutocomplete(
              "selectedOwasp",
              "Owasp",
              owaspList,
              (option) => option?.categoryDesc || ""
            )}
          </Grid>
        )}
        <Grid style={styles.searchBtn} item mb={0.2} xs={1.2}>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            size="small"
            // style={styles.searchBtn_submit}
            onClick={handleSearch}
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              width: "105px",
              height: "50px",
              borderRadius: "10px",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation
            }}
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestCaseInputControls;
