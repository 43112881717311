import React from "react";
import {
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import Avatar from "../../ui-component/extended/Avatar";

// assets
import big from "../../assets/images/customization/big.svg";
import small from "../../assets/images/customization/small.svg";
import useConfig from "src/hooks/useConfig";
import theme from "src/themes/theme";

// ==============================|| CUSTOMIZATION - MODE ||============================== //

const BoxContainer: React.FC = () => {
  const { container, onChangeContainer } = useConfig();

  const changeWidth = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newContainer: boolean = e.target.value === "container";
    if (newContainer !== container) {
      onChangeContainer(newContainer);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      pb={2}
      px={2}
      justifyContent="space-between"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <Typography variant="h5">THEME WIDTH</Typography>
      <RadioGroup
        row
        aria-label="layout"
        value={container ? "container" : "fluid"}
        onChange={changeWidth}
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          control={<Radio value="fluid" sx={{ display: "none" }} />}
          label={
            <Avatar
              size="md"
              variant="rounded"
              outline
              color="inherit"
              sx={{
                mr: 1.25,
                width: 48,
                height: 48,
                borderColor: theme.palette.divider,
              }}
            >
              <CardMedia
                component="img"
                src={big}
                alt="defaultLayout"
                sx={{ width: 28, height: 28 }}
              />
            </Avatar>
          }
        />
        <FormControlLabel
          control={<Radio value="container" sx={{ display: "none" }} />}
          label={
            <Avatar
              size="md"
              variant="rounded"
              color="inherit"
              outline
              sx={{
                width: 48,
                height: 48,
                ...(!container && { borderColor: theme.palette.divider }),
              }}
            >
              <CardMedia
                component="img"
                src={small}
                alt="defaultLayout"
                sx={{ width: 16, height: 28 }}
              />
            </Avatar>
          }
        />
      </RadioGroup>
    </Stack>
  );
};

export default BoxContainer;
