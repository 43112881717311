// third-party
import {
  AnyAction,
  createSlice,
  Dispatch,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";

// project imports
import { dispatch, ReducerRootState, RootState } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import { FetchParams, UserGroupState } from "./reducerTypes";

interface FetctParams {
  [key: string]: string | number | unknown;
}

const version = "v1";
const commonEndpoint = "user-group/";
const userGroupEndpoint = "user-group";
const initialState: UserGroupState = {
  error: null,
  selectedUserGroupDetailsTab: 0,
  unMappedUserList: [],
  mappedUserList: [],
  actionGroups: [],
  menuGroups: [],
  allGroupMenu: [],
  allGroupRoles: [],
  addUserUnmappedGroupResponse: {},
  addUserMappedGroupResponse: {},
  userGroupDetails: {},
  addUserGroupResponse: {},
  updateActionGroupResponse: {},
  allUserGroups: {
    data: [],
    totalRecords: 0
  },
};

const slice = createSlice({
  name: "userGroup",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    manageSelectedUserGroupDetailsTab(state, action) {
      state.selectedUserGroupDetailsTab = action.payload;
    },

    // --------------------- GET APIs --------------------------------
    // GET USER_GROUP_LIST
    getAllUserSuccess(state, action) {
      state.allUserGroups = action.payload;
    },
    // GET USER_GROUP
    getUserGroupSuccess(state, action) {
      state.userGroupDetails = action.payload;
    },
    // GET UNMAPPED_USER_GROUP
    getUnMappedUserGroupSuccess(state, action) {
      state.unMappedUserList = action.payload;
    },
    // GET MAPPED_USER_GROUP
    getMappedUserGroupSuccess(state, action) {
      state.mappedUserList = action.payload;
    },
    getActionGroupSuccess(state, action) {
      state.actionGroups = action.payload;
    },
    updateMenuGroupSuccess(state, action) {
      state.menuGroups = action.payload;
    },
    updateGroupNameSuccess(state, action) {
      state.menuGroups = action.payload;
    },
    getUserGroupMenus(state, action) {
      state.allGroupMenu = action.payload;
    },
    getUserGroupRoles(state, action) {
      state.allGroupRoles = action.payload;
    },
    // --------------------- POST APIs --------------------------------
    // ADD USER_GROUP
    addUserGroupSuccess(state, action) {
      state.addUserGroupResponse = action.payload;
    },
    addUnMappedUserGroupSuccess(state, action) {
      state.addUserUnmappedGroupResponse = action.payload;
    },
    addMappedUserGroupSuccess(state, action) {
      state.addUserMappedGroupResponse = action.payload;
    },

    // --------------------- DELETE APIs --------------------------------
    // UPDATE_USER_GROUP
    updateActionGroupSuccess(state, action) {
      state.updateActionGroupResponse = action.payload;
    },
    // --------------------- DELETE APIs --------------------------------
    // DELETE USER_GROUP
    deleteUserGroupSuccess(state, action) {
      state.addUserGroupResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const manageSelectedUserGroupDetailsTab = (tab: number) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(slice.actions.manageSelectedUserGroupDetailsTab(tab));
    try {
      // Additional logic can be added here if needed.
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addUserGroup = (
  payload: unknown
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));

    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";

    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${userGroupEndpoint}`,
        payload
      );

      dispatch(slice.actions.addUserGroupSuccess(response));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const deleteUserGroup = (payload: any) => {
  return async (
    dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
    getState: () => ReducerRootState
  ) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}`,
        { data: payload }
      );
      dispatch(slice.actions.deleteUserGroupSuccess(response));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const getAllUserGroup = (params: FetchParams | any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}list`,
        { params }
      );
      dispatch(slice.actions.getAllUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getUserGroup = (
  userGroupId: number
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    // return async (getState: () => ReducerRootState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${userGroupId}`
      );
      dispatch(slice.actions.getUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getUnMappedUserGroup = (
  params: FetctParams
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    // export const getUnMappedUserGroup = (params: FetctParams) => {
    //   return async (getState: () => ReducerRootState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}unmapped-user/${params?.userGroupId}`,
        { params }
      );
      dispatch(slice.actions.getUnMappedUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
// export const getMappedUserGroup = (params: FetctParams) => {
// return async (getState: () => ReducerRootState) => {
export const getMappedUserGroup = (
  params: FetctParams
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}mapped-user/${params?.userGroupId}`,
        { params }
      );
      dispatch(slice.actions.getMappedUserGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const addUnMappedUserGroup = (
  userGroupId: number,
  payload: unknown
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}unmap-users/${userGroupId}`,
        payload
      );
      dispatch(slice.actions.addUnMappedUserGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addMappedUserGroup = (
  userGroupId: number,
  payload: unknown | string | number
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.post(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}map-users/${userGroupId}`,
        payload
      );
      dispatch(slice.actions.addMappedUserGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getActionGroup = (
  allowAction: string | number | any
): ThunkAction<Promise<void>, RootState, unknown, any> => {
  // return async (getState: () => ReducerRootState) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          allowAction === "allow" ? "user-actions" : "user-actions-deny"
        }`
      );
      dispatch(slice.actions.getActionGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const updateActionGroupData = (
  payload: unknown | any,
  actionType: string
): ThunkAction<Promise<void>, ReducerRootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          actionType === "allow" ? "action-codes" : "action-codes-deny"
        }`,
        payload
      );

      dispatch(slice.actions.updateActionGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateMenuGroupData = (
  payload: unknown,
  actionType: string
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${commonEndpoint}${
          actionType === "allow" ? "menu-codes" : "menu-codes-deny"
        }`,
        payload
      );

      dispatch(slice.actions.updateMenuGroupSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getUserGroupMenu = (
  allowAction: string
): ThunkAction<Promise<void>, ReducerRootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    // return async (getState: () => ReducerRootState) => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response: any = await axios.get(
        `${userGroupApiEndpoint}/${version}/user-group/${
          allowAction === "allow" ? "menus" : "menus-deny"
        }`
      );

      dispatch(slice.actions.getUserGroupMenus(response.data.menus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));

      dispatch(isLoading(false));
    }
  };
};

export const updateGroupName = (
  payload: unknown
): ThunkAction<Promise<void>, RootState, undefined, AnyAction> => {
  return async (dispatch, getState) => {
    // return async (getState: () => ReducerRootState) => {
    dispatch(setLoading(true));
    const userGroupApiEndpoint =
      getState().userInfoState.userInfo.organizationType === "CO"
        ? "consultant"
        : getState().userInfoState.userInfo.organizationType === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.put(
        `${userGroupApiEndpoint}/${version}/${userGroupEndpoint}`,
        payload
      );

      dispatch(slice.actions.updateGroupNameSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchUserGroupRoles = () => {
  return async () => {
    dispatch(setLoading(true));

    const userGroupApiEndpoint =
      localStorage.getItem("organizationType") === "CO"
        ? "consultant"
        : localStorage.getItem("organizationType") === "CL"
        ? "client"
        : "platform";
    try {
      const response = await axios.get(
        `${userGroupApiEndpoint}/${version}/user-role/list`
      );
      dispatch(slice.actions.getUserGroupRoles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
