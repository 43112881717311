import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type BootstrapDialogTitleProps = {
  children: ReactNode;
  onClose: () => void;
} & React.ComponentProps<typeof MuiDialogTitle>;

const BootstrapDialogTitle = ({
  children,
  onClose,
  ...other
}: BootstrapDialogTitleProps) => (
  <MuiDialogTitle
    sx={{
      m: 0,
      p: 2,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
    {...other}
  >
    {children}
    {onClose && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    )}
  </MuiDialogTitle>
);

type DialogBoxProps = {
  open: boolean;
  handleClose: () => any;
  modelTitle: string;
  updateGroupBtn?: boolean;
  dialogContent?: ReactNode;
  dialogActions?: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

const DialogBox = ({
  open,
  handleClose,
  modelTitle,
  updateGroupBtn,
  dialogContent,
  dialogActions,
  maxWidth,
}: DialogBoxProps) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        sx: {
          border: "1px solid #303053",
          borderRadius: "20px",
          boxShadow: "0px 0px 38px 0px #4D4D4D1A",
          p: 0,
        },
      }}
      maxWidth={maxWidth}
      fullWidth
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        sx={{
          // backgroundColor: "#181835",
          fontFamily: "Poppins",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "36px",
          padding: "30px",
          letterSpacing: "0px",
          // color: "#FFFFFF",
          textTransform: "capitalize",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {modelTitle}
      </BootstrapDialogTitle>

      <DialogContent
        sx={{
          // borderTop: "1px solid #FFFFFF26",
          // boxShadow: "0px 4px 9.8px 0px #FFFFFF33",
          // backgroundColor: "#181835",
          borderBottom: "none",
          padding: "30px !important",
        }}
        dividers
      >
        {dialogContent}
      </DialogContent>

      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </BootstrapDialog>
  );
};

export default DialogBox;
