import PropTypes from "prop-types";
import React, { useMemo, ReactNode } from "react";
// material-ui
import { CssBaseline, PaletteMode, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";

// project import
import useConfig from "../hooks/useConfig";
import Palette from "./palette";
import Typography from "./typography";

// import componentStyleOverrides from "./compStyleOverride";
import customShadows from "./shadows";

export default function ThemeCustomization({
  children,
}: {
  children: ReactNode;
}) {
  const {
    borderRadius,
    fontFamily,
    navType,
    outlinedFilled,
    presetColor,
    rtlLayout,
  } = useConfig();

  const theme = useMemo(
    () => Palette(navType as PaletteMode, presetColor),
    [navType, presetColor]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = useMemo(
    () => Typography(theme, borderRadius, fontFamily),
    [theme, borderRadius, fontFamily]
  );
  const themeCustomShadows = useMemo(
    () => customShadows(navType, theme),
    [navType, theme]
  );

  const themeOptions = useMemo(
    () => ({
      direction: rtlLayout ? "rtl" : "ltr",
      palette: theme.palette,
      mixins: {
        toolbar: {
          minHeight: "48px",
          padding: "16px",
          "@media (min-width: 600px)": {
            minHeight: "48px",
          },
        },
      },
      typography: themeTypography,
      customShadows: themeCustomShadows,
    }),
    [rtlLayout, theme, themeCustomShadows, themeTypography]
  );

  // const themes = createTheme(themeOptions);
  // themes.components = useMemo(
  //     () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
  //     [themes, borderRadius, outlinedFilled]
  // );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

// ThemeCustomization.propTypes = {
//   children: PropTypes.node,
// };
