import React, { SyntheticEvent } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import filter_tick_icon from "../../../assets/images/filter_tick_icon.svg";
import filter_close_icon from "../../../assets/images/filter_close_icon.svg";
import { useTheme } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { dispatch } from "../../../store";
import FormControlSelect from "../../../ui-component/extended/Form/FormControlSelect";
import InputLabel from "../../../ui-component/extended/Form/InputLabel";
import { scanCategory } from "../../pages/consultant/assets/constant";
import * as yup from "yup";
import { scanMode } from "../constant";
import theme from "src/themes/theme";
import { DateFilter } from "src/views/pages/platform/types";
import { color } from "@amcharts/amcharts4/core";
import useConfig from "src/hooks/useConfig";

interface IProps {
  setScanFilter: (filter: any) => void;
  scanFilter: any;
  page: number;
  size: number;
  departmentId: string | undefined;
  clientOrgId: string | undefined;
  assetGroupFilter?: any;
  setAssetGroupFilter?: any;
  createdOnFilter: string | any;
  // vulnerabilityName: string;
  // scanStatus?: string;
  // consultantStatus?: string;
  // clientStatus?: string;
  // riskRating?: string;
  // scanMode?: string;
  // scanType?: string;
  // scanSubType?: string;
  // scanName?: string;
  // scanRefNo?: string;
  filters: any;
  riskRatingValue: string | any;
  clientStatusValue: string | any;
  riskRatingApi: () => void;
  clientStatusApi: () => void;
  dateFilter: {
    startDate: string | null;
    endDate: string | null;
  };
  setDateFilter: React.Dispatch<React.SetStateAction<DateFilter>> | any;
  selectedConsultantStatus: string | null | any;
  setSelectedConsultantStatus:
    | React.Dispatch<React.SetStateAction<string>>
    | any;
  selectedClientStatus: string | null | any;
  setSelectedClientStatus:
    | React.Dispatch<React.SetStateAction<string | null>>
    | any;
  selectedRiskRating: string | null | any;
  setSelectedRiskRating:
    | React.Dispatch<React.SetStateAction<string | null>>
    | any;
  setVulnerabilityName:
    | React.Dispatch<React.SetStateAction<string | null>>
    | any;
  vulnerabilityName?: string;
  setFilters: (filters: any) => void;
  createdOnStartDate?: string;
  enableClientStatusFilter: boolean;
  consultantStatusValue: string | any;
  enableReportedOnFilter: boolean;
  consultantStatusApi: () => void;
  enableRiskRatingFilter: boolean;
  createdOnEndDate?: string;
  publishedOnStartDate?: string;
  enableVulnerabilityNameFilter: boolean;
  publishedOnEndDate?: string;
  pendingWith?: string;
  publishedOnFilter: any;
  rowsPerPage: number;
  setPublishedOnFilter: (filter: any) => void;
  enableConsultantStatusFilter: boolean;
  setCreatedOnFilter: (filter: any) => void;
  setPublishOnFilter: (filter: any) => void;
  selectedConsultantScanStatus: any;
  publishOnFilter: any;
  enableScanFilter: boolean;
  selectedScanMode: any;
  enableClientOrganizationFilter: boolean;
  scanSubTypes: any[];
  enableScanRefFilter: boolean;
  handleApplyFilters: () => void;
  enableApplyFilter: boolean;
  enableClearFilter: boolean;
  enablePublishedDateFilter: boolean;
  enableScanCategory: boolean;
  enablePublishOnFilter: boolean;
  enableConsultantStatus: boolean;
  selectedTesterName: any;
  enableDateRangeFilter: boolean;
  consultantScanStatusList: any[];
  selectedScanCategory: any;
  enableScanSubTypeFilter: boolean;
  selectedScanStatus: any;
  selectedSecurityManagerName: any;
  scanStatusList: any[];
  enableManagerFilter: boolean;
  enableScanModeFilter: boolean;
  scanTypes: any[];
  enableTesterFilter: boolean;
  clientOrganizationList: any[];
  enablePendingWithFilter: boolean;
  selectedClientOrganization: any;
  enableClientStatus: boolean;
  scanTypeApi: () => void;
  scanStatusApi: () => void;
  scanSubTypeApi: (scanType: any) => void;
  setSelectedScanStatus: (status: any) => void;
  setSelectedClientOrganization: (org: any) => void;
  setSelectedScanMode: (mode: any) => void;
  setSelectedScanCategory: (category: any) => void;
  setSelectedSecurityManagerName: (name: any) => void;
  setSelectedTesterName: (name: any) => void;
  setSelectedConsultantScanStatus: (status: any) => void;
  consultantSMList: any[];
  consultantTesterList: any[];
  selectedPendingOption: any;
  setSelectedPendingOption: (option: any) => void;
  handleReset: () => void;
}

const validationSchema = yup.object().shape({
  scanType: yup.string().required("Scan Type is required"),
  scanSubType: yup.string().required("Scan Sub Type is required"),
  scanName: yup.string().required("Scan Name is required"),
  scanRefNo: yup.string().required("Scan Ref No is required"),
});

const useStyles = makeStyles(() => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: theme.spacing(0.5),
    cursor: "text",
  },
  input: {
    flex: 1,
    minWidth: "120px",
    border: "none",
    outline: "none",
    padding: "12px 16px",
    fontSize: "16px",
  },
  card: {
    width: "350px",
    maxHeight: "200px",
    overflowY: "auto",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    // backgroundColor: "#fff",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
  },
  // InputLabel: {
  //   color: "#FFFFFF",
  //   fontFamily: "Poppins",
  //   fontWeight: "500",
  //   fontSize: "14px",
  //   linHeight: "21px",
  // },
}));

const GenericScanFilterBar: React.FC<IProps> = (props) => {
  const { navType } = useConfig();

  const inputLabelStyles = {
    // color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
    linHeight: "21px",
  };
  const classes = useStyles();
  const [showCard, setShowCard] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    scanStatus: null,
    scanFilter: null,
    dateFilter: null,
    pendingFilter: null,
    scanMode: null,
    clientOrg: null,
    manager: null,
    tester: null,
    consultantScanStatus: null,
    publishedOnFilter: null,
    scanCategory: null,
  });
  const [names, setNames] = useState<string[]>([]);
  const [currentName, setCurrentName] = useState<string>("");
  const [selectedScanType, setSelectedScanType] = useState<
    string | undefined
  >();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  interface FormValues {
    scanType: string;
    scanSubType: string;
    scanName: string;
    scanRefNo: string;
  }

  const { register, watch, setValue, reset } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    watch((value: { scanType?: string }) => {
      props?.setScanFilter({
        ...props?.scanFilter,
        ...value,
      });
      setSelectedScanType(value?.scanType);
    });
    if (selectedScanType) {
      setValue("scanSubType", "");
    }
  }, [watch, selectedScanType]);

  useEffect(() => {
    const scanTypeAction: any = props?.scanTypeApi?.();

    if (props?.enableScanFilter) {
      dispatch(scanTypeAction);
    }
    const scanStatusApiAction: any = props?.scanStatusApi?.();
    if (props?.enableClientStatus) {
      dispatch(scanStatusApiAction);
    }
  }, []);

  useEffect(() => {
    if (selectedScanType) {
      const action: any = props?.scanSubTypeApi(selectedScanType);
      if (action) {
        dispatch(action);
      }
    }
  }, [selectedScanType]);

  // const handleDeleteName = (index) => {
  //   setNames(names?.filter((_, i) => i !== index));
  // };

  const handleChangeDateValue = (field: string, date: dayjs.Dayjs | null) => {
    const newDateFilter = { ...props?.createdOnFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (field === "startDate" && !newDateFilter.endDate && date) {
      newDateFilter.endDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (field === "endDate" && !newDateFilter.startDate && date) {
      newDateFilter.startDate = date.format("YYYY-MM-DD");
    }
    props?.setCreatedOnFilter(newDateFilter);
  };
  const handleChangePublishDateValue = (
    field: string,
    date: dayjs.Dayjs | null
  ) => {
    const newDateFilter = { ...props?.publishOnFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // If end date is not selected, default it to start date
    if (
      field === "publishedStartDate" &&
      !newDateFilter.publishedEndDate &&
      date
    ) {
      newDateFilter.publishedEndDate = date.format("YYYY-MM-DD");
    }
    // If start date is not selected, default it to end date
    if (
      field === "publishedEndDate" &&
      !newDateFilter.publishedStartDate &&
      date
    ) {
      newDateFilter.publishedStartDate = date.format("YYYY-MM-DD");
    }
    props?.setPublishOnFilter(newDateFilter);
  };

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter" && currentName.trim()) {
  //     setNames([...names, currentName.trim()]);
  //     setCurrentName("");
  //   }
  // };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    menuType: string
  ) => {
    setAnchorEl({ [menuType]: event.currentTarget });
  };

  // const handleClose = (name) => {
  //   if (name === "scanFilter") {
  //     setAnchorEl({
  //       scanFilter: null,
  //     });
  //   }
  //   if (name === "scanStatus") {
  //     setAnchorEl({
  //       scanStatus: null,
  //     });
  //   }
  //   if (name === "dateFilter") {
  //     setAnchorEl({
  //       dateFilter: null,
  //     });
  //   }
  //   if (name === "publishedOnFilter") {
  //     setAnchorEl({
  //       publishedOnFilter: null,
  //     });
  //   }
  //   if (name === "pendingFilter") {
  //     setAnchorEl({
  //       pendingFilter: null,
  //     });
  //   }
  //   if (name === "scanMode") {
  //     setAnchorEl({
  //       scanMode: null,
  //     });
  //   }
  //   if (name === "clientOrg") {
  //     setAnchorEl({
  //       clientOrg: null,
  //     });
  //   }
  //   if (name === "manager") {
  //     setAnchorEl({
  //       manager: null,
  //     });
  //   }
  //   if (name === "tester") {
  //     setAnchorEl({
  //       tester: null,
  //     });
  //   }
  //   if (name === "consultantScanStatus") {
  //     setAnchorEl({
  //       consultantScanStatus: null,
  //     });
  //   }
  //   if (name === "scanCategory") {
  //     setAnchorEl({
  //       scanCategory: null,
  //     });
  //   }
  // };

  const handleClose = (name: string) => {
    setAnchorEl({ [name]: null });
  };

  interface ScanStatusOption {
    id: string;
    desc: string;
  }

  const handleScanStatusChange = (
    event: SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ScanStatusOption>
  ) => {
    props?.setSelectedScanStatus(value);
  };

  interface ClientOrganizationOption {
    id: string;
    orgName: string;
  }

  const handleClientOrganizationChange = (value: any) => {
    props?.setSelectedClientOrganization(value);
  };

  interface ScanModeOption {
    scanModeCode: string;
    scanModeDesc: string;
  }

  const handleScanModeChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: ScanModeOption[] | null
  ) => {
    props.setSelectedScanMode(newValue || []); // Always set an empty array if the new value is null/undefined
  };

  interface ScanCategoryOption {
    scanCategoryId: string;
    scanCategory: string;
  }

  const handleScanCategory = (value: ScanCategoryOption[] | any) => {
    props?.setSelectedScanCategory(value);
  };

  interface ManagerOption {
    smName: string;
    smId: string;
  }

  const handleChangeManagerName = (
    event: SyntheticEvent<Element, Event>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    props?.setSelectedSecurityManagerName(value);
  };

  interface TesterOption {
    testerName: string;
    testerId: string;
  }

  const handleChangeTesterName = (
    event?: SyntheticEvent<Element, Event>,
    value?: TesterOption[],
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    props?.setSelectedTesterName(value);
  };

  interface ConsultantScanStatusOption {
    scanStatusId: string;
    scanStatusDescription: string;
  }

  const handleConsultantScanStatus = (
    event: SyntheticEvent<Element, Event>,
    value: ConsultantScanStatusOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    props?.setSelectedConsultantScanStatus(value);
  };

  const displaySMName =
    props?.consultantSMList?.length > 0 &&
    props?.consultantSMList?.map((value) => {
      return {
        smName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        smId: value.userId,
      };
    });
  const displayTesterName =
    props?.consultantTesterList?.length > 0 &&
    props?.consultantTesterList?.map((value) => {
      return {
        testerName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        testerId: value.userId,
      };
    });

  const handleReset = (type: string) => {
    switch (type) {
      case "scanFilter":
        props?.setScanFilter({
          scanType: "",
          scanSubType: "",
          scanName: "",
          scanRefNo: "",
        });
        reset();
        setValue("scanType", "");
        setValue("scanSubType", "");
        break;
      case "dateFilter":
        props?.setCreatedOnFilter({
          startDate: null,
          endDate: null,
        });
        break;
      case "publishedOnFilter":
        props?.setPublishOnFilter({});
        break;
      default:
        break;
    }
  };

  const handleMenuItemClick = (option: string) => {
    if (option === "All") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: false,
      });
    } else if (option === "Client") {
      props?.setSelectedPendingOption({
        client: true,
        consultant: false,
      });
    } else if (option === "Consultant") {
      props?.setSelectedPendingOption({
        client: false,
        consultant: true,
      });
    }
    setAnchorEl({ pendingFilter: null });
  };

  const getPendingWithLabel = () => {
    if (props?.selectedPendingOption?.client) {
      return "Pending With Client";
    } else if (props?.selectedPendingOption?.consultant) {
      return "Pending With Consultant";
    } else {
      return "Pending With All";
    }
  };

  const buttonStyles = (condition: string | number) => ({
    padding: "3px 12px",
    height: "50px",
    marginTop: "auto",
    border: "1px solid #A2A2D2",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    textTransform: "none",

    // console.log(condition, "condition")

    // border: !condition
    //   ? theme.palette.mode === "dark"
    //     ? "2px solid white"
    //     : "2px solid black"
    //   : "none",
    "&:hover": {
      backgroundColor: !condition
        ? ""
        : navType === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    // "&:active": {
    //   backgroundColor: condition
    //     ? ""
    //     : navType === "dark"
    //     ? "white"
    //     : "white",
    // },
    marginRight: "1px",
    // backgroundColor: !condition
    //   ? ""
    //   : navType === "dark"
    //   ? "white"
    //   : theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    // color: "#A2A2D2",
    // color: condition
    //   ? navType === "light"
    //     ? "darkblue"
    //     : "black"
    //   : navType === "dark"
    //   ? "white"
    //   : "black",
    // marginTop: "6px",
  });

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <div>
        {/* Pending With Button */}
        {props?.enablePendingWithFilter && (
          <Button
            aria-controls="pending-with-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, "pendingFilter")}
            sx={buttonStyles(
              props?.selectedPendingOption?.client ||
                props?.selectedPendingOption?.consultant
            )}
          >
            {getPendingWithLabel()}
            <ArrowDropDownIcon
              sx={{
                fontSize: "20px",
                marginLeft: "3px",
              }}
            />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl?.pendingFilter}
          open={Boolean(anchorEl?.pendingFilter)}
          onClose={() => handleClose("pendingFilter")}
        >
          <MenuItem onClick={() => handleMenuItemClick("Client")}>
            Client
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Consultant")}>
            Consultant
          </MenuItem>
          {(props?.selectedPendingOption?.client ||
            props?.selectedPendingOption?.consultant) && (
            <MenuItem onClick={() => handleMenuItemClick("All")}>All</MenuItem>
          )}
        </Menu>
      </div>
      {/* Consultant Organization Button */}
      {props?.enableClientOrganizationFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "clientOrg")}
          sx={buttonStyles(props?.selectedClientOrganization?.length)}
        >
          Client Organization
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.clientOrg}
        open={Boolean(anchorEl?.clientOrg)}
        onClose={() => handleClose("clientOrg")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 400 }}>
            <Autocomplete
              size="small"
              multiple
              options={props?.clientOrganizationList || []}
              getOptionLabel={(option) => option?.orgName}
              value={props?.selectedClientOrganization || ""}
              onChange={handleClientOrganizationChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip key={index} label={option?.orgName} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Manager Filter Button */}
      {props?.enableManagerFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "manager")}
          sx={buttonStyles(props?.selectedSecurityManagerName?.length)}
        >
          Manager
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.manager}
        open={Boolean(anchorEl?.manager)}
        onClose={() => handleClose("manager")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              size="small"
              options={displaySMName || []}
              getOptionLabel={(option) => option?.smName}
              value={props?.selectedSecurityManagerName || []}
              onChange={handleChangeManagerName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip label={option?.smName} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Tester Filter Button */}
      {props?.enableTesterFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "tester")}
          sx={buttonStyles(props?.selectedTesterName?.length)}
        >
          Tester
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.tester}
        open={Boolean(anchorEl?.tester)}
        onClose={() => handleClose("tester")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              size="small"
              multiple
              options={displayTesterName || []}
              getOptionLabel={(option) => option?.testerName}
              value={props?.selectedTesterName || []}
              onChange={handleChangeTesterName}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip label={option?.testerName} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              // sx={{
              //   "& .MuiAutocomplete-listbox": {
              //     maxHeight: "200px", // Set the maximum height of the dropdown
              //     overflow: "auto", // Add scroll bar if needed
              //   },
              // }}
              // PaperComponent={(props) => (
              //   <Paper
              //     {...props}
              //     style={{ maxHeight: 200, overflow: "auto" }}
              //   />
              // )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Scan Filter Button */}
      {props?.enableScanFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanFilter")}
          sx={buttonStyles(
            props?.scanFilter?.scanName ||
              props?.scanFilter?.scanRefNo ||
              props?.scanFilter?.scanSubType ||
              props?.scanFilter?.scanType
          )}
        >
          Scan
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanFilter}
        open={Boolean(anchorEl?.scanFilter)}
        onClose={() => handleClose("scanFilter")}
      >
        <div
          style={{
            width: 550,
            padding: 15,
          }}
        >
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={props.enableScanSubTypeFilter ? 6 : 12}>
              <InputLabel sx={{ ...inputLabelStyles }}>Type:</InputLabel>
              <FormControlSelect
                size="small"
                itemsList={props?.scanTypes}
                optionLabel={"scanTypeDescription"}
                optionValue={"scanTypeId"}
                {...register("scanType")}
                value={props?.scanFilter?.scanType || ""}
                name="scanType"
              />
            </Grid>
            {props.enableScanSubTypeFilter && (
              <Grid item xs={6}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  Sub-Type:
                </InputLabel>
                <FormControlSelect
                  size="small"
                  disabled={
                    !(props?.scanSubTypes?.length > 0 && selectedScanType)
                  }
                  itemsList={props?.scanSubTypes || []}
                  optionLabel={"scanSubTypeDescription"}
                  optionValue={"scanSubTypeId"}
                  {...register("scanSubType")}
                  value={props?.scanFilter?.scanSubType || ""}
                  name="scanSubType"
                />
              </Grid>
            )}
            <Grid item xs={props.enableScanSubTypeFilter ? 6 : 12}>
              <InputLabel style={{ ...inputLabelStyles }}>Name</InputLabel>
              <TextField
                size="small"
                // name="scanName"
                variant="outlined"
                value={props?.scanFilter?.scanName || ""}
                {...register("scanName")}
                fullWidth
                autoComplete="off"
              />
            </Grid>
            {props.enableScanRefFilter && (
              <Grid item xs={6}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  Scan Ref Code
                </InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  value={props?.scanFilter?.scanRefNo || ""}
                  // name="scanRefNo"
                  {...register("scanRefNo")}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="large"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "0px",
                    color: "white",
                    width: "95px",
                    height: "35px",
                    borderRadius: "6px",
                    background:
                      "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                    textTransform: "none", // This removes the uppercase transformation
                  }}
                  variant="contained"
                  onClick={() => handleReset("scanFilter")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  // sx={{
                  //   minWidth: 90,
                  // }}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>
      {/* Client Status Button */}
      {props?.enableClientStatus && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanStatus")}
          sx={buttonStyles(props?.selectedScanStatus?.length)}
        >
          Status
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanStatus}
        open={Boolean(anchorEl?.scanStatus)}
        onClose={() => handleClose("scanStatus")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              size="small"
              multiple
              id="scan-status-autocomplete"
              options={props?.scanStatusList || []}
              getOptionLabel={(option) => option?.desc}
              value={props?.selectedScanStatus || []}
              onChange={handleScanStatusChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    key={option?.id}
                    label={option?.desc}
                    // {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Scan Category */}
      {props?.enableScanCategory && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanCategory")}
          sx={buttonStyles(props?.selectedScanCategory?.length)}
        >
          Scan Category
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanCategory}
        open={Boolean(anchorEl?.scanCategory)}
        onClose={() => handleClose("scanCategory")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              multiple
              size="small"
              id="scan-status-autocomplete"
              options={scanCategory || []}
              getOptionLabel={(option) => option?.scanCategory || ""}
              value={props?.selectedScanCategory || []}
              onChange={handleScanCategory}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip label={option?.scanCategory} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Consultant Status Button */}
      {props?.enableConsultantStatus && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "consultantScanStatus")}
          sx={buttonStyles(props?.selectedConsultantScanStatus?.length)}
        >
          Status
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.consultantScanStatus}
        open={Boolean(anchorEl?.consultantScanStatus)}
        onClose={() => handleClose("consultantScanStatus")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              size="small"
              multiple
              id="scan-status-autocomplete"
              options={props?.consultantScanStatusList || []}
              getOptionLabel={(option) => option?.scanStatusDescription}
              value={props?.selectedConsultantScanStatus || []}
              onChange={handleConsultantScanStatus}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip label={option?.scanStatusDescription} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Mode Button */}
      {props?.enableScanModeFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "scanMode")}
          sx={buttonStyles(Object.keys(props?.setSelectedScanMode)?.length)}
        >
          Mode
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "3px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.scanMode}
        open={Boolean(anchorEl?.scanMode)}
        onClose={() => handleClose("scanMode")}
      >
        <div style={{}}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Autocomplete
              // multiple
              size="small"
              id="scan-status-autocomplete"
              options={scanMode || []}
              // onChange={(event, newValue) => handleScanModeChange(event, newValue)}
              getOptionLabel={(option) => option?.scanModeDesc || ""}
              value={props?.selectedScanMode || []}
              onChange={handleScanModeChange}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip label={option?.scanModeDesc} key={index} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>

      {/* Date Range*/}
      {props?.enableDateRangeFilter && (
        <Button
          // color="primary"
          onClick={(event) => handleClick(event, "dateFilter")}
          sx={buttonStyles(
            props?.createdOnFilter?.startDate || props?.createdOnFilter?.endDate
            // || props?.publishedOnFilter?.startDate ||
            // props?.publishedOnFilter?.endDate
          )}
        >
          {props.enablePublishedDateFilter
            ? "Publish Date Range"
            : "Date Range"}
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.dateFilter}
        open={Boolean(anchorEl?.dateFilter)}
        onClose={() => handleClose("dateFilter")}
      >
        <div style={{ width: 450, padding: 10 }}>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel style={{ ...inputLabelStyles }}>
                {props.enablePublishedDateFilter
                  ? "Published on"
                  : "Created On"}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="From Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.createdOnFilter?.startDate
                        ? dayjs(props?.createdOnFilter?.startDate)
                        : null
                    }
                    onChange={(d) => handleChangeDateValue("startDate", d)}
                  />
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="End Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.createdOnFilter?.endDate
                        ? dayjs(props?.createdOnFilter?.endDate)
                        : null
                    }
                    minDate={dayjs(props?.createdOnFilter?.startDate)}
                    onChange={(d) => handleChangeDateValue("endDate", d)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "-4px", gap: 2 }}
              >
                <Button
                  disabled={
                    !props?.createdOnFilter?.startDate ||
                    !props?.createdOnFilter?.endDate
                      ? true
                      : false
                  }
                  size="large"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "0px",
                    color: "white",
                    width: "95px",
                    height: "35px",
                    borderRadius: "6px",
                    background:
                      "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                    textTransform: "none", // This removes the uppercase transformation
                  }}
                  variant="contained"
                  onClick={() => handleReset("dateFilter")}
                  startIcon={<ReplayIcon sx={{ fontSize: "20px" }} />}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>

      {/* Published On Date Range*/}
      {props?.enablePublishOnFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "publishedOnFilter")}
          sx={buttonStyles(
            props?.publishOnFilter?.publishedStartDate ||
              props?.publishOnFilter?.publishedEndDate
          )}
        >
          {props.enablePublishedDateFilter ? "Created On" : "Published on"}
          <ArrowDropDownIcon
            sx={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.publishedOnFilter}
        open={Boolean(anchorEl?.publishedOnFilter)}
        onClose={() => handleClose("publishedOnFilter")}
      >
        <div style={{ width: 450, padding: 10 }}>
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel style={{ ...inputLabelStyles }}>
                {"Published on"}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="From Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.publishOnFilter?.publishedStartDate
                        ? dayjs(props?.publishOnFilter?.publishedStartDate)
                        : null
                    }
                    onChange={(d) =>
                      handleChangePublishDateValue("publishedStartDate", d)
                    }
                  />
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="End Date"
                    format="DD/MM/YYYY"
                    value={
                      props?.publishOnFilter?.publishedEndDate
                        ? dayjs(props?.publishOnFilter?.publishedEndDate)
                        : null
                    }
                    minDate={dayjs(props?.publishOnFilter?.publishedStartDate)}
                    onChange={(d) =>
                      handleChangePublishDateValue("publishedEndDate", d)
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: "-4px", gap: 2 }}
              >
                <Button
                  disabled={
                    !props?.publishOnFilter?.publishedStartDate ||
                    !props?.publishOnFilter?.publishedEndDate
                      ? true
                      : false
                  }
                  size="large"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "0px",
                    color: "white",
                    width: "95px",
                    height: "35px",
                    borderRadius: "6px",
                    background:
                      "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                    textTransform: "none", // This removes the uppercase transformation
                  }}
                  variant="contained"
                  onClick={() => handleReset("publishedOnFilter")}
                  startIcon={<ReplayIcon sx={{ fontSize: "20px" }} />}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Menu>

      {/* Apply Filter */}
      {props?.enableApplyFilter && (
        <Tooltip title={"Apply Filter"}>
          <IconButton
            onClick={props?.handleApplyFilters}
            sx={{ p: 0, color: "#fff" }}
          >
            <img src={filter_tick_icon} alt="Icon" width={28} height={28} />
          </IconButton>
        </Tooltip>
        // <Button
        //   color="primary"
        //   onClick={props?.handleApplyFilters}
        //   sx={{
        //     ...commonButtonStyles,
        //   }}
        // >
        //   Apply
        //   <CheckIcon
        //     sx={{
        //       fontSize: "20px",
        //       marginLeft: "5px",
        //     }}
        //   />
        // </Button>buttonStyles = (condition: string | number
      )}
      {/* Clear Filter */}
      {props?.enableClearFilter && (
        <Tooltip title={"Clear Filter"}>
          <IconButton
            onClick={() => {
              reset();
              props?.handleReset();
            }}
            sx={{ p: 0, color: "#fff" }}
          >
            <img src={filter_close_icon} alt="Icon" width={28} height={28} />
          </IconButton>
        </Tooltip>
        // <Button
        //   color="primary"
        //   onClick={() => {
        //     reset();
        //     props?.handleReset();
        //   }}
        //   sx={{
        //     ...commonButtonStyles,
        //   }}
        // >
        //   Clear All Filters
        //   <ReplayIcon sx={{ fontSize: "20px" }} />
        // </Button>
      )}
    </div>
  );
};

export default GenericScanFilterBar;
