import React from "react";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NO_VALUE, USER_ROLES } from "../../../../../../constant";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { dispatch } from "../../../../../../store";
import {
  getAssetsForScan,
  getAssetsSubType,
  getAssetsType,
  getConsultantSMList,
  getConsultantTesterList,
  getScanDetails,
  getScanTypes,
  getVACAScanTypes,
} from "../../../../../../store/slices/scanReducer";
import {
  acceptTesterStatus,
  deleteVulnerabilityToScan,
  downlaodNessustemplate,
  downlaodNessusTemplateSuccess,
  getClientStatusValue,
  getConsultantStatusValue,
  getRiskRatingValue,
  getScanVulnerabilities,
  smartCloseVulnerabilities,
  statusSM,
  statusTester,
  submitToSM,
  submitToTester,
  updateVulnerabilityStatus,
  uploadNessusCsv,
} from "../../../../../../store/slices/vulnerabilityReducer";
import Delete from "../../../../../../ui-component/delete";
import DialogBox from "../../../../../../ui-component/dialogbox";
import ErrorDisplay from "../../../../../../ui-component/errorList";
import VmTable from "../../../../../../ui-component/table";
import {
  downloadTemplateFile,
  getChipDesign,
  handleUserRoles,
} from "../../../../../../utils/helpers";
import UpdateIcon from "@mui/icons-material/Update";
import GenericFilterBar from "../../../../../../views/Common/genericFilterBar";
import { DataListType, rowsPerPageOptions, SCAN_TYPE } from "../../constant";
import ActionModal from "./actionModal";
import NessusUpload from "./nessusUpload";
import {
  ActionButtonNessusUploadProps,
  DateFilterState,
  FilterState,
  ScanVulnerabilitiesProps,
  StatusDetail,
} from "../../scanTypes";
import { RootState } from "src/store";
import theme from "src/themes/theme";
import ConfirmUpdateInternalStatus from "src/ui-component/extended/updateVulnerabilityStatusConfirmDialog";

const ActionButton: React.FC<ActionButtonNessusUploadProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<{
    open: null | HTMLElement;
    close: null | HTMLElement;
    falsePositive: null | HTMLElement;
    uploadFiles: null | HTMLElement;
  }>({
    open: null,
    close: null,
    falsePositive: null,
    uploadFiles: null,
  });
  const params: any = useParams();
  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state: RootState) => state.menu);
  const scanDetails = props?.scanDetails;
  const handleDeleteVulnerability = () => {
    if (props?.handleDeleteVulnerabilityDialog) {
      props?.handleDeleteVulnerabilityDialog();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLButtonElement; // or HTMLInputElement depending on your element type

    setAnchorEl((prevState) => ({
      ...prevState,
      [target.name]: target,
    }));
  };

  const handleClose = () => {
    setAnchorEl({
      open: null,
      close: null,
      falsePositive: null,
      uploadFiles: null,
    });
  };

  const handleStatus = (status: string, validRoles?: boolean) => {
    if (props?.selectedIds && props?.selectedIds?.length > 0) {
      const payload = {
        status: status,
        clientOrgId,
        vulnerabilityIdList: props?.selectedIds,
      };
      if (props?.setSelectedIds) {
        props?.setSelectedIds([]);
      }
      if (
        handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER) &&
        validRoles
      ) {
        dispatch(statusSM(payload)).then(() => {
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: props?.page,
              size: props?.rowsPerPage,
            })
          );
          handleClose();
        });
      } else {
        dispatch(statusTester(payload)).then(() => {
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: props?.page,
              size: props?.rowsPerPage,
            })
          );
          handleClose();
        });
      }
    }
  };

  const handleSubmit = (role: string) => {
    const payload = {
      idList: props?.selectedIds,
    };
    if (props?.selectedIds && props?.selectedIds?.length > 0) {
      if (role === "sm") {
        dispatch(submitToTester(clientOrgId, payload)).then(() => {
          if (props?.setSelectedIds) {
            props?.setSelectedIds([]);
          }
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: props?.page,
              size: props?.rowsPerPage,
            })
          );
        });
      } else {
        dispatch(submitToSM(clientOrgId, payload)).then(() => {
          if (props?.setSelectedIds) {
            props?.setSelectedIds([]);
          }
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: props?.page,
              size: props?.rowsPerPage,
            })
          );
        });
      }
    }
  };

  const handleRoles = (role: string) => {
    if (handleUserRoles(userRoles, role)) {
      return true;
    }
    return false;
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event?.target?.files[0];
  //   if (!selectedFile) {
  //     setFile(null);
  //     return;
  //   }
  //   setFile(selectedFile);
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     // Set the file and its Data URL in the state
  //     setFile({
  //       dataUrl: e.target.result,
  //       name: selectedFile?.name,
  //       type: selectedFile?.type,
  //     });
  //   };
  //   reader.readAsDataURL(selectedFile);
  // };

  // const vulnerabilityMenus = () => {
  //   return (
  //     <>
  //       <MenuItem aria-label="actions">
  //         <Button
  //           variant="text"
  //           component="label"
  //           size="small"
  //           onClick={() => handleStatus("O", true)}
  //         >
  //           Selected Record
  //         </Button>
  //       </MenuItem>
  //       <MenuItem aria-label="actions">
  //         <Button
  //           variant="text"
  //           component="label"
  //           size="small"
  //           onClick={() => handleStatus("O")}
  //         >
  //           All
  //         </Button>
  //       </MenuItem>
  //     </>
  //   );
  // };
  const onClickUpload = (normalUpload: boolean) => {
    if (props.setOpenNessusUploadDialog && props.setNormalUpload) {
      props.setOpenNessusUploadDialog(true);
      props.setNormalUpload(normalUpload);
    }
  };
  return (
    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}>
      <Grid xs={12} sx={{ display: "flex", gap: "5px" }}></Grid>
      <Grid sx={{ display: "flex", paddingBottom: "30px", gap: "2px" }}>
        {scanDetails?.displayVulnerabilityField?.acceptTesterStatus ? (
          <IconButton
            title="Click to bulk update Security Manager Status"
            size="large"
            color="inherit"
            aria-label="live customize"
            onClick={props.handleTesterVulStatueDialog}
          >
            <UpdateIcon />
          </IconButton>
        ) : (
          <></>
        )}
        {/* <Grid sx={{ paddingRight: "18px" }}>
          <Link to={`/scan/${params?.scanId}/vulnerability/add`}>
            {scanDetails?.displayScanVulnerabilityAction?.addVulnerability ? (
              <Button
                title="Add Vulnerability"
                // variant="contained"
                size="large"
                color="inherit"
                variant="contained"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                  letterSpacing: "0px",
                  color: "white",
                  width: "105px",
                  height: "50px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                  textTransform: "none", // This removes the uppercase transformation
                }}
                startIcon={<AddIcon />}
                onClick={() => props.setOpen && props.setOpen(true)}
              >
                Add
              </Button>
            ) : (
              <></>
            )}
          </Link>
        </Grid> */}

          <Link to={`/scan/${params?.scanId}/vulnerability/add`}>
            {scanDetails?.displayScanVulnerabilityAction?.addVulnerability ? (
              <IconButton
                title="Add Vulnerability"
                // variant="contained"
                size="large"
                color="inherit"
                aria-label="live customize"
                sx={{
                  color: "grey",
                }}
                onClick={() => props.setOpen && props.setOpen(true)}
              >
                <AddIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Link>

        {scanDetails?.displayScanVulnerabilityAction?.deleteVulnerability ? (
          <IconButton
            disabled={!props?.selectedIds?.length}
            // variant="contained"
            title="Delete"
            size="large"
            aria-label="live customize"
            onClick={handleDeleteVulnerability}
            color="inherit"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )}

        {/* <Grid>
          {scanDetails?.displayScanVulnerabilityAction?.deleteVulnerability ? (
            <Button
              disabled={!props?.selectedIds?.length}
              // variant="contained"
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "27px",
                letterSpacing: "0px",
                color: "white",
                width: "105px",
                height: "50px",
                borderRadius: "10px",
                background:
                  "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                textTransform: "none", // This removes the uppercase transformation
              }}
              title="Delete"
              size="large"
              aria-label="live customize"
              onClick={handleDeleteVulnerability}
              color="inherit"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
        </Grid> */}
        <Grid>
          <IconButton
            // variant="contained"
            title="Actions"
            size="large"
            aria-label="live customize"
            name="open"
            onClick={() =>
              props.setVisibleActionModal && props.setVisibleActionModal(true)
            }
            color="inherit"
          >
            <FileOpenIcon />
          </IconButton>
        </Grid>

        <Link to={`/scan/${params?.scanId}/vulnerability/pull`}>
          {scanDetails?.displayScanVulnerabilityAction?.pullVulnerability ? (
            <IconButton
              title="Pull Vulnerability"
              // variant="contained"
              size="large"
              color="inherit"
              aria-label="live customize"
              sx={{
                color: "grey",
              }}
            >
              <SystemUpdateAltIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Link>
        <>
          {scanDetails?.displayScanVulnerabilityAction?.uploadNessusCSV && (
            <IconButton
              aria-label="actions"
              onClick={(e) => handleClick(e)}
              title="Upload Nessus File"
              color="inherit"
              name="uploadFiles"
            >
              <FileUploadIcon />
            </IconButton>
          )}
          <Menu
            id="menu-simple-card"
            anchorEl={anchorEl?.uploadFiles}
            keepMounted
            open={Boolean(anchorEl?.uploadFiles)}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <>
              <>
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    onClick={() => onClickUpload(false)}
                  >
                    Nessus Upload
                  </Button>
                </MenuItem>

                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    onClick={() => onClickUpload(true)}
                  >
                    Normal Upload
                  </Button>
                </MenuItem>
              </>
            </>
          </Menu>
          {/* <Input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          // onChange={handleFileChange}
          accept="image/*"
        />
        <label htmlFor="fileInput">
          <IconButton
            size="small"
            component="span"
            variant="outlined"
            color="inherit"
            title="Upload Files"
            name="uploadFiles"
          >
            <FileUploadIcon />
          </IconButton>
        </label> */}
        </>
        {/* ) : (
        <></>
      )} */}
        {/* <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.uploadFiles}
          keepMounted
          open={Boolean(anchorEl?.uploadFiles)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            {/* {scanDetails?.displayScanVulnerabilityAction?.uploadNessusCSV ? (
            <MenuItem aria-label="actions" for="fileInput">
              <Button
                onChange={props.uploadCsv}
                variant="text"
                component="label"
                size="small"
                sx={{
                  background: "#ffffff",
                  color: "black",
                }}
              >
                Nessus V2 - CSV
                <input id="fileInput" type="file" hidden />
              </Button>
            </MenuItem>
          ) : (
            <></>
          )} */}
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.open}
          keepMounted
          open={Boolean(anchorEl?.open)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("O", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("O")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.close}
          keepMounted
          open={Boolean(anchorEl?.close)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("C", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("C")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.falsePositive}
          keepMounted
          open={Boolean(anchorEl?.falsePositive)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("FP", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("FP")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>

        {scanDetails?.displayScanVulnerabilityAction?.showMarkAsWIP ? (
          <>
            {/* <IconButton
              disabled={!props?.selectedIds?.length}
              variant="contained"
              title="Work-in-Progress"
              size="large"
              aria-label="live customize"
              onClick={() => handleStatus("WIP")}
              color="inherit"
            >
              <HourglassBottomIcon />
            </IconButton> */}
            {scanDetails?.displayScanVulnerabilityAction?.showSubmitToSM ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                // variant="contained"
                title="Submit To Security Manager"
                size="large"
                aria-label="live customize"
                onClick={() => handleSubmit("tester")}
                color="inherit"
              >
                <AssignmentIndIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {scanDetails?.displayScanVulnerabilityAction?.showAssignToTester ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                // variant="contained"
                title="Assign To Tester"
                size="large"
                aria-label="live customize"
                onClick={() => handleSubmit("sm")}
                color="inherit"
              >
                <AssignmentIndIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        )}

        {/* <IconButton
          title="Template For Reference"
          variant="contained"
          size="large"
          color="inherit"
          onClick={() => handleDownloadTemplate()}
        >
          <UploadFileIcon />
        </IconButton> */}
        {scanDetails?.displayVulnerabilityField?.smartClose ? (
          <IconButton
            // variant="contained"
            title="Auto Close"
            size="large"
            aria-label="live customize"
            onClick={() => props.handleAutoClose && props.handleAutoClose()}
            color="inherit"
          >
            <AutoModeIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

const ScanVulnerabilities: React.FC<ScanVulnerabilitiesProps> = (props) => {
  // const theme = useTheme();
  const themeMode = theme.palette.mode;
  const location = useLocation()?.pathname?.split("/")[1];
  const navigate = useNavigate();
  const params: any = useParams();
  const [
    updateVulInternalStatusDialogVisible,
    setUpdateVulInternalStatusDialogVisible,
  ] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[] | undefined | any>(
    []
  );
  const [valueLabel, setValueLabel] = useState("all");
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteAssetDialog, setOpenDeleteVulnerabilityDialog] =
    useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [openNessusUploadDialog, setOpenNessusUploadDialog] = useState(false);
  const {
    scanVulnerabilities,
    uploadNessusReponse,
    consultantStatusValue,
    riskRatingValue,
    clientStatusValue,
  } = useSelector((state: RootState) => state?.vulnerabilityState);
  const {
    assetsTypes,
    assetSubTypes,
    allScans,
    scanTypes,
    scanSubTypes,
    consultantClientOrganizationList,
    allScanStatus,
    consultantSMList,
    consultantTesterList,
    VACAscanTypes,
    assetsForScan
  } = useSelector((state: RootState) => state?.scansState);
  const { setDataloading } = useSelector(
    (state: RootState) => state?.userState
  );
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [filters, setFilters] = useState<any>({
    assetType: "",
    assetSubType: "",
    assetURL: "",
    assetId: "",
    assetTypeName: "",
  });
  const [dateFilter, setDateFilter] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [selectedConsultantStatus, setSelectedConsultantStatus] = useState<any>(
    []
  );
  const [selectedClientStatus, setSelectedClientStatus] = useState<any[]>([]);
  const [selectedComplianceStatus, setSelectedComplianceStatus] = useState<any>(
    {}
  );
  const [selectedRiskRating, setSelectedRiskRating] = useState<any[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [normalUpload, setNormalUpload] = useState<boolean>(false);
  const [visibleActionModal, setVisibleActionModal] = useState<boolean>(false);
  const [selectedSecurityManagerName, setSelectedSecurityManagerName] =
    useState<any>([]);
  const [selectedTesterName, setSelectedTesterName] = useState<any>([]);
  const [roleList, setRoleList] = useState<any[]>([]);
  const [statusDetail, setStatusDetail] = useState<{ [key: string]: string }>({
    status: "",
    markFor: "",
    data: "",
  });
  const {
    OrgAssetsList,
    uploadAsset,
    exportAssetDataRes,
    assetGroupList,
    assetGroupManager,
    OrgAssetsListForFilter,
  } = useSelector((state: RootState) => state?.assetsState);

  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state: RootState) => state.menu);
  const { downlaodNessusTemplateResponse } = useSelector(
    (state: RootState) => state?.vulnerabilityState
  );
  const [scanFilter, setScanFilter] = useState<any>({
    scanType: "",
    scanSubType: "",
    scanName: "",
    scanRefNo: "",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "vulRefNumber",
        header: "Vulnerability Ref Code",
        muiTableBodyCellProps: ({ row }: any) => ({
          onClick: () => {
            navigate(`/vulnerabilities/${row?.original?.scanVulnerabilityId}`);
          },
          sx: {
            cursor: "pointer",
            // color: theme.palette.secondary.main,
            textDecoration: "underline",
          },
        }),
      },
      {
        accessorKey: "sourceSystemRefNo",
        header: "Source System Ref No",
        Cell: ({ row }: any) => (
          <>
            {row?.original?.sourceSystemRefNo
              ? row?.original?.sourceSystemRefNo
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetName",
        header: "Asset Name",
      },
      {
        accessorKey: "scanTypeName",
        header: "Scan Type",
        Cell: ({ row }: any) => (
          <>
            {row?.original?.scanTypeName
              ? row?.original?.scanTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
      },
      {
        accessorKey: "smartClose",
        header: "Smart Close",
        Cell: ({ row }: any) => <>{row?.original?.smartClose ? "Yes" : "No"}</>,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ row }) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "vulnerabilityName",
        header: "Vulnerability Name",
      },
      {
        accessorKey: "easeOfExploitationName",
        header: "Ease of Exploitation",
        Cell: ({ row }) => (
          <>
            {row?.original?.easeOfExploitationName
              ? row?.original?.easeOfExploitationName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "riskRatingName",
        header: "Risk Rating",
        Cell: ({ row }) => {
          return row?.original?.riskRatingName ? (
            <Chip
              size="small"
              label={row?.original?.riskRatingName}
              variant="outlined"
              sx={{
                color:
                  getChipDesign(row?.original?.riskRatingName, themeMode)
                    ?.color || "black",
                border:
                  "1.5px solid " +
                  getChipDesign(row?.original?.riskRatingName, themeMode)
                    ?.color,
              }}
            />
          ) : (
            ""
          );
        },
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        Cell: ({ row }) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
      {
        accessorKey: "noOfEvidences",
        header: "No Of Evidences",
      },
      {
        accessorKey: "clientStatusName",
        header: "Client Status",
      },
      {
        accessorKey: "internalStatusName",
        header: "SM Status",
      },
      {
        accessorKey: "testerStatusName",
        header: "Tester Status",
      },
      {
        accessorKey: "stageName",
        header: "Stage",
      },
      {
        accessorKey: "createdByName",
        header: "Added By",
      },
      ...(SCAN_TYPE?.CONFIGURATION_AUDIT === props?.scanDetails?.scanTypeCode ||
      SCAN_TYPE?.VACA === props?.scanDetails?.scanTypeCode
        ? [
            {
              accessorKey: "complianceStatus",
              header: "Compliance Status",
            },
          ]
        : []),
    ],
    []
  );

  const handleDownloadTemplate = (normalUpload: boolean | string) => {
    dispatch(downlaodNessustemplate(normalUpload));
  };

  useEffect(() => {
    if (Object.keys(downlaodNessusTemplateResponse)?.length > 0) {
      const { fileContent, fileName } = downlaodNessusTemplateResponse;
      downloadTemplateFile(fileContent, fileName);
      return () => {
        dispatch(downlaodNessusTemplateSuccess({}));
      };
    }
  }, [downlaodNessusTemplateResponse]);

  const handleReset = () => {
    setFilterApplied(false);
    const resetData: any = {
      page,
      size: rowsPerPage,
      scanId: params?.scanId,
      clientOrgId,
    };
    setScanFilter({
      scanType: "",
      scanSubType: "",
      scanName: "",
      scanRefNo: "",
    });
    setSelectedRiskRating([]);
    setSelectedConsultantStatus([]);
    setSelectedSecurityManagerName([]);
    setSelectedComplianceStatus({});
    setSelectedClientStatus([]);
    setFilters({ assetType: "", assetSubType: "", assetURL: "", assetId: "", assetTypeName: "" });
    setDateFilter({ startDate: null, endDate: null });
    dispatch(getScanVulnerabilities(resetData));
  };

  const handleApplyFilters = () => {
    setFilterApplied(true);
    const filterData = {
      page: page,
      scanId: params?.scanId,
      clientOrgId,
      size: rowsPerPage,
      ...(selectedConsultantStatus?.length > 0 && {
        consultantStatus: selectedConsultantStatus
          ?.map((mode: any) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedSecurityManagerName?.length > 0 && {
        internalStatus: selectedSecurityManagerName
          ?.map((risk: any) => risk.smId)
          .join(","),
      }),
      ...(selectedComplianceStatus?.complianceStatusCode && {
        complianceStatus: selectedComplianceStatus?.complianceStatusCode,
      }),
      ...(selectedTesterName?.length > 0 && {
        testerStatus: selectedTesterName
          ?.map((risk: any) => risk.testerId)
          .join(","),
      }),
      ...(selectedClientStatus?.length > 0 && {
        clientStatus: selectedClientStatus
          ?.map((mode: any) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedRiskRating?.length > 0 && {
        riskRating: selectedRiskRating
          ?.map((mode: any) => mode.riskRatingCode)
          .join(","),
      }),
      ...(filters?.assetTypeName && { assetTypeId: filters?.assetTypeName }),
      ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
      ...(filters?.assetType && { assetType: filters?.assetType }),
      ...(filters?.assetSubType && { assetSubType: filters?.assetSubType }),
      ...(filters?.assetURL && { assetUrl: filters?.assetURL }),
      ...(filters?.assetId && { assetId: filters?.assetId }),

      ...(dateFilter?.startDate && {
        createdOnStartDate: dateFilter?.startDate,
      }),
      ...(dateFilter?.endDate && {
        createdOnEndDate: dateFilter?.endDate,
      }),
    };
    dispatch(getScanVulnerabilities(filterData));
  };

  // useEffect(() => {
  //   dispatch(getScanVulnerabilities({ scanId: params?.scanId, clientOrgId }));
  // }, [uploadNessusReponse]);

  useEffect(() => {
    if (scanVulnerabilities?.data?.length) {
      dispatch(getScanDetails(params?.scanId, clientOrgId));
    }
  }, [scanVulnerabilities]);
  const handleChangeRowSelect = (selectedIds: any) => {
    setSelectedIds(selectedIds);
  };
  const handleDeleteVulnerabilityDialog = () => {
    setOpenDeleteVulnerabilityDialog(!openDeleteAssetDialog);
  };
  const handleVulnerabilityDeleteAction = () => {
    const payload = {
      deleteIdList: selectedIds,
    };
    setSelectedIds([]);
    dispatch(
      deleteVulnerabilityToScan(params?.scanId, clientOrgId, payload)
    ).then((response: any) => {
      if (response?.status >= 200 && response?.status < 400) {
        dispatch(
          getScanVulnerabilities({
            scanId: params?.scanId,
            clientOrgId,
            page: page,
            size: rowsPerPage,
          })
        );
        dispatch(getScanDetails(params?.scanId, clientOrgId));
      }
      handleDeleteVulnerabilityDialog();
    });
  };

  const uploadCsv = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    const file = fileInput?.files?.[0];
    if (file) {
      const formData: any = new FormData();
      formData.append("file", file);
      try {
        dispatch(
          uploadNessusCsv(clientOrgId, params?.scanId, formData, normalUpload)
        ).then(() => {
          setShowError(true);
        });
      } catch (err: any) {}
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleAutoClose = () => {
    dispatch(
      smartCloseVulnerabilities({
        scanId: params?.scanId,
        clientOrgId: clientOrgId,
      })
    ).then(() => {
      // dispatch(getScanVulnerabilities(params?.scanId, clientOrgId));
    });
  };

  const handleSubmitStatus = async (): Promise<void> => {
    const vulnerabilityIdList = statusDetail?.data === "all" ? [] : selectedIds;
    const payload = {
      status: statusDetail?.status,
      vulnerabilityIdList,
      clientOrgId,
      scanId: params?.scanId,
      markFor: statusDetail?.markFor,
    };

    if (statusDetail?.data === "all" || selectedIds?.length > 0) {
      dispatch(updateVulnerabilityStatus(payload))
        .then((res) => {
          // if (res?.status >= 200 && res?.status < 400) {
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: page,
              size: rowsPerPage,
            })
          ).then(() => {
            setStatusDetail({ status: "", markFor: "", data: "" });
            setVisibleActionModal(false);
          });
          setSelectedIds([]);
          // }
        })
        .catch((error) => {
          console.error("Error updating vulnerability status:", error);
        });
    }
  };

  const handleTesterVulStatus = () => {
    const payload = {
      scanId: params?.scanId,
      clientOrgId,
    };
    dispatch(acceptTesterStatus(payload)).then((response: any) => {
      if (response?.status === 200) {
        dispatch(
          getScanVulnerabilities({
            scanId: params?.scanId,
            clientOrgId,
            page: page,
            size: rowsPerPage,
          })
        );
      }
      setUpdateVulInternalStatusDialogVisible(false);
    });
  };

  const handleTesterVulStatueDialog = () => {
    setUpdateVulInternalStatusDialogVisible(
      !updateVulInternalStatusDialogVisible
    );
  };

  useEffect(() => {
    if (!filterApplied) {
      dispatch(
        getScanVulnerabilities({
          scanId: params?.scanId,
          clientOrgId,
          page,
          size: rowsPerPage,
          ...(selectedSecurityManagerName?.length > 0 && {
            internalStatus: selectedSecurityManagerName
              ?.map((risk: any) => risk.smId)
              .join(","),
            ...(selectedTesterName?.length > 0 && {
              testerStatus: selectedTesterName
                ?.map((risk: any) => risk.testerId)
                .join(","),
            }),
          }),
          // internalStatus: selectedSecurityManagerName
        })
      );
    }
  }, [page, rowsPerPage, uploadNessusReponse]);

  useEffect(() => {
    if (filterApplied) {
      const payload = {
        size: rowsPerPage,
        page: page,
        ...(selectedConsultantStatus?.length > 0 && {
          consultantStatus: selectedConsultantStatus
            ?.map((status: any) => status.vulStatusId)
            .join(","),
        }),
        ...(selectedComplianceStatus?.complianceStatusCode && {
          complianceStatus: selectedComplianceStatus?.complianceStatusCode,
        }),
        ...(selectedClientStatus?.length > 0 && {
          clientStatus: selectedClientStatus
            ?.map((clientStatus) => clientStatus.vulStatusId)
            .join(","),
        }),
        ...(selectedRiskRating?.length > 0 && {
          riskRating: selectedRiskRating
            ?.map((risk) => risk.riskRatingCode)
            .join(","),
        }),
        ...(selectedSecurityManagerName?.length > 0 && {
          internalStatus: selectedSecurityManagerName
            ?.map((risk: any) => risk.smId)
            .join(","),
        }),
        ...(selectedTesterName?.length > 0 && {
          testerStatus: selectedTesterName
            ?.map((risk: any) => risk.testerId)
            .join(","),
        }),
        ...(filters?.assetTypeName && { assetTypeId: filters?.assetTypeName }),
        ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
        ...(filters?.assetType && { assetType: filters?.assetType }),
        ...(filters?.assetSubType && { assetSubType: filters?.assetSubType }),
        ...(filters?.assetURL && { assetURL: filters?.assetURL }),
        ...(filters?.assetId && { assetId: filters?.assetId }),
        ...(dateFilter?.startDate && {
          startDate: dateFilter?.startDate,
        }),
        ...(dateFilter?.endDate && {
          endDate: dateFilter?.endDate,
        }),
      };
      dispatch(getScanVulnerabilities(payload));
    }
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (userRoles) {
      const mappedData = userRoles?.map((item) => {
        if (item === "CO_SECURITY_MANAGER") {
          return {
            label: "Security Manager",
            value: item,
          };
        } else {
          return {
            label: "Tester",
            value: item,
          };
        }
      });
      setRoleList(mappedData);
    }
  }, [userRoles]);

  useEffect(() => {
    dispatch(getConsultantSMList());
    dispatch(getConsultantTesterList());
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          ></Stack>
        </Grid>
      </Grid>
      <Grid
        // container
        sx={{
          border: "0.5px solid #a2a2d299",
           // background: "#1D1D3A",
          padding: "30px",
          // padding: "30px",
          height: "100%",
          borderRadius: "10px",
          // color: "#fff",
        }}
      >
        {/* <Typography variant="h2">Vulnerabilities</Typography> */}
        <VmTable
          // tableTitle={""}
          enableRowSelection={true}
          enablePagination={false}
          enableBottomToolbar={false}
          tableTitle={
            <ActionButton
              handleTesterVulStatueDialog={handleTesterVulStatueDialog}
              open={open}
              selectedIds={selectedIds ?? []}
              setSelectedIds={setSelectedIds}
              setOpen={setOpen}
              handleDeleteVulnerabilityDialog={handleDeleteVulnerabilityDialog}
              uploadCsv={uploadCsv}
              enableManagerFilter={true}
              // scanVulnerabilities={scanVulnerabilities?.data || []}
              scanDetails={props?.scanDetails}
              setOpenNessusUploadDialog={setOpenNessusUploadDialog}
              handleAutoClose={handleAutoClose}
              page={page}
              rowsPerPage={rowsPerPage}
              enableAssetFilter={true}
              selectedComplianceStatus={selectedComplianceStatus}
              setSelectedComplianceStatus={setSelectedComplianceStatus}
              // enableComplianceStatusFilter={true}
              enableComplianceStatusFilter={
                props?.scanDetails?.scanTypeName === "Configuration Audit"
              }
              enableRiskRatingFilter={true}
              enableApplyFilter={true}
              enableClearFilter={true}
              enableClientStatusFilter={true}
              enableConsultantStatusFilter={false}
              enableReportedOnFilter={true}
              selectedConsultantStatus={selectedConsultantStatus}
              setSelectedConsultantStatus={setSelectedConsultantStatus}
              selectedClientStatus={selectedClientStatus}
              setSelectedClientStatus={setSelectedClientStatus}
              selectedRiskRating={selectedRiskRating}
              setSelectedRiskRating={setSelectedRiskRating}
              handleApplyFilters={handleApplyFilters}
              handleReset={handleReset}
              setNormalUpload={setNormalUpload}
              setVisibleActionModal={setVisibleActionModal}
              enableUrlIpAddressFilter={true}
              setSelectedSecurityManagerName={setSelectedSecurityManagerName}
              selectedSecurityManagerName={selectedSecurityManagerName}
              enableTesterFilter={true}
              // consultantSMList={consultantSMList}
              // consultantTesterList={consultantTesterList}
              setSelectedTesterName={setSelectedTesterName}
              selectedTesterName={selectedTesterName}
            />
          }
          headerAction={
            <GenericFilterBar
              enableManagerFilter={true}
              assetsForScan={assetsForScan}
              enableAssetTypeFilter={true}
              // scanVulnerabilities={scanVulnerabilities?.data || []}
              scanDetails={props?.scanDetails}
              enableAssetFilter={false}
              enableScanFilter={true}
              scanTypeApi={getVACAScanTypes}
              clientOrgId={clientOrgId}
              selectedComplianceStatus={selectedComplianceStatus}
              setSelectedComplianceStatus={setSelectedComplianceStatus}
              // enableComplianceStatusFilter={true}
              enableComplianceStatusFilter={
                props?.scanDetails?.scanTypeName === "Configuration Audit"
              }
              enableRiskRatingFilter={true}
              enableApplyFilter={true}
              scanTypes={VACAscanTypes}
              enableClearFilter={true}
              enableClientStatusFilter={true}
              enableConsultantStatusFilter={false}
              enableReportedOnFilter={true}
              assetsTypes={assetsTypes}
              assetSubTypes={assetSubTypes}
              assetSubTypeApi={getAssetsSubType}
              consultantStatusValue={consultantStatusValue}
              consultantStatusApi={getConsultantStatusValue}
              riskRatingValue={riskRatingValue}
              riskRatingApi={getRiskRatingValue}
              clientStatusValue={clientStatusValue}
              clientStatusApi={getClientStatusValue}
              assetTypeApi={getAssetsType}
              filters={filters}
              getAssetsForScan={getAssetsForScan}
              setFilters={setFilters}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              selectedConsultantStatus={selectedConsultantStatus}
              setSelectedConsultantStatus={setSelectedConsultantStatus}
              selectedClientStatus={selectedClientStatus}
              setSelectedClientStatus={setSelectedClientStatus}
              selectedRiskRating={selectedRiskRating}
              setSelectedRiskRating={setSelectedRiskRating}
              handleApplyFilters={handleApplyFilters}
              handleReset={handleReset}
              enableUrlIpAddressFilter={true}
              setSelectedSecurityManagerName={setSelectedSecurityManagerName}
              selectedSecurityManagerName={selectedSecurityManagerName}
              enableTesterFilter={true}
              consultantSMList={consultantSMList}
              consultantTesterList={consultantTesterList}
              setSelectedTesterName={setSelectedTesterName}
              selectedTesterName={selectedTesterName}
              enablePublishedDateFilter={false}
              vulReference={""}
              clientOrganizationList={[]}
              selectedClientOrganization={[]}
              scanFilter={scanFilter}
              setScanFilter={setScanFilter}
              setVulReference={function (vulReference: string): void {
                throw new Error("Function not implemented.");
              }}
              setVulnerabilityName={function (vulnerabilityName: string): void {
                throw new Error("Function not implemented.");
              }}
              setSelectedClientOrganization={function (
                organization: { orgName: string; id: string }[]
              ): void {
                throw new Error("Function not implemented.");
              }}
              setSelectedDepartment={function (department: string): void {
                throw new Error("Function not implemented.");
              }}
              setSelectedAssetGroupList={function (
                assetGroupList: string
              ): void {
                throw new Error("Function not implemented.");
              }}
            />
          }
          columns={columns}
          data={scanVulnerabilities?.data || []}
          selectedIdsField={"scanVulnerabilityId"}
          selectedIds={selectedIds}
          handleChangeRowSelect={handleChangeRowSelect}
        />
        <TablePagination
          component="div"
          count={
            scanVulnerabilities?.totalRecords
              ? scanVulnerabilities?.totalRecords
              : 0
          }
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
        <DialogBox
          maxWidth="sm"
          modelTitle={"Delete Vulnerability"}
          open={openDeleteAssetDialog}
          handleClose={handleDeleteVulnerabilityDialog}
          dialogContent={
            <Delete
              handleDeleteAction={handleVulnerabilityDeleteAction}
              handleClose={handleDeleteVulnerabilityDialog}
              loading={setDataloading}
            />
          }
        />
        {uploadNessusReponse?.errors?.length > 0 ? (
          <DialogBox
            handleClose={() => setShowError(false)}
            maxWidth="sm"
            modelTitle={"File Upload Status"}
            open={showError}
            dialogContent={<ErrorDisplay errorDetails={uploadNessusReponse} />}
          />
        ) : (
          ""
        )}

        <DialogBox
          maxWidth="lg"
          modelTitle="Upload File"
          open={openNessusUploadDialog}
          handleClose={() => setOpenNessusUploadDialog(false)}
          dialogContent={
            <NessusUpload
              scanId={params?.scanId}
              clientOrgId={clientOrgId}
              normalUpload={normalUpload}
              setOpenNessusUploadDialog={setOpenNessusUploadDialog}
              handleDownloadTemplate={handleDownloadTemplate}
            />
          }
        />
        <DialogBox
          maxWidth="md"
          modelTitle={"Update Status"}
          open={visibleActionModal}
          handleClose={() => setVisibleActionModal(false)}
          dialogContent={
            <ActionModal
              data={DataListType}
              roleList={roleList}
              handleClose={() => setVisibleActionModal(false)}
              statusDetail={statusDetail}
              setStatusDetail={setStatusDetail}
              handleSubmitStatus={handleSubmitStatus}
              selectedIds={selectedIds}
              displayActionList={
                props?.scanDetails?.displayScanVulnerabilityAction
              }
            />
          }
        />
        <ConfirmUpdateInternalStatus
          open={updateVulInternalStatusDialogVisible}
          confirmAction={handleTesterVulStatus}
          handleClose={handleTesterVulStatueDialog}
          loader={setDataloading}
        />

        <Box my={2}>
          <Divider />
        </Box>
      </Grid>
      {/* <VmTable columns={visibilityColumns} data={visibilityData} /> */}
    </>
  );
};

export default ScanVulnerabilities;
