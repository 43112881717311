import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";
import { DownloadReportState } from "./reducerTypes";

// initial state
const initialState: DownloadReportState = {
  error: null,
  downloadReportList: [],
  ReportContent: {},
};

// ==============================|| SLICE - DOWNLOAD REPORT ||============================== //
const downloadReportApiEndpoint = "/client/v1/reports/";
const downloadReport = createSlice({
  name: "downloadReport",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    downloadReportListSuccess(state, action) {
      state.downloadReportList = action.payload;
    },

    ReportContentSuccess(state, action) {
      state.ReportContent = action.payload;
    },
  },
});
export default downloadReport.reducer;
export const { ReportContentSuccess } = downloadReport.actions;

export function fetchDownloadReportList() {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${downloadReportApiEndpoint}asset-group-report-list`
      );
      dispatch(downloadReport.actions.downloadReportListSuccess(response.data));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}

export function getReportContent(payload: {
  reportCode: string;
  rawData: unknown;
}) {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${downloadReportApiEndpoint}asset-group/${payload?.reportCode}/${payload?.rawData}`
      );
      dispatch(downloadReport.actions.ReportContentSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
