import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getScanTestCasesList,
  refreshTheTestData,
  updateTestData,
} from "../../../../../../../store/slices/scanTestReducer";
import VmTable from "../../../../../../../ui-component/table";
import { Chip, Grid, IconButton } from "@mui/material";
import UpdateTestCaseModal from "../updateTestDetail";
import DialogBox from "../../../../../../../ui-component/dialogbox";
// import { getChipDesign } from "../../../../../../../utils/helpers";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { DataListType } from "../../../constant";
import TestPlanGenericFilter from "../../../../../../../views/Common/testPlanGenericFilter";
import { getRiskRatingValue } from "../../../../../../../store/slices/vulnerabilityReducer";
import { LoadingButton } from "@mui/lab";
import { Filters, Props } from "../../../scanTypes";
import { dispatch, RootState } from "src/store";
import FormControlSelect from "src/ui-component/extended/Form/FormControlSelect";
import { getAllTypes } from "src/store/slices/masterTestPlanReducer";

const ActionButton = (props: {
  handleRefresh: () => void;
  setVisibleActionModal: (value: boolean) => void;
  scanDetails: { testPlanAction?: boolean };
  enableRiskRatingFilter: boolean;
  enableTestResultFilter: boolean;
  enableTestPlanNameFilter: boolean;
  enableApplyFilter: boolean;
  enableClearFilter: boolean;
  riskRatingValue: any;
  filters: any;
  riskRatingApi: () => any;
  setFilters: (filters: any) => void;
  handleApplyFilters: () => void;
  handleReset: () => void;
  setTestPlanTypeId: (testPlanTypeId: number) => void;
  testPlanTypeId: number;
  clientOrgId: number;
  scanId: number;
  allTypes: any;
  setTestPlanTypeName: any;
  testPlanTypeName: () => void;
}) => {
  const handleSelectChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const testPlanTypeId = event.target.value as number;
    props?.setTestPlanTypeId(testPlanTypeId);
    const selectedTestPlan = props?.allTypes?.find(
      (item: any) => item.testPlanTypeId === testPlanTypeId
    );
    const testPlanTypeDesc = selectedTestPlan
      ? selectedTestPlan.testPlanTypeDesc
      : "Not Found";
    props?.setTestPlanTypeName(testPlanTypeDesc);
    const payload = {
      scanId: props?.scanId,
      clientOrgId: props?.clientOrgId,
      testPlanType: testPlanTypeId,
    };
    if (testPlanTypeId) {
      dispatch(getScanTestCasesList(payload));
    }
  };

  return (
    <Grid
      xs={12}
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
        paddingBottom: "10px",
      }}
    >
      <Grid item>
        <FormControlSelect
          itemsList={props?.allTypes}
          optionLabel="testPlanTypeDesc"
          name="testPlanTypeDesc"
          optionValue="testPlanTypeId"
          placeholder="Select Test Plan Type"
          size="large"
          onChange={handleSelectChange}
          value={props?.testPlanTypeId}
        />
      </Grid>
      <Grid spacing={1} item>
        <LoadingButton
          size="small"
          variant="contained"
          type="submit"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          onClick={props?.handleRefresh}
        >
          {"Refresh"}
        </LoadingButton>
        {props?.scanDetails?.testPlanAction && (
          <IconButton
            title="Actions"
            size="large"
            aria-label="live customize"
            name="open"
            onClick={() => props?.setVisibleActionModal(true)}
            color="inherit"
          >
            <FileOpenIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const TestCase = ({ scanDetails }: Props) => {
  const params: any = useParams();
  const { scanTestList } = useSelector(
    (state: RootState) => state?.scanTestState
  );
  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const [selectedIds, setSelectedIds] = useState<number[] | any>([]);
  const [visibleActionModal, setVisibleActionModal] = useState<boolean | any>(
    false
  );
  const [filters, setFilters] = useState<Filters>({
    selectedRiskRating: [],
    selectedTestResult: [],
    selectedTestPlanName: "",
  });
  const [statusDetail, setStatusDetail] = useState({
    data: "",
    testResult: "",
    testRiskRating: "",
  });
  const [testPlanTypeId, setTestPlanTypeId] = useState(1);
  const [testPlanTypeName, setTestPlanTypeName] = useState<any>("");
  const { allTypes } = useSelector((state: RootState) => state.masterTestPlan);
  const { riskRatingValue } = useSelector(
    (state: RootState) => state?.vulnerabilityState
  );

  const updatedData = scanTestList?.map((item: any) => ({
    ...item,
    owaspCategoryDesc: item.owaspCategoryDesc
      ? item.owaspCategoryDesc
      : "Miscellaneous",
  }));

  const handleChangeRowSelect = (selectedIds: number) => {
    setSelectedIds(selectedIds);
  };
  const handleReset = () => {
    const resetData: {
      scanId: number;
      clientOrgId: number;
      testPlanType: number;
    } = {
      scanId: params?.scanId,
      clientOrgId: clientOrgId,
      testPlanType: testPlanTypeId,
    };
    setFilters({
      selectedRiskRating: [],
      selectedTestResult: [],
      selectedTestPlanName: "",
    });
    dispatch(getScanTestCasesList(resetData));
  };

  const handleApplyFilters = () => {
    const filterData: any = {
      scanId: params?.scanId,
      clientOrgId,
      testPlanType: testPlanTypeId,
      ...(filters?.selectedRiskRating?.length > 0 && {
        riskRating: filters?.selectedRiskRating
          ?.map((mode: any) => mode.riskRatingCode)
          .join(","),
      }),
      ...(filters?.selectedTestResult?.length > 0 && {
        result: filters?.selectedTestResult
          ?.map((mode: any) => mode?.value)
          .join(","),
      }),
      ...(filters?.selectedTestPlanName && {
        testPlanName: filters?.selectedTestPlanName,
      }),
    };
    dispatch(getScanTestCasesList(filterData));
  };

  // Refresh the data
  const handleRefreshData = () => {
    const payload: any = {
      scanId: params?.scanId,
      clientOrgId: clientOrgId,
      testPlanType: testPlanTypeId,
    };
    dispatch(refreshTheTestData(payload)).then(() => {
      dispatch(getScanTestCasesList(payload));
    });
  };

  useEffect(() => {
    if (params?.scanId && clientOrgId) {
      dispatch(
        getScanTestCasesList({
          scanId: params?.scanId,
          clientOrgId: clientOrgId,
          testPlanType: testPlanTypeId,
        })
      );
    }
  }, [params?.scanId, clientOrgId, dispatch]);

  const handleUpdateData = async () => {
    const idsToUpdate =
      statusDetail?.data === "all"
        ? scanTestList?.map((item: any) => item?.scanTestPlanId) || []
        : selectedIds || [];
    if (idsToUpdate.length === 0) {
      console.error("No selected IDs found.");
      return;
    }

    const { scanId } = params || {};
    const payload = {
      scanId,
      clientOrgId,
      data: idsToUpdate.map((id: any) => ({
        scanTestPlanId: id,
        tpRiskRating: statusDetail?.testRiskRating || "", // Default value if undefined
        tpStatus: statusDetail?.testResult || "", // Default value if undefined
      })),
    };

    try {
      await dispatch(updateTestData(payload)); // Ensure the update completes before fetching new data

      await dispatch(
        getScanTestCasesList({
          scanId,
          clientOrgId,
          testPlanType: testPlanTypeId,
        })
      ); // Fetch updated data

      setStatusDetail({ data: "", testResult: "", testRiskRating: "" });
      setVisibleActionModal(false);
    } catch (error) {
      console.error("Error updating test data:", error);
    }
  };
  useEffect(() => {
    dispatch(getAllTypes());
  }, []);

  const columns = [
    {
      accessorKey: "testPlanDesc",
      header: "Test Plan",
      size: 500,
      Cell: ({ row }: any) => (
        <>
          <span style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {row?.original?.testPlanDesc}
          </span>
        </>
      ),
    },
    ...(testPlanTypeName === "Miscellaneous"
      ? []
      : [
          {
            accessorKey: "categoryDesc",
            header:
              testPlanTypeName === "" ? "OWASP Category" : testPlanTypeName,
          },
        ]),
    {
      accessorKey: "testResultDesc",
      header: "Result",
      Cell: ({ row }: any) => {
        return row?.original?.testResultDesc ? (
          <Chip
            size="small"
            label={
              row?.original?.testResultDesc
              // row?.original?.testResultDesc === "S"
              //   ? "Safe"
              //   : row?.original?.testResultDesc === "U"
              //   ? "Unsafe"
              //   : "Not Applicable"
            }
            variant="outlined"
          />
        ) : (
          ""
        );
      },
      size: 20,
    },
    {
      accessorKey: "testRiskRatingDesc",
      header: "Risk Rating",
      Cell: ({ row }: any) => {
        const riskRating = row?.original?.testRiskRatingDesc;
        return riskRating ? (
          <Chip
            size="small"
            label={row?.original?.testRiskRatingDesc}
            variant="outlined"
            // sx={{
            //   color:
            //     getChipDesign(row?.original?.testRiskRatingDesc)?.fontColor ||
            //     "black",
            //   border:
            //     "1.5px solid " +
            //     (getChipDesign(row?.original?.testRiskRatingDesc)?.fontColor ||
            //       "black"),
            // }}
          />
        ) : null;
      },
      size: 20,
    },
    {
      accessorKey: "updatedOn",
      header: "Updated On",
      Cell: ({ row }: any) => (
        <>
          {row?.original?.updatedOn &&
            moment(row.original.updatedOn).format("Do MMM YYYY")}
        </>
      ),
      size: 20,
    },
    // {
    //   accessorKey: "actions",
    //   header: "Actions",
    //   size: 50,
    //   Cell: ({ row }) => (
    //     <IconButton title="Edit" onClick={() => setVisibleActionModal(row.original)}>
    //       <EditIcon sx={style?.icon} />
    //     </IconButton>
    //   ),
    // },
  ];

  return (
    <>
      <Grid
        // container
        sx={{
          border: "0.5px solid #a2a2d299",
           // background: "#1D1D3A",
          padding: "30px",
          // padding: "30px",
          height: "100%",
          borderRadius: "10px",
          // color: "#fff",
        }}
      >
        <VmTable
          enableRowSelection={true}
          enablePagination={false}
          enableBottomToolbar={false}
          columns={columns}
          data={updatedData || []}
          headerAction={
            <ActionButton
              setTestPlanTypeName={setTestPlanTypeName}
              testPlanTypeName={testPlanTypeName}
              testPlanTypeId={testPlanTypeId}
              setTestPlanTypeId={setTestPlanTypeId}
              clientOrgId={clientOrgId}
              scanId={params?.scanId}
              allTypes={allTypes}
              handleRefresh={handleRefreshData}
              setVisibleActionModal={setVisibleActionModal}
              scanDetails={scanDetails}
              enableRiskRatingFilter={true}
              enableTestResultFilter={true}
              enableTestPlanNameFilter={true}
              enableApplyFilter={true}
              enableClearFilter={true}
              riskRatingValue={riskRatingValue}
              riskRatingApi={getRiskRatingValue}
              filters={filters}
              setFilters={setFilters}
              handleApplyFilters={handleApplyFilters}
              handleReset={handleReset}
            />
          }
          selectedIdsField={"scanTestPlanId"}
          selectedIds={selectedIds}
          handleChangeRowSelect={handleChangeRowSelect}
        />
        <DialogBox
          maxWidth="md"
          modelTitle={"Update Test Detail"}
          open={visibleActionModal}
          handleClose={() => setVisibleActionModal(false)}
          dialogContent={
            <UpdateTestCaseModal
              DataListType={DataListType}
              handleClose={() => setVisibleActionModal(false)}
              statusDetail={statusDetail}
              setStatusDetail={setStatusDetail}
              handleSubmitStatus={handleUpdateData}
              selectedIds={selectedIds}
            />
          }
        />
      </Grid>
    </>
  );
};

const style = {
  icon: {
    cursor: "pointer",
  },
};

export default TestCase;
