import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Stack,
  ListItemIcon,
  TextField,
  IconButton,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { handleTrim } from "../../../../../../../utils/helpers";
import { ReportConfigListProps } from "../../../scanTypes";

const ReportConfigList: React.FC<ReportConfigListProps> = ({
  data,
  title,
  handleSave,
  url,
  listAction,
  label,
  isEdit,
  allowUpdate,
  getDetail
}) => {
  const [newItemText, setNewItemText] = useState("");
  const [listItems, setListItems] = useState(data);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showList, setShowList] = useState(isEdit);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableDeleteIcon, setDisableDeleteIcon] = useState(false);

  const handleAddNewItem = () => {
    if (handleTrim(newItemText)) {
      if (editingIndex !== null) {
        const updatedList = [...listItems];
        updatedList[editingIndex] = newItemText;
        setListItems(updatedList);
        setEditingIndex(null);
      } else {
        setListItems([...listItems, newItemText]);
      }
      setNewItemText("");
    }
  };

  const handleDeleteItem = (index: number) => {
    const updatedList = [...listItems];
    updatedList.splice(index, 1);
    setListItems(updatedList);
    setEditingIndex(null);
  };

  const handleEditItem = (value: string, index: number) => {
    const updatedList = [...listItems];
    updatedList[index] = value;
    setListItems(updatedList);
  };

  const listItemStyle = {
    fontSize: "25px",
  };

  const handleSubmit = (name: string, data: string[], url: string, label: string) => {
    const updateData = data?.map((val) => ({ [label]: val }));
    if (newItemText) {
      handleSave(name, [...updateData, { [label]: newItemText }], url);
      setNewItemText("");
      setShowList(false);
      return;
    }
    if (!(data?.length > 0) && !newItemText) {
      handleSave(name, updateData, url);
      return;
    }
    
    handleSave(name, updateData, url);
    setShowList(!showList);
    setNewItemText("");
  };
  useEffect(() => {
    setListItems(data);
    setShowList(isEdit);
  }, [data]);

  useEffect(() => {
    if (listItems?.length === 1 && listItems[0] === "") {
      setDisableSaveBtn(true);
    } else {
      setDisableSaveBtn(false);
    }
    if (listItems?.length === 1) {
      // setDisableDeleteIcon(true);
    } else {
      setDisableDeleteIcon(false);
      setDisableSaveBtn(false);
    }
  }, [listItems]);
  const hanldeEditOption = () => {
    if (showList) {
      getDetail()
    }
    setShowList(!showList);
  }

  return (
    <Stack direction="column" gap={2}>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        direction={"row"}
      >
        <Typography variant="h5">{title}</Typography>
        {allowUpdate ? (
          <IconButton
            // variant="contained"
            size="large"
            onClick={
              hanldeEditOption
            }
            color="inherit"
            disableRipple
            aria-label="live customize"
          >
            {!showList ? <ModeEditIcon /> : <CloseIcon />}
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
      {showList ? (
        <List>
          {listItems?.map((item, index) => (
            <ListItem key={index}>
              <TextField
                value={item}
                onChange={(e) => handleEditItem(e.target.value, index)}
                sx={{ width: "90%" }}
              />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Button
                  onClick={() => handleDeleteItem(index)}
                  disabled={disableDeleteIcon}
                >
                  <DeleteIcon />
                </Button>
              </Stack>
            </ListItem>
          ))}
          <ListItem>
            <Stack
              sx={{ width: "90%" }}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <TextField
                value={newItemText}
                onChange={(e) => setNewItemText(e.target.value)}
                sx={{ width: "90%" }}
                placeholder="New Item"
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Button onClick={handleAddNewItem}>
                  <AddIcon />
                </Button>
              </Stack>
            </Stack>
          </ListItem>
        </List>
      ) : (
        <List>
          {listItems?.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
              </ListItemIcon>
              <ListItemText primary={item} style={listItemStyle} />
            </ListItem>
          ))}
        </List>
      )}
      {showList && allowUpdate && (
        <IconButton
          // variant="contained"
          color="inherit"
          disableRipple
          aria-label="live customize"
          size="large"
          onClick={() => {
            handleSubmit(listAction, listItems, url, label);
          }}
          // disabled={disableSaveBtn}
          sx={{ alignSelf: "flex-end" }}
        >
          <SaveIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ReportConfigList;
