import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputLabel, Stack, TextField, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { dispatch, RootState, useSelector } from "../../../../../../../store";
import {
  generateScanReport,
  getScanReportHistory,
} from "../../../../../../../store/slices/scanReducer";
import { GenerateReportProps } from "../../../scanTypes";
import theme from "src/themes/theme";

const GenerateReport: React.FC<GenerateReportProps> = ({
  reportFileFormat,
  handleCloseDialog,
  scanReferenceNo,
}) => {
  const [reportName, setReportName] = useState(
    scanReferenceNo +
      ` | ${
        reportFileFormat?.charAt(0)?.toUpperCase() +
        reportFileFormat?.slice(1)?.toLowerCase()
      }`
  );
  const params: any = useParams();
  const clientOrgId: any = useMemo(
    () => sessionStorage.getItem("clientOrgId"),
    []
  );
  const setDataloading = useSelector(
    (state: RootState) => state?.userState?.setDataloading
  );

  const handleGenerateReport = useCallback(() => {
    const payload = {
      reportName: reportName,
      scanId: params?.scanId,
      clientOrgId: clientOrgId,
      reportFileFormat: reportFileFormat,
    };
    dispatch(generateScanReport(payload))
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(getScanReportHistory(params?.scanId, clientOrgId));
          handleCloseDialog();
        }
      })
      .catch(() => {});
  }, [reportName, params, clientOrgId, reportFileFormat, handleCloseDialog]);


  const handleReportNameChange = useCallback((e: any) => {
    setReportName(e.target.value);
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <InputLabel>Report Name</InputLabel>
        <TextField
          size="medium"
          type="text"
          fullWidth
          value={reportName}
          onChange={handleReportNameChange}
        />
      </Stack>

      <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px !important",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          onClick={handleGenerateReport}
          loading={setDataloading}
        >
          Generate
        </LoadingButton>
      </Stack>
    </>
  );
};

export default GenerateReport;
