export const SCAN_TYPE = {
  VAPT: "VAPT",
  VULNERABILITY_ASSESSMENT: "VA",
  CONFIGURATION_AUDIT: "CA",
  SECURE_CODE_REVIEW: "SCR",
  BLACKBOX: "BLACKBOX",
  GREYBOX: "GREYBOX",
  VACA: "VACA",
  Application: "Application",
  scanTypeCount: "scanTypeCount",
  assetTypeCount: "assetTypeCount",
};

export const SCAN_TYPE_Risk = {
  ["VAPT"]: "VAPT",
  ["Vulnerability Assessment"]: "Vulnerability Assessment",
  ["Configuration Audit"]: "Configuration Audit",
  ["Secure Code Review"]: "Secure Code Review",
  ["Blackbox"]: "Blackbox",
  ["Greybox"]: "Greybox",
};

export const NESSUS_UPLOAD_FILE_STATUS_CODE = {
  APPROVED: "A",
  VERIFICATION_PENDING: "V",
};
export const UPLOAD_ASSET_UPLOAD_STATUS = {
  WARNING: "WARNING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};

export const rowsPerPageOptions = [10, 20, 30, 40, 50, 100];

export const DataListType = [
  { label: "All", value: "all" },
  { label: "Selected", value: "selected" },
];

export const actionType = [
  { label: "Open", value: "O" },
  { label: "Close", value: "C" },
  { label: "False Positive", value: "FP" },
  { label: "Rework", value: "RT" },
  { label: "Review", value: "RV" },
  { label: "Work In Progress", value: "WIP" },
];

export const NEW_CLIENT_STATUS = [
  {
    label: "False Positive",
    value: "FALSE_POSITIVE",
  },
  {
    label: "Fixed",
    value: "FIXED",
  },
];
