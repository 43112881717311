import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isLoading, setLoading } from "./user";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";

// Define types for the state
interface ConsultantMappingState {
  error: string | null;
  organizationList: unknown[]; // Update `any` to a specific type if you know the structure
  selectedOrganizationDetails: Record<string, unknown>; // Replace `any` with specific key-value structure
  unmappedConsultantList: unknown[] | any; // Update if you know the structure
  mappedConsultantList: unknown[] | any; // Update if you know the structure
  addUnmappedConsultantDetail: unknown[]; // Update if you know the structure
}

// Define initial state
const initialState: ConsultantMappingState = {
  error: null,
  organizationList: [],
  selectedOrganizationDetails: {},
  unmappedConsultantList: [],
  mappedConsultantList: [],
  addUnmappedConsultantDetail: [],
};

// Define slice
const slice = createSlice({
  name: "consultantMapping",
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    getOrganizationList(state, action) {
      state.organizationList = action.payload;
    },
    getSelectedOrganizationDetails(
      state,
      action
    ) {
      state.selectedOrganizationDetails = action.payload;
    },
    getUnmappedConsultantList(state, action) {
      state.unmappedConsultantList = action.payload;
    },
    getMappedConsultantList(state, action) {
      state.mappedConsultantList = action.payload;
    },
    addconsultantData(state, action) {
      state.addUnmappedConsultantDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Define API types
interface FetchOrganizationDetailsPayload {
  orgId: string;
}

interface ConsultantListParams {
  orgId: string;
  [key: string]: unknown; // Add additional query params if needed
}

interface AddUnmappedConsultantPayload {
  orgId: string;
  data: unknown; // Replace `any` with the specific type for payload
}

// Define API actions
export const fetchOrganizationList = () => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get("/consultant/v1/client-org/list");
      dispatch(slice.actions.getOrganizationList(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedOrganizationDetails = (
  payload: FetchOrganizationDetailsPayload
) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedOrganizationDetails(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchUnmappedConsultantList = (params: ConsultantListParams) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/unmapped-user/${params.orgId}`,
        {
          params,
        }
      );
      dispatch(slice.actions.getUnmappedConsultantList(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchMappedConsultantList = (params: ConsultantListParams) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/consultant/v1/client-org/mapped-user/${params.orgId}`,
        {
          params,
        }
      );
      dispatch(slice.actions.getMappedConsultantList(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const addUnmappedConsultant = (
  payload: AddUnmappedConsultantPayload
) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `/consultant/v1/client-org/map-consultant/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.addconsultantData(response.data));
      notificationManager(response);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar({ message: errorMessage }));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
