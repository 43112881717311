import React from 'react';
import { createRoot } from 'react-dom/client';

// third-party imports
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import App from './App';
import { store, persister } from './store'; // Ensure `store` and `persister` types are correctly defined
import { ConfigProvider } from './contexts/ConfigContext';

// styles and assets
import '../src/assets/scss/style.scss';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// google fonts
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

// ==============================|| REACT DOM RENDER ||============================== //

// Get the root container and ensure it is not null
const container = document.getElementById('root');

if (container) {
    const root = createRoot(container); // `createRoot` expects a non-null HTMLElement
    root.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persister}>
                <ConfigProvider>
                    <App />
                </ConfigProvider>
            </PersistGate>
        </Provider>
    );
} else {
    console.error('Root container not found.');
}


