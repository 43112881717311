import { LAYOUT_CONST } from "./constant";
import { Config } from "./types";

// Constants
export const DASHBOARD_PATH = "/home";
export const DASHBOARD_LABEL = "/dashboard";
export const HORIZONTAL_MAX_ITEM = 7;

// Define the type for the config object
// Config object with proper types
const config: Config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.MINI_DRAWER, // default, mini-drawer
  fontFamily: `'Inter', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "dark", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export const outlineButtonStyle = {
  fontFamily: "Poppins",
  borderRadius: "10px",
  fontWeight: "500",
  fontSize: "18px",
  color: " #F45F43",
  border: "1px solid",
  height: "50px",
  borderImageSlice: 1,
  textTransform: "none",
  "&:hover": {
    border: "1px solid",
    borderRadius: "10px",
    color: "#F45F43",
    borderImageSlice: 1,
  },
};
export const buttonStyle = {
  fontFamily: "Poppins",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "27px",
  letterSpacing: "0px",
  color: "white",
  width: "105px",
  height: "50px",
  borderRadius: "10px !important",
  background:
    "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
  textTransform: "none", // This removes the uppercase transformation
};

export default config;
