import React from "react";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch, RootState } from "../../../../../../store";
import { getAffectedAssets } from "../../../../../../store/slices/scanReducer";
import { addVulnerability } from "../../../../../../store/slices/vulnerabilityReducer";
// import Accordion from "../../../../../../ui-component/extended/Accordion";
import VmTable from "../../../../../../ui-component/table";
import { NO_VALUE } from "../../../../../../constant";
import {
  AddAffectedAssetsProps,
  Asset,
  VulnerabilityPayload,
} from "../../vulnerabiltyTypes";
import theme from "src/themes/theme";

const AddAffectedAssets: React.FC<AddAffectedAssetsProps> = (props) => {
  const [selectedIds, setSelectedIds] = useState<string[] | undefined>([]);
  const [affectedAssetsList, setAffectedAssetsList] = useState<Asset[]>([]);
  const params: any = useParams();
  // const theme = useTheme();
  const navigate = useNavigate();
  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const { affectedAssets } = useSelector(
    (state: RootState) => state?.scansState
  );
  useEffect(() => {
    dispatch(getAffectedAssets(params?.scanId, clientOrgId));
  }, []);
  useEffect(() => {
    setAffectedAssetsList(affectedAssets?.data ?? []);
  }, [affectedAssets]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "assetName",
        header: "Asset Name",
        size: 150,
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
        size: 150,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ cell, row }: any) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        size: 150,
        Cell: ({ row }: any) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
    ],
    []
  );
  const handleChangeRowSelect = (selectedIds: string[]) => {
    setSelectedIds(selectedIds);
  };
  const handleSubmit = () => {
    const payload: VulnerabilityPayload = {
      ...props?.vulnerabilityDetails,
      assetIdList: selectedIds,
      scanId: params?.scanId,
      vmId: props?.vulnerabilityDetails?.vulnerabilityId,
      owaspcategory: props?.vulnerabilityDetails?.owaspCategory,
    };
    if (payload?.vulnerabilityId) {
      delete payload.vulnerabilityId;
    }

    dispatch(addVulnerability(payload))
      .then((res: any) => {
        if (res && res.status === 200) {
          handleGoBack();
        }
      })
      .catch(() => {});
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const AssetsList = affectedAssetsList?.map((val) => {
    return { ...val.assetDetails };
  });

  return (
    <>
      {" "}
      {/* <Accordion data={basicData} defaultExpandedId={true} /> */}
      <VmTable
        enableRowSelection={true}
        columns={columns}
        data={AssetsList || []}
        selectedIdsField={"assetId"}
        selectedIds={selectedIds}
        handleChangeRowSelect={handleChangeRowSelect}
      />
      <Stack
        mt={3}
        justifyContent={"flex-end"}
        width={"100%"}
        direction={"row"}
        spacing={2}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            fontFamily: "Poppins",
            borderRadius: "10px",
            fontWeight: "500",
            fontSize: "18px",
            color: " #F45F43",
            border: "1px solid",
            height: "50px",
            borderImageSlice: 1,
            textTransform: "none",
            "&:hover": {
              border: "1px solid",
              borderRadius: "10px",
              color: "#F45F43",
              borderImageSlice: 1,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedIds?.length}
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            letterSpacing: "0px",
            color: "white",
            width: "105px",
            height: "50px",
            borderRadius: "10px !important",
            background:
              "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
            textTransform: "none", // This removes the uppercase transformation
          }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};

export default AddAffectedAssets;
