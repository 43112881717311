import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { gridSpacing } from "../../../../../store/constant";
import MainCard from "../../../../../ui-component/cards/MainCard";
import AddAffectedAssets from "./addAffectedAssets";
import AddVulnerabilityDetails from "./addVulnerabilityDetails";
import { TabPanelProps } from "../vulnerabiltyTypes";
import theme from "src/themes/theme";

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

function a11yProps(index: string | number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddVulnerability: React.FC = () => {
  const params = useParams();
  const [value, setValue] = useState(0);
  const [vulnerabilityDetails, setVulnerabilityDetails] = useState<{
    vulnerabilityId: string;
    owaspCategory: string;
  }>({
    vulnerabilityId: "",
    owaspCategory: "",
  });

  // Ensure default values to prevent undefined errors
  const displayFieldData = sessionStorage.getItem("displayVulnerabilityField");
  const displayVulnerabilityField = displayFieldData
    ? JSON.parse(displayFieldData)
    : {};
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeTab = () => {
    setValue(1);
  };

  return (
    <Container maxWidth="lg">
      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="scrollable"
              sx={{
                mb: 3,
                "& a": {
                  minHeight: "auto",
                  minWidth: 10,
                  py: 1.5,
                  px: 1,
                  mr: 2.25,
                  color:
                    theme.palette.mode === "dark" ? "grey.600" : "grey.900",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& a.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                "& .MuiTabs-indicator": {
                  bottom: 2,
                },
                "& a > svg": {
                  marginBottom: "0px !important",
                  mr: 1.25,
                },
              }}
            >
              <Tab
                key={0}
                component={Link}
                to="#"
                label={"Detail"}
                {...a11yProps(0)}
              />

              {!params?.assetId && !displayVulnerabilityField?.singleAsset && (
                <Tab
                  key={1}
                  disabled={value === 0}
                  component={Link}
                  to="#"
                  label={"Affected Assets"}
                  {...a11yProps(1)}
                />
              )}
            </Tabs>

            <TabPanel value={value} index={0}>
              <AddVulnerabilityDetails
                handleChangeTab={handleChangeTab}
                vulnerabilityDetails={vulnerabilityDetails}
                setVulnerabilityDetails={setVulnerabilityDetails}
                singleAsset={displayVulnerabilityField?.singleAsset} // Ensure it's a boolean
              />
            </TabPanel>

            {!params?.assetId && !displayVulnerabilityField?.singleAsset && (
              <TabPanel value={value} index={1}>
                <AddAffectedAssets
                  vulnerabilityDetails={vulnerabilityDetails}
                  setVulnerabilityDetails={setVulnerabilityDetails}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </Container>
  );
};

export default AddVulnerability;
