import { dispatch } from "../../store";
import { openSnackbar } from "../../store/slices/snackbarReducer";

// Define the type for the `res` parameter
interface ApiResponse {
  [key: string]: any | unknown | string | number;
  // status?: number;
  // data?: {
  //   responseMessage?: string;
  // };
  // message?: string;
}

// Define the `notificationManager` function
export const notificationManager = (res: ApiResponse, successAlert?: string | null): void => {
  let alert: { color: 'success' | 'error'; message: string } | undefined;

  switch (res?.status) {
    case 200:
      alert = {
        color: "success",
        message:
          res?.data?.responseMessage ||
          successAlert ||
          "Operation executed successfully.",
      };
      break;
    case 401:
      alert = {
        color: "error",
        message: res?.data?.responseMessage || "Unauthorized access.",
      };
      break;
    case 400:
      alert = {
        color: "error",
        message: res?.message || "Bad request.",
      };
      break;
    default:
      alert = {
        color: "error",
        message: "An unknown error occurred.",
      };
      break;
  }

  if (alert) {
    dispatch(
      openSnackbar({
        open: true,
        message: alert.message,
        transition: "Fade",
        variant: "alert",
        alert: {
          color: alert.color,
          variant: "filled",
        },
      })
    );
  }
};
