import React from "react";
import { Link as RouterLink } from "react-router-dom";

// Material-UI components
import { Link } from "@mui/material";

// Project imports
import Logo from "../../../ui-component/Logo";
import { DASHBOARD_PATH } from "src/config";
import { dispatch } from "src/store";
import { getMenutitleForHeader } from "src/store/slices/menu";

// ==============================|| LOGO SECTION ||============================== //

/**
 * Handles setting the menu title to "Home" when the logo is clicked.
 */
const handleMenuSelection = () => {
  dispatch(getMenutitleForHeader("Home"));
};

const LogoSection: React.FC = () => (
  <Link
    component={RouterLink}
    to={DASHBOARD_PATH}
    aria-label="Armor logo"
    onClick={handleMenuSelection}
  >
    <Logo />
  </Link>
);

export default LogoSection;
