import React from "react";
import AddCommentIcon from '@mui/icons-material/AddComment';
import { IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dispatch, RootState } from '../../../../../../store';
import { addScanComment, deleteScanComment, getScanComments, uploadNewScanComment } from '../../../../../../store/slices/scanReducer';
import MainCard from '../../../../../../ui-component/cards/MainCard';
import Delete from '../../../../../../ui-component/delete';
import DialogBox from '../../../../../../ui-component/dialogbox';
import AddComments from '../../../../../../views/Common/addComment';
import Comment from './comment';
import { ScanCommentData, ScanCommentProps } from '../../scanTypes';
import theme from "src/themes/theme";
import { FileData } from "src/views/pages/client/scan/ScanTypes";

const ScanComment: React.FC<ScanCommentProps> = (props) => {
  // const theme = useTheme();
  const params: any = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState<boolean>(false);
  const [scanCommentsList, setScanCommentsList] = useState<ScanCommentData[] | any>([]);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null | any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<FileData[]>([]);
  const { scanComments } = useSelector((state: RootState) => state?.scansState);
  const { addScanCommentResponse } = useSelector((state: RootState) => state?.scansState);
  useEffect(() => {
    setScanCommentsList(scanComments);
  }, [scanComments]);
  useEffect(() => {
    setScanCommentsList([...scanCommentsList, addScanCommentResponse]);
  }, [addScanCommentResponse]);
  useEffect(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
    dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
    // }
  }, [params?.scanId]);
  const handleCommentsModel = () => {
    setOpen(!open);
  };

  const handleSubmitComment = (comment: string) => {
    const payload: any = {
      scanId: params?.scanId,
      isLocked: true,
      clientDisplay: true,
      commentDesc: comment,
      createdOn: moment().toISOString(),
      createdBy: 1,
      clientOrgId: props?.scanDetails.clientOrgId,
    };
    dispatch(addScanComment(payload)).then((res: any) => {
      const resData: any[] = [];
      if (uploadedFiles?.length) {
        uploadedFiles?.map((item) => {
          const formData = new FormData();
          formData.append('file', item.file);
          if (res && res?.scanCommentId) {
            const payload: any = {
              commentId: res?.scanCommentId,
              clientOrgId: props?.scanDetails.clientOrgId,
              file: formData,
            };
            dispatch(uploadNewScanComment(payload)).then((res: any) => {
              resData.push(res?.data);
              if (uploadedFiles?.length === resData?.length) {
                dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
                handleCommentsModel();
              }
            });
          }
        });
      } else {
        // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
          dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
          // }
      }
    });
  };
  const handleManageDeleteModel = () => {
    setDeleteModelOpen(!deleteModelOpen);
  };
  const handleCommentDeleteAction = () => {
    dispatch(deleteScanComment(selectedCommentId, props?.scanDetails.clientOrgId));
    setTimeout(() => {
      // if (userActionAccess(ACTION_TYPE?.GET_SCAN_CMT_LIST)) {
        dispatch(getScanComments(params?.scanId, props?.scanDetails.clientOrgId));
        // }
    }, 200);
    handleManageDeleteModel();
  };

  return (
    <MainCard
      sx={{
        // background: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.grey[50],
      }}
    >
      <Stack
        mb={3}
        justifyContent={'flex-end'}
        width={'100%'}
        direction={'row'}
      >
        {props?.scanDetails?.displayScanVulnerabilityAction
          ?.showAddScanComment ? (
          <IconButton
            title="Comment"
            // variant="contained"
            color="inherit"
            size="large"
            aria-label="live customize"
            onClick={handleCommentsModel}
          >
            <AddCommentIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
      {scanCommentsList && scanCommentsList?.length ? (
        <>
          {scanCommentsList[0].scanCommentId &&
            scanCommentsList?.map((item: any) => (
              <>
                {item.scanCommentId && (
                  <Comment
                    item={item}
                    handleManageDeleteModel={handleManageDeleteModel}
                    setSelectedCommentId={setSelectedCommentId}
                  />
                )}
              </>
            ))}
        </>
      ) : (
        <></>
      )}
      <DialogBox
        maxWidth="md"
        modelTitle={'Add Comments'}
        open={open}
        handleClose={handleCommentsModel}
        dialogContent={
          <AddComments
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            handleCommentsModel={handleCommentsModel}
            handleSubmitComment={handleSubmitComment}
          />
        }
      />
      <DialogBox
        maxWidth="sm"
        modelTitle={'Delete Comment'}
        open={deleteModelOpen}
        handleClose={handleManageDeleteModel}
        dialogContent={
          <Delete
            handleDeleteAction={handleCommentDeleteAction}
            handleClose={handleManageDeleteModel}
          />
        }
      />
    </MainCard>
  );
};

export default ScanComment;
