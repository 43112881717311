import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Chip,
  createTheme,
} from "@mui/material";
import { IconMenu2, IconMoon, IconSun } from "@tabler/icons-react";
import { useSelector } from "src/store";
import { dispatch } from "src/store";
import { openDrawer } from "../../../store/slices/menu";
import { getOrgDetail, getOrgDetailSuccess } from "../../../store/slices/user";
import theme from "src/themes/theme";
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import itemIcon from "../../../assets/images/darkmode_icon.svg";
import { useLocation } from "react-router";
import useConfig from "src/hooks/useConfig";

const Header: React.FC = () => {
  const location = useLocation();
  const { navType, onChangeMenuType } = useConfig();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const { drawerOpen, selectMenu } = useSelector(
    (state: { menu: { drawerOpen: boolean; selectMenu: string } }) => state.menu
  );

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // const theme = createTheme({
  //   palette: {
  //     mode: isDarkMode ? "dark" : "light",
  //   },
  // });

  const formatPathname = (pathname: string) => {
    if (!pathname) return "";

    const parts = pathname.split("/").filter(Boolean); // Remove empty parts
    const lastPart = parts[parts.length - 1];

    // Check if last part is a number (ID), return an empty string if true
    if (!isNaN(Number(lastPart))) {
      return "";
    }

    return lastPart
      .replace(/-/g, " ") // Replace '-' with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };
  const handleTheme = () => {
    console.log("navTypenavType", navType);
    setIsDarkMode((prevMode) => !prevMode);

    // const isChecked = e?.target?.checked;
    // setToggleTheme(isChecked);
    // onChangeMenuType(theme?.palette?.mode === "dark" ? "light" : "dark");
    onChangeMenuType(navType === "dark" ? "light" : "dark");
    // onChangeMenuType("light");
  };
  useEffect(() => {
    console.log("navType", navType);
  }, [navType]);
  useEffect(() => {
    dispatch(getOrgDetail());
    return () => {
      dispatch(getOrgDetailSuccess({}));
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        // paddingRight: 2,
        paddingRight: "6px",
        backgroundColor: "transparent",
      }}
    >
      {/* Logo with 60px right margin */}
      <Box
      // <Box sx={{ mr: "59px", ml: drawerOpen ? "60px" : "0px" }}>

       sx={{ mr: "59px", ml: drawerOpen ? "0px" : "0px" }}>
        <LogoSection />
      </Box>

      {/* Hamburger Icon with 20px right margin */}
      <IconMenu2
        // size={"35px"}
        size={"30px"}
        style={{ cursor: "pointer", marginRight: "20px" }}
        onClick={() => dispatch(openDrawer(!drawerOpen))}
      />

      {/* Dynamic SelectMenu Text */}
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontWeight: "500",
          // fontSize: "22px",
          fontSize: "16px",
          lineHeight: "33px",
          // color: "#FFFFFF",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {selectMenu ? selectMenu : formatPathname(location?.pathname)}
      </Typography>

      {/* Spacer to push items to the right */}
      <Box sx={{ flexGrow: 1 }} />

      {/*Toggle Mode Icon */}
      <IconButton
        color="inherit"
        size="large"
        // disableRipple
        aria-label="toggle theme"
        sx={{
          width: 40,
          height: 40,
          minWidth: 40,
          background: "#F45F43 !important",
          alignItems: "center",
          // color: "#fff",
          textAlign: "center",
          borderRadius: "25px",
        }}
        onClick={handleTheme}
      >
        {isDarkMode ? <IconMoon stroke={2} /> : <IconSun stroke={2} />}
      </IconButton>

      {/* Vertical Divider */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ mx: 2, borderColor: "#383953e0" }}
      />

      {/* Profile Section */}
      <ProfileSection />
    </Box>
  );
};

export default Header;
