import React from "react";
import { Grid, InputLabel, Typography } from "@mui/material";
import MainCard from "../../../../../../../ui-component/cards/MainCard";
import { ScanAssetDetailsProps } from "../../../scanTypes";
import { NO_VALUE } from "src/constant";

const ScanAssetDetails: React.FC<ScanAssetDetailsProps> = (props) => {
  const data = props.data;
  return (
    <MainCard>
      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Asset Name:</InputLabel>
          <Typography variant="h6">{data?.assetName ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Sensitivity:</InputLabel>
          <Typography variant="h6">
            {data?.sensitivityDesc ?? NO_VALUE}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Operating system:</InputLabel>
          <Typography variant="h6">
            {data?.operatingSystemDesc ?? NO_VALUE}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Version :</InputLabel>
          <Typography variant="h6">{data?.version ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Language:</InputLabel>
          <Typography variant="h6">{data?.programLang ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Package Link:</InputLabel>
          <Typography variant="h6">{data?.packageLink ?? NO_VALUE}</Typography>
        </Grid>
      </Grid>

      {/* <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Client Name:</InputLabel>
          <Typography variant="h6">{data?.clientOrgName ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Created By:</InputLabel>
          <Typography variant="h6">{data?.createdByName ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Created On:</InputLabel>
          <Typography variant="h6">{data?.createdOn ?? NO_VALUE}</Typography>
        </Grid>
      </Grid> */}

      {/* <Grid container spacing={5} style={{ marginBottom: "10px" }}> */}
      {/* <Grid item xs={12} lg={3}>
          <InputLabel>Scan Status:</InputLabel>
          <Typography variant="h6">{data?.displayScanStatus}</Typography>
        </Grid> */}

      {/* <Grid item xs={12} lg={3}>
          <InputLabel>Organization Name:</InputLabel>
          <Typography variant="h6">{data?.organisationName ?? NO_VALUE}</Typography>
        </Grid>

        <Grid item xs={12} lg={3}>
          <InputLabel>Client Scan Status:</InputLabel>
          <Typography variant="h6">
            {data?.scanStatusClientDescription ?? NO_VALUE}
          </Typography>
        </Grid>
      </Grid> */}

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Mac Address:</InputLabel>
          <Typography variant="h6">{data?.macAddress ?? NO_VALUE}</Typography>
        </Grid>
        <Grid item xs={12} lg={3}>
          <InputLabel>Exposure:</InputLabel>
          <Typography variant="h6">{data?.exposureDesc ?? NO_VALUE}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Asset URL/IP Address:</InputLabel>
          <Typography variant="h6">{data?.assetURL}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} lg={3}>
          <InputLabel>Source System Ref No:</InputLabel>
          <Typography variant="h6">
            {data?.sourceSystemRefNo ?? NO_VALUE}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={5} style={{ marginBottom: "10px" }}>
        <Grid item xs={12}>
          <InputLabel>Location:</InputLabel>
          <Typography variant="h6">{data?.locationDesc ?? NO_VALUE}</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Description:</InputLabel>
          <Typography variant="h6">{data?.description ?? NO_VALUE}</Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ScanAssetDetails;
