import { createSlice } from "@reduxjs/toolkit";
import { isLoading, setLoading } from "./user";
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import axios from "src/utils/axios";
import { notificationManager } from "src/ui-component/notificationManager";

const platformOrganizationApi = "/platform/v1/organization";
const initialState = {
  error: null,
  organizationList: [],
  updateOrganizationDetails: {},
  selectedOrganizationDetails: {},
  newOrganizationDetails: {},
  generateAdminResponse: {},
};

const slice = createSlice({
  name: "addOrganization",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // --------------------- GET APIs --------------------------------
    getOrganizationList(state, action) {
      state.organizationList = action.payload;
    },

    getSelectedOrganizationDetails(state, action) {
      state.selectedOrganizationDetails = action.payload;
    },

    //-----------------------PUT APIS------------------------------
    updateOrganizationDetail(state, action) {
      state.updateOrganizationDetails = action.payload;
    },

    //--------------------POST APIS--------------------------
    addNewOrganizationDetails(state, action) {
      state.newOrganizationDetails = action.payload;
    },

    //--------------------POST APIS--------------------------
    generateAdminSuccess(state, action) {
      state.generateAdminResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

//-------------------------------------------------
export const fetchPlatformOrgList = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get("/platform/v1/organization/list");
      dispatch(slice.actions.getOrganizationList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const updateOrganizationData = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put("/platform/v1/organization", payload);
      dispatch(slice.actions.updateOrganizationDetail(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addNewOrganization = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post("/platform/v1/organization", payload);
      dispatch(slice.actions.addNewOrganizationDetails(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedOrganizationDetails = (payload: {
  orgId: string;
}) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `/platform/v1/organization/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedOrganizationDetails(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const generateAdmin = (payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.post(
        `${platformOrganizationApi}/generate-admin`,
        payload
      );
      dispatch(slice.actions.generateAdminSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
