import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, InputLabel, useTheme, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormControlSelect from "../../../../../../../ui-component/extended/Form/FormControlSelect";
// import LinearProgressWithLabel from "../../scanVulnerabilities/actionModal/linearLoader";
import { TEST_RESULT } from "../constant";
import { dispatch, RootState } from "../../../../../../../store";
import { getRiskRating } from "../../../../../../../store/slices/vulnerabilityReducer";
import { UpdateTestCaseModalProps } from "../../../scanTypes";
import theme from "src/themes/theme";

const UpdateTestCaseModal: React.FC<UpdateTestCaseModalProps> = ({
  DataListType,
  handleClose,
  statusDetail,
  setStatusDetail,
  handleSubmitStatus,
  selectedIds,
}) => {
  // const theme = useTheme();
  const { loading } = useSelector((state: RootState) => state?.userState);
  // const [progress, setProgress] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    data: "",
  });
  const { riskRating } = useSelector(
    (state: RootState) => state?.vulnerabilityState
  );
  const validateForm = () => {
    let valid = true;
    const newErrors = { data: "" };
    if (statusDetail.data === "selected" && selectedIds?.length <= 0) {
      newErrors.data = "Please select a test case.";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    setStatusDetail((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Fetch risk ratings on component mount
  useEffect(() => {
    dispatch(getRiskRating());
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={6}>
              <InputLabel>Data :</InputLabel>
              <FormControlSelect
                itemsList={DataListType || []}
                optionLabel={"label"}
                optionValue={"value"}
                // selected={statusDetail?.data}
                value={statusDetail?.data}
                onChange={handleOnChange}
                name="data"
                autoComplete="on"
              />
              {errors.data && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.data}
                </span>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel>Test Result</InputLabel>
              <FormControlSelect
                itemsList={TEST_RESULT || []}
                optionLabel={"label"}
                optionValue={"value"}
                // selected={statusDetail?.testResult}
                value={statusDetail?.testResult}
                onChange={handleOnChange}
                name="testResult"
                autoComplete="on"
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputLabel>Test Risk Rating</InputLabel>
              <FormControlSelect
                itemsList={riskRating || []}
                optionLabel={"riskRatingDesc"}
                optionValue={"riskRatingCode"}
                // selected={statusDetail?.testRiskRating}
                value={statusDetail?.testRiskRating}
                onChange={handleOnChange}
                name="testRiskRating"
                autoComplete="on"
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            my={2}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => handleClose()}
                sx={{
                  fontFamily: "Poppins",
                  borderRadius: "10px",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: " #F45F43",
                  border: "1px solid",
                  height: "50px",
                  borderImageSlice: 1,
                  textTransform: "none",
                  "&:hover": {
                    border: "1px solid",
                    borderRadius: "10px",
                    color: "#F45F43",
                    borderImageSlice: 1,
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                size="large"
                variant="contained"
                color="secondary"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                  letterSpacing: "0px",
                  color: "white",
                  width: "105px",
                  height: "50px",
                  borderRadius: "10px !important",
                  background:
                    "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                  textTransform: "none", // This removes the uppercase transformation
                }}
                type="submit"
                loading={loading}
                onClick={handleSubmitStatus} // Updated
                disabled={
                  !(
                    statusDetail?.testResult &&
                    statusDetail?.testRiskRating &&
                    statusDetail?.data
                  )
                }
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
          {/* Linear Progress Bar */}
          {/* {isLoading && (
            <Box sx={{ width: "100%", mt: 2 }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          )} */}
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateTestCaseModal;
