import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import { Autocomplete, Button, Chip, Menu, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { dispatch } from "../../../store";
import { TEST_RESULT } from "../../pages/consultant/scan/scanInfo/testCases/constant";
import theme from "src/themes/theme";

interface RiskRating {
  riskRatingCode: string;
  riskRatingDesc: string;
}

interface TestResult {
  value: string;
  label: string;
}

interface FilterProps {
  selectedRiskRating?: RiskRating[];
  selectedTestResult?: TestResult[];
  selectedTestPlanName?: string;
}

interface TestPlanGenericFilterProps {
  enableRiskRatingFilter?: boolean;
  enableTestResultFilter?: boolean;
  enableTestPlanNameFilter?: boolean;
  enableApplyFilter?: boolean;
  enableClearFilter?: boolean;
  riskRatingValue?: RiskRating[];
  filters: FilterProps;
  riskRatingApi?: () => void;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  handleApplyFilters?: () => void;
  handleReset?: () => void;
}

const TestPlanGenericFilter: React.FC<TestPlanGenericFilterProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState({
    riskRating: null,
    testResult: null,
    testPlanName: null,
  });

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    name: keyof typeof anchorEl
  ) => {
    setAnchorEl((prev) => ({
      ...prev,
      [name]: event?.currentTarget,
    }));
  };

  const handleClose = (name: keyof typeof anchorEl) => {
    setAnchorEl((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  // const handleClose = (name: keyof typeof anchorEl) => {
  //   if (name === "riskRating") {
  //     setAnchorEl({
  //       riskRating: null,
  //     });
  //   }
  //   if (name === "testResult") {
  //     setAnchorEl({
  //       testResult: null,
  //     });
  //   }
  //   if (name === "testPlanName") {
  //     setAnchorEl({
  //       testPlanName: null,
  //     });
  //   }
  // };

  useEffect(() => {
    if (props.enableRiskRatingFilter && props.riskRatingApi) {
      // dispatch(props.riskRatingApi());
      props.riskRatingApi();
    }
  }, []);

  // Single handleChange function for updating all filters
  const handleChange = (
    name: keyof FilterProps,
    event: React.SyntheticEvent,
    value?: any
  ) => {
    const newValue = value || (event.target as HTMLInputElement).value;
    props.setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: newValue,
    }));
  };

  const commonButtonStyles = {
    padding: "5px 12px",
    fontSize: "0.9rem",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor:
        theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor:
      theme.palette.mode === "dark" ? "white" : theme.palette.primary.light,
    color: theme.palette.mode === "dark" ? "black" : "darkblue",
  };

  const ArrowDropDownIconsCss = {
    fontSize: "20px",
    marginLeft: "5px",
  };

  const ReplayIconCss = {
    fontSize: "20px",
  };

  const buttonStyles = (condition?: boolean | number) => ({
    padding: "3px 12px",
    fontSize: "0.9rem",
    borderRadius: "7px",
    border: !condition
      ? theme.palette.mode === "dark"
        ? "2px solid white"
        : "2px solid black"
      : "none",
    "&:hover": {
      backgroundColor: !condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    marginRight: "1px",
    backgroundColor: !condition
      ? ""
      : theme.palette.mode === "dark"
      ? "white"
      : theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    color: condition
      ? theme.palette.mode === "light"
        ? "darkblue"
        : "black"
      : theme.palette.mode === "dark"
      ? "white"
      : "black",
  });

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Clears all the filters by invoking the handleReset function
   * from props if it is provided.
   */

  /******  9fed26a1-c5ff-45da-99fe-76527aa2702f  *******/
  const handleClearFilter = () => {
    if (props?.handleReset) {
      props.handleReset();
    }
  };

  return (
    <>
      {/* Risk Rating Status Filter */}
      {props?.enableRiskRatingFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "riskRating")}
          sx={buttonStyles(props?.filters?.selectedRiskRating?.length)}
        >
          Risk Rating
          <ArrowDropDownIcon
            sx={{
              ...ArrowDropDownIconsCss,
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.riskRating}
        open={Boolean(anchorEl?.riskRating)}
        onClose={() => handleClose("riskRating")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ width: 300 }}>
            <Autocomplete
              multiple
              // name="riskRating"
              id="client-status-autocomplete"
              options={props?.riskRatingValue || []}
              getOptionLabel={(option) => option?.riskRatingDesc}
              value={props?.filters?.selectedRiskRating || []}
              onChange={(event, value) =>
                handleChange("selectedRiskRating", event, value)
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <>
                    <Chip
                      label={option?.riskRatingDesc}
                      {...getTagProps({ index })}
                    />
                  </>
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* Test Result Filter for test plan */}
      {props?.enableTestResultFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "testResult")}
          sx={buttonStyles(props?.filters?.selectedTestResult?.length)}
        >
          Test Result
          <ArrowDropDownIcon
            sx={{
              ...ArrowDropDownIconsCss,
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.testResult}
        open={Boolean(anchorEl?.testResult)}
        onClose={() => handleClose("testResult")}
      >
        <div style={{ padding: 10 }}>
          <FormControl sx={{ width: 300 }}>
            <Autocomplete
              multiple
              id="client-status-autocomplete"
              options={TEST_RESULT || []}
              getOptionLabel={(option) => option?.label}
              value={props?.filters?.selectedTestResult || []}
              onChange={(event, value) =>
                handleChange("selectedTestResult", event, value)
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <>
                    <Chip
                      label={option?.label}
                      {...getTagProps({ index })} // `getTagProps` already includes a key
                    />
                  </>
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </Menu>
      {/* testPlanName for test plan */}
      {props?.enableTestPlanNameFilter && (
        <Button
          color="primary"
          onClick={(event) => handleClick(event, "testPlanName")}
          sx={buttonStyles(props?.filters?.selectedTestPlanName?.length)}
        >
          Test Plan Name
          <ArrowDropDownIcon
            sx={{
              ...ArrowDropDownIconsCss,
            }}
          />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl?.testPlanName}
        open={Boolean(anchorEl?.testPlanName)}
        onClose={() => handleClose("testPlanName")}
      >
        <div style={{ padding: 10 }}>
          <TextField
            sx={{ width: 300 }}
            name="selectedTestPlanName"
            variant="outlined"
            value={props?.filters?.selectedTestPlanName || ""}
            onChange={(event) =>
              handleChange("selectedTestPlanName", event, event?.target.value)
            }
            fullWidth
            autoComplete="off"
          />
        </div>
      </Menu>

      {/* Apply Button */}
      {props?.enableApplyFilter && (
        <Button
          color="primary"
          onClick={props?.handleApplyFilters}
          sx={{
            ...commonButtonStyles,
          }}
        >
          Apply
          <CheckIcon
            sx={{
              ...ArrowDropDownIconsCss,
            }}
          />
        </Button>
      )}
      {/* Clear Filter */}
      {props?.enableClearFilter && (
        <Button
          color="primary"
          onClick={handleClearFilter}
          sx={{
            ...commonButtonStyles,
          }}
        >
          Clear All Filters
          <ReplayIcon
            sx={{
              ...ReplayIconCss,
            }}
          />
        </Button>
      )}
    </>
  );
};

export default TestPlanGenericFilter;
