import React from "react";
// material-ui
import { Box } from "@mui/system";
// import LogoImg from "../assets/images/logo.png";
// import LogoImg from "../assets/images/logo_white_1 (1).png";
import collapseMenuLogo from "../assets/images/auth/collapseMenuLogo.png";
import openMenuLogo from "../assets/images/auth/openMenuLogo.png";
import { useSelector } from "react-redux";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const { drawerOpen, selectMenu } = useSelector(
    (state: { menu: { drawerOpen: boolean; selectMenu: string } }) => state.menu
  );

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <Box
      component="img"
      // src={drawerOpen ? openMenuLogo : collapseMenuLogo}
      src={collapseMenuLogo}
      alt="qSEAp"
      // width={drawerOpen ? 83   : 46}
      width={46}
      // width={90}
      // width={46}
      sx={
        {
          // width: "12em",
          // height: "12em",
          // backgroundColor: "white",
          // paddingTop: "45px",
          // padding: "35px 15px 15px 15px",
          // padding: "12px",
          // marginRight: "20px",
        }
      }
    />
  );
};

export default Logo;
