import { createSlice } from "@reduxjs/toolkit";

// project imports
import { isLoading, setLoading } from "./user";
import { dispatch } from "../index";
import { openSnackbar } from "./snackbarReducer";
import axios from "src/utils/axios";
import { FetchParams } from "./reducerTypes";

const clientVulnerabilityApiEndpoint = "/client/v1/vulnerability";
const clientVulExceptionApiEndpoint = "/client/v1/vul-exception";
const clientVulnerabilityEvidenceApiEndpoint = "client/v1/vul-evidence";

interface clientVulnerabilities {
  error: string | null;
  publishedVulnerabilityList: unknown[] | any;
  selectedPublishedVulnerabilityDetail: Record<string, unknown> | any;
  consultantStatusValue: unknown[] | any;
  riskRatingValue: unknown[] | any;
  clientStatusValue: unknown[] | any;
  publishedEvidenceList: unknown[];
  publishedEvidenceImage: Record<string, unknown>;
  publishedVulStatusHistory: unknown[] | any;
  exportPublishVulnerability: Record<string, unknown>;
}

const initialState: clientVulnerabilities = {
  publishedVulnerabilityList: [],
  selectedPublishedVulnerabilityDetail: {},
  consultantStatusValue: [],
  riskRatingValue: [],
  clientStatusValue: [],
  publishedEvidenceList: [],
  publishedEvidenceImage: {},
  publishedVulStatusHistory: [],
  error: "",
  exportPublishVulnerability: {},
};
const slice = createSlice({
  name: "clientVulnerabilities",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // --------------------- GET APIs --------------------------------
    //Published Vulnerability List
    getClientPublishedVulnerabilitiesListSuccess(state, action) {
      state.publishedVulnerabilityList = action.payload;
    },
    //Selected Published Vulnerability Detail
    getSelectedPublishedVulnerabilityDetailSuccess(state, action) {
      state.selectedPublishedVulnerabilityDetail = action.payload;
    },
    //Consultant Status Value
    getConsultantStatusValueSuccess(state, action) {
      state.consultantStatusValue = action.payload;
    },
    //Risk Rating Value
    getRiskRatingValueSuccess(state, action) {
      state.riskRatingValue = action.payload;
    },
    //Client Status Value
    getClientStatusSuccess(state, action) {
      state.clientStatusValue = action.payload;
    },
    //GET Published Evidence List
    getPublishedEvidenceListSuccess(state, action) {
      state.publishedEvidenceList = action.payload;
    },
    //GET Published Evidence Image
    getPublsihedEvidenceImageSuccess(state, action) {
      state.publishedEvidenceImage = action.payload;
    },
    //GET Published Vulnerability Status History
    getPublishedVulnerabilityStatusHistorySuccess(state, action) {
      state.publishedVulStatusHistory = action.payload;
    },
    //Get Publish Vulnerability Export Data
    getexportPublishVulnerabilitySuccess(state, action) {
      state.exportPublishVulnerability = action.payload;
    },
  },
});

export default slice.reducer;

export const getClientPublishedVulnerabilitiesList = (params: FetchParams) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${clientVulnerabilityApiEndpoint}`, {
        params,
      });
      dispatch(
        slice.actions.getClientPublishedVulnerabilitiesListSuccess(
          response.data
        )
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilityDetailSuccess({})
      );
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedPublishedVulnerabilityDetail = (cvId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/${cvId}`
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilityDetailSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getConsultantStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/consultant-status`
      );
      dispatch(slice.actions.getConsultantStatusValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getRiskRatingValue = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientVulnerabilityApiEndpoint}/risk-rating`
        : `/consultant/v1/vulnerability/risk-rating`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getRiskRatingValueSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getClientStatusValue = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulExceptionApiEndpoint}/status`
      );
      dispatch(slice.actions.getClientStatusSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedEvidenceList = (vulnerabilityId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityEvidenceApiEndpoint}/evidence-list/${vulnerabilityId}`
      );
      dispatch(slice.actions.getPublishedEvidenceListSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedEvidenceImage = (vulnerabilityEvidenceId: number) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityEvidenceApiEndpoint}/download/${vulnerabilityEvidenceId}`
      );
      dispatch(slice.actions.getPublsihedEvidenceImageSuccess(response.data));
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityStatusHistory = (
  clientVulnerabilityId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/status-history/${clientVulnerabilityId}`
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityStatusHistorySuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
export const getexportPublishVulnerability = (scanId: number) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/published-export/${scanId}`
      );
      dispatch(
        slice.actions.getexportPublishVulnerabilitySuccess(response.data)
      );
    } catch (error) {
      dispatch(openSnackbar({ message: String(error) }));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
