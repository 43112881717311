import { shadows } from "@mui/system";

const colors = {
  darkPaper: "#1c1c1c",
  // darkPaper: "#E58829",
  primaryLight: "#7986cb",
  primaryMain: "#3f51b5",
  primaryDark: "#303f9f",
  primary: "#303f9f",
  grey200: "#eeeeee",
  primary200: "#9fa8da",
  primary800: "#283593",
  secondaryLight: "#ff4081",
  secondaryMain: "#f50057",
  secondaryDark: "#c51162",
  secondary200: "#ff80ab",
  secondary800: "#d500f9",
  errorLight: "#e57373",
  errorMain: "#f44336",
  errorDark: "#d32f2f",
  orangeLight: "#ffb74d",
  orangeMain: "#ff9800",
  orangeDark: "#f57c00",
  warningLight: "#ffb74d",
  warningMain: "#ff9800",
  warningDark: "#f57c00",
  successLight: "#81c784",
  success200: "#66bb6a",
  successMain: "#4caf50",
  successDark: "#388e3c",
  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey800: "#595959",
  grey300: "#595959",
  grey900: "#212121",
  grey400: "#212121",
  darkTextPrimary: "#ffffff",
  darkLevel1: "#333333",
  darkLevel2: "#424242",
  darkBackground: "#1E1E3A",
  darkBackground900: "#5B5B5B",
  paper: "#1D1D3A",
};

const theme = {
  unstable_sx: {}, // add this property
  unstable_sxConfig: {}, // add this property
  applyStyles: {}, // add this property
  shadows: Array(25).fill("none"), // Default shadows array
  colors, // Assuming `colors` is already defined elsewhere in your project
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    up: (key: string) => `@media (min-width: ${key}px)`,
    down: (key: string) => `@media (max-width: ${key}px)`,
    between: (start: string, end: string) =>
      `@media (min-width: ${start}px) and (max-width: ${end}px)`,
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  customShadows: {
    primary: colors.primary,
    colors,
    secondary: 12,
    z1: "rgba(0,0,0,0.08)",
  }, // Custom shadows
  typography: {
    mainContent: {},
    customInput: {},
    commonAvatar: {},
    subMenuCaption: {},
    mediumAvatar: {},
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.08333em",
      textTransform: "uppercase",
    },
  },
  palette: {
    mode: "dark", // Switch to 'dark' for dark mode
    common: {
      black: colors.darkPaper,
    },
    primary: {
      light: colors.primaryLight,
      main: colors.primaryMain,
      dark: colors.primaryDark,
      200: colors.primary200,
      800: colors.primary800,
    },
    secondary: {
      light: colors.secondaryLight,
      main: colors.secondaryMain,
      dark: colors.secondaryDark,
      200: colors.secondary200,
      800: colors.secondary800,
    },
    error: {
      light: colors.errorLight,
      main: colors.errorMain,
      dark: colors.errorDark,
    },
    warning: {
      light: colors.warningLight,
      main: colors.warningMain,
      dark: colors.warningDark,
    },
    success: {
      light: colors.successLight,
      main: colors.successMain,
      dark: colors.successDark,
    },
    orange: {
      main: colors.orangeMain,
      dark: colors.orangeDark,
      light: colors.orangeLight,
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      300: colors.grey100,
      500: colors.grey500,
      600: colors.grey600,
      700: colors.grey700,
      800: colors.grey800,
      900: colors.grey900,
    },
    text: {
      dark: colors.darkTextPrimary,
      primary: colors.grey700,
      secondary: colors.grey500,
      hint: colors.grey100,
    },
    divider: colors.grey200,
    background: {
      paper: colors.paper,
      default: colors.paper,
    },
    // dark:Array(25).fill("none"),
    dark: {
      800: colors.darkBackground,
      900: colors.darkBackground900,
      100: colors.darkBackground,
      500: colors.darkBackground,
      600: colors.darkBackground,
      700: colors.grey700,
      200: colors.grey900,
      300: colors.grey900,
      400: colors.grey900,
      light: colors.grey900,
      main: colors.darkLevel1,
      mode: "dark",
      common: {
        black: colors.darkPaper,
      },
      primary: {
        light: colors.primary200,
        main: colors.primary800,
        dark: colors.primaryDark,
      },
      secondary: {
        light: colors.secondary200,
        main: colors.secondary800,
        dark: colors.secondaryDark,
      },
      error: {
        light: colors.errorMain,
        main: colors.errorDark,
        dark: colors.errorDark,
      },
      warning: {
        light: colors.warningMain,
        main: colors.warningDark,
        dark: colors.warningDark,
      },
      success: {
        light: colors.successMain,
        main: colors.successDark,
        dark: colors.successDark,
      },
      grey: {
        50: colors.grey700,
        100: colors.grey600,
        500: colors.grey500,
        900: colors.grey100,
      },
      text: {
        primary: colors.grey100,
        secondary: colors.grey200,
        hint: colors.grey500,
      },
      divider: colors.grey200,
      background: {
        paper: colors.darkPaper,
        default: colors.darkBackground,
      },
    },
  },
  // spacing: (factor: number) => `${factor * 8}px`,
  shape: {
    borderRadius: 8,
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  transitions: {
    create: (prop: string | string[], options: any) =>
      `transition: ${prop} ${options.duration}ms ${options.easing}`,
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
          boxShadow: "none",
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableRipple: true, // Disable ripple effect by default
    },
    MuiTextField: {
      variant: "outlined", // Set default variant
    },
  },
  direction: "ltr", // Set to 'rtl' for right-to-left support
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
};

export default theme;
