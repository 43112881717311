import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch, RootState } from "../../../../../../store";
import {
  addVulnerability,
  getComplianceStatusList,
  getEaseOfExploitation,
  getOWASPandSansCategoriesByScanId,
  getOWASPCategories,
  getOWASPCategoriesByScanId,
  getOWASPCategoriesSuccess,
  getRiskRating,
  getVulnerabilitiesMaster,
  getVulnerbilitySans25,
} from "../../../../../../store/slices/vulnerabilityReducer";
import DialogBox from "../../../../../../ui-component/dialogbox";
import FormAutoComplete from "../../../../../../ui-component/extended/Form/FormControlAutoComplete";
import FormControlSelect from "../../../../../../ui-component/extended/Form/FormControlSelect";
import * as yup from "yup";
import { SCAN_SUB_TYPE, vulnerabilityCategory } from "../../constant";
import AddEvidenceOnVulnerabilityPage from "../addEvidanceOnVulnerabilityPage";
import {
  AddVulnerabilityDetailsProps,
  EvidenceItem,
  VulnerabilityFormData,
} from "../../vulnerabiltyTypes";
import theme from "src/themes/theme";
import VulnerabilityDetialConfigTable from "../../vulnerabilityInfo/vulnerabilityDetails/VulnerabilityDetialConfigTable";

const AddVulnerabilityDetails: React.FC<AddVulnerabilityDetailsProps> = (
  props
) => {
  // const theme = useTheme();
  const params: any = useParams();
  // const params = useParams<{ type: string }>();
  const navigate = useNavigate();
  const [references, setReferences] = useState<string>();
  const [impact, setImpact] = useState<string>();
  const [affectedUrl, setAffectedUrl] = useState<string>();
  const [solution, setSolution] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [pluginOutput, setPluginOutput] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [vulnerabilityName, setVulnerabilityName] = useState<string>("");
  const [port, setPort] = useState<string>("");
  const [protocol, setProtocol] = useState<string>("");
  const [sourceSystemRefNo, setSourceSystemRefNo] = useState<string>("");
  const [proofOfConcepts, setProofOfConcepts] = useState<string>("");
  const [CVEId, setCVEId] = useState<string>("");
  const [selectedVmId, setSelectedVmId] = useState<string>();
  const [synopsis, setSynopsis] = useState<string>();
  const [seeAlso, setSeeAlso] = useState<string>();
  const [policyValue, setPolicyValue] = useState<string>();
  const [actualValue, setActualValue] = useState<string>();
  const [fileName, setFileName] = useState<string | null>();
  const [lineNo, setLineNo] = useState<number | string>();
  const [selectedRiskRating, setSelectedRiskRating] = useState<string>("");
  const [selectedOWASP, setSelectedOWASP] = useState<string>("");
  const [selectedSans25, setSelectedSans25] = useState<string>("");
  const [selectedEaseOfExploitation, setSelectedEaseOfExploitation] =
    useState<string>("");
  const [identifiedOnDate, setIdentifiedOnDate] = useState<dayjs.Dayjs | null>(
    null
  );
  const [selectedComplianceStatus, setComplianceStatus] = useState<string>();
  const { setDataloading } = useSelector(
    (state: RootState) => state?.userState
  );
  const displayFieldData = sessionStorage.getItem("displayVulnerabilityField");
  const displayVulnerabilityField = JSON.parse(displayFieldData || "{}");
  const clientOrgId: any = sessionStorage.getItem("clientOrgId");
  const {
    allVulnerabilities,
    complianceStatusList,
    easeOfExploitation,
    OWASPCategories,
    riskRating,
    VulnerabiltySans25Success,
    OWASPSansCategories,
    addVulnerabilityRepose,
  } = useSelector((state: RootState) => state?.vulnerabilityState);
  const { scanDetails } = useSelector((state: RootState) => state?.scansState);
  const handleEffectIpValidation = [
    SCAN_SUB_TYPE.mobile,
    SCAN_SUB_TYPE.thick_client,
  ].includes(scanDetails?.scanSubTypeName);
  const [open, setOpen] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [evidenceName, setEvidenceName] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [sourceDetails, setSourceDetails] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [submitEvidence, setSubmitEvidence] = useState<boolean>(false);
  const [evidenceList, setEvidenceList] = useState<EvidenceItem[]>([]);
  const [alignment, setAlignment] = useState<string>(
    vulnerabilityCategory.defined_value
  );
  const isBlackboxOrGreyboxVisible =
    scanDetails?.scanTypeName === "Blackbox" ||
    scanDetails?.scanTypeName === "Greybox";
    scanDetails?.scanTypeName === "Blackbox" ||
    scanDetails?.scanTypeName === "Greybox";

  const handleVulnerabilityModeChange = (
    event: React.ChangeEvent<unknown>,
    newAlignment: string
  ) => {
    props?.setVulnerabilityDetails({});
    setSelectedVmId("");
    setSelectedVmId("");
    setAlignment(newAlignment);
    reset();
    setSelectedRiskRating("");
    setSelectedEaseOfExploitation("");
    setSelectedOWASP("");
    setSelectedSans25("");
    setDescription("");
    setImpact("");
    setReferences("");
    setSolution("");
    setValue("riskRating", "");
    setValue("port", "");
    setValue("owaspCategory", "");
    setValue("description", "");
    setValue("solution", "");
    setValue("svReference", "");
    setValue("impact", "");
    setValue("affectedUrl", "");
    setValue("vulnerabilityName", "");
    setValue("cve_cwe_id", "");
    setValue("synopsis", "");
    setValue("sourceFile", "");
    setValue("proofOfConcept", "");
    setValue("lineNo", "");
    setValue("policyValue", "");
    setValue("actualValue", "");
    setValue("pluginOutput", "");
    // setValue("identifiedOnDate", props?.vulnerabilityDetails?.identifiedOnDate);
    setValue("protocol", "");
    setValue("sourceSystemRefNo", "");
    setValue("easeOfExploitation", "");
    setValue("SANS25Category", "");
    setValue("affectedUrl", "");
    setValue("solution", "");
    setValue("description", "");
    setValue("pluginOutput", "");
    setValue("impact", "");
    setValue("svReference", "");
    setValue("affectedUrl", "");
  };

  const selectedToggleColor = {
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    padding: "2px",
    paddingX: "10px",
  };
  const handleCloseDialog = () => {
    setOpen(false);
    setFile(null);
    setEvidenceName("");
    if (submitEvidence) {
      setSubmitEvidence(!submitEvidence);
      setEvidenceList([]);
      navigate(-1);
    }
    // setOpenImage(false);
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
  };

  const validationSchema = yup.object().shape({
    port: yup.string().nullable(),
    sourceSystemRefNo: yup.string().nullable(),
    sourceFile: yup.string().nullable(),
    proofOfConcept: yup.string().nullable(),
    lineNo: yup.string().nullable(),
    actualValue: yup.string().nullable(),
    policyValue: yup.string().nullable(),
    protocol: yup.string().nullable(),
    SANS25Category: yup.string().nullable(),

    vulnerabilityName: displayVulnerabilityField?.vulnerabilityName
      ? yup.string().required("Vulnerability Name is required")
      : yup.string().nullable(), // Ensures no invalid schema is passed

    vulnerabilityId: displayVulnerabilityField?.vulnerabilityId
      ? yup.string().required("Vulnerability is required")
      : yup.string().nullable(),

    riskRating: displayVulnerabilityField?.riskRating
      ? yup.string().required("Risk Rating is required")
      : yup.string().nullable(),

    easeOfExploitation: displayVulnerabilityField?.easeOfExploitation
      ? yup.string().required("Ease Of Exploitation is required")
      : yup.string().nullable(),

    complianceStatus: displayVulnerabilityField?.complianceStatus
      ? yup.string().required("Compliance Status is required")
      : yup.string().nullable(),

    impact: displayVulnerabilityField?.impact
      ? yup.string().required("Impact is required")
      : yup.string().nullable(),

    affectedUrl:
      displayVulnerabilityField?.affectedURL && !handleEffectIpValidation
        ? yup.string().required("Affected Asset (IP/URL) is required")
        : yup.string().nullable(),

    description: displayVulnerabilityField?.description
      ? yup.string().required("Description is required")
      : yup.string().nullable(),

    solution: displayVulnerabilityField?.solution
      ? yup.string().required("Solution is required")
      : yup.string().nullable(),

    svReference: displayVulnerabilityField?.reference
      ? yup.string().required("Reference is required")
      : yup.string().nullable(),

    pluginOutput: displayVulnerabilityField?.pluginOutput
      ? yup.string()
      : yup.string().nullable(),

    synopsis: displayVulnerabilityField?.synopsis
      ? yup.string()
      : yup.string().nullable(),
    cve_cwe_id: displayVulnerabilityField?.cve_cwe_id
      ? yup
          .string()
          .matches(/\b(CWE|CVE)-\d+\b/, "Enter valid CVE/ CWE ID")
          .required("CVE ID / CWE ID is required")
      : yup.string().nullable(), // Make sure it's a valid schema

    owaspCategory: displayVulnerabilityField?.owaspCategory
      ? yup.string().required("OWASP Category is required")
      : yup.string().nullable(),

    // Uncomment and add validation for SANS25Category if necessary
    // SANS25Category: displayVulnerabilityField?.SANS25Category
    //   ? yup.string().required("SANS25 Category is required")
    //   : yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const AuditColumns = ["Line Number", "Source File", "Proof Of Concept"];

  // setValue("port" as keyof FormValues, "");
  useEffect(() => {
    setValue("riskRating", props?.vulnerabilityDetails?.riskRating);
    setValue(
      "easeOfExploitation",
      props?.vulnerabilityDetails?.easeOfExploitation
    );
    setValue("port", props?.vulnerabilityDetails?.port);
    setValue("owaspCategory", props?.vulnerabilityDetails?.owaspcategory);
    setValue("SANS25Category", props?.vulnerabilityDetails?.SANS25Category);
    setValue("description", props?.vulnerabilityDetails?.description);
    setValue("solution", props?.vulnerabilityDetails?.solution);
    setValue("protocol", props?.vulnerabilityDetails?.protocol);
    setValue(
      "sourceSystemRefNo",
      props?.vulnerabilityDetails?.sourceSystemRefNo
    );
    setValue("svReference", props?.vulnerabilityDetails?.svReference);
    setValue("impact", props?.vulnerabilityDetails?.impact);
    setValue("affectedUrl", props?.vulnerabilityDetails?.affectedUrl);
    setValue(
      "vulnerabilityName",
      props?.vulnerabilityDetails?.vulnerabilityName
    );
    setValue("cve_cwe_id", props?.vulnerabilityDetails?.cve_cwe_id);
    setValue("sourceFile", props?.vulnerabilityDetails?.sourceFile);
    setValue("proofOfConcept", props?.vulnerabilityDetails?.proofOfConcept);
    setValue("lineNo", props?.vulnerabilityDetails?.lineNo);
    setValue("synopsis", props?.vulnerabilityDetails?.synopsis);
    setValue("policyValue", props?.vulnerabilityDetails?.policyValue);
    setValue("actualValue", props?.vulnerabilityDetails?.actualValue);
    setValue("pluginOutput", props?.vulnerabilityDetails?.pluginOutput);
    // setValue("identifiedOnDate", props?.vulnerabilityDetails?.identifiedOnDate);
    setReferences(props?.vulnerabilityDetails?.svReference);
    setVulnerabilityName(props?.vulnerabilityDetails?.vulnerabilityName);
    setImpact(props?.vulnerabilityDetails?.impact);
    setAffectedUrl(props?.vulnerabilityDetails?.affectedUrl);
    setSolution(props?.vulnerabilityDetails?.solution);
    setDescription(props?.vulnerabilityDetails?.description);
    setProtocol(props?.vulnerabilityDetails?.protocol);
    setSourceSystemRefNo(props?.vulnerabilityDetails?.sourceSystemRefNo);
    setPort(props?.vulnerabilityDetails?.port);
    setCVEId(props?.vulnerabilityDetails?.cve_cwe_id);
    setProofOfConcepts(props?.vulnerabilityDetails?.proofOfConcept);
    setSynopsis(props?.vulnerabilityDetails?.synopsis);
    setSeeAlso(props?.vulnerabilityDetails?.seeAlso);
    setIdentifiedOnDate(props?.vulnerabilityDetails?.identifiedOn);
    setPolicyValue(props?.vulnerabilityDetails?.policyValue);
    setActualValue(props?.vulnerabilityDetails?.actualValue);
    setFileName(props?.vulnerabilityDetails?.sourceFile || null);
    setLineNo(props?.vulnerabilityDetails?.lineNo);
    setPluginOutput(props?.vulnerabilityDetails?.pluginOutput);
    setSelectedRiskRating(props?.vulnerabilityDetails?.riskRating);
    setSelectedEaseOfExploitation(
      props?.vulnerabilityDetails?.easeOfExploitation
    );
    setComplianceStatus(props?.vulnerabilityDetails?.complianceStatus);
    setSelectedOWASP(props?.vulnerabilityDetails?.owaspCategory);
    setSelectedSans25(props?.vulnerabilityDetails?.SANS25Category);
    setSourceDetails(props?.vulnerabilityDetails?.source_details);
  }, []);

  // import { FormEvent } from 'react';

  const onClickNext = (
    data: VulnerabilityFormData | any,
    event: FormEvent<HTMLFormElement> | any
  ) => {
    event.preventDefault(); // Prevent default form submission

    const identifiedOnDate = data?.identifiedOnDate; // Ensure identifiedOnDate is passed properly

    if (params?.assetId || displayVulnerabilityField?.singleAsset) {
      const payload = {
        ...data,
        ...props?.vulnerabilityDetails,
        solution: data?.solution,
        description: data?.description,
        pluginOutput: data?.plugInOutput ?? data?.pluginOutput,
        impact: data?.impact,
        svReference: data?.svReference,
        affectedUrl: data?.affectedUrl,
        assetIdList: params?.assetId ? [params?.assetId] : null,
        scanId: params?.scanId,
        port: data?.port,
        protocol: data?.protocol,
        sourceSystemRefNo: data?.sourceSystemRefNo,
        identifiedOn: identifiedOnDate, // Directly pass identifiedOnDate as identifiedOn
        proofOfConcept: data?.proofOfConcept,
        vmId: selectedVmId,
        cve_cwe_id: data?.cve_cwe_id,
        clientOrgId,
        lineNo: data?.lineNo,
        riskRating: data?.riskRating,
        easeOfExploitation: data?.easeOfExploitation,
        owaspCategory: data?.owaspCategory,
        sourceDetails: sourceDetails,
      };
      dispatch(addVulnerability(payload))
        .then((res: any) => {
          if (res && res.status === 200) {
            if (evidenceList?.length > 0) {
              setOpen(true);
              setSubmitEvidence(true);
            } else {
              navigate(-1);
            }
          }
        })
        .catch(() => {});
    } else {
      const passData = {
        ...data,
        ...props?.vulnerabilityDetails,
        riskRating: data?.riskRating,
        easeOfExploitation: data?.easeOfExploitation,
        owaspCategory: data?.owaspCategory,
        SANS25Category: data?.SANS25Category,
        solution: data?.solution,
        description: data?.description,
        pluginOutput: data?.pluginOutput,
        impact: data?.impact,
        svReference: data.svReference,
        identifiedOn: identifiedOnDate,
        affectedUrl: data?.affectedUrl,
        sourceFile: data?.sourceFile ? data?.sourceFile : null,
        lineNo: data?.lineNo,
        vulnerabilityName: data?.vulnerabilityName ?? null,
        port: data?.port,
        protocol: data?.protocol,
        sourceSystemRefNo: data?.sourceSystemRefNo,
        proofOfConcept: data?.proofOfConcept,
        cve_cwe_id: data?.cve_cwe_id,
        clientOrgId,
        vulnerabilityId:
          selectedVmId || props.vulnerabilityDetails?.vulnerabilityId,
        complianceStatus: data?.complianceStatus,
        sourceDetails: sourceDetails,
      };
      props?.setVulnerabilityDetails(passData);
      props?.handleChangeTab();
    }
  };

  useEffect(() => {
    dispatch(getVulnerabilitiesMaster(params?.scanId, clientOrgId));
    dispatch(getRiskRating());
    dispatch(getComplianceStatusList());
    dispatch(getEaseOfExploitation());
    if (!props?.vulnerabilityDetails?.vulnerabilityId) {
      dispatch(getOWASPCategoriesSuccess([]));
    }
  }, []);

  const handleSelectedValId = (
    event: React.SyntheticEvent,
    selectedValue: any
  ) => {
    if (selectedValue?.vulnerabilityId && allVulnerabilities?.length) {
      const selectedObj = allVulnerabilities?.find(
        (item: any) => item?.vulnerabilityId === selectedValue?.vulnerabilityId
      );
      const obj = {
        ...(props?.vulnerabilityDetails || {}),
        ...(selectedObj || {}),
      };
      setSelectedVmId(obj?.vulnerabilityId);
      props?.setVulnerabilityDetails(obj);
      if (selectedValue?.serviceType) {
        // dispatch(getOWASPCategories(selectedValue?.serviceType));
        dispatch(
          getOWASPandSansCategoriesByScanId(params?.scanId, clientOrgId)
        );
      }
      if (selectedValue?.serviceType) {
        // dispatch(getVulnerbilitySans25(selectedValue?.serviceType));
      }
      setValue("protocol", obj?.protocol);
      setValue("sourceSystemRefNo", obj?.sourceSystemRefNo);
      setValue("riskRating", obj?.riskRating);
      setValue("easeOfExploitation", obj?.easeOfExploitation);
      setValue("owaspCategory", obj?.owaspcategory);
      setValue("affectedUrl", obj?.affectedUrl);
      setValue("solution", obj?.solution);
      setValue("description", obj?.vulnerabilityDesc);
      setValue("pluginOutput", obj?.pluginOutput);
      setValue("impact", obj?.impact);
      setValue("svReference", obj?.vulnerabilityRef);
      setValue("affectedUrl", obj?.affectedUrl);
      setSelectedRiskRating(obj?.riskRating);
      setSelectedEaseOfExploitation(obj?.easeOfExploitation);
      setComplianceStatus(obj?.complianceStatus);
      setSourceDetails(obj?.source_details);
      setSolution(obj?.solution);
      setDescription(obj?.vulnerabilityDesc);
      setPluginOutput(obj?.pluginOutput);
      setImpact(obj?.impact);
      setReferences(obj?.vulnerabilityRef);
      setSelectedOWASP(obj?.owaspcategory);
      setSelectedSans25(obj?.SANS25Category);
      setAffectedUrl(obj?.affectedUrl);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "vulnerabilityName":
        // const inputValue = value;
        if (value?.length <= 100) {
          setVulnerabilityName(value);
        }
        break;
      case "port":
        setPort(value);
        break;
      case "protocol":
        setProtocol(value);
        break;
      case "sourceSystemRefNo":
        setSourceSystemRefNo(value);
        break;
      case "proofOfConcept":
        setProofOfConcepts(value);
        break;
      case "cve_cwe_id":
        setCVEId(value);
        break;
      case "synopsis":
        setSynopsis(value);
        break;
      case "seeAlso":
        setSeeAlso(value);
        break;
      case "identifiedOn":
        setIdentifiedOnDate(value);
        break;
      case "policyValue":
        setPolicyValue(value);
        break;
      case "actualValue":
        setActualValue(value);
        break;
      case "sourceFile":
        setFileName(value);
        break;
      case "lineNo":
        setLineNo(value);
        break;
      case "riskRating":
        setSelectedRiskRating(value);
        // trigger("riskRating");
        break;
      case "easeOfExploitation":
        setSelectedEaseOfExploitation(value);
        // trigger("easeOfExploitation");
        break;
      case "owaspCategory":
        setSelectedOWASP(value);
        // trigger("owaspCategory");
        break;
      case "SANS25Category":
        setSelectedSans25(value);
        // trigger("owaspCategory");
        break;
      case "complianceStatus":
        setComplianceStatus(value);
        // trigger("owaspCategory");
        break;
      default:
        break;
    }
  };

  const { vulnerabilityDetails, setVulnerabilityDetails } = props;
  useEffect(() => {
    if (!selectedVmId) return;

    const subscription = watch((value) => {
      const obj = {
        ...vulnerabilityDetails,
        ...value,
        vulnerabilityId: selectedVmId,
      };
      setVulnerabilityDetails(obj);
    });

    return () => subscription.unsubscribe(); // Cleanup function to avoid memory leaks
  }, [selectedVmId, watch, vulnerabilityDetails, setVulnerabilityDetails]);

  useEffect(() => {
    if (alignment === vulnerabilityCategory.custom_value) {
      // dispatch(getOWASPCategoriesByScanId(params?.scanId, clientOrgId));
      dispatch(getOWASPandSansCategoriesByScanId(params?.scanId, clientOrgId));
    }
    reset();
  }, [alignment]);

  const makeDropDownList = (data: any, value: any, label: any) => {
    const itemList = data?.map((item: any) => {
      if (label === "serviceType") {
        return {
          [label]: item.serviceDesc,
          [value]: item.serviceId,
        };
      }
      if (label === "riskRating") {
        return {
          [label]: item.riskRatingDesc,
          [value]: item.riskRatingCode,
        };
      }
      if (label === "easeOfExploitation") {
        return {
          [label]: item.easeOfExploitationDesc,
          [value]: item.easeOfExploitationCode,
        };
      }
      if (label === "owaspcategory") {
        return {
          [label]: `${item.prefixCode}  ${item.owaspcategoryDesc}`,
          [value]: item.owaspcategoryId,
        };
      }
      if (label === "sans25category") {
        return {
          [label]: `${item.cweId}  ${item.SANS25Desc}`,
          [value]: item.SANS25Category,
        };
      }
    });
    return itemList;
  };

  const handleSaveTableData = (name?: string | any, data?: any) => {
    setSourceDetails(data);
    // const payload: any = {
    //   // [name]: data,
    //   // url: url,
    // };
    // dispatch(updateVulnerabilityDetails(data))
    // .then(() => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT_CONFIG)) {
    // dispatch(getScanReportConfig(params?.scanId, clientOrgId));
    // }
    // setEditDescription(true)
    // });
  };

  return (
    <>
      {!displayVulnerabilityField?.vulnerabilityName && (
        <Grid m={0.5} display="flex" justifyContent="end">
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleVulnerabilityModeChange}
            aria-label="Platform"
            size="small"
            sx={{
              padding: "2px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <ToggleButton
              value={vulnerabilityCategory?.defined_value}
              sx={selectedToggleColor}
            >
              {vulnerabilityCategory?.defined_label}
            </ToggleButton>
            <ToggleButton
              value={vulnerabilityCategory?.custom_value}
              sx={selectedToggleColor}
            >
              {vulnerabilityCategory?.custom_label}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <form onSubmit={handleSubmit(onClickNext)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={12}>
            <InputLabel>Vulnerability:</InputLabel>
            {displayVulnerabilityField?.vulnerabilityName ||
            alignment === vulnerabilityCategory.custom_value ? (
              <TextField
                fullWidth
                // name="vulnerabilityName"
                placeholder="Vulnerability"
                value={vulnerabilityName}
                {...register("vulnerabilityName")}
                error={Boolean(errors.vulnerabilityName)}
                helperText={
                  errors.vulnerabilityName
                    ? errors.vulnerabilityName.message
                    : ""
                }
                onChange={handleChange}
                autoComplete="off"
              />
            ) : (
              <FormAutoComplete
                // options={allVulnerabilities ?? []}
                itemsList={allVulnerabilities ?? []}
                defaultValue={
                  allVulnerabilities &&
                  allVulnerabilities?.find(
                    (item: any) =>
                      item?.vulnerabilityId ===
                      props?.vulnerabilityDetails?.vulnerabilityId
                  )
                }
                optionLabel={"vulnerabilityName"}
                {...register("vulnerabilityId")}
                error={Boolean(errors.vulnerabilityId)}
                helperText={
                  errors.vulnerabilityId ? errors.vulnerabilityId.message : ""
                }
                name="VulnerabilityId"
                placeholder={"Vulneraility"}
                onChange={handleSelectedValId}
                // issue in autocomplete
                // onChange={(_: any, selectedValue: any) => handleSelectedValId(selectedValue)}
                clearIcon={false}
              />
              // <FormAutoComplete
              //   itemsList={allVulnerabilities}
              //   optionValue={"vulnerabilityId"}
              //   options={allVulnerabilities}
              //   defaultValue={() => {
              //     return allVulnerabilities.find(
              //       (item: any) =>
              //         item?.vulnerabilityId ===
              //         props?.vulnerabilityDetails?.vulnerabilityId
              //     );
              //   }}
              //   optionLabel={"vulnerabilityName"}
              //   {...register("vulnerabilityId")}
              //   error={Boolean(errors.vulnerabilityId)}
              //   helperText={
              //     errors.vulnerabilityId ? errors.vulnerabilityId.message : ""
              //   }
              //   name="VulnerabilityId"
              //   placeholder={"Vulneraility"}
              //   onChange={handleSelectedValId}
              //   clearIcon={false}
              // />
            )}
          </Grid>
          <>
            {displayVulnerabilityField?.riskRating && (
              <Grid item xs={6} lg={6}>
                <InputLabel>Risk Rating:</InputLabel>
                <FormControlSelect
                  itemsList={riskRating}
                  value={selectedRiskRating}
                  optionLabel={"riskRatingDesc"}
                  optionValue={"riskRatingCode"}
                  {...register("riskRating")}
                  error={Boolean(errors.riskRating)}
                  helperText={
                    errors.riskRating ? errors.riskRating.message : ""
                  }
                  name="riskRating"
                  onChange={handleChange}
                />
              </Grid>
            )}
          </>
          {displayVulnerabilityField?.easeOfExploitation && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Ease of Exploitation:</InputLabel>
              <FormControlSelect
                itemsList={easeOfExploitation}
                optionLabel={"easeOfExploitationDesc"}
                optionValue={"easeOfExploitationCode"}
                {...register("easeOfExploitation")}
                value={selectedEaseOfExploitation}
                error={Boolean(errors.easeOfExploitation)}
                helperText={
                  errors.easeOfExploitation
                    ? errors.easeOfExploitation.message
                    : ""
                }
                onChange={handleChange}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.port && !isBlackboxOrGreyboxVisible && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Port:</InputLabel>
              <TextField
                fullWidth
                defaultValue={port}
                {...register("port")}
                placeholder="Port"
                autoComplete="off"
                // error={Boolean(errors.port)}
                // helperText={errors.port ? errors.port.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.protocol &&
            !isBlackboxOrGreyboxVisible && (
              <Grid item xs={6} lg={6}>
                <InputLabel>Protocol:</InputLabel>
                <TextField
                  fullWidth
                  defaultValue={protocol}
                  {...register("protocol")}
                  // error={Boolean(errors.protocol)}
                  // helperText={errors.protocol ? errors.protocol.message : ""}
                  autoComplete="off"
                  placeholder="Protocol"
                />
              </Grid>
            )}
          {displayVulnerabilityField?.owaspCategory && (
            <Grid item xs={6}>
              <InputLabel>OWASP Category:</InputLabel>
              <FormControlSelect
                disabled={!OWASPSansCategories?.owaspCategoryList?.length}
                itemsList={
                  OWASPSansCategories?.owaspCategoryList &&
                  OWASPSansCategories?.owaspCategoryList?.length > 0
                    ? makeDropDownList(
                        OWASPSansCategories?.owaspCategoryList,
                        "owaspcategoryId",
                        "owaspcategory"
                      )
                    : []
                }
                optionLabel={"owaspcategory"}
                optionValue={"owaspcategoryId"}
                {...register("owaspCategory")}
                value={selectedOWASP}
                onChange={handleChange}
                // name="owaspCategory"
                error={Boolean(errors.owaspCategory)}
                helperText={
                  errors.owaspCategory ? errors.owaspCategory.message : ""
                }
              />
            </Grid>
          )}

          {/* {displayVulnerabilityField?.SANS25Category && ( */}
          {displayVulnerabilityField?.SANS25Category && (
            <Grid item xs={6}>
              <InputLabel>SANS Top 25 Category:</InputLabel>
              <FormControlSelect
                disabled={!OWASPSansCategories?.sansCategoryList?.length}
                itemsList={
                  OWASPSansCategories?.sansCategoryList &&
                  OWASPSansCategories?.sansCategoryList?.length > 0
                    ? makeDropDownList(
                        OWASPSansCategories?.sansCategoryList,
                        "SANS25Category",
                        "sans25category"
                      )
                    : []
                }
                optionLabel={"sans25category"}
                optionValue={"SANS25Category"}
                {...register("SANS25Category")}
                value={selectedSans25}
                onChange={handleChange}
                // name="SANS25Category"
                error={Boolean(errors.SANS25Category)}
                helperText={
                  errors.SANS25Category ? errors.SANS25Category.message : ""
                }
              />
            </Grid>
          )}

          {displayVulnerabilityField?.affectedURL && (
            <Grid item xs={12}>
              <InputLabel>Affected Asset (IP/URL) :</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                defaultValue={affectedUrl}
                // name="affectedUrl"
                {...register("affectedUrl")}
                error={Boolean(errors.affectedUrl)}
                helperText={
                  errors.affectedUrl ? errors.affectedUrl.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.description && (
            <Grid item xs={12}>
              <InputLabel>Description:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                // name="description"
                defaultValue={description}
                {...register("description")}
                error={Boolean(errors.description)}
                helperText={
                  errors.description ? errors.description.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.impact && (
            <Grid item xs={12}>
              <InputLabel>Impact:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                // name="impact"
                defaultValue={impact}
                {...register("impact")}
                error={Boolean(errors.impact)}
                helperText={errors.impact ? errors.impact.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.plugInOutput && (
            <Grid item xs={12}>
              <InputLabel>Plugin Output:</InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                // name="pluginOutput"
                defaultValue={pluginOutput}
                {...register("pluginOutput")}
                error={Boolean(errors.pluginOutput)}
                helperText={
                  errors.pluginOutput ? errors.pluginOutput.message : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel>Solution:</InputLabel>
            <TextField
              multiline
              rows={3}
              fullWidth
              // name="solution"
              defaultValue={solution}
              {...register("solution")}
              error={Boolean(errors.solution)}
              helperText={errors.solution ? errors.solution.message : ""}
            />
          </Grid>
          {displayVulnerabilityField?.reference && (
            <Grid item xs={12}>
              <InputLabel>
                Reference (Please Use Coma “ , “ for adding Multiple
                References):
              </InputLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                defaultValue={references}
                // name="svReference"
                {...register("svReference")}
                error={Boolean(errors.svReference)}
                helperText={
                  errors.svReference ? errors.svReference.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.complianceStatus && (
            <Grid item xs={6} lg={4}>
              <InputLabel>Compliance Status:</InputLabel>
              <FormControlSelect
                itemsList={complianceStatusList}
                optionLabel={"desc"}
                optionValue={"code"}
                {...register("complianceStatus")}
                // name="complianceStatus"
                // value={selectedCompolianceStatus}
                error={Boolean(errors.complianceStatus)}
                helperText={
                  errors.complianceStatus ? errors.complianceStatus.message : ""
                }
                onChange={handleChange}
              />
            </Grid>
          )}

          {displayVulnerabilityField?.policyValue && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Policy Value:</InputLabel>
              <TextField
                fullWidth
                {...register("policyValue")}
                defaultValue={policyValue}
                error={Boolean(errors.policyValue)}
                autoComplete="off"
                placeholder="Policy Value"
                helperText={
                  errors.policyValue ? errors.policyValue.message : ""
                }
              />
            </Grid>
          )}
          {displayVulnerabilityField?.actualValue && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Actual Value:</InputLabel>
              <TextField
                fullWidth
                {...register("actualValue")}
                defaultValue={actualValue}
                error={Boolean(errors.actualValue)}
                autoComplete="off"
                placeholder="Actual Value"
                helperText={
                  errors.actualValue ? errors.actualValue.message : ""
                }
              />
            </Grid>
          )}

          {displayVulnerabilityField?.sourceDetails && (
            <VulnerabilityDetialConfigTable
              tableName=""
              tableAction=""
              columns={AuditColumns}
              // data={props?.vulnerabilityDetails?.source_details ? props?.vulnerabilityDetails?.source_details : []}
              data={sourceDetails ? sourceDetails : []}
              valueLabel={["lineNo", "sourceFile", "proofOfConcept"]}
              handleSave={handleSaveTableData}
              url=""
              isEdit={
                props?.vulnerabilityDetails?.source_details?.length > 0
                  ? false
                  : true
              }
              allowUpdate={true}
            />
          )}

          {/* {displayVulnerabilityField?.sourceFile && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Source File:</InputLabel>
              <TextField
                fullWidth
                defaultValue={fileName}
                {...register("sourceFile")}
                error={Boolean(errors.sourceFile)}
                autoComplete="off"
                placeholder="Source File"
                helperText={errors.sourceFile ? errors.sourceFile.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.lineNo && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Line Number:</InputLabel>
              <TextField
                fullWidth
                {...register("lineNo")}
                defaultValue={lineNo}
                error={Boolean(errors.lineNo)}
                autoComplete="off"
                placeholder="Line Number"
                helperText={errors.lineNo ? errors.lineNo.message : ""}
              />
            </Grid>
          )}
          {displayVulnerabilityField?.proofOfConcept && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Proof Of Concepts:</InputLabel>
              <TextField
                fullWidth
                defaultValue={proofOfConcepts}
                {...register("proofOfConcept")}
                error={Boolean(errors.proofOfConcept)}
                autoComplete="off"
                placeholder="Proof Of Concepts"
                helperText={
                  errors.proofOfConcept ? errors.proofOfConcept.message : ""
                }
              />
            </Grid>
          )} */}
          {displayVulnerabilityField?.identifiedOn && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Identified On :</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{
                      width: "100%",
                    }}
                    format="YYYY-MM-DD"
                    defaultValue={identifiedOnDate}
                    onChange={(date) => {
                      setIdentifiedOnDate(date); // Directly set the dayjs object
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {/* <InputLabel>Identified On :</InputLabel>
                <TextField
                  fullWidth
                  {...register('identifiedOn')}
                  value={identifiedOn}
                  error={Boolean(errors.identifiedOn)}
                  autoComplete="off"
                  placeholder="Identified On"
                  helperText={
                    errors.identifiedOn ? errors.identifiedOn.message : ''
                  }
                /> */}
            </Grid>
          )}

          {displayVulnerabilityField?.cve && (
            <Grid item xs={6} lg={6}>
              <InputLabel>CVE ID / CWE ID:</InputLabel>
              <TextField
                fullWidth
                defaultValue={CVEId}
                {...register("cve_cwe_id")}
                // name="cve_cwe_id"
                // onChange={handleChange}
                error={Boolean(errors.cve_cwe_id)}
                placeholder="CVE ID / CWE ID"
                helperText={errors.cve_cwe_id ? errors.cve_cwe_id.message : ""}
              />
            </Grid>
          )}

          {displayVulnerabilityField?.synopsis && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Synopsis:</InputLabel>
              <TextField
                fullWidth
                {...register("synopsis")}
                defaultValue={synopsis}
                error={Boolean(errors.synopsis)}
                helperText={errors.synopsis ? errors.synopsis.message : ""}
                placeholder="Synopsis"
                autoComplete="off"
              />
            </Grid>
          )}
          {/* {displayVulnerabilityField?.singleAsset ? (
            <Grid item xs={12} lg={12}>
              <Button
                fullWidth
                component="label"
                role={undefined}
                // variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onClick={() => setOpen(true)}
              >
                Add Evidence
              </Button>
            </Grid>
          ) : (
            ""
          )} */}

          <DialogBox
            maxWidth="md"
            modelTitle={"Add Evidence"}
            open={open}
            handleClose={handleCloseDialog}
            dialogContent={
              <AddEvidenceOnVulnerabilityPage
                handleClose={handleCloseDialog}
                valId={params?.valId ?? ""}
                clientOrgId={clientOrgId ?? ""}
                setBtnLoading={setBtnLoading}
                btnLoading={btnLoading}
                file={file}
                setFile={setFile}
                evidenceName={evidenceName ?? ""}
                setEvidenceName={setEvidenceName}
                handleFileChange={handleFileChange}
                submitEvidence={submitEvidence}
                setEvidenceList={setEvidenceList}
                evidenceList={evidenceList}
              />
            }
          />

          {/* {displayVulnerabilityField?.seeAlso && (
            <Grid item xs={6} lg={6}>
              <InputLabel>See Also:</InputLabel>
              <TextField
                fullWidth
                {...register('seeAlso')}
                value={seeAlso}
                error={Boolean(errors.seeAlso)}
                helperText={errors.seeAlso ? errors.seeAlso.message : ''}
                placeholder="See Also"
                autoComplete="off"
              />
            </Grid>
          )} */}

          {/* {displayVulnerabilityField?.port && (
            <Grid item xs={6} lg={6}>
              <InputLabel>Port:</InputLabel>
              <TextField
                fullWidth
                defaultValue={port}
                {...register("port")}
                placeholder="Port"
                autoComplete="off"
                error={Boolean(errors.port)}
                helperText={errors.port ? errors.port.message : ""}
              />
            </Grid>
          )} */}
        </Grid>
        <Stack
          mt={3}
          justifyContent={"flex-end"}
          width={"100%"}
          direction={"row"}
          spacing={2}
        >
          <Button
            size="large"
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              fontFamily: "Poppins",
              borderRadius: "10px",
              fontWeight: "500",
              fontSize: "18px",
              color: " #F45F43",
              border: "1px solid",
              height: "50px",
              borderImageSlice: 1,
              textTransform: "none",
              "&:hover": {
                border: "1px solid",
                borderRadius: "10px",
                color: "#F45F43",
                borderImageSlice: 1,
              },
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              width: "105px",
              height: "50px",
              borderRadius: "10px",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation
            }}
            loading={setDataloading}
          >
            {params?.assetId || displayVulnerabilityField?.singleAsset
              ? "Save"
              : "Next"}
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

export default AddVulnerabilityDetails;
