import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dispatch } from "../../../store";
import filter_tick_icon from "../../../assets/images/filter_tick_icon.svg";
import filter_close_icon from "../../../assets/images/filter_close_icon.svg";
// import { fetchClientAssetGroupMyList } from "store/slices/clientAssetsReducer";
// import { fetchDepartmentMyList } from "store/slices/departmentReducer";
import FormControlSelect from "../../../ui-component/extended/Form/FormControlSelect";
import InputLabel from "../../../ui-component/extended/Form/InputLabel";
import { complianceStatus } from "../../../views/pages/client/scan/constant";
import * as yup from "yup";
import theme from "src/themes/theme";
import { text } from "stream/consumers";

const validationSchema = yup.object().shape({
  // assetType: yup.string().required('Asset Type is required'),
  // assetSubType: yup.string().required('Asset Sub Type is required'),
});

interface AssetGroupManager {
  userId: string;
  displayName: string;
}

interface Consultant {
  firstName: string;
  lastName: string;
  empCode: string;
  userId: string;
}

interface Filters {
  assetType?: string;
  assetSubType?: string;
  assetName?: string;
  assetId?: string;
  scanType?: string;
  vulReference?: string;
  vulnerabilityName?: string;
  assetURL?: string;
  amId?: string;
  groupId?: string;
  assetTypeName?: string | any;
}

interface DepartmentOption {
  departmentName: string;
  dmId: string;
}

interface GenericFilterBarProps {
  getAssetsForScan?: any;
  assetsTypes?: Array<any>;
  scanDetails?: any;
  VACAscanTypes?: any;
  getVACAScanTypesApi?: any;
  assetsForScan?: any;
  enableAssetTypeFilter?: boolean | any;
  enableAssetListFilter?: boolean;
  assetSubTypeApi?: (assetType: string | any) => any;
  setOpen?: (value: boolean) => void;
  scanTypeId?: string | any;
  orgId?: string;
  locationList?: any;
  fetchLocationListApi?: (orgId?: string | any | number) => any;
  enableLocationFilter?: boolean;
  selectedSecurityManagerName?: string | any;
  setOpenAssetToGroup?: (value: boolean) => void;
  consultantSMList?: Array<Consultant> | any;
  enableReportedOnFilter?: boolean;
  assetName?: string;
  selectedClientStatus?: string | any;
  enableAssetNameFilter?: boolean;
  enableClearFilter?: boolean;
  handleApplyFilters?: () => void;
  selectedDepartment?: string | any;
  enableAssetManagerFilter?: boolean;
  enableUrlIpAddressFilter?: boolean;
  assetGroupFilter?: { amId: string | null; groupId: string | null };
  enableManagerFilter?: boolean;
  enablePublishedDateFilter: boolean;
  enableVulReferenceFilter?: boolean;
  enableAssetGroupFilter?: string | boolean;
  enableApplyFilter?: boolean;
  enableComplianceStatusFilter?: boolean;
  selectedTesterName?: string | any;
  enableVulnerabilityNameFilter?: boolean;
  enableAssetgroupFilter?: boolean;
  vulnerabilityName?: string;
  assetGroupMyLists?: { data: Array<any> };
  vulReference: string;
  scanFilter?: {
    scanName: any;
    scanType: string;
    scanRefNo: string;
    scanSubType: string;
  };
  enableTesterFilter?: boolean;
  enableClientStatusFilter?: boolean;
  enableDepartmentFilter?: boolean;
  assetList?: { data: Array<any> };
  scanId?: string;
  clientOrganizationList: Array<{ orgName: string; id: string }>;
  consultantTesterList?: Array<Consultant> | any;
  selectedClientOrganization: Array<{ orgName: string; id: string }>;
  enableAssetFilter?: boolean;
  enableClientOrganizationFilter?: boolean;
  enableConsultantStatusFilter?: boolean;
  assetTypeApi?: (scanTypeId?: string) => void;
  scanTypes?: Array<{ scanTypeDescription: string; scanTypeId: string } | any>;
  enableScanFilter?: boolean;
  clientOrgId?: string | any;
  assetSubTypes?: any;
  consultantStatusApi?: () => void;
  riskRatingApi?: () => void;
  consultantStatusValue?: Array<{ vulStatusDesc: string; vulStatusId: string }>;
  enableRiskRatingFilter?: boolean;
  clientStatusApi?: () => void;
  riskRatingValue?: Array<{ riskRatingCode: string; riskRatingDesc: string }>;
  clientStatusValue?: Array<{ vulStatusDesc: string; vulStatusId: string }>;
  fetchAssetGroupList?: (params: { orgId: string | any }) => void;
  scanTypeApi?: () => void;
  scanSubTypeApi?: (type: string) => void;
  getAssetListApi?: (params?: {
    orgId?: string | any;
    assetType?: string | any;
    assetSubType?: string | any;
    scanId?: string | any;
  }) => void;
  fetchAssetGroupManager?: (params: {
    assetGroupId: string;
    clientOrgId: string;
  }) => void;
  scanSubTypes?: Array<{
    scanSubTypeDescription: string;
    scanSubTypeId: string;
  }>;
  setFilters: (filters: Filters) => void;
  setScanFilter: (scanFilter: { scanType: string }) => void;
  setVulReference: (vulReference: string) => void;
  setVulnerabilityName: (vulnerabilityName: string) => void;
  setDateFilter: (dateFilter: {
    startDate: string | null;
    endDate: string | null;
  }) => void;
  setAssetGroupFilter?: (assetGroupFilter: {
    amId: string | null;
    groupId: string | null;
  }) => void;
  setSelectedConsultantStatus: (status: string) => void;
  setSelectedComplianceStatus: (status: string) => void;
  selectedComplianceStatus?: string | any;
  selectedConsultantStatus?: string | any;
  selectedLocation?: string | any;
  setSelectedClientStatus: (status: string | any) => void;
  setSelectedRiskRating: (rating: string | any) => void;
  setSelectedLocation?: (locationCode: string | any) => void;
  setSelectedClientOrganization: (
    organization: { orgName: string; id: string }[]
  ) => void;
  handleReset: () => void;
  setAssetName?: (assetName: string) => void;
  selectedRiskRating?: string[] | any;
  setSelectedDepartment?: (department?: string | any) => void;
  setSelectedAssetGroupList?: (assetGroupList: string | any) => void;
  setSelectedSecurityManagerName?: (manager: string) => void;
  setSelectedTesterName: (tester: string) => void;
  selectedAssetGroupList?: string[] | any;
  assetGroupManager?: AssetGroupManager[];
  assetGroupList?: string[] | any;
  filters?: Filters;
  dateFilter?: { startDate: string | null; endDate: string | null };
  departmentMyLists?: DepartmentOption[];
}

const GenericFilterBar: React.FC<GenericFilterBarProps> = (props) => {
  const inputLabelStyles = {
    // color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
    linHeight: "21px",
  };

  const [anchorEl, setAnchorEl] = useState({
    assetFilter: null,
    consultantStatus: null,
    assetTypeFilter: null,
    location: null,
    riskRating: null,
    clientStatus: null,
    dateFilter: null,
    assetManager: null,
    manager: null,
    tester: null,
    assetGroupFilter: null,
    scanFilter: null,
    department: null,
    assetGroupList: null,
    vulReference: null,
    vulnerabilityName: null,
    assetURL: null,
    assetTypeName: null,
    complianceStatus: null,
    assetName: null,
    clientOrg: null,
  });
  const [selectedAssetType, setSelectedAssetType] = useState<any>();
  const [selectedAssetSubType, setSelectedAssetSubType] = useState<
    string | undefined
  >();
  const [selectedAssetGroup, setSelectedAssetGroup] = useState<
    string | undefined | any
  >();
  const [selectedScanType, setSelectedScanType] = useState<
    string | undefined
  >();
  const [amList, setAmList] = useState<{ amId: string; amName: string }[]>([]);
  // const { departmentList , departmentMyList} = useSelector((state) => state?.departmentState);
  // const { assetGroupList, assetGroupMyList } = useSelector((state) => state?.clientAssetsState);
  const { register, watch, setValue, reset } = useForm<Filters>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const params: any = useParams();

  const isAssetSubTypeApplicable = (assetId: string) => {
    let isApplicable = false;
    props?.assetsTypes?.find((value) => {
      if (value?.assetTypeId === assetId) {
        isApplicable = value?.assetTypeApplicable;
      }
    });
    return isApplicable;
  };

  const filteredAssets = props?.assetsTypes?.filter(
    (asset) => asset?.assetTypeApplicable
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    setAnchorEl((prev) => ({
      ...prev,
      [type]: event?.currentTarget,
    }));
  };

  const handleClose = (name: string) => {
    setAnchorEl((prev) => ({
      ...prev,
      [name]: null,
    }));
  };

  // const handleClose = (name: string) => {
  //   if (name === "assetFilter") {
  //     setAnchorEl({
  //       assetFilter: null,
  //     });
  //   }
  //   if (name === "consultantStatus") {
  //     setAnchorEl({
  //       consultantStatus: null,
  //     });
  //   }
  //   if (name === "clientStatus") {
  //     setAnchorEl({
  //       clientStatus: null,
  //     });
  //   }
  //   if (name === "riskRating") {
  //     setAnchorEl({
  //       riskRating: null,
  //     });
  //   }
  //   if (name === "dateFilter") {
  //     setAnchorEl({
  //       dateFilter: null,
  //     });
  //   }
  //   if (name === "assetGroupFilter") {
  //     setAnchorEl({
  //       assetGroupFilter: null,
  //     });
  //   }
  //   if (name === "manager") {
  //     setAnchorEl({
  //       manager: null,
  //     });
  //   }
  //   if (name === "tester") {
  //     setAnchorEl({
  //       tester: null,
  //     });
  //   }
  //   if (name === "scanFilter") {
  //     setAnchorEl({
  //       scanFilter: null,
  //     });
  //   }
  //   if (name === "department") {
  //     setAnchorEl({
  //       department: null,
  //     });
  //   }
  //   if (name === "assetGroupList") {
  //     setAnchorEl({
  //       assetGroupList: null,
  //     });
  //   }

  //   if (name === "vulReference") {
  //     setAnchorEl({
  //       vulReference: null,
  //     });
  //   }
  //   if (name === "vulnerabilityName") {
  //     setAnchorEl({
  //       vulnerabilityName: null,
  //     });
  //   }
  //   if (name === "assetURL") {
  //     setAnchorEl({
  //       assetURL: null,
  //     });
  //   }
  //   if (name === "complianceStatus") {
  //     setAnchorEl({
  //       complianceStatus: null,
  //     });
  //   }
  //   if (name === "assetName") {
  //     setAnchorEl({
  //       assetName: null,
  //     });
  //   }
  // };

  useEffect(() => {
    const assetTypeAction = props?.assetTypeApi?.(props?.scanTypeId);
    if (props?.enableAssetFilter && assetTypeAction) {
      dispatch(assetTypeAction);
    }
    const consultantStatusAction = props?.consultantStatusApi?.();
    if (props?.enableConsultantStatusFilter && consultantStatusAction) {
      dispatch(consultantStatusAction);
    }
    const riskRatingAction = props?.riskRatingApi?.();
    if (props?.enableRiskRatingFilter && riskRatingAction) {
      dispatch(riskRatingAction);
    }

    const clientStatusAction = props?.clientStatusApi?.();
    if (props?.enableClientStatusFilter && clientStatusAction) {
      dispatch(clientStatusAction);
    }

    const fetchAssetGroupTypeAction = props.fetchAssetGroupList?.({
      orgId: props?.orgId,
    });
    if (props?.enableAssetFilter && fetchAssetGroupTypeAction) {
      if (props.orgId) {
        dispatch(fetchAssetGroupTypeAction);
      }
    }
    const scanTypeAction = props?.scanTypeApi?.();
    if (props?.enableScanFilter && scanTypeAction) {
      dispatch(scanTypeAction);
    }

    if (props?.enableLocationFilter && props?.fetchLocationListApi) {
      dispatch(props?.fetchLocationListApi(props?.orgId));
    }

    if (props?.enableAssetTypeFilter && props?.getAssetsForScan) {
      dispatch(props?.getAssetsForScan({ scanId: params?.scanId, clientOrgId: props?.clientOrgId }));
    }
  }, []);

  useEffect(() => {
    if (selectedAssetType && props?.assetSubTypeApi) {
      const action = props.assetSubTypeApi(selectedAssetType);
      if (action) {
        dispatch(action);
      }
    }
  }, [selectedAssetType]);

  useEffect(() => {
    if (selectedAssetType && selectedAssetSubType && props?.enableAssetFilter) {
      if (props?.getAssetListApi) {
        const action: any = props.getAssetListApi({
          assetType: selectedAssetType,
          assetSubType: selectedAssetSubType,
          // scanId: props.scanId || "",
          // orgId: props?.orgId || "",
          ...(props?.orgId && { orgId: props?.orgId || "" }),
          ...(props.scanId && { scanId: props.scanId || "" }),
        });

        if (action) {
          dispatch(action);
        }
      }
    }
  }, [selectedAssetSubType]);

  useEffect(() => {
    if (selectedAssetGroup && props?.fetchAssetGroupManager) {
      const action: any = props.fetchAssetGroupManager({
        assetGroupId: selectedAssetGroup,
        clientOrgId: props.orgId || "",
      });

      if (action) {
        dispatch(action);
      }
    }
  }, [selectedAssetGroup]);

  useEffect(() => {
    watch((value, type) => {
      props?.setFilters &&
        props?.setFilters({
          ...props?.filters,
          ...value,
        });
      setSelectedAssetType((value as Filters | any)?.assetType);
      if (type?.name === "assetType") {
        setValue("assetSubType", "");
        setValue("assetURL", "");
        setValue("assetTypeName", "");
        setValue("assetName", "");
        setValue("assetId", "");
      }
    });
    if (selectedAssetType) {
      setValue("assetSubType", "");
    }
  }, [watch, selectedAssetType]);
  useEffect(() => {
    watch((value, type) => {
      if (type?.name === "assetSubType") {
        props?.setFilters &&
          props?.setFilters({
            ...props?.filters,
            ...value,
          });
        setSelectedAssetSubType(value?.assetSubType);
      } else if (type?.name === "assetId") {
        props?.setFilters &&
          props?.setFilters({
            ...props?.filters,
            ...value,
          });
      } else if (type?.name === "scanType") {
        props?.setScanFilter({
          scanType: value?.scanType || "",
        });
      } else if (type?.name === "assetName") {
        props?.setFilters &&
          props?.setFilters({
            ...props?.filters,
            ...value,
          });
      } else if (type?.name === "assetTypeName") {
        props?.setFilters &&
          props?.setFilters({
            ...props?.filters,
            ...value,
          });
      } else if (type?.name === "assetURL") {
        props?.setFilters &&
          props?.setFilters({
            ...props?.filters,
            ...value,
          });
      } else if (type?.name === "vulReference") {
        props.setVulReference(value?.vulReference || "");
      } else if (type?.name === "vulnerabilityName") {
        props.setVulnerabilityName(value?.vulnerabilityName || "");
      }
    });
  }, [watch, selectedAssetSubType]);
  useEffect(() => {
    if (props?.setAssetGroupFilter) {
      watch((value) => {
        props?.setAssetGroupFilter &&
          props?.setAssetGroupFilter({
            amId: value?.amId || null,
            groupId: value?.groupId || null,
          });
        setSelectedAssetGroup(value?.groupId);
      });
      if (selectedAssetGroup) {
        setValue("amId", "");
      }
    }
  }, [watch, selectedAssetGroup]);

  useEffect(() => {
    setAmList(
      ((props?.assetGroupManager?.length ?? 0) > 0
        ? props.assetGroupManager?.map((value, index) => {
            return {
              amId: value?.userId,
              amName: value?.displayName,
            };
          })
        : []) ?? []
    );
  }, [props.assetGroupManager]);

  const handleConsultantStatusChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any,
    details?: any
  ) => {
    props?.setSelectedConsultantStatus(value);
  };
  const handleComplianceStatusChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    props?.setSelectedComplianceStatus(value);
  };

  const handleClientStatusChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any[],
    reason: any,
    details?: any
  ) => {
    props?.setSelectedClientStatus(value);
  };

  const handleRiskRatingChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | any
  ) => {
    props?.setSelectedRiskRating(value);
  };

  const handleLocationChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | any
  ) => {
    props?.setSelectedLocation && props?.setSelectedLocation(value);
  };

  const handleClientOrganizationChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { orgName: string; id: string }[]
  ) => {
    props?.setSelectedClientOrganization(value);
  };

  interface DateFilter {
    startDate: string | null;
    endDate: string | null;
  }

  const handleChangeDateValue = (
    field: keyof DateFilter,
    date: dayjs.Dayjs | null
  ) => {
    const newDateFilter: DateFilter = {
      ...(props?.dateFilter || { startDate: null, endDate: null }),
    };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    if (field === "startDate" && !newDateFilter.endDate && date) {
      newDateFilter.endDate = date.format("YYYY-MM-DD");
    }
    if (field === "endDate" && !newDateFilter.startDate && date) {
      newDateFilter.startDate = date.format("YYYY-MM-DD");
    }
    props?.setDateFilter(newDateFilter);
  };
  const handleReset = (type: string) => {
    switch (type) {
      case "dateFilter":
        props?.setDateFilter({ startDate: null, endDate: null });
        break;
      case "vulReference":
        props?.setVulReference("");
        break;
      case "vulnerabilityName":
        props?.setVulnerabilityName("");
        break;
      case "assetFilter":
        setSelectedAssetType("");
        setSelectedAssetSubType("");
        setValue("assetType", "");
        setValue("assetSubType", "");
        setValue("assetId", "");
        setValue("assetName", "");
        break;
      case "assetGroup":
        setSelectedAssetGroup("");
        props?.setAssetGroupFilter &&
          props.setAssetGroupFilter({ amId: null, groupId: null });
        break;
      // case "manager":
      //   props?.setAssetGroupFilter({ amId: null, groupid: null });
      //   break;
      case "scanFilter":
        props?.setScanFilter({ scanType: "" });
        setValue("scanType", "");
        break;
      case "department":
        props?.setSelectedDepartment && props.setSelectedDepartment("");
        break;
      case "assetGroupList":
        props?.setSelectedAssetGroupList && props.setSelectedAssetGroupList("");
        break;

      default:
        break;
    }
  };
  const buttonStyles = (condition: string | number | boolean) => ({
    padding: "0px 12px",
    height: "50px",
    marginTop: "auto",
    border: "1px solid #A2A2D2",
    borderRadius: "10px",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    textTransform: "none",
    // border: !condition
    //   ? theme.palette.mode === "dark"
    //     ? "2px solid white"
    //     : "2px solid black"
    //   : "none",
    "&:hover": {
      backgroundColor: !condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : theme.palette.primary.light,
    },
    "&:active": {
      backgroundColor: condition
        ? ""
        : theme.palette.mode === "dark"
        ? "white"
        : "#A2A2D2",
    },
    marginRight: "1px",
    backgroundColor: !condition
      ? ""
      : theme.palette.mode === "dark"
      ? "white"
      : theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    // color: "#A2A2D2",
    // color: condition
    //   ? theme.palette.mode === "light"
    //     ? "darkblue"
    //     : "black"
    //   : theme.palette.mode === "dark"
    //   ? "white"
    //   : "black",
    // marginTop: "6px",
  });

  interface DepartmentOption {
    departmentName: string;
    dmId: string;
  }

  const handleChangeDepartmentName = (
    event: React.SyntheticEvent<Element, Event>,
    value: DepartmentOption[] | any
  ) => {
    if (props?.setSelectedDepartment) {
      props.setSelectedDepartment(value);
    }
  };

  interface AssetGroupOption {
    groupName: string;
    groupId: string;
  }

  const handleChangeAssetGroupNameList = (
    event: React.SyntheticEvent<Element, Event>,
    value: AssetGroupOption[] | any
  ) => {
    props?.setSelectedAssetGroupList && props?.setSelectedAssetGroupList(value);
  };

  interface ManagerOption {
    smName: string;
    smId: string;
  }
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {

  const handleChangeManagerName = (
    event: React.SyntheticEvent,
    value: ManagerOption[] | any
  ) => {
    props?.setSelectedSecurityManagerName &&
      props?.setSelectedSecurityManagerName(value);
  };

  interface TesterOption {
    testerName: string;
    testerId: string;
  }

  const handleChangeTesterName = (event: React.SyntheticEvent, value: any) => {
    props?.setSelectedTesterName(value);
  };

  const displaySMName =
    (props?.consultantSMList?.length ?? 0) > 0 &&
    props?.consultantSMList?.map((value: any) => {
      return {
        smName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        smId: value.userId,
      };
    });

  const displayTesterName =
    (props?.consultantTesterList?.length ?? 0) > 0 &&
    props?.consultantTesterList?.map((value: any) => {
      return {
        testerName: `${value.firstName} ${value.lastName} (${value.empCode})`,
        testerId: value.userId,
      };
    });

  return (
    <>
      <>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* Client Organization Filter */}
          {props?.enableClientOrganizationFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "clientOrg")}
              sx={buttonStyles(props?.selectedClientOrganization?.length)}
            >
              Client Organization
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "3px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.clientOrg}
            open={Boolean(anchorEl?.clientOrg)}
            onClose={() => handleClose("clientOrg")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  multiple
                  size="small"
                  // id="scan-status-autocomplete"
                  options={props?.clientOrganizationList || []}
                  getOptionLabel={(option) => option?.orgName}
                  value={props?.selectedClientOrganization || []}
                  onChange={handleClientOrganizationChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.orgName} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Asset Filter Button */}
          {props?.enableAssetTypeFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetTypeFilter")}
              sx={buttonStyles(props?.filters?.assetType || "")}
            >
              Asset
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetTypeFilter}
            open={Boolean(anchorEl?.assetTypeFilter)}
            onClose={() => handleClose("assetTypeFilter")}
          >
            <div style={{ width: 450, padding: 10 }}>
              {" "}
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel style={{ ...inputLabelStyles }}>
                      Asset:
                    </InputLabel>
                    <FormControlSelect
                      size="small"
                      // disabled={
                      //   selectedAssetType && selectedAssetSubType ? false : true
                      // }
                      itemsList={
                        props?.assetsForScan?.data?.map(
                          (item: any) => item.assetDetails
                        ) ?? []
                      }
                      optionLabel={"assetName"}
                      optionValue={"assetId"}
                      {...register("assetTypeName")}
                      value={props?.filters?.assetTypeName || ""}
                      name="assetTypeName"
                    />
                  </Grid>

              </Grid>
            </div>
          </Menu>

          {/* Asset Filter Button */}
          {props?.enableAssetFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetFilter")}
              sx={buttonStyles(
                props?.filters?.assetType ||
                  "" ||
                  props?.filters?.assetSubType ||
                  "" ||
                  props?.filters?.assetId ||
                  ""
              )}
            >
              Assets
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetFilter}
            open={Boolean(anchorEl?.assetFilter)}
            onClose={() => handleClose("assetFilter")}
          >
            <div style={{ width: 450, padding: 10 }}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel style={{ ...inputLabelStyles }}>
                    Asset Type:
                  </InputLabel>
                  <FormControlSelect
                    size="small"
                    itemsList={filteredAssets}
                    optionLabel={"assetTypeDesc"}
                    optionValue={"assetTypeId"}
                    {...register("assetType")}
                    value={props?.filters?.assetType || ""}
                    name="assetType"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel style={{ ...inputLabelStyles }}>
                    Asset Sub-Type:
                  </InputLabel>
                  <FormControlSelect
                    size="small"
                    disabled={
                      !selectedAssetType ||
                      !isAssetSubTypeApplicable(selectedAssetType)
                    }
                    itemsList={
                      props?.assetsTypes?.find(
                        (item) => item?.assetTypeId === selectedAssetType
                      )?.assetSubTypes || []
                    }
                    optionLabel={"assetSubTypeDesc"}
                    optionValue={"assetSubTypeId"}
                    {...register("assetSubType")}
                    value={props?.filters?.assetSubType || ""}
                    name="assetSubType"
                  />
                </Grid>
                {props?.enableAssetListFilter ? (
                  <Grid item xs={12}>
                    <InputLabel style={{ ...inputLabelStyles }}>
                      Asset:
                    </InputLabel>
                    <FormControlSelect
                      size="small"
                      disabled={
                        selectedAssetType && selectedAssetSubType ? false : true
                      }
                      itemsList={
                        selectedAssetType && selectedAssetSubType
                          ? props?.assetList?.data
                          : []
                      }
                      optionLabel={"assetName"}
                      optionValue={"assetId"}
                      {...register("assetId")}
                      value={props?.filters?.assetId || ""}
                      name="assetId"
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {/* {props?.enableAssetNameFilter ? (
                <Grid item xs={12}>
                   <InputLabel style={{ ...inputLabelStyles}}>Asset Name:</InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    value={props?.filters?.assetName || ""}
                    name="assetName"
                    {...register("assetName")}
                    fullWidth
                  />
                </Grid>
              ) : (
                ""
              )} */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                    <Button
                      size="large"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0px",
                        color: "white",
                        width: "95px",
                        height: "35px",
                        borderRadius: "6px",
                        background:
                          "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                        textTransform: "none", // This removes the uppercase transformation
                      }}
                      variant="contained"
                      disabled={
                        !selectedAssetType && !props?.filters?.assetName
                      }
                      onClick={() => handleReset("assetFilter")}
                      startIcon={
                        <ReplayIcon
                          sx={{
                            fontSize: "20px",
                          }}
                        />
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Menu>

          {/* Manager Filter Button */}
          {props?.enableManagerFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "manager")}
              sx={buttonStyles(props?.selectedSecurityManagerName?.length || 0)}
            >
              Manager
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "3px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.manager}
            open={Boolean(anchorEl?.manager)}
            onClose={() => handleClose("manager")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  options={displaySMName || []}
                  getOptionLabel={(option) => option?.smName}
                  value={props.selectedSecurityManagerName || []}
                  onChange={handleChangeManagerName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.smName} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
          </Menu>
          {/* Tester Filter Button */}
          {props?.enableTesterFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "tester")}
              sx={buttonStyles(props?.selectedTesterName?.length || 0)}
            >
              Tester
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "3px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.tester}
            open={Boolean(anchorEl?.tester)}
            onClose={() => handleClose("tester")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  multiple
                  size="small"
                  options={displayTesterName || []}
                  getOptionLabel={(option) => option?.testerName}
                  value={props.selectedTesterName || []}
                  onChange={handleChangeTesterName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.testerName} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  // sx={{
                  //   "& .MuiAutocomplete-listbox": {
                  //     maxHeight: "200px", // Set the maximum height of the dropdown
                  //     overflow: "auto", // Add scroll bar if needed
                  //   },
                  // }}
                  // PaperComponent={(props) => (
                  //   <Paper
                  //     {...props}
                  //     style={{ maxHeight: 200, overflow: "auto" }}
                  //   />
                  // )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Manager department Button */}
          {props?.enableDepartmentFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "department")}
              sx={buttonStyles(props?.selectedDepartment?.length || 0)}
            >
              Department
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "3px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.department}
            open={Boolean(anchorEl?.department)}
            onClose={() => handleClose("department")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  options={props?.departmentMyLists || []}
                  getOptionLabel={(option) => option?.departmentName}
                  value={props.selectedDepartment || []}
                  onChange={handleChangeDepartmentName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.departmentName} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Asset group list filter */}
          {props?.enableAssetgroupFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetGroupList")}
              sx={buttonStyles(props?.selectedAssetGroupList?.length || 0)}
            >
              Asset group
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "3px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetGroupList}
            open={Boolean(anchorEl?.assetGroupList)}
            onClose={() => handleClose("assetGroupList")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  options={props?.assetGroupMyLists?.data || []}
                  getOptionLabel={(option) => option?.groupName}
                  value={props?.selectedAssetGroupList || []}
                  onChange={handleChangeAssetGroupNameList}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.groupName} key={option?.id} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {props?.enableScanFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "scanFilter")}
              sx={buttonStyles(props?.filters?.scanType || "")}
            >
              Scan
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.scanFilter}
            open={Boolean(anchorEl?.scanFilter)}
            onClose={() => handleClose("scanFilter")}
          >
            <div style={{ width: 550, padding: 10 }}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel style={{ ...inputLabelStyles }}>Type:</InputLabel>
                  <FormControlSelect
                    size="small"
                    itemsList={props?.scanTypes}
                    optionLabel={"scanTypeDescription"}
                    optionValue={"scanTypeId"}
                    {...register("scanType")}
                    value={props?.scanFilter?.scanType || ""}
                    name="scanType"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                    <Button
                      size="large"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0px",
                        color: "white",
                        width: "95px",
                        height: "35px",
                        borderRadius: "6px",
                        background:
                          "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                        textTransform: "none", // This removes the uppercase transformation
                      }}
                      variant="contained"
                      onClick={() => handleReset("scanFilter")}
                      startIcon={
                        <ReplayIcon
                          sx={{
                            fontSize: "20px",
                          }}
                        />
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Menu>
          {/* Risk Rating Status Filter */}
          {props?.enableRiskRatingFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "riskRating")}
              sx={buttonStyles(props?.selectedRiskRating?.length || 0)}
            >
              Risk Rating
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.riskRating}
            open={Boolean(anchorEl?.riskRating)}
            onClose={() => handleClose("riskRating")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  id="client-status-autocomplete"
                  options={props?.riskRatingValue || []}
                  getOptionLabel={(option) => option?.riskRatingDesc}
                  value={props?.selectedRiskRating || []}
                  onChange={handleRiskRatingChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.riskRatingDesc} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label=" "
                    />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Location Filter */}
          {props?.enableLocationFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "location")}
              sx={buttonStyles(props?.selectedLocation?.length || 0)}
            >
              Location
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.location}
            open={Boolean(anchorEl?.location)}
            onClose={() => handleClose("location")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  id="client-status-autocomplete"
                  options={props?.locationList || []}
                  getOptionLabel={(option) => option?.locationDesc}
                  value={props?.selectedLocation || []}
                  onChange={handleLocationChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.locationDesc} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label=" "
                    />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Client Status Filter */}
          {props?.enableClientStatusFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "clientStatus")}
              sx={buttonStyles(props?.selectedClientStatus?.length || 0)}
            >
              Client Status
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.clientStatus}
            open={Boolean(anchorEl?.clientStatus)}
            onClose={() => handleClose("clientStatus")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  multiple
                  id="client-status-autocomplete"
                  options={props?.clientStatusValue || []}
                  getOptionLabel={(option: { vulStatusDesc: string }) =>
                    option?.vulStatusDesc
                  }
                  value={props?.selectedClientStatus || []}
                  onChange={handleClientStatusChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.vulStatusDesc} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label=" "
                    />
                  )}
                />
              </FormControl>
            </div>
          </Menu>
          {props?.enableVulReferenceFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "vulReference")}
              sx={buttonStyles(
                props?.scanFilter?.scanRefNo ||
                  props?.scanFilter?.scanSubType ||
                  props?.scanFilter?.scanType ||
                  props?.vulReference
              )}
            >
              Vulnerability Reference
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.vulReference}
            open={Boolean(anchorEl?.vulReference)}
            onClose={() => handleClose("vulReference")}
          >
            <div style={{ width: 550, padding: 10 }}>
              <Grid item xs={6}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  Vulnerability Ref Code
                </InputLabel>
                <TextField
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={props?.vulReference || ""}
                  // name="vulReference"
                  {...register("vulReference")}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("vulReference")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  sx={{
                    minWidth: 90,
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid> */}
              {/* </Grid> */}
            </div>
          </Menu>
          {props?.enableVulnerabilityNameFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "vulnerabilityName")}
              sx={buttonStyles(
                props?.scanFilter?.scanName ||
                  props?.scanFilter?.scanRefNo ||
                  props?.scanFilter?.scanSubType ||
                  props?.scanFilter?.scanType ||
                  props?.vulnerabilityName
              )}
            >
              Vulnerability Name
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.vulnerabilityName}
            open={Boolean(anchorEl?.vulnerabilityName)}
            onClose={() => handleClose("vulnerabilityName")}
          >
            <div style={{ width: 550, padding: 10 }}>
              <Grid item xs={12}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  Vulnerability Name
                </InputLabel>
                <TextField
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={props?.vulnerabilityName || ""}
                  // name="vulnerabilityName"
                  {...register("vulnerabilityName")}
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleReset("vulReference")}
                  startIcon={
                    <ReplayIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  sx={{
                    minWidth: 90,
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid> */}
              {/* </Grid> */}
            </div>
          </Menu>
          {props?.enableUrlIpAddressFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetURL")}
              sx={buttonStyles(props?.filters?.assetURL || "")}
            >
              URL/IP Address
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetURL}
            open={Boolean(anchorEl?.assetURL)}
            onClose={() => handleClose("assetURL")}
          >
            <div style={{ width: 550, padding: 10 }}>
              <Grid item xs={12}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  URL / IP address:
                </InputLabel>
                <TextField
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={props?.filters?.assetURL || ""}
                  // name="assetURL"
                  {...register("assetURL")}
                  fullWidth
                  placeholder="eg: 198.1.1.1"
                />
              </Grid>
            </div>
          </Menu>
          {props?.enableAssetNameFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetName")}
              sx={buttonStyles(props?.filters?.assetName || "")}
            >
              Asset Name
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetName}
            open={Boolean(anchorEl?.assetName)}
            onClose={() => handleClose("assetName")}
          >
            <div style={{ width: 550, padding: 10 }}>
              <Grid item xs={12}>
                <InputLabel style={{ ...inputLabelStyles }}>
                  Asset Name:{" "}
                </InputLabel>
                <TextField
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  value={props?.filters?.assetName || ""}
                  // name="assetName"
                  {...register("assetName")}
                  fullWidth
                  // placeholder="Enter Asset Name"
                />
              </Grid>
            </div>
          </Menu>
          {/* Consultant Status Filter */}
          {props?.enableConsultantStatusFilter && (
            <Button
              // variant="contained"
              color="primary"
              onClick={(event) => handleClick(event, "consultantStatus")}
              sx={buttonStyles(props?.selectedConsultantStatus?.length || 0)}
            >
              Consultant Status
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.consultantStatus}
            open={Boolean(anchorEl?.consultantStatus)}
            onClose={() => handleClose("consultantStatus")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  multiple
                  size="small"
                  id="consultant-status-autocomplete"
                  options={props?.consultantStatusValue || []}
                  getOptionLabel={(option: { vulStatusDesc: string }) =>
                    option?.vulStatusDesc
                  }
                  value={props?.selectedConsultantStatus || []}
                  onChange={handleConsultantStatusChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.vulStatusDesc} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label=" "
                    />
                  )}
                />
              </FormControl>
            </div>
          </Menu>

          {/* Complaince Status Filter */}
          {props?.enableComplianceStatusFilter && (
            <Button
              // variant="contained"
              color="primary"
              onClick={(event) => handleClick(event, "complianceStatus")}
              sx={buttonStyles(props?.selectedComplianceStatus?.length || 0)}
            >
              Compliance Status
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.complianceStatus}
            open={Boolean(anchorEl?.complianceStatus)}
            onClose={() => handleClose("complianceStatus")}
          >
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Autocomplete
                  size="small"
                  id="compliance-status-autocomplete"
                  options={complianceStatus || []}
                  getOptionLabel={(option) =>
                    option?.complianceStatusLabel || ""
                  }
                  value={props?.selectedComplianceStatus || []}
                  onChange={handleComplianceStatusChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue?.map((option, index) => (
                      <Chip label={option?.complianceStatusLabel} key={index} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label=" "
                    />
                  )}
                />
              </FormControl>
            </div>
          </Menu>
          {props?.enableAssetGroupFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "assetGroupFilter")}
              sx={buttonStyles(props?.assetGroupFilter?.groupId ?? "")}
            >
              Assets Group
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.assetGroupFilter}
            open={Boolean(anchorEl?.assetGroupFilter)}
            onClose={() => handleClose("assetGroupFilter")}
          >
            <div style={{ width: 450, padding: 10 }}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={props.enableAssetManagerFilter ? 6 : 12}>
                  <InputLabel style={{ ...inputLabelStyles }}>
                    Asset Group :
                  </InputLabel>
                  <FormControlSelect
                    size="small"
                    itemsList={props?.assetGroupList?.data}
                    optionLabel={"groupName"}
                    optionValue={"groupId"}
                    {...register("groupId")}
                    value={props?.assetGroupFilter?.groupId || ""}
                    name="groupId"
                  />
                </Grid>
                {props.enableAssetManagerFilter && (
                  <Grid item xs={6}>
                    <InputLabel style={{ ...inputLabelStyles }}>
                      Asset Manager:
                    </InputLabel>
                    <FormControlSelect
                      size="small"
                      disabled={!selectedAssetGroup}
                      itemsList={amList || []}
                      optionLabel={"amName"}
                      optionValue={"amId"}
                      {...register("amId")}
                      value={props?.assetGroupFilter?.amId || ""}
                      name="amId"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                    <Button
                      size="large"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0px",
                        color: "white",
                        width: "95px",
                        height: "35px",
                        borderRadius: "6px",
                        background:
                          "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                        textTransform: "none", // This removes the uppercase transformation
                      }}
                      variant="contained"
                      disabled={!selectedAssetGroup}
                      onClick={() => handleReset("assetGroup")}
                      startIcon={
                        <ReplayIcon
                          sx={{
                            fontSize: "20px",
                          }}
                        />
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Menu>
          {/* Reported On Filter */}
          {props?.enableReportedOnFilter && (
            <Button
              color="primary"
              onClick={(event) => handleClick(event, "dateFilter")}
              sx={buttonStyles(
                props?.dateFilter?.startDate || props?.dateFilter?.endDate || 0
              )}
            >
              {props?.enablePublishedDateFilter
                ? "Published On"
                : "Reported On"}
              <ArrowDropDownIcon
                sx={{
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          )}
          <Menu
            anchorEl={anchorEl?.dateFilter}
            open={Boolean(anchorEl?.dateFilter)}
            onClose={() => handleClose("dateFilter")}
          >
            <div style={{ width: 450, padding: 10 }}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="From Date"
                        format="DD/MM/YYYY"
                        value={
                          props?.dateFilter?.startDate
                            ? dayjs(props?.dateFilter?.startDate)
                            : null
                        }
                        onChange={(d) => handleChangeDateValue("startDate", d)}
                      />
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        format="DD/MM/YYYY"
                        value={
                          props?.dateFilter?.endDate
                            ? dayjs(props?.dateFilter?.endDate)
                            : null
                        }
                        minDate={dayjs(props?.dateFilter?.startDate)}
                        onChange={(d) => handleChangeDateValue("endDate", d)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ marginTop: "10px", gap: 2 }}
                >
                  <Button
                    disabled={
                      !(
                        props?.dateFilter?.startDate ||
                        props?.dateFilter?.endDate
                      )
                    }
                    size="large"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                      letterSpacing: "0px",
                      color: "white",
                      width: "95px",
                      height: "35px",
                      borderRadius: "6px",
                      background:
                        "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
                      textTransform: "none", // This removes the uppercase transformation
                    }}
                    variant="contained"
                    onClick={() => handleReset("dateFilter")}
                    startIcon={
                      <ReplayIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </div>
          </Menu>

          {/* Apply Button */}
          {props?.enableApplyFilter && (
            <Tooltip title={"Apply Filter"}>
              <IconButton
                onClick={props?.handleApplyFilters}
                sx={{ p: 0, color: "#fff" }}
              >
                <img src={filter_tick_icon} alt="Icon" width={28} height={28} />
              </IconButton>
            </Tooltip>
            // <Button
            //   color="primary"
            //   onClick={props?.handleApplyFilters}
            //   sx={{
            //     ...commonButtonStyles,
            //   }}
            // >
            //   Apply
            //   <CheckIcon
            //     sx={{
            //       fontSize: "20px",
            //       marginLeft: "5px",
            //     }}
            //   />
            // </Button>
          )}
          {/* Clear Filter */}
          {props?.enableClearFilter && (
            <Tooltip title={"Clear Filter"}>
              <IconButton
                onClick={() => {
                  reset();
                  props?.handleReset();
                }}
                sx={{ p: 0, color: "#fff" }}
              >
                <img
                  src={filter_close_icon}
                  alt="Icon"
                  width={28}
                  height={28}
                />
              </IconButton>
            </Tooltip>

            // <Button
            //   color="primary"
            //   onClick={() => {
            //     reset();
            //     props?.handleReset();
            //   }}
            //   sx={{
            //     ...commonButtonStyles,
            //   }}
            // >
            //   Clear All Filters
            //   <ReplayIcon
            //     sx={{
            //       fontSize: "20px",
            //     }}
            //   />
            // </Button>
          )}
        </div>
      </>
    </>
  );
};

export default GenericFilterBar;
