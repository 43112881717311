import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { buttonStyle, outlineButtonStyle } from "src/config";

interface ConfirmUpdateInternalStatusProps {
  open: boolean;
  handleClose: () => void;
  confirmAction: () => void;
  loader: boolean;
}

const ConfirmUpdateInternalStatus: React.FC<ConfirmUpdateInternalStatusProps> = ({
  open,
  handleClose,
  confirmAction,
  loader,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 10,
        sx: {
          borderRadius: 8,
          padding: "20px",
        },
      }}
    >
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          textAlign: "center",
          fontSize: 22,
          fontWeight: 700,
          color: theme.palette.text.primary,
          marginBottom: 2,
        }}
      >
        Confirm Update
      </DialogTitle>

      <DialogContent sx={{ paddingX: 4, paddingBottom: 3 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 2,
            color: theme.palette.text.primary,
            fontSize: "1rem",
          }}
        >
          Please note that when you proceed with bulk status update of findings
          using this feature, the system assumes that you have carefully gone
          through all the findings under review and are aware of the impact of the changes
          you are making.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", paddingX: 4, paddingBottom: 3 }}>
        <Stack direction="row" justifyContent="flex-end" gap={2} sx={{ width: "100%" }}>
          <LoadingButton
            size="small"
            onClick={handleClose}
            loading={loader}
            sx={{
              backgroundColor: "transparent",
              ...outlineButtonStyle,
              padding: "6px 16px",
              flex: 1,
            }}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            size="small"
            variant="contained"
            // autoFocus
            onClick={confirmAction}
            loading={loader}
            sx={{
              padding: "6px 16px",
              flex: 1,
              ...buttonStyle
            }}
          >
            Proceed
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateInternalStatus;
