import axios from 'axios';

const createAxiosInstance = () => {
  // const serviceToken:string = localStorage.getItem('serviceToken');

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });

  // Interceptor for modifying requests
  instance.interceptors.request.use(
    (config) => {
      const serviceToken = localStorage.getItem('serviceToken');
      if (serviceToken) {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${serviceToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Interceptor for handling response errors
  instance.interceptors.response.use(
    (response) => response,
    (error) =>
      Promise.reject({
        status: error?.response?.status,
        open: true,
        message:
          error?.response?.data?.errors[0]?.errorMessage || 'Something Wrong',
        transition: 'Fade',
        variant: 'alert',
        alert: {
          color: 'error',
        },
      })
  );

  return instance;
};

const axiosServices = createAxiosInstance();

export default axiosServices;
