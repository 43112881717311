import React, { useMemo, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ACTION_TYPE } from "../../../constant";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import VmTable from "../../../ui-component/table";
import { userActionAccess } from "../../../utils/helpers";
import theme from "src/themes/theme";
import useConfig from "src/hooks/useConfig";

interface FileData {
  id: number;
  file: File;
}

interface RawActionProps {
  uploadedFiles: FileData[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<FileData[]>>;
  row: { index: number };
}

const RawAction: React.FC<RawActionProps> = ({
  uploadedFiles,
  setUploadedFiles,
  row,
}) => {
  const handleDeleteFile = () => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(row.index, 1);
    setUploadedFiles(updatedFiles);
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <IconButton onClick={handleDeleteFile}>
        <DeleteIcon sx={{ color: theme.palette.error.main }} />
      </IconButton>
    </Stack>
  );
};

interface AddCommentsProps {
  handleCommentsModel?: () => void;
  uploadedFiles: FileData[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<FileData[]>>;
  handleSubmitComment: (comment: string) => void;
}

const AddComments: React.FC<AddCommentsProps> = ({
  uploadedFiles,
  setUploadedFiles,
  handleSubmitComment,
}) => {
  const { drawerType, container, layout, navType } = useConfig();
  const params = useParams();
  const [comment, setComment] = useState<string>("");

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFile = e.target.files[0];
      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        {
          id: prevUploadedFiles.length + 1,
          file: newFile,
        },
      ]);
    }
  };

  const handleSubmit = () => {
    handleSubmitComment(comment);
    setUploadedFiles([]);
  };

  const handleChangeEditor = (value: string) => {
    setComment(value);
  };

  const commentAttachment = useMemo(
    () => [
      {
        accessorKey: "file.name",
        header: "File Name",
        size: 150,
      },
      {
        accessorKey: "size",
        header: "File Size",
        size: 150,
        Cell: ({ row }: { row: { original: FileData } }) => (
          <>{(row.original.file.size / 1024 ** 2).toFixed(2)}MB</>
        ),
      },
      {
        accessorKey: "lastModifiedDate",
        header: "Uploaded On",
        Cell: ({ row }: { row: { original: FileData } }) => (
          <>{moment(row.original.file.lastModified).format("Do MMM YYYY")}</>
        ),
      },
      {
        header: "Actions",
        Cell: ({ row }: { row: { index: number } }) => (
          <RawAction
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            row={row}
          />
        ),
      },
    ],
    [uploadedFiles]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction={"column"} gap={2}>
          <Typography variant="h5">Description</Typography>
          <ReactQuill
            className={navType === "dark" ? "custom-quill" : ""}
            onChange={handleChangeEditor}
            value={comment}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={"column"} gap={2}>
          <Typography variant="h5">Attachment</Typography>
          <TextField
            type="file"
            id="file-upload"
            fullWidth
            sx={{ display: "none" }}
            onChange={handleUploadFile}
          />
          <InputLabel
            htmlFor="file-upload"
            sx={{
              py: 3.75,
              px: 0,
              textAlign: "center",
              height: "100px",
              borderRadius: "4px",
              border: "1px dashed gray",
              cursor: "pointer",
              mb: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CloudUploadIcon sx={{ color: theme.palette.error.main }} /> Drop
            file here to upload
          </InputLabel>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <VmTable columns={commentAttachment} data={uploadedFiles} />
      </Grid>

      {userActionAccess(ACTION_TYPE?.ADD_SCAN_CMT) && (
        <Stack
          mt={3}
          justifyContent={"flex-end"}
          width={"100%"}
          direction={"row"}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              letterSpacing: "0px",
              color: "white",
              width: "105px",
              height: "50px",
              borderRadius: "10px !important",
              background:
                "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
              textTransform: "none", // This removes the uppercase transformation
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Stack>
      )}
    </Grid>
  );
};

export default AddComments;
