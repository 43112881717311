import React from 'react';
// material-ui
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

// project imports
import Avatar from "../../ui-component/extended/Avatar";
import useConfig from "src/hooks/useConfig";
import theme from "src/themes/theme";

// ==============================|| CUSTOMIZATION - MODE ||============================== //

const InputFilled: React.FC = () => {
  const { outlinedFilled, onChangeOutlinedField } = useConfig();

  // Ensure the event is typed
  const changeInputBackground = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOutlinedFilled = e.target.value === "filled";
    if (newOutlinedFilled !== outlinedFilled) {
      onChangeOutlinedField(newOutlinedFilled);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      pb={2}
      px={2}
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Typography variant="h5">INPUT BACKGROUND</Typography>
      <RadioGroup
        row
        aria-label="layout"
        value={outlinedFilled ? "outlined" : "filled"}
        onChange={changeInputBackground}
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          control={<Radio value="filled" sx={{ display: "none" }} />}
          label={
            <Avatar
              size="md"
              variant="rounded"
              color="inherit"
              outline
              sx={{
                width: 48,
                height: 30,
                bgcolor: theme.palette.mode === "dark" ? "dark.800" : "grey.50",
                mr: 1,
                ...(!outlinedFilled && {
                  borderColor: `${theme.palette.divider}20`, // Add transparency
                }),
              }}
            >
              {" "}
            </Avatar>
          }
        />
        <FormControlLabel
          control={<Radio value="outlined" sx={{ display: "none" }} />}
          label={
            <Avatar
              size="md"
              variant="rounded"
              outline
              color="inherit"
              sx={{
                width: 48,
                height: 30,
                ...(outlinedFilled && { borderColor: theme.palette.divider }),
              }}
            >
              {" "}
            </Avatar>
          }
        />
      </RadioGroup>
    </Stack>
  );
};

export default InputFilled;
