import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import React from "react";

export default function BackButtonCustom({ handleGoBack }: any) {
  return (
    <IconButton
      sx={{
        background:
          "linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%) !important",
        padding: "13px",
        marginRight: "20px",
        alignItems: "center",
        textAlign: "center",
        borderRadius: "10px",
      }}
      onClick={handleGoBack}
    >
      <ArrowBackIosNewIcon />
    </IconButton>
  );
}
