import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { isLoading, setLoading } from "./user";
import { PORTAL_TYPE } from "src/constant";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";

// initial state
const initialState = {
  error: null,
  otp: {},
  changePasswordResponce: "",
};

// ==============================|| SLICE - CHANGE PASSOWORD ||============================== //

const changePassword = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getOtp(state, action) {
      state.otp = action.payload;
    },
  },
});

export default changePassword.reducer;

export function getOtpToChangePassword() {
  return async () => { dispatch(setLoading(true));

    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      const prefix =
        localStorage.getItem("organizationType") === PORTAL_TYPE.CONSULTANT
          ? "/consultant/v1/user"
          : localStorage.getItem("organizationType") === PORTAL_TYPE.PLATFORM
          ? "/platform/v1/user"
          : "/client/v1/user";
      const response = await axios.post(`${prefix}/change-password-otp`);
      dispatch(changePassword.actions.getOtp(response.data));
      notificationManager(response);
    } catch (error: any) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(changePassword.actions.hasError(error));
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
}
