// third-party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { openSnackbar, SnackbarState } from "./snackbarReducer";

// project imports
import { setLoading } from "./user";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";
import { dispatch } from "../index";
import { ResponseState } from "./reducerTypes";

interface ClientConsultantMapState {
  error: string | null;
  consultantorgList: Record<string, unknown>[]; // Array of objects
  consultantClientUnmappedList: Record<string, unknown>[]; // Array of objects
  consultantClientMappedList: Record<string, unknown>[]; // Array of objects
  selectedClientMappingDetails: Record<string, unknown> | unknown | any; // Single object
}

const initialState: ClientConsultantMapState = {
  error: null,
  consultantorgList: [],
  consultantClientUnmappedList: [],
  consultantClientMappedList: [],
  selectedClientMappingDetails: {},
};

const slice = createSlice({
  name: "clientconsultantmap",
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    getConsultantOrganizationSuccess(state, action) {
      state.consultantorgList = action.payload;
    },

    getConsultantClientUnmappedList(state, action) {
      state.consultantClientUnmappedList = action.payload;
    },

    getConsultantClientMappedList(state, action) {
      state.consultantClientMappedList = action.payload;
    },

    getSelectedClientMappingDetails(state, action) {
      state.selectedClientMappingDetails = action.payload;
    },

    addconsultantClientMappingPost(state, action) {
      state.consultantClientMappedList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

//----------------------------------------------------------

export const getConsultantOrganization = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get("/platform/v1/consultant-org/list");
      dispatch(slice.actions.getConsultantOrganizationSuccess(response.data));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unexpected error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

interface OrganizationPayload {
  orgId: string;
  data?: Record<string, unknown>;
}

export const getUnmappedClientOrganization = (payload: OrganizationPayload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/unmapped-clients/${payload.orgId}`
      );
      dispatch(slice.actions.getConsultantClientUnmappedList(response.data));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unexpected error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMappedClientOrganization = (payload: OrganizationPayload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/mapped-clients/${payload.orgId}`
      );
      dispatch(slice.actions.getConsultantClientMappedList(response.data));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unexpected error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addUnmappedClientOrganization = (payload: OrganizationPayload) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `/platform/v1/consultant-org/map-clients/${payload.orgId}`,
        payload.data
      );
      dispatch(slice.actions.addconsultantClientMappingPost(response.data));
      notificationManager(response);
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unexpected error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchSelectedClientOrganizationDetails = (
  payload: OrganizationPayload
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `/platform/v1/consultant-org/details/${payload.orgId}`
      );
      dispatch(slice.actions.getSelectedClientMappingDetails(response.data));
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unexpected error occurred";
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
