// third-party
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { setLoading } from "./user";
import { notificationManager } from "src/ui-component/notificationManager";
import axios from "src/utils/axios";

// API endpoints
const clientScanApiEndpoint = "/client/v1/scan";
const clientReportsApiEndpoint = "/client/v1/reports";
const clientVulnerabilityApiEndpoint = "/client/v1/vulnerability";
const consultantEndpointApi = "/consultant/v1";
const clientEvidenceApiEndpoint = "/client/v1/vul-evidence";

// State Types
interface ClientScansState {
  error: string | null;
  consultantOrganizationList: unknown[];
  deleteScanResponse: unknown[];
  publishedVulnerabilityList: Record<string, unknown> | any;
  selectedPublishedVulnerability: Record<string, unknown> | any;
  rescanSavedVulnerabilityList: unknown[] | any;
  vulnerabilityList: unknown[] | any;
  rescanVulnerabilities: Record<string, unknown> | any;
  rescanVulnerabilityList: unknown[];
  deleteRescanSavedVulnerabilityResponse: unknown[];
  updateSubmitScanToConsultant: unknown[];
  publishedVulnerabilityEvidenceList: unknown[];
  downloadSelectedVulnerabilityEvidence: Record<string, unknown> | any;
  selectedRescanVulnerability: Record<string, unknown> | any;
  updateRescanVulnerabilityStatus: Record<string, unknown>;
  AgeBuckets: unknown[];
  VulnerabilitiesExportResponse: Record<string, unknown>;
  scanStatusFilterList: unknown[];
  exportPushlishedVulnerabilityRes: Record<string, unknown>;
}

// Initial State
const initialState: ClientScansState = {
  error: null,
  consultantOrganizationList: [],
  deleteScanResponse: [],
  publishedVulnerabilityList: {},
  selectedPublishedVulnerability: {},
  rescanSavedVulnerabilityList: [],
  vulnerabilityList: [],
  rescanVulnerabilities: {},
  rescanVulnerabilityList: [],
  deleteRescanSavedVulnerabilityResponse: [],
  updateSubmitScanToConsultant: [],
  publishedVulnerabilityEvidenceList: [],
  downloadSelectedVulnerabilityEvidence: {},
  selectedRescanVulnerability: {},
  updateRescanVulnerabilityStatus: {},
  AgeBuckets: [],
  VulnerabilitiesExportResponse: {},
  scanStatusFilterList: [],
  exportPushlishedVulnerabilityRes: {},
};

// Slice
const slice = createSlice({
  name: "client-scans",
  initialState,
  reducers: {
    // Handle errors
    hasError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },

    // GET APIs
    getConsultantOrganizationListSuccess(state, action) {
      state.consultantOrganizationList = action.payload;
    },
    getPublishedVulnerabilityListSuccess(state, action) {
      state.publishedVulnerabilityList = action.payload;
    },

    getSelectedPublishedVulnerabilitySuccess(state, action) {
      state.selectedPublishedVulnerability = action.payload;
    },
    getRescanSavedVulnerabilityListSuccess(state, action) {
      state.rescanSavedVulnerabilityList = action.payload;
    },
    getVulnerabilitiesListSuccess(state, action) {
      state.vulnerabilityList = action.payload;
    },
    getPublishedVulnerabilityEvidenceListSuccess(state, action) {
      state.publishedVulnerabilityEvidenceList = action.payload;
    },
    getDownloadSelectedPublishedVulEvidenceSuccess(state, action) {
      state.downloadSelectedVulnerabilityEvidence = action.payload;
    },
    getSelectedRescanVulnerabiitySuccess(state, action) {
      state.selectedRescanVulnerability = action.payload;
    },
    fetchAgeBucketsSuccess(state, action) {
      state.AgeBuckets = action.payload;
    },
    VulnerabilitiesExportSuccess(state, action) {
      state.VulnerabilitiesExportResponse = action.payload;
    },
    getScanStatusFilterListSuccess(state, action) {
      state.scanStatusFilterList = action.payload;
    },
    exportPushlishedVulnerabilitySuccess(state, action) {
      state.exportPushlishedVulnerabilityRes = action.payload;
    },

    // DELETE APIs
    deleteScanSuccess(state, action) {
      state.deleteScanResponse = action.payload;
    },
    deleteRescanSavedVulnerabilitySuccess(state, action) {
      state.deleteRescanSavedVulnerabilityResponse = action.payload;
    },

    // POST APIs
    addRescanVulnerabilitiesSuccess(state, action) {
      state.rescanVulnerabilities = action.payload;
    },

    // PUT APIs
    updateSubmitScanToConsultantSuccess(state, action) {
      state.updateSubmitScanToConsultant = action.payload;
    },
    updateRescanVulnerabilityStatusSuccess(state, action) {
      state.updateRescanVulnerabilityStatus = action.payload;
    },
  },
});

// Export reducer and actions
export default slice.reducer;
export const {
  VulnerabilitiesExportSuccess,
  exportPushlishedVulnerabilitySuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export const getConsultantOrganizationList = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientScanApiEndpoint}/consultant-org/list`
      );
      dispatch(
        slice.actions.getConsultantOrganizationListSuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteScan = (payload: any) => {
  return async () => {
    dispatch(setLoading(true));
    try {
      // const response = await axios.delete(`${clientScanApiEndpoint}`, {
      //   params: payload,
      //   // data: payload,
      // });
      const response = await axios.request({
        url:clientScanApiEndpoint,
        method: "DELETE",
        data: payload,
      });
      dispatch(slice.actions.deleteScanSuccess(response.data));
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityList = (
  params: Record<string, unknown>
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/published/${params?.scanId}`,
        {
          params,
        }
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityListSuccess(response.data)
      );
      dispatch(slice.actions.getSelectedPublishedVulnerabilitySuccess({}));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedPublishedVulnerability = (svId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/scan-vul/${svId}`
      );
      dispatch(
        slice.actions.getSelectedPublishedVulnerabilitySuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getRescanSavedVulnerabilityList = (
  params: Record<string, unknown>
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/saved-list/${params?.scanId}`,
        {
          params,
        }
      );
      dispatch(
        slice.actions.getRescanSavedVulnerabilityListSuccess(response.data)
      );
      dispatch(slice.actions.getSelectedRescanVulnerabiitySuccess({}));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addRescanVulnerabilities = (scanId: number, payload: unknown) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${clientVulnerabilityApiEndpoint}/add/${scanId}`,
        payload
      );
      dispatch(slice.actions.addRescanVulnerabilitiesSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getVulnerabilityList = (params: Record<string, unknown>) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/list/${params.scanId}`,
        { params }
      );
      dispatch(slice.actions.getVulnerabilitiesListSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteRescanSavedVulnerability = (
  scanId: number,
  payload: any
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      // const response = await axios.delete(
      //   `${clientVulnerabilityApiEndpoint}/re-scan/${scanId}`,
      //   {
      //     // data: payload,
      //     params: payload,
      //   }
      // );
      const response = await axios.request({
        url: `${clientVulnerabilityApiEndpoint}/re-scan/${scanId}`,
        method: "DELETE",
        data: payload,
      });
      dispatch(
        slice.actions.deleteRescanSavedVulnerabilitySuccess(response.data)
      );
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleSubmitScanToConsultant = (
  scanId: number,
  description?: string
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${clientScanApiEndpoint}/submit-scan/${scanId}`,
        { commentDesc: description }
      );
      dispatch(
        slice.actions.updateSubmitScanToConsultantSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getPublishedVulnerabilityEvidenceListSuccess = (
  vulnerabilityId: number | string
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientEvidenceApiEndpoint}/evidence-list/${vulnerabilityId}`
      );
      dispatch(
        slice.actions.getPublishedVulnerabilityEvidenceListSuccess(
          response.data
        )
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getDownloadSelectedPublishedVulEvidence = (
  vulnerabilityEvidenceId: number
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientEvidenceApiEndpoint}/download/${vulnerabilityEvidenceId}`
      );
      dispatch(
        slice.actions.getDownloadSelectedPublishedVulEvidenceSuccess(
          response.data
        )
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getSelectedRescanVulnerability = (cvId: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientVulnerabilityApiEndpoint}/${cvId}`
      );
      dispatch(
        slice.actions.getSelectedRescanVulnerabiitySuccess(response.data)
      );
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleRescanVulnerabilityStatus = (
  scanId: number,
  payload: unknown
) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${clientVulnerabilityApiEndpoint}/update/${scanId}`,
        payload
      );
      dispatch(
        slice.actions.updateRescanVulnerabilityStatusSuccess(response.data)
      );
      notificationManager(response);
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchAgeBuckets = (params: Record<string, unknown>) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientReportsApiEndpoint}/vulnerability/age-buckets`,
        { params }
      );
      dispatch(slice.actions.fetchAgeBucketsSuccess(response.data));
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const VulnerabilitiesExport = (params: Record<string, unknown>) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(
        `${clientReportsApiEndpoint}/vulnerability/age-buckets`,
        { params }
      );
      dispatch(slice.actions.VulnerabilitiesExportSuccess(response.data));
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getScanStatusFilterList = () => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientScanApiEndpoint}/scan-status/list`
        : `consultant/v1/scan/all-status`;
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getScanStatusFilterListSuccess(response.data));
      return response;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const exportPushlishedVulnerability = (
  params: Record<string, unknown>
) => {
  return async () => {
    dispatch(setLoading(true));

    const url =
      localStorage.getItem("organizationType") === "CL"
        ? `${clientVulnerabilityApiEndpoint}/published/export`
        : `${consultantEndpointApi}/published/vul/list/export`;
    try {
      const response = await axios.get(url, {
        params,
      });
      dispatch(
        slice.actions.exportPushlishedVulnerabilitySuccess(response.data)
      );
      notificationManager(response);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(errorMessage));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
