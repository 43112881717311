// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { TEST_PLAN_MASTER_TYPE } from "../../constant";
import { isLoading, setLoading } from "../../store/slices/user";
import { notificationManager } from "../../ui-component/notificationManager";
import axios from "../../utils/axios";
import { dispatch } from "../index";
import { openSnackbar, SnackbarState } from "./snackbarReducer";
import { FetchParams } from "./reducerTypes";

// ----------------------------------------------------------------------

const consultantApiEndpoint = `/consultant/v1/tc-master`;

interface State {
  error: string | null;
  allTypes: any[];
  owaspList: any[];
  masterTestList: any[];
  serviceTypes: any[];
  testPlanName: any[];
  deleteTestPlan: any[];
  updateOrderById: any[];
  activeAndDeactive: boolean | null;
  owaspCategoryMasterId: string;
  addNewTestPlans: string;
  serviceTypeId: string;
  activeCount: string;
  deactiveCount: string;
}

const initialState: State = {
  error: null,
  allTypes: [],
  owaspList: [],
  masterTestList: [],
  serviceTypes: [],
  testPlanName: [],
  deleteTestPlan: [],
  updateOrderById: [],
  activeAndDeactive: null,
  owaspCategoryMasterId: "",
  addNewTestPlans: "",
  serviceTypeId: "",
  activeCount: "",
  deactiveCount: "",
};

const slice = createSlice({
  name: "masterTestPlan",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    //--------------------- GET APIs --------------------------------

    // GET CONFIRM SCAN
    getAllTypesSuccess(state, action) {
      state.allTypes = action.payload;
    },
    // GET AllOwaspSuccess
    getAllOwaspList(state, action) {
      state.owaspList = action.payload;
    },
    // GET getAllServiceTypes
    getAllServiceTypes(state, action) {
      state.serviceTypes = action.payload;
    },
    // GET getMasterTestList
    getMasterTestList(state, action) {
      state.masterTestList = action.payload;
    },
    // GET GetTestPlanName
    getTestPlanName(state, action) {
      state.testPlanName = action.payload;
    },
    deleteTestPlanSuccess(state, action) {
      state.deleteTestPlan = action.payload;
    },
    updateOrderById(state, action) {
      state.updateOrderById = action.payload;
    },
    activeAndDeactiveSuccess(state, action) {
      state.activeAndDeactive = action.payload;
    },
    addNewTestPlan(state, action) {
      state.addNewTestPlans = action.payload;
    },
    setServiceTypeId(state, action) {
      state.serviceTypeId = action.payload;
    },
    setOwaspCategoryMasterId(state, action) {
      state.owaspCategoryMasterId = action.payload;
    },
    setActiveCount(state, action) {
      state.activeCount = action.payload;
    },
    setDeactiveCount(state, action) {
      state.deactiveCount = action.payload;
    },
  },
});
export const {
  // downloadScanSuccess,
  // getConfirmScanSuccess,
  setServiceTypeId,
  setOwaspCategoryMasterId,
  setActiveCount,
  setDeactiveCount,
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const manageSelectedScanDetailsTab = (tab: any) => {
  return async () => {
    dispatch(setLoading(true));
    // dispatch(slice.actions.manageSelectedScanDetailsTab(tab));
    try {
      console.log(tab);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAllTypes = () => {
  return async () => {
    dispatch(setLoading(true));

    dispatch(isLoading(true));
    try {
      const response = await axios.get(`${consultantApiEndpoint}/tc-types`);
      dispatch(slice.actions.getAllTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const getAllServiceTypes = () => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${consultantApiEndpoint}/service-type`);
      dispatch(slice.actions.getAllServiceTypes(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getAllOwasp = (payload: FetchParams) => {
  return async () => {
    const url =
      payload?.testPlanTypeCode === TEST_PLAN_MASTER_TYPE?.OWASP_CATEGORY
        ? `${consultantApiEndpoint}/tc-owasp?serviceType=${payload?.serviceId}`
        : `${consultantApiEndpoint}/tc-sans?serviceType=${payload?.serviceId}`;

    dispatch(setLoading(true));

    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getAllOwaspList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMasterTestListAPI = (payload: any) => {
  return async () => {
    const apiEndpoints: any = {
      OWASP_CATEGORY: `${consultantApiEndpoint}/owasp-list/${payload?.serviceType}/${payload?.id}`,
      SANS_25: `${consultantApiEndpoint}/sans-list/${payload?.serviceType}/${payload?.id}`,
      MISCELLANEOUS: `${consultantApiEndpoint}/miscellaneous-list/${payload?.serviceType}`,
    };

    dispatch(setLoading(true));
    const apiUrl = apiEndpoints[payload?.testPlanTypeCode];
    try {
      const response = await axios.get(apiUrl);
      dispatch(slice.actions.getMasterTestList(response.data)); // Renamed action for clarity
    } catch (error) {
      dispatch(slice.actions.hasError(error)); // Renamed action for clarity
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getChangeTestPlanName = (testPlanId: any, payload: any) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantApiEndpoint}/update-test-case-name/${testPlanId}?testCaseName=${payload}`
      );
      dispatch(slice.actions.getTestPlanName(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changeOrderBy = (testPlanId: number, orderByID: number) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantApiEndpoint}/change-order/${testPlanId}?orderBy=${orderByID}`
      );
      dispatch(slice.actions.updateOrderById(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const activeAndDeactive = (boolean: boolean | any, payload: any) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${consultantApiEndpoint}/activate?activate=${boolean}`,
        {
          idList: payload,
        }
      );
      dispatch(slice.actions.activeAndDeactiveSuccess(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addNewTestPlan = (payload: any) => {
  return async () => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${consultantApiEndpoint}`, payload);
      dispatch(slice.actions.addNewTestPlan(response.data));
      notificationManager(response);
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(setLoading(false));
      dispatch(isLoading(false));
    }
  };
};

export const deleteTestPlanMaster = (payload: any) => {
  return async () => {
    dispatch(setLoading(true));
    dispatch(isLoading(true));
    try {
      // const response = await axios.delete(`${consultantApiEndpoint}`, {
      //   data: payload,
      // });
      const response = await axios({
        method: "delete",
        url: `${consultantApiEndpoint}`,
        data: payload,
      });
      dispatch(slice.actions.deleteTestPlanSuccess(response.data));
      notificationManager(response);
      return response;
    } catch (error) {
      dispatch(openSnackbar(error as SnackbarState));
      dispatch(slice.actions.hasError(error));
      return error;
    } finally {
      dispatch(isLoading(false));
      dispatch(setLoading(false));
    }
  };
};
