import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { dispatch, RootState } from "../../../../../../store";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { NO_VALUE, USER_ROLES } from "../../../../../../constant";
import {
  getScanDetails,
  getSecurityManagerList,
  getTesterList,
  updateScan,
  updateExpectedDate,
  updateReportedPublishedDate,
} from "../../../../../../store/slices/scanReducer";
import { handleUserRoles } from "../../../../../../utils/helpers";
import { ScanDetailsProps } from "../../scanTypes";
interface DateFilter {
  startDate: string | null;
  endDate: string | null;
}

const ScanDetails: React.FC<ScanDetailsProps> = (props) => {
  const location = useLocation()?.pathname?.split("/")[1];
  const params: any = useParams();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { securityManagerList } = useSelector(
    (state: any) => state?.scansState
  );
  const [expectedStartDate, setExpectedStartDate] = useState(false);
  const [expectedEndDate, setExpectedEndDate] = useState(false);
  const [reportPublishedDateEdit, setReportPublishedDateEdit] = useState(false);
  const [reportPublishedDate, setReportPublishedDate] = useState<dayjs.Dayjs | null>(null);
  const { testerList } = useSelector((state: any) => state?.scansState);
  const [testerName, setTesterName] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    undefined
  );
  const [securityManagerListName, setSecurityManagerListName] = useState<any[]>(
    []
  );
  const clientOrgId: number | any = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state: any) => state?.menu);
  const [dateFilter, setDateFilter] = useState<DateFilter>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (props?.scanDetails) {
      setDateFilter({
        startDate: props.scanDetails.expectedStartDate
          ? dayjs(props.scanDetails.expectedStartDate).format("YYYY-MM-DD")
          : null,
        endDate: props.scanDetails.expectedEndDate
          ? dayjs(props.scanDetails.expectedEndDate).format("YYYY-MM-DD")
          : null,
      });
    }
  }, [props?.scanDetails]);

  const scanTypeName = props?.scanDetails?.scanTypeName;
  const IsScanVisible = [
    "Greybox",
    "Blackbox",
    "Secure Code Review",
    "VAPT",
  ].includes(scanTypeName);
  const scanType = props?.scanDetails?.scanTypeName;
  const displayValue =
    scanType === "Greybox" ||
    scanType === "Blackbox" ||
    scanType === "VAPT" ||
    scanType === "Secure Code Review"
      ? "N.A"
      : props?.scanDetails?.isCredential
      ? "Yes"
      : "No";

  useEffect(() => {
    if (props?.scanDetails) {
      if (handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)) {
        dispatch(getTesterList(Number(props?.scanDetails?.clientOrgId)));
      } else {
        if (userRoles?.length > 0) {
          dispatch(
            getSecurityManagerList(Number(props?.scanDetails?.clientOrgId))
          );
        }
      }
      setSelectedUser(
        handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
          ? props?.scanDetails?.testerId
          : props?.scanDetails?.smId
      );
    }
  }, [props?.scanDetails, userRoles]);
  useEffect(() => {
    const list =
      securityManagerList?.length &&
      securityManagerList?.map((item: any) => {
        return {
          ...item,
          name: `${item?.firstName} ${item?.lastName} (${item?.loginName})`,
        };
      });
    setSecurityManagerListName(list || []);
  }, [securityManagerList]);
  useEffect(() => {
    const list =
      testerList?.length &&
      testerList?.map((item: any) => {
        return {
          ...item,
          name: `${item?.firstName} ${item?.lastName} (${item?.loginName})`,
        };
      });
    setTesterName(list || []);
  }, [testerList]);
  const handleEdit = () => {
    if (isEdit) {
      dispatch(
        updateScan(
          {
            scanId: params?.scanId,
            consultantId: selectedUser,
            clientOrgId: props?.scanDetails?.clientOrgId,
          },
          "tester"
        )
      ).then(() => {
        dispatch(getScanDetails(params?.scanId, clientOrgId));
      });
    }
    setIsEdit(!isEdit);
  };

  const handleExpectedDateUpdate = (type: string) => {
    if (type === "reportPublishedDate") {
      const payload = {
        scanId: parseInt(params?.scanId),
        clientOrgId: props?.scanDetails?.clientOrgId,
        reportPublishedDate: reportPublishedDate
          ? moment(reportPublishedDate?.toDate()).format("YYYY-MM-DD")
          : "",
      };
      if (reportPublishedDateEdit) {
        dispatch(updateReportedPublishedDate(payload)).then(() => {
          dispatch(getScanDetails(params?.scanId, clientOrgId));
        });
      }
      setReportPublishedDateEdit(!reportPublishedDateEdit);
      return;
    } else {
      const payload = {
        scanId: parseInt(params?.scanId),
        consultantId: selectedUser,
        clientOrgId: props?.scanDetails?.clientOrgId,
        expectedStartDate: dateFilter?.startDate,
        expectedEndDate: dateFilter?.endDate,
      };
      if (
        (type === "startDate" && expectedStartDate) ||
        (type === "endDate" && expectedEndDate)
      ) {
        dispatch(updateExpectedDate(type, payload)).then(() => {
          dispatch(getScanDetails(params?.scanId, clientOrgId));
        });
      }

      if (type === "startDate") {
        setExpectedStartDate(!expectedStartDate);
      } else {
        setExpectedEndDate(!expectedEndDate);
      }
    }
  };

  const handleChange = (e: any, value: any) => {
    setSelectedUser(value?.userId);
  };

  const handleChangeDateValue = (
    field: keyof DateFilter,
    date: dayjs.Dayjs | null
  ) => {
    const newDateFilter: DateFilter = { ...dateFilter };
    newDateFilter[field] = date ? date.format("YYYY-MM-DD") : null;
    // // If end date is not selected, default it to start date
    // if (field === "startDate" && !newDateFilter.endDate && date) {
    //   newDateFilter.endDate = date.format("YYYY-MM-DD");
    // }
    // // If start date is not selected, default it to end date
    // if (field === "endDate" && !newDateFilter.startDate && date) {
    //   newDateFilter.startDate = date.format("YYYY-MM-DD");
    // }
    setDateFilter(newDateFilter);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{
                "& td": {
                  borderBottom: "none",
                  padding: "4px 4px 4px", // Adjust the padding here
                },
              }}
              size="small"
            >
              <TableBody>
                {/* First Row */}
                <TableRow>
                  <TableCell variant="head" size="small">
                    Type
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanTypeName}</TableCell>

                  <TableCell variant="head">Sub Type</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.scanSubTypeName || NO_VALUE}
                  </TableCell>

                  {!IsScanVisible && (
                    <>
                      <TableCell variant="head">Credential Scan</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>{displayValue}</TableCell>
                    </>
                  )}
                </TableRow>

                {/* Second Row */}
                <TableRow>
                  <TableCell variant="head">Created On</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    {props?.scanDetails?.createdOn &&
                      moment(props.scanDetails?.createdOn)?.format(
                        "Do MMM YYYY"
                      )}
                  </TableCell>
                  <TableCell variant="head">Expected Start Date</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      {expectedEndDate ? (
                        <Box sx={{ width: "100%" }}>
                          <>
                            <Grid item>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  sx={{ overflow: "hidden" }}
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    // label="From Date"
                                    format="DD/MM/YYYY"
                                    value={
                                      props?.scanDetails?.expectedStartDate
                                        ? dayjs(
                                            props?.scanDetails
                                              ?.expectedStartDate
                                          )
                                        : null
                                    }
                                    // minDate={dayjs(dateFilter?.startDate)}
                                    onChange={(d) =>
                                      handleChangeDateValue("startDate", d)
                                    }
                                    slotProps={{
                                      textField: {
                                        variant: "standard",
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </>
                        </Box>
                      ) : (
                        <TableCell>
                          {props?.scanDetails?.expectedStartDate &&
                            moment(
                              props.scanDetails?.expectedStartDate
                            )?.format("Do MMM YYYY")}
                        </TableCell>
                      )}
                      {/* {
                        <Box>
                          <>
                            <Button
                              onClick={() =>
                                handleExpectedDateUpdate("startDate")
                              }
                            >
                              {!expectedStartDate ? <EditIcon /> : <SaveIcon />}
                            </Button>
                            {expectedStartDate && (
                              <Button
                                onClick={() =>
                                  setExpectedStartDate(!expectedStartDate)
                                }
                              >
                                <CloseIcon />
                              </Button>
                            )}
                          </>
                        </Box>
                      } */}
                    </Stack>
                  </TableCell>
                  <TableCell variant="head">Expected End Date</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <TableCell>
                      <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        {expectedEndDate ? (
                          <Box sx={{ width: "90%" }}>
                            <>
                              <Grid item>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    sx={{ overflow: "hidden" }}
                                    components={["DatePicker", "DatePicker"]}
                                  >
                                    <DatePicker
                                      // label="From Date"
                                      format="DD/MM/YYYY"
                                      value={
                                        props?.scanDetails?.expectedEndDate
                                          ? dayjs(
                                              props?.scanDetails
                                                ?.expectedEndDate
                                            )
                                          : null
                                      }
                                      // minDate={dayjs(dateFilter?.endDate)}
                                      onChange={(d) =>
                                        handleChangeDateValue("endDate", d)
                                      }
                                      slotProps={{
                                        textField: {
                                          variant: "standard",
                                        },
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                            </>
                          </Box>
                        ) : (
                          <TableCell>
                            {props?.scanDetails?.expectedEndDate &&
                              moment(
                                props.scanDetails?.expectedEndDate
                              )?.format("Do MMM YYYY")}
                          </TableCell>
                        )}
                        {
                          <Box sx={{ display: "flex" }}>
                            <>
                              <Button
                                onClick={() =>
                                  handleExpectedDateUpdate("endDate")
                                }
                              >
                                {!expectedEndDate ? (
                                  <Tooltip title="Edit" arrow>
                                    <EditIcon />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Save" arrow>
                                    <SaveIcon />
                                  </Tooltip>
                                )}
                              </Button>
                              {expectedEndDate && (
                                <Tooltip title="Close" arrow>
                                  <Button
                                    onClick={() =>
                                      setExpectedEndDate(!expectedEndDate)
                                    }
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          </Box>
                        }
                      </Stack>
                    </TableCell>
                  </TableCell>
                </TableRow>

                {/* Third Row */}
                <TableRow>
                  <TableCell variant="head">Status</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.status}</TableCell>
                  <TableCell variant="head">Mode</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanModeDesc}</TableCell>

                  <TableCell variant="head">Category</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.scanCategoryDesc}</TableCell>
                </TableRow>

                {/* Fourth Row */}
                <TableRow>
                  <TableCell variant="head">Created By</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.createdByName}</TableCell>
                  <TableCell variant="head">
                    {handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
                      ? "Security Manager"
                      : "Tester"}
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      <Box>
                        {handleUserRoles(
                          userRoles,
                          USER_ROLES?.CO_SECURITY_MANAGER
                        )
                          ? props?.scanDetails?.smName
                          : props?.scanDetails?.testerName}
                      </Box>
                    </Stack>
                  </TableCell>

                  <TableCell variant="head">
                    {handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER)
                      ? "Tester"
                      : "Security Manager"}
                  </TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      {isEdit ? (
                        <Box sx={{ width: "90%" }}>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          ) ? (
                            <>
                              <Grid item>
                                <Autocomplete
                                  fullWidth
                                  options={testerName}
                                  getOptionLabel={(option) => option.name}
                                  defaultValue={() =>
                                    testerName.find(
                                      (i) => i.userId === selectedUser
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} variant="standard" />
                                  )}
                                  // name="testerId"
                                  onChange={(e, value) =>
                                    handleChange(e, value)
                                  }
                                  clearIcon={false}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>{props?.scanDetails?.smName}</>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          )
                            ? props?.scanDetails?.testerName
                            : props?.scanDetails?.smName}
                        </Box>
                      )}
                      {props?.scanDetails?.updateTesterAllowed && (
                        <Box>
                          {handleUserRoles(
                            userRoles,
                            USER_ROLES?.CO_SECURITY_MANAGER
                          ) && (
                            <>
                              <Button onClick={handleEdit}>
                                {!isEdit ? <EditIcon /> : <SaveIcon />}
                              </Button>
                              {isEdit && (
                                <Button onClick={() => setIsEdit(!isEdit)}>
                                  <CloseIcon />
                                </Button>
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
                {/* Fifth Row */}
                <TableRow>
                  {props?.scanDetails?.parentScanName && (
                    <>
                      <TableCell variant="head">Parent Scan Name</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {props?.scanDetails?.parentScanName}
                      </TableCell>
                    </>
                  )}
                  {props?.scanDetails?.parentScanRefNo && (
                    <>
                      <TableCell variant="head">Parent Scan Ref Code</TableCell>
                      <TableCell>:</TableCell>
                      <TableCell>
                        {props?.scanDetails?.parentScanRefNo}
                      </TableCell>
                    </>
                  )}
                  <TableCell variant="head">Department</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>{props?.scanDetails?.departmentName}</TableCell>

                  <TableCell variant="head">Report Publish Date</TableCell>
                  <TableCell>:</TableCell>
                  <TableCell>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      {reportPublishedDateEdit ? (
                        <Box sx={{ width: "100%" }}>
                          <>
                            <Grid item>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  sx={{ overflow: "hidden" }}
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    // label="From Date"
                                    format="DD/MM/YYYY"
                                    value={
                                      props?.scanDetails?.reportPublishedDate
                                        ? dayjs(
                                            props?.scanDetails
                                              ?.reportPublishedDate
                                          )
                                        : null
                                    }
                                    // minDate={dayjs(dateFilter?.startDate)}
                                    onChange={(d) => setReportPublishedDate(d)}
                                    slotProps={{
                                      textField: {
                                        variant: "standard",
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </Grid>
                          </>
                        </Box>
                      ) : (
                        <TableCell>
                          {props?.scanDetails?.reportPublishedDate &&
                            moment(
                              props?.scanDetails?.reportPublishedDate
                            )?.format("Do MMM YYYY")}
                        </TableCell>
                      )}
                      {
                        <Box sx={{ display: "flex" }}>
                          <>
                            <Button
                              onClick={() =>
                                handleExpectedDateUpdate("reportPublishedDate")
                              }
                            >
                              {!reportPublishedDateEdit ? (
                                <Tooltip title="Edit" arrow>
                                  <EditIcon />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Save" arrow>
                                  <SaveIcon />
                                </Tooltip>
                              )}
                            </Button>
                            {reportPublishedDateEdit && (
                              <Button
                                onClick={() =>
                                  setReportPublishedDateEdit(
                                    !reportPublishedDateEdit
                                  )
                                }
                              >
                                <CloseIcon />
                              </Button>
                            )}
                          </>
                        </Box>
                      }
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ScanDetails;
