import React, { useState } from "react";
import { PreviewAttachmentList } from "./PreviewAttachmentList";
import { Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import { userActionAccess } from "../../../../../../utils/helpers";
import { ACTION_TYPE } from "../../../../../../constant";
import { CommentProps } from "../../scanTypes";
import theme from "src/themes/theme";

const Comment: React.FC<CommentProps> = ({
  item,
  handleManageDeleteModel,
  setSelectedCommentId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const theme = useTheme();

  const avatarUrl =
    "https://cdn.iconscout.com/icon/free/png-512/free-avatar-370-456322.png?f=webp&w=512";

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Box component="img" src={avatarUrl} alt="User 1" height={40} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography
                  align="left"
                  // variant="h6"
                  component="div"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    fontFamily: "Inter, sans-serif",
                    lineHeight: "1.235",
                    textAlign: "left",
                    // color: "rgb(189, 200, 240)",
                  }}
                  // color={theme.palette.text.primary}
                >
                  {item?.commentedByName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    fontFamily: "Inter, sans-serif",
                    lineHeight: "1.235",
                    textAlign: "left",
                    // color: "#8492c4",
                  }}
                  // variant="subtitle1"
                  // color={theme.palette.text.secondary}
                >
                  {item?.createdOn &&
                    moment(item?.createdOn).format("- Do MMM YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Box direction={'row'} alignItems={'center'} gap={1}> */}
            <Box
              component="div"
              sx={{
                display: "flex", // Set the display to 'flex' to apply Flexbox properties
                flexDirection: "row", // Use 'flexDirection' to define the row direction
                alignItems: "center",
                gap: 1,
              }}
            >
              {" "}
              <IconButton onClick={() => setIsOpen(!isOpen)}>
                <AttachFileIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              {userActionAccess(ACTION_TYPE?.DEL_SCAN_CMT) ? (
                <IconButton
                  onClick={() => {
                    setSelectedCommentId(Number(item?.scanCommentId));
                    handleManageDeleteModel();
                  }}
                >
                  <DeleteIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* post - content */}
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          background: theme.palette.background.paper,
          borderRadius: "8px",
          my: 2,
          "& > p": {
            ...theme.typography.body1,
            color: theme.palette.text.primary,
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: item?.commentDesc }}></div>
      </Grid>

      <Grid item xs={12}>
        {isOpen && (
          <PreviewAttachmentList
            item={item}
            fieldName="scanCommentId"
            endPoint="scan"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Comment;
